import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import imgUser from "../assets/user.png";
import "../theme/HorariosView.css";

export const ListaEmpleadosHoras = (props) => {
  const { horarios, entrada, salida } = props;
  const patron = /^\d{2}:\d{2}$/;

  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [listEmpleados, setlistEmpleados] = useState(horarios);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setlistEmpleados(horarios);
  }, [horarios]);

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(listEmpleados.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };
  const getData = (current, pageSize) => {
    return listEmpleados.slice((current - 1) * pageSize, current * pageSize);
  };
  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };

  const handleClear = () => {
    setSearch("");
    setlistEmpleados(horarios);
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value;

    // Filtrar los resultados en función del término de búsqueda
    if (searchTerm) {
      const newData = listEmpleados.filter(function (item) {
        const itemData = item.fc_nombre_empleado
          ? item.fc_nombre_empleado.toUpperCase()
          : "".toUpperCase();
        const textData = searchTerm.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setlistEmpleados(newData);
      setSearch(searchTerm);
    } else {
      setlistEmpleados(horarios);
      setSearch(searchTerm);
    }
  };

  return (
    <div>
      <div className="top-list-horarios">
        <div className="icon-text-top-list-horarios">
          <img className="image-top-horarios" src={imgUser} alt="Empleado" />
          <div>Empleados</div>
        </div>
        <div className="content-box-filter">
          <input
            type="text"
            value={search}
            onChange={handleChange}
            placeholder="Buscar por nombre"
            className="input-buscador-horarios"
          />
          {search === "" ? (
            <div className="icon-filter-horarios">
              <SearchSharpIcon />
            </div>
          ) : (
            <button className="button-clean-horarios" onClick={handleClear}>
              <ClearSharpIcon />
            </button>
          )}
        </div>
      </div>

      <div>
        {getData(current, size).map((item) => (
          <div>
            <div
              className="item-content-list-horarios"
              key={item.fc_nombre_empleado}
            >
              <div className="item-name-list-data">
                <div className="item-name-list">{item.fc_nombre_empleado}</div>
                <div className="item-puesto-list">{item.fc_nombre_puesto}</div>
              </div>

              {patron.test(`${item[`${entrada}`]}`) ? (
                <div className="item-list-horarios">
                  <div className="item-hora-row">
                    <div className="item-hora-title">Entrada</div>
                    <div className="item-number-hora">
                      {`${item[`${entrada}`]}`}
                    </div>
                  </div>
                  <div className="item-hora-row">
                    <div className="item-hora-title">Salida</div>
                    <div className="item-number-hora">
                      {`${item[`${salida}`]}`}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="item-list-horarios">
                  <div className="item-hora-row">
                    <div className="item-number-text">
                      {`${item[`${entrada}`]}`}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="divider"></div>
          </div>
        ))}
      </div>
      <Pagination
        className="pagination-data"
        showTotal={(total, range) =>
          `Mostrando ${range[0]}-${range[1]} de ${total}`
        }
        onChange={PaginationChange}
        total={listEmpleados.length}
        current={current}
        pageSize={size}
        showSizeChanger={true}
        itemRender={PrevNextArrow}
        onShowSizeChange={PerPageChange}
      />
    </div>
  );
};
