import React from "react";
import "../theme/AdministracionCatalogo.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TableAdministracionTipoATB } from "../components/TableAdministracionTipoATB";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";

export const AdminPageTipoATB = () => {
  const navigate = useNavigate();

  return (
   <AzureAD provider={authProvider}>
  {({ authenticationState, accountInfo }) => {
  const isAuthenticated =
    authenticationState === AuthenticationState.Authenticated;

  if (isAuthenticated) {
    return (
      <div className="body-color-admin">
        <div className="title-name-admin">
          <div className="icon-goback-title-admin">
            <button
              className="button-goback-style"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon />
            </button>
            <div> Administración de Tipos de ATB´s </div>
          </div>
        </div>
        <TableAdministracionTipoATB />
      </div>
    );
    }
   }}
  </AzureAD>
  );
};