import { createContext } from "react";

export const AuthLoginContext = createContext({
  status: "idle",
  accessToken: null,
  expiration: null,
  uniqueId: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});
