import { useContext } from "react";
import { AuthLoginContext } from "../context/AuthLoginContext";

export function UseAuth() {
  const context = useContext(AuthLoginContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
