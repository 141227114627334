import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { fetchFormasPagoAdd } from "../Api/fetchFormasPagoAdd";
import { fetchFormasPagoEdith } from "../Api/fetchFormasPagoEdith";
import { InputSelect } from "./utils/InputSelect";

export const AdministrationFormasPagoForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  formasPagoUnicas,
  modalKey
}) => {
  const objDefault = {
    FIMARCAID: "",
    FCNOMBRE: "",
    FIFORMAPAGOID: "0",
    FIESTATUS: 1,
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FCNOMBRE: selectedItem.FCNOMBRE,
        FIFORMAPAGOID: selectedItem.FIFORMAPAGOID,
        FIESTATUS: selectedItem.FIESTATUS,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: "",
        FCNOMBRE: "",
        FIFORMAPAGOID: "0",
        FIESTATUS: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
        if (formData.FIMARCAID === "") {
          errors.FIMARCAID = "Seleciona la marca";
        } 
        if (formData.FIFORMAPAGOID === "") {
          errors.FIFORMAPAGOID = "Seleciona forma de pago";
        }
        if(formData.FIFORMAPAGOID === "0"){
          if (formData.FCNOMBRE === "") {
            errors.FCNOMBRE = "Ingresa el nombre";
          }
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors("");
        } 
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };

      const onSuccess = ({ data }) => {
        setLoading(false)
        //console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      const nameForma = formasPagoUnicas.find(
        (it) => it.FIFORMAPAGOID === parseInt(formData.FIFORMAPAGOID)
      );

      //console.log("nameForma", nameForma);

      const formDataToSend = {
        FIMARCAID: parseInt(formData.FIMARCAID),
        FCNOMBRE:
          formData.FIFORMAPAGOID === "0"
            ? formData.FCNOMBRE
            : nameForma.FCNOMBRE,
        FIFORMAPAGOID: parseInt(formData.FIFORMAPAGOID),
        FIESTATUS: formData.FIESTATUS.toString(),
      };
      //console.log("formDataToSend", formDataToSend);
      if (selectedItem) {
        fetchFormasPagoEdith(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      } else {
        fetchFormasPagoAdd(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      }
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Forma pago
        </div>
      </Col>
      <Col xs={24} md={22}>
        <label className="text-context-inputs">Formas de pago:</label>
        <select
          className="input-content"
          value={formData.FIFORMAPAGOID}
          onChange={handleChange}
          name={"FIFORMAPAGOID"}
          disabled={selectedItem ? true : false}
        >
          <option value="0">Nueva forma de pago</option>
          {formasPagoUnicas.map((option) => (
            <option key={option.FIFORMAPAGOID} value={option.FIFORMAPAGOID}>
              {option.FIFORMAPAGOID}-{option.FCNOMBRE}
            </option>
          ))}
        </select>
        {errors && <div className="error-input-data">{errors.FIFORMAPAGOID}</div>}
      </Col>

      {formData.FIFORMAPAGOID === "0" && (
        <Col xs={24} md={22}>
          <InputForm
            handleChange={handleChange}
            value={formData.FCNOMBRE}
            name={"FCNOMBRE"}
            type={"text"}
            label={"Nombre"}
          />
           {errors && <div className="error-input-data">{errors.FCNOMBRE}</div>}
        </Col>
      )}
      <Col xs={24} md={22}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
        {errors && <div className="error-input-data">{errors.FIMARCAID}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
