import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { AddFetchRol } from "../Api/Administracion/AddFetchRol";
import { PutFetchVersion } from "../Api/Administracion/PutFetchVersion";

export const AdministrationVersionAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  modalKey
}) => {
  
  const objDefault = {
    FCNOMBRE: "",
    FCVALOR: "",
    FCTIPOMEDIDA: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FCNOMBRE: selectedItem.FCNOMBRE,
        FCTIPOMEDIDA: selectedItem.FCTIPOMEDIDA,
        FCVALOR: selectedItem.FCVALOR,
      };
      setFormData(newObj);
    }
  }, [selectedItem,modalKey]);
  
  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCNOMBRE === "") {
      errors.FCNOMBRE = "Ingresa el valor de nombre";
    } 
    if (formData.FCVALOR === "") {
      errors.FCVALOR = "Ingresa el valor de version";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }

    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        setLoading(false)
        //console.log("error", error);
        alert(error);
      };

      const onSuccess = ({ data }) => {
        setLoading(false)
        //console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      if (selectedItem) {
        PutFetchVersion(formData).then(onSuccess).catch(onFailure);
      }
    }
      
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Versión
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBRE}
          name={"FCNOMBRE"}
          type={"text"}
          label={"Nombre"}
        />
         {errors && <div className="error-input-data">{errors.FCNOMBRE}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCVALOR}
          name={"FCVALOR"}
          type={"text"}
          label={"Versión"}
        />
        {errors && <div className="error-input-data">{errors.FCVALOR}</div>}
      </Col>
      <div className="modal-footer-edith">
       <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
