import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "../theme/Comunicados.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../context/authProvider";
import { UseUser, UseTokenGlobal } from "../hooks";
import { fetchAdmin } from "../Api/fetchAdmin";
import { LoadingSpinner } from "../components/LoadingSpinner";

export const ComunicadosPage = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [comunicados, setComunicados] = useState([]);
  const { company, ceco, jobTitle } = UseUser();
  const { tokenProd } = UseTokenGlobal();

  useEffect(() => {
    getDataBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, ceco, jobTitle]);

  const getDataBanner = async () => {
    if (
      company != null &&
      ceco != null &&
      jobTitle != null 
    ) {
      const onFailure = ({ error, config }) => {
        console.log(error);

        setLoading(false);
      };

      const onSuccess = async ({ data, config }) => {
        setComunicados(data.body.comunicados);
        setLoading(false);
      };

      fetchAdmin(company, ceco, jobTitle, tokenProd)
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="body-color">
              <div className="title-name-comunicados">
                <div className="icon-goback-title-comunicados">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div>Comunicados</div>
                </div>
              </div>
              <div>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    {comunicados.map((item) => (
                      <div
                        className="container-item-comunicados"
                        key={item.FICOMUNICADOID}
                      >
                        <h3>{item.FCTITULO} </h3>
                        <div>{item.FCDESCRIPCION} </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
