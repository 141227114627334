
const attachInterceptor = (axiosInstance, refreshToken, nameId) => { //nameId es el identificador de la bandera para este metodo
  axiosInstance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      //const bandera en  en async store llamada nameId
      const bandera = (localStorage.getItem(nameId)) === 'true';
      console.log("bandera",bandera)
      if (error.response && error.response.status === 401 && !bandera) { 
        try {
          //si es false la bandera, entra aqui y hace un set a true, para que no vuelva a entrar
          //el set a true me refiero a el local storage
          // set true
          localStorage.setItem(nameId, 'true');
          const nuevoToken = await refreshToken();
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${nuevoToken}`;
          error.config.headers['Authorization'] = `Bearer ${nuevoToken}`;
          return axiosInstance(error.config);  // Reintentar la solicitud con el nuevo token
        } catch (errorRenovacion) {
          console.error("Error al renovar el token:", errorRenovacion);
          return Promise.reject(errorRenovacion);
        }
      }
      //borramos la bandera, para que no la encuentre a la siguiente vez que haya error 401
      //revisar variable cuando no existe que es lo que trae y no irnos con finta de que null/undefined es false
      localStorage.removeItem(nameId);
      return Promise.reject(error);
    }
  );
};

export default attachInterceptor;