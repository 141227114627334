import "./App.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import { authProvider } from "./context/authProvider";
import { Header } from "./components/Header";
//import { HomePage } from "./pages/HomePage";
import { LoadingPage } from "./pages/LoadingPage";
//import { LoginPage } from "./pages/LoginPage";
//import { HubPage } from "./pages/HubPage";
import { AppWraper } from "./AppWraper";
//import { HomePage } from "./pages/HomePage";
import { UnAuthenticatedRouter } from "./router/UnAuthenticatedRouter";

function App() {
  return (
    <div className="App">
      <Header />

      <AzureAD provider={authProvider}>
        {({ authenticationState }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return <AppWraper />;
            case AuthenticationState.Unauthenticated:
              return <UnAuthenticatedRouter />;
            case AuthenticationState.InProgress:
              return <LoadingPage />;
            default:
              return;
          }
        }}
      </AzureAD>
    </div>
  );
}

export default App;
