/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  Button,
  Row,
  Col,
  Image,
  Input,
  Space,
  Modal,
  Switch,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { AdministrationBannerAddForm } from "./AdministrationBannerAddForm";
import { AdministrationBannerEdithUrl } from "./AdministrationBannerEdithUrl";
import { GetFetchBanner } from "../Api/Administracion/GetFetchBanner";
import { GetFetchRol } from "../Api/Administracion/GetFetchRol";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { PutFetchBanner } from "../Api/Administracion/PutFetchBanner";

export const TableAdministracionBanners = () => {
  const [data, setData] = useState([]);
  const [dataRol, setDataRol] = useState([]);
  const [dataMarca, setDataMarca] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalUrlEdith, setShowModalUrlEdith] = useState(false);
  const [modalKey, setModalKey] = useState(0);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };
      const onSuccess = ({ data }) => {
        //console.log("banners", data.body.data);
        setData(data.body.data);
        setIsLoading(false);
      };
      GetFetchBanner().then(onSuccess).catch(onFailure);
    
  };

  useEffect(() => {
    fetchDataRol();
    fetchDataMarca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataRol = async () => {

      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        console.log(data.body.data);
        setDataRol(data.body.data);
      };
      GetFetchRol(true).then(onSuccess).catch(onFailure);
    
  };

  const fetchDataMarca = async () => {
    
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleOpenModalUrlEdith = (dato) => {
    setSelectedItem(dato);
    setShowModalUrlEdith(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const HandleSwitch = ({ value, all }) => {
    const [hs, setHs] = useState(parseInt(value) === 1);
    const handleChangeSwitch = (v) => {
      setIsLoading(true)
      const newObj = {
        ...all,
        FCEDITARLIGA: false,
        FCURLS3: "",
        FIESTATUSID: v ? 1 : 0,
      };

      console.log("all", all);
      //peticion....
      const onFailure = (error) => {
        //console.log("error", error);
        setIsLoading(false)
        alert(error);
        
      };
      const onSuccess = ({ data }) => {
        setIsLoading(false)
        console.log("state", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      console.log("Nuevo objeto a enviar", newObj);
      PutFetchBanner(newObj).then(onSuccess).catch(onFailure);

      setHs(v);
    };
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };

  //table--->>
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      title: "ID Banner",
      dataIndex: "FIBANNERID",
      key: "FIBANNERID",
      sorter: (a, b) => parseInt(a.FIBANNERID) - parseInt(b.FIBANNERID),
      ...getColumnSearchProps("FIBANNERID"),
    },
    {
      title: "Imagen",
      dataIndex: "FCURLS3",
      key: "FCURLS3",
      render: (item) => (
        <Image src={`${item}?${new Date().getTime()}`} width={50} />
      ),
    },
    {
      title: "Editar Url",
      render: (item) => (
        <button
          onClick={() => handleOpenModalUrlEdith(item)}
          className="button-styles-detalais-catalogo"
        >
          <LinkIcon style={{ fontSize: 20 }} />
        </button>
      ),
    },
    {
      title: "Título",
      dataIndex: "FCTITULO",
      key: "FCTITULO",
      ...getColumnSearchProps("FCTITULO"),
    },
    {
      title: "Descripción",
      dataIndex: "FCDESCRIPCION",
      key: "FCDESCRIPCION",
    },
    {
      title: "Marca",
      dataIndex: "FCMARCAIDNOMBRE",
      key: "FCMARCAIDNOMBRE",
      ...getColumnSearchProps("FCMARCAIDNOMBRE"),
    },
    {
      title: "Rol",
      dataIndex: "FCROLIDNOMBRE",
      key: "FCROLIDNOMBRE",
      ...getColumnSearchProps("FCROLIDNOMBRE"),
    },
    {
      title: "Estatus",
      dataIndex: "FIESTATUSID",
      key: "FIESTATUSID",
      render: (item, all) => <HandleSwitch value={item} all={all} />,
      fixed: "right",
      width: "10%",
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"],
      onFilter: (value, record) => record.FIESTATUSID === Number(value),
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "10%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd} className="button-styles-add-element">
            Agregar Banner
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={(record) =>
              record.FIBANNERID + record.FCURLS3 + record.FCDESCRIPCION
            }
            columns={columns}
            loading={isLoading}
            scroll={{ x: 1200, y: 600 }}
          />
        </Col>
      </Row>

      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <AdministrationBannerAddForm
          selectedItem={selectedItem}
          fetchData={fetchData}
          setShowModal={setShowModal}
          marca={dataMarca}
          rol={dataRol}
          modalKey={modalKey}
        />
      </Modal>
      <Modal
        open={showModalUrlEdith}
        onCancel={() => setShowModalUrlEdith(false)}
        footer={null}
      >
        <AdministrationBannerEdithUrl
          selectedItem={selectedItem}
          fetchData={fetchData}
          setShowModalUrlEdith={setShowModalUrlEdith}
          marca={dataMarca}
          rol={dataRol}
          modalKey={modalKey}
        />
      </Modal>
    </>
  );
};
