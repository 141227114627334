import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { AddFetchMarca } from "../Api/Administracion/AddFetchMarca";
import { PutFetchMarca } from "../Api/Administracion/PutFetchMarca";

export const AdministrationMarcaAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  modalKey
}) => {

  const objDefault = {
    FIMARCAID: 0,
    FCNOMBRE: "",
    FIESTATUSID: 1,
    FIBIID: "",
    FCEQUIVALENCIA: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FCNOMBRE: selectedItem.FCNOMBRE,
        FIESTATUSID: selectedItem.FIESTATUSID,
        FIBIID: selectedItem.FIBIID,
        FCEQUIVALENCIA: selectedItem.FCEQUIVALENCIA,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: 0,
        FCNOMBRE: "",
        FIESTATUSID: 1,
        FIBIID: "",
        FCEQUIVALENCIA: "",
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCNOMBRE" && value.length > 30) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOMBRE: "El título no debe exceder 30 caracteres.",
      }));
      return;
    }
    if(name === "FIBIID"){
      const numericValue = value.replace(/[^0-9]/g, "");
        setFormData({ ...formData, [name]: numericValue.slice(0, 10) });
        setErrors((prevFormData) => ({
          ...prevFormData,
          FIBIID: "El FIBI solo debe contener digitos",
        }));
      return;
    }
    const parsedValue =
      name === "FIMARCAID" || name === "FIBIID" ? parseInt(value, 10) : value;
    setFormData({ ...formData, [name]: parsedValue });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCNOMBRE === "") {
      errors.FCNOMBRE = "Ingresa el valor de nombre";
    } 
    if (formData.FCEQUIVALENCIA === "") {
      errors.FCEQUIVALENCIA = "Ingresa el valor de equivalencia";
    }
    if (formData.FIBIID === "") {
      errors.FIBIID = "Ingresa el valor de fibi";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    } 

    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };

      const onSuccess = ({ data }) => {
        setLoading(false)
        // console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      const newObj = {
        ...formData,
        FIBIID: parseInt(formData.FIBIID),
      };

      if (selectedItem) {
        PutFetchMarca(newObj).then(onSuccess).catch(onFailure);
      } else {
        AddFetchMarca(newObj).then(onSuccess).catch(onFailure);
      }
    }
    
      
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Marca
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBRE}
          name={"FCNOMBRE"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCNOMBRE}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCEQUIVALENCIA}
          name={"FCEQUIVALENCIA"}
          type={"text"}
          label={"Equivalencia"}
        />
        {errors && <div className="error-input-data">{errors.FCEQUIVALENCIA}</div>}
      </Col>

      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FIBIID}
          name={"FIBIID"}
          type={"number"}
          label={"FIBI"}
        />
        {errors && <div className="error-input-data">{errors.FIBIID}</div>}
      </Col>

      <div className="modal-footer-edith">
         <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
