import { createContext } from "react";

export const UserContext = createContext({
  status: "idle",
  mail: null,
  displayName: null,
  jobTitle: null,
  contry: null,
  ceco: null,
  company: null,
  userEnable: null,
  employeeId: null,
  device: null,
  usuario: null,
  userIn: () => Promise.resolve(),
  userOut: () => Promise.resolve(),
});
