import createAxiosInstance from "../LambdaApiAdministracion";
import { ENVIROMENT } from "../../utilities"

export async function PutFetchComunicados(body, accessToken) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/admon/comunicado`: `/home/admon/comunicado`
  const requestConfig = {
    method: "PUT", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: body // Aquí cambiamos 'body' por 'data'
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
