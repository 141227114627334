import createAxiosInstance from "../LambdaApiAtb";
import { ENVIROMENT } from "../../utilities";

export async function GetFetchMarcaAtb(useFIESTATUS) {
  const axiosInstance = await createAxiosInstance();
  const baseUrl = ENVIROMENT === "dev" ? `/atb/marcaatb` : `/atb/marcaatb`;
  const url = useFIESTATUS ? `${baseUrl}?FIESTATUS=1` : baseUrl;
  
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
