import React, { useState, useEffect } from "react";
import { UseTokenGlobal } from "../hooks";
import { AddFetchNotificaciones } from "../Api/Administracion/AddFetchNotificaciones";
import axios from "axios";
import { Row, Col, Select } from "antd";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import InputForm from "./utils/InputForm";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { GetFetchSucursalesByMarca } from "../Api/Administracion/GetFetchSucursalesByMarca"
import { blue } from "@mui/material/colors";

const dataDeepk = [
  {
    id: 1,
    name: "Home",
    stack: "Home",
  },
  {
    id: 2,
    name: "Cierre de día",
    stack: "Reclasificacion",
  },
  {
    id: 3,
    name: "Indicadores",
    stack: "Indicadores",
  },
  {
    id: 4,
    name: "Asistencia",
    stack: "Asistencia",
  },
  {
    id: 5,
    name: "Bitacora de horneo",
    stack: "HomeBitacora",
  },
  {
    id: 6,
    name: "Notificaciones",
    stack: "Notification",
  },
  {
    id: 7,
    name: "Hub del gerente",
    stack: "Hub",
  },
  {
    id: 8,
    name: "Terminales",
    stack: "TerminalesBancarias",
  }
];

export const TableAdministracionNotificaciones = () => {
  const [isLoadingSucursal, setIsLoadingSucursal] = useState(false);
  const [dataSucursal, setDataSucursales] = useState([]);
  const [dataMarca, setDataMarca] = useState([]);
  const objDefault = {
    countryId: 1,
    brandId: 0,
    title: "",
    message: "",
    url: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [branches, setbranches] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchDataMarca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataMarca = async () => {
   
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let maxLength = 0;

    // Determinar la longitud máxima permitida según el nombre del campo
    if (name === "title") {
      maxLength = 50;
    } else if (name === "message") {
      maxLength = 150;
    }

    // Verificar si el nombre del campo es 'titulo' o 'mensaje' y si el valor supera la longitud máxima permitida
    if ((name === "title" || name === "message") && value.length > maxLength) {
      // Si supera la longitud máxima, truncar el valor
      const truncatedValue = value.substring(0, maxLength);
      setFormData({ ...formData, [name]: truncatedValue });
    } else {
      // Si no es 'titulo' ni 'mensaje', o si no supera la longitud máxima, actualizar el estado normalmente
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangeSelec = (selectedValues) => {
    //console.log("selectedValues",selectedValues)
    if (selectedValues.includes("0")) {
      setSelectAll(true);
      setbranches(["0"]);
    } else {
      setSelectAll(false);
      setbranches(selectedValues.filter((value) => value !== "0"));
    }
  };

  const options = [
    { label: "Enviar a todas las sucursales", value: "0" },
    ...dataSucursal.map((option) => ({
      label: `${option.FISUCURSALID}-${option.FCNOMBRE}`,
      value: String(option.FISUCURSALID),
    })),
  ];

  useEffect(() => {
    fetchDataSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.brandId]);

  const fetchDataSucursal = async () => {
    if (formData.brandId !== 0) {
      setIsLoadingSucursal(true);
      const onFailure = (error) => {
        setIsLoadingSucursal(false);
      };
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataSucursales(data.body.data);
        setIsLoadingSucursal(false);
      };

      GetFetchSucursalesByMarca(formData.brandId).then(onSuccess).catch(onFailure);
    }
  };

  const sentNotificaciones = () => {
    //console.log("entro aqui")
   // console.log("branches",branches)
   // console.log("brandId",formData.brandId)
      const errors = [];
    if (formData.title === "") {
      errors.title = "Es necesario un  título para su notificación";
    } 
    if (formData.message === "") {
      errors.message = "Es necesario un mensaje para su notificación";
    }
    if(formData.brandId !==0){
      if (branches.length === 0) {
        errors.branches = "Es necesario selecionar una sucursal";
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
    } 
      const onFailure = (error) => {};

      const branchesArray =
        Array.isArray(branches) && branches.length > 0
          ? branches.map((str) => parseInt(str))
          : [0];
      const formDataToSend = {
        countryId: 1,
        brandId: parseInt(formData.brandId),
        branches: branchesArray,
        title: formData.title,
        message: formData.message,
        url: formData.url === "" ? "Home" : formData.url,
      };

     // console.log("formDataToSend", formDataToSend);
      AddFetchNotificaciones(formDataToSend)
        .then(() => {
         // console.log("Petición enviada");
        })
        .catch(onFailure);
      setTimeout(() => {
        setFormData(objDefault);
        setbranches([]);
        alert("Notificación enviada exitosamente");
      }, 100);
    
  };

  const filteredData =
    formData.brandId === "6"
      ? dataDeepk
      : dataDeepk.filter((item) => item.id !== 5);

  /// console.log("filteredData", filteredData);

  return (
    <div className="container-body-notificaciones-table">
      <div className="icono-notificacion-view">
        <NotificationsNoneIcon sx={{ color: blue[600], fontSize: 60 }} />
      </div>
      <Row justify="space-around">
        <Col span={20}>
          <div className="input-content-titulo">
            Información de la notificación
          </div>
        </Col>
        <Col xs={20} md={15}>
          <InputForm
            handleChange={handleChange}
            value={formData.title}
            name={"title"}
            type={"text"}
            label={"Título"}
          />
          {formData.title.length >= 50 && (
            <div className="text-error">Deben ser menos de 50 caracteres</div>
          )}
          {errors && <div className="error-input-data">{errors.title}</div>}
        </Col>
        <Col xs={20} md={15}>
          <label className="text-context-inputs">Mensaje:</label>
          <textarea
            type="text"
            name="message"
            onChange={handleChange}
            value={formData.message}
            className={`input-content ${
              formData.message.length >= 150 ? "input-comentario-error" : ""
            }`}
          ></textarea>

          {formData.message.length >= 150 && (
            <div className="text-error">Deben ser menos de 150 caracteres</div>
          )}
          {errors && <div className="error-input-data">{errors.message}</div>}
        </Col>

        <Col span={20}>
          <div className="input-content-titulo">Configuración de envío</div>
        </Col>
        <Col xs={20} md={15}>
          {dataMarca.length !== 0 && (
            <>
              <label className="text-context-inputs">Marcas:</label>
              <select
                className="input-content"
                value={formData.brandId}
                onChange={handleChange}
                name={"brandId"}
                //disabled={selectedItem ? true : false}
              >
                <option value="0">Enviar a todas las marcas</option>
                {dataMarca.map((option) => (
                  <option key={option.FIMARCAID} value={option.FIMARCAID}>
                    {option.FCNOMBRE}
                  </option>
                ))}
              </select>
            </>
          )}
        </Col>
        <Col xs={20} md={15}>
          {formData.brandId !== 0 && formData.brandId !== "0" && (
            <>
              {isLoadingSucursal ? (
                <LoadingSpinner />
              ) : (
                <>
                  <label className="text-context-inputs">Sucursales:</label>
                  <Select
                    name="branches"
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={handleChangeSelec}
                    tokenSeparators={[","]}
                    options={options}
                    value={selectAll ? ["0"] : branches}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 || String(option.value).indexOf(input) >= 0
                    }
                  />
                   {errors && <div className="error-input-data">{errors.branches}</div>}
                </>
              )}
            </>
          )}
        </Col>
        <Col xs={20} md={15}>
          <>
            <label className="text-context-inputs">
              Seleccionar dirección de envío dentro de la app:
            </label>
            <select
              className="input-content"
              value={formData.url}
              onChange={handleChange}
              name={"url"}
              //disabled={selectedItem ? true : false}
            >
              {filteredData.map((option) => (
                <option key={option.id} value={option.stack}>
                  {option.name}
                </option>
              ))}
            </select>
          </>
        </Col>

        <Col xs={15} md={15}>
          <div className="button-send-notificaciones">
            <button
              onClick={sentNotificaciones}
              type="submit"
              className="primary-button button-modal"
            >
              Enviar
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
