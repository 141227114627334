/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../theme/Hub.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import { fetchBitacora } from "../Api/fetchBitacora";
import { UseAdmin, UseUser,UseAuth } from "../hooks";
import { VERSION_APP } from "../utilities";
import moment from "moment";

export const HubPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //console.log("state-home", state.dataRouter);
  const { employeeId, mail, device, usuario } = UseUser();
  const {
    brandfiMarcaId,
    branchFiSucursalId,
    branchFiPaisId,
    brandfiBiId,
  } = UseAdmin();
  const { uniqueId } = UseAuth();
  const [isLoading, setLoading] = useState(true);
  const [dataHub, setDataHub] = useState(null);

  useEffect(() => {
    if (state.dataRouter) {
      setDataHub(state.dataRouter);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [state]);

  const navigateToADNalsea = (url) => {
    window.open(url, "_blank");
  };

  const navigateToKronos = (url) => {
    window.open(url, "_blank");
  };

  const navigateToEMI = (url) => {
    window.open(url, "_blank");
  };

  const navigateToDelivery = (url) => {
    const interpolatedString = url.replace(
      /\${([^}]+)}/g,
      (match, variable) => {
        switch (variable) {
          case "branchFiPaisId":
            return branchFiPaisId;
          case "brandfiMarcaId":
            return brandfiMarcaId;
          case "branchFiSucursalId":
            return branchFiSucursalId;
          case "usuario":
            return usuario;
          default:
            return match;
        }
      }
    );
    window.open(interpolatedString, "_blank");
  };

  const navigateToAlseaCollege = (url) => {
    window.open(url, "_blank");
  };

  const navigateToMedalia = (url) => {
    window.open(url, "_blank");
  };

  const navigateToOracle = (url) => {
    window.open(url, "_blank");
  };

  const navigateToCarpetaLegal = (url) => {
    const urlInterpolada = url.replace(
      "${branchFiSucursalId}",
      branchFiSucursalId
    );
    window.open(urlInterpolada, "_blank");
  };

  const navigateToHMC = (url) => {
    window.open(url, "_blank");
  };

  ///funciones nuevas
  const navigateToFuncionUrl = (url) => {
    window.open(url, "_blank");
  };
  const navigateToFuncionWithSSO = (url) => {
    window.open(url, "_blank");
  };
  const navigateToFuncionWithData = (url) => {
    const interpolatedString = url.replace(
      /\${([^}]+)}/g,
      (match, variable) => {
        switch (variable) {
          case "branchFiPaisId":
            return branchFiPaisId;
          case "brandfiMarcaId":
            return brandfiMarcaId;
          case "branchFiSucursalId":
            return branchFiSucursalId;
          case "brandfiBiId":
            return brandfiBiId;
          case "usuario":
            return usuario;
          default:
            return match;
        }
      }
    );
    window.open(interpolatedString, "_blank");
  };
  const navigateToFuncionStack = (url, child2) => {
    navigate(`/${url} `, { state: { dataRouter: child2 } });
  };

  const handleClickLink = (itemPress, urlPress) => {
    if (itemPress === "navigateToADNalsea") {
      navigateToADNalsea(urlPress);
    } else if (itemPress === "navigateToKronos") {
      navigateToKronos(urlPress);
    } else if (itemPress === "navigateToEMI") {
      navigateToEMI(urlPress);
    } else if (itemPress === "navigateToDelivery") {
      navigateToDelivery(urlPress);
    } else if (itemPress === "navigateToAlseaCollege") {
      navigateToAlseaCollege(urlPress);
    } else if (itemPress === "navigateToMedalia") {
      navigateToMedalia(urlPress);
    } else if (itemPress === "navigateToOracle") {
      navigateToOracle(urlPress);
    } else if (itemPress === "navigateToCarpetaLegal") {
      navigateToCarpetaLegal(urlPress);
    } else if (itemPress === "navigateToHMC") {
      navigateToHMC(urlPress);
    } else if (itemPress === "navigateToFuncionUrl") {
      navigateToFuncionUrl(urlPress);
    } else if (itemPress === "navigateToFuncionWithSSO") {
      navigateToFuncionWithSSO(urlPress);
    } else if (itemPress === "navigateToFuncionWithData") {
      navigateToFuncionWithData(urlPress);
    } else if (itemPress === "navigateToFuncionStack") {
      navigateToFuncionStack(urlPress);
    }
  };

  const handleButtonPress = (item) => {
    console.log("item.FCSEGUNDASECCION",item.FCSEGUNDASECCION)
    handleClickLink(
      item.FCJSONSECCION3, item.FCLINKSECCION3
    );
    getBitacora("code 200",item.FCJSONSECCION3,`Hub-${item.FCTERCERASECCION}`)
  };

  const getBitacora = async (data, config, description) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      // Agrega 5 segundos a la fecha actual
      ahora.add(2, "seconds");
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      config != null &&
      data != null
    ) {
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {};
      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        config,
        data,
        description,
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const IconItemBox = (props) => {
    const { item } = props;

    return (
      <div className="icon-item-box-hub" key={item.FITERCERASECCION}>
        <button
          className="custom-button"
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleButtonPress(item)
          }
        >
          <img
            className="img"
            src={item.FCURLIMAGEN3}
            alt={item.FCTERCERASECCION}
          />
          <div className="text-button">{item.FCTERCERASECCION}</div>
        </button>
      </div>
    );
  };

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="body-color">
              <div className="title-name-hub">
                <div className="icon-goback-title-hub">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div> Hub del gerente </div>
                </div>
              </div>
              <div>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="container-body-hub">
                    {dataHub && (
                      <div className="icon-box-hub">
                        {dataHub.map((item) => (
                          <IconItemBox
                            item={item}
                            key={item.FITERCERASECCION}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
