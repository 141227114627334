/* eslint-disable default-case */
import React, { useEffect, useMemo, useReducer } from "react";
import { AuthLoginContext } from "./AuthLoginContext";
import { getStoredValue, removeStoredValue, storeValue } from "../storage";
//import CookieManager from "@react-native-cookies/cookies";

const reducer = (prevState, action) => {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        status: "restoreToken",
        accessToken: action.accessToken,
        expiration: action.expiration,
        uniqueId: action.uniqueId,
      };
    case "SIGN_OUT":
      return {
        status: "signOut",
        accessToken: null,
        expiration: null,
        uniqueId: null,
      };
    case "SIGN_IN":
      return {
        status: "signIn",
        accessToken: action.accessToken,
        expiration: action.expiration,
        uniqueId: action.uniqueId,
      };
  }
};

export function AuthLoginProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    status: "idle",
    accessToken: null,
    expiration: null,
    uniqueId: null,
  });

  useEffect(() => {
    const loadAuthData = async () => {
      try {
        const accessToken = await getStoredValue("accessToken");
        const expiration = await getStoredValue("expiration");
        const uniqueId = await getStoredValue("uniqueId");

        if (accessToken !== null && expiration !== null && uniqueId !== null) {
          dispatch({
            type: "RESTORE_TOKEN",
            accessToken,
            expiration,
            uniqueId,
          });
        } else {
          dispatch({ type: "SIGN_OUT" });
        }
      } catch (e) {
        dispatch({ type: "SIGN_OUT" });
      }
    };
    if (state.status === "idle") {
      loadAuthData();
    }
  }, [state, dispatch]);

  const actions = useMemo(
    () => ({
      signIn: async (accessToken, expiration, uniqueId) => {
        await Promise.all([
          storeValue("accessToken", accessToken),
          storeValue("expiration", expiration),
          storeValue("uniqueId", uniqueId),
        ]);
        dispatch({
          type: "SIGN_IN",
          accessToken,
          expiration,
          uniqueId,
        });
      },
      signOut: async () => {
        await Promise.all([
          removeStoredValue("accessToken"),
          removeStoredValue("expiration"),
          removeStoredValue("uniqueId"),
        ]);
        dispatch({ type: "SIGN_OUT" });
      },
    }),
    [dispatch]
  );

  return (
    <AuthLoginContext.Provider value={{ ...state, ...actions }}>
      {children}
    </AuthLoginContext.Provider>
  );
}
