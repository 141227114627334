import React, { useState } from "react";
import "../theme/Indicadores.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../context/authProvider";
import iconPower from "../assets/iconPower.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IndicatorsDay } from "../components/IndicatorsDay";
import { IndicatorsWeek } from "../components/IndicatorsWeek";
import { IndicatorsMonth } from "../components/IndicatorsMonth";
import { IndicatorsYear } from "../components/IndicatorsYear";
import { IndicatorsLastTwelve } from "../components/IndicatorsLastTwelve";
import { useNavigate } from "react-router-dom";

export const IndicadoresPage = () => {
  const navigate = useNavigate();
  const [changeDate, setChangeDate] = useState("day");

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div>
              <div className="title-name-row">
                <div className="title-name-indicadores">
                  <div className="icon-goback-title">
                    <button
                      className="button-goback-style"
                      onClick={() => navigate(-1)}
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <div>Indicadores</div>
                  </div>
                  <a href={`/Home`}>
                    <img className="image-icon" src={iconPower} alt="Logo" />
                  </a>
                </div>

                <div className="conter-day-table-indicadores">
                  <button
                    className={`${
                      changeDate === "day"
                        ? "buttoon-selected-indicadores-active"
                        : "buttoon-selected-indicadores"
                    }`}
                    onClick={() => setChangeDate("day")}
                  >
                    Día
                  </button>
                  <button
                    className={`${
                      changeDate === "week"
                        ? "buttoon-selected-indicadores-active"
                        : "buttoon-selected-indicadores"
                    }`}
                    onClick={() => setChangeDate("week")}
                  >
                    Semana
                  </button>
                  <button
                    className={`${
                      changeDate === "month"
                        ? "buttoon-selected-indicadores-active"
                        : "buttoon-selected-indicadores"
                    }`}
                    onClick={() => setChangeDate("month")}
                  >
                    Mes
                  </button>
                  <button
                    className={`${
                      changeDate === "year"
                        ? "buttoon-selected-indicadores-active"
                        : "buttoon-selected-indicadores"
                    }`}
                    onClick={() => setChangeDate("year")}
                  >
                    Año
                  </button>
                  <button
                    className={`${
                      changeDate === "lastTwelve"
                        ? "buttoon-selected-indicadores-active"
                        : "buttoon-selected-indicadores"
                    }`}
                    onClick={() => setChangeDate("lastTwelve")}
                  >
                    Últimas 12
                  </button>
                </div>
              </div>

              <div>
                {changeDate === "day" ? (
                  <IndicatorsDay />
                ) : changeDate === "week" ? (
                  <IndicatorsWeek />
                ) : changeDate === "month" ? (
                  <IndicatorsMonth />
                ) : changeDate === "year" ? (
                  <IndicatorsYear />
                ) : (
                  <IndicatorsLastTwelve />
                )}
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
