import createAxiosInstance from "./LambdaApiHorariosEmpleados";
import { ENVIROMENT } from "../utilities"

// Función para realizar la solicitud fetchHorariosEmpleados
export async function  fetchVistaHorarios(week, brandId, branchId, tokenProd) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/horarios?week=${week}&brandId=${brandId}&branchId=${branchId}`: `home/horarios?week=${week}&brandId=${brandId}&branchId=${branchId}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
