import createAxiosInstance from "../LambdaApiAtb";
import { ENVIROMENT } from "../../utilities"

export async function GetFetchModeloAtbByMarcaAtb(marcaatb) {
  const axiosInstance = await createAxiosInstance();
  const url =   `/atb/modeloatb?FIESTATUS=1&FIMARCAATBID=${marcaatb}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}