import React, { useState, useEffect } from "react";
import "../theme/Administracion.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { authProvider } from "../context/authProvider";
import { UseAdmin, UseUser, UseTokenGlobal } from "../hooks";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VERSION_APP } from "../utilities";
import { fetchSecciones } from "../Api/fetchSecciones";

export const ReportesPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [adminData, setAdminData] = useState(null);
  const { jobTitle } = UseUser();
  const { branchFiSucursalId, brandfiMarcaId, branchFiPaisId, rolId } =
    UseAdmin();
  const { tokenProd } = UseTokenGlobal();

  useEffect(() => {
    getDataSeccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    branchFiPaisId,
    brandfiMarcaId,
    jobTitle,
    branchFiSucursalId,
    rolId,
  ]);

  const getDataSeccion = () => {
    if (
      branchFiPaisId !== null &&
      branchFiSucursalId !== null &&
      brandfiMarcaId !== null &&
      jobTitle !== null &&
      rolId !== null
    ) {
      const onFailure = ({ error }) => {
        setLoading(false);
      };
      const onSuccess = ({ data }) => {
        const extractedData = data.body.seccion.CHILD1.map((item) => ({
          FCJSONSECCION2: item.FCJSONSECCION2,
          FCLINKSECCION2: item.FCLINKSECCION2,
          FCSEGUNDASECCION: item.FCSEGUNDASECCION,
          FCURLIMAGEN2: item.FCURLIMAGEN2,
          FISEGUNDASECCIONID: item.FISEGUNDASECCIONID,
          FCSTACKSECCION2: item.FCSTACKSECCION2,
          FCCHIL2: item.CHILD2,
        }));

        const contieneAdmin = extractedData.some(
          (item) => item.FCSEGUNDASECCION === "Reportes"
        );

        if (contieneAdmin) {
          const soloAministracion = data.body.seccion.CHILD1.filter(
            (item) => item.FCSEGUNDASECCION === "Reportes"
          );
          setAdminData(soloAministracion[0].CHILD2);
        }
        setLoading(false);
      };

      fetchSecciones(
        branchFiPaisId,
        brandfiMarcaId,
        jobTitle,
        branchFiSucursalId,
        VERSION_APP,
        Number(rolId),
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const navigateToFuncionOnRouter = (url, child2) => {
    navigate(`/${url} `);
  };

  const handleClickLink = (itemPress, urlPress) => {
    if (itemPress === "navigateToFuncionOnRouter") {
      navigateToFuncionOnRouter(urlPress);
    }
  };

  const IconItemBox = (props) => {
    const { item } = props;

    return (
      <div className="icon-item-box-hub" key={item.FITERCERASECCION}>
        <button
          className="custom-button"
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleClickLink(item.FCJSONSECCION3, item.FCLINKSECCION3)
          }
        >
          <img
            className="img"
            src={item.FCURLIMAGEN3}
            alt={item.FCTERCERASECCION}
          />
          <div className="text-button">{item.FCTERCERASECCION}</div>
        </button>
      </div>
    );
  };

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="body-color-admin">
              <div className="title-name-admin">
                <div className="icon-goback-title-admin">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div> {state.dataRouter[0].FCSEGUNDASECCION} </div>
                </div>
              </div>

              <div>
                <div>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="container-body-hub">
                      {adminData && (
                        <div className="icon-box-hub">
                          {adminData.map((item) => (
                            <IconItemBox
                              item={item}
                              key={item.FITERCERASECCION}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
