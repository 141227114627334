import createAxiosInstance from "../Api/LambdaApiAtb";
import { ENVIROMENT } from "../utilities"

export async function GetModuloAtb(FIPAISID,FIMARCAID,FISUCURSALID,FIANIOID,FITRIMESTREID,FCNOSERIE,FITIPOATBID,FIMARCAATBID,FIMODELOATBID,FCAFILIACION) {
  const axiosInstance = await createAxiosInstance();
  const url =   `/atb/terminalbancaria?FIPAISID=${FIPAISID}&FIMARCAID=${FIMARCAID}&FISUCURSALID=${FISUCURSALID}&FIANIOID=${FIANIOID}&FITRIMESTREID=${FITRIMESTREID}&FCNOSERIE=${FCNOSERIE}&FITIPOATBID=${FITIPOATBID}&FIMARCAATBID=${FIMARCAATBID}&FIMODELOATBID=${FIMODELOATBID}&FCAFILIACION=${FCAFILIACION}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
