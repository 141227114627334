import createAxiosInstance from "../LambdaApiAdministracion";
import { ENVIROMENT } from "../../utilities"

export async function AddFetchComunicados(body) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/admon/comunicado`: `/home/admon/comunicado`
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: body
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
