import createAxiosInstance from "./LambdaReclasificacionApi";

export async function fetReporteCvsCierreDia(
  branchFiPaisId,
  brandfiMarcaId,
  brandfiSucursalId,
  fechaIncio,
  fechaFin,
  mail,
) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: `/reclasificacion`,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      code: 3,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: brandfiSucursalId,
        fechaInicio: fechaIncio,
        fechaFin: fechaFin,
        correo: mail,
      },
    },
  };
  
  return axiosInstance(requestConfig);
}
