import React, { useEffect, useMemo, useReducer } from "react";
import { TokenGlobalContext } from "./TokenGlobalContext";
import { getStoredValue, removeStoredValue, storeValue } from "../storage";
//import CookieManager from "@react-native-cookies/cookies";

const reducer = (prevState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        status: "restoreToken",
        tokenProd: action.tokenProd,
      };
    case "SIGN_OUT":
      return {
        status: "signOut",
        tokenProd: null,
      };
    case "SIGN_IN":
      return {
        status: "signIn",
        tokenProd: action.tokenProd,
      };
  }
};

export function TokenGlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    status: "idle",
    tokenProd: null,
  });

  useEffect(() => {
    const loadAuthData = async () => {
      try {
        const tokenProd = await getStoredValue("tokenProd");

        if (tokenProd !== null) {
          dispatch({
            type: "RESTORE_TOKEN",
            tokenProd,
          });
        } else {
          dispatch({ type: "SIGN_OUT" });
        }
      } catch (e) {
        dispatch({ type: "SIGN_OUT" });
      }
    };
    if (state.status === "idle") {
      loadAuthData();
    }
  }, [state, dispatch]);

  const actions = useMemo(
    () => ({
      TokenIn: async (tokenProd) => {
        await Promise.all([storeValue("tokenProd", tokenProd)]);
        dispatch({
          type: "SIGN_IN",
          tokenProd,
        });
      },
      TokenOut: async () => {
        await Promise.all([removeStoredValue("tokenProd")]);
        dispatch({ type: "SIGN_OUT" });
        // CookieManager.clearAll().then((success) => {
        //    console.log("CookieManager.clearAll =>", success);
        //    });
      },
    }),
    [dispatch]
  );

  return (
    <TokenGlobalContext.Provider value={{ ...state, ...actions }}>
      {children}
    </TokenGlobalContext.Provider>
  );
}
