import React from "react";

const InputForm = ({ handleChange, value, label, name, type, disabled }) => {
  return (
    <>
      <label className="text-context-inputs">{label}:</label>
      <input
        type={type}
        name={name}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        className="input-content"
      />
    </>
  );
};

export default InputForm;
