import createAxiosInstance from "./LambdaApiFrecuencias";

export async function fetchReclasificacionCatalogoDias(
  branchFiPaisId,
  brandfiMarcaId,
  branchFiSucursalId,
) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: `/frecuencias`,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      code: 4,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: branchFiSucursalId,
      },
    },
  };
  return axiosInstance(requestConfig);
}
