import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchReclasificacionEdit(
  branchFiPaisId,
  brandfiMarcaId,
  branchFiSucursalId,
  brandfcNombre,
  ventaTotal,
  depositoTombola,
  efectivoADepositar,
  nombre,
  fechaRegistro,
  fecha,
  deviceinfo,
  propina,
  porpripina,
  imgCierre,
  imgDeposito,
  imgValores,
  noTicket,
  latitude,
  longitude,
  comentario,
  amex,
  tpv,
  pinpad,
  vales,
  valescdmx,
  delivery,
  didi,
  didicredito,
  rappi,
  rapicargo,
  uber,
  uberdirect,
  mercadopago,
  wow,
  mop,
  startbucksRewords,
  scanypay,
  bkapp,
  eventosWalmart,
  eventosempresariales,
  efectivomxp,
  efectivouds,
  didiEfectivo,
  uberEfectivo,
  rappiEfectivo,
  anticioOtros,
  otrosCreditos,
  deliverySr,
  cierreComentario,
  cierreEseditable,
  cierreEvidencia,
  cierreFecha,
  fichaComentario,
  fichaEseditable,
  fichaEvidencia,
  fichaFecha,
  valoresComentario,
  valoresEseditable,
  valoresEvidencia,
  valoresFecha,
  accessToken
) {

  const axiosInstance = await createAxiosInstance();

  const requestConfig = {
    method: "PUT", // Cambia el método si es necesario
    url: `/reclasificacion`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`, //the token is a variable which holds the token
    },
    data: {
      code: 1,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: branchFiSucursalId,
        nombreTienda: brandfcNombre,
        ventaTotal: ventaTotal,
        depositoTombola: depositoTombola,
        efectivoADepositar: efectivoADepositar,
        nombreGerente: nombre,
        fechaRegistro: fechaRegistro,
        fechaModificacion: fecha,
        estatus: 1,
        descEstatus: "REGISTRO",
        dispositivo: deviceinfo,
        propina: propina,
        porcentajePropina: porpripina,
        Cierre_Lote: imgCierre,
        Ficha_Deposito: imgDeposito,
        Ficha_Entrega_Valores: imgValores,
        recoleccion: "0",
        noTicket: noTicket,
        latitud: latitude,
        longitud: longitude,
        comentarios: comentario,
        formaPagoReclasificacion: [
          {
            formaPago: 1,
            descFormaPago: "AMEX",
            cantidad: amex,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 2,
            descFormaPago: "TPV",
            cantidad: tpv,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 3,
            descFormaPago: "PINPAD",
            cantidad: pinpad,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 4,
            descFormaPago: "VALES",
            cantidad: vales,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 5,
            descFormaPago: "VALESCDMX",
            cantidad: valescdmx,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 6,
            descFormaPago: "DELIVERYALSEA",
            cantidad: delivery,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 7,
            descFormaPago: "DIDI",
            cantidad: didi,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 8,
            descFormaPago: "DIDICREDITOS",
            cantidad: didicredito,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 9,
            descFormaPago: "RAPPI",
            cantidad: rappi,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 10,
            descFormaPago: "RAPPICARG0",
            cantidad: rapicargo,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 11,
            descFormaPago: "UBER",
            cantidad: uber,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 12,
            descFormaPago: "UBERDIRECT",
            cantidad: uberdirect,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 13,
            descFormaPago: "MERCADOPAGO",
            cantidad: mercadopago,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 14,
            descFormaPago: "WOW",
            cantidad: wow,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 15,
            descFormaPago: "MOP",
            cantidad: mop,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 16,
            descFormaPago: "STARBUCKSREWARDS",
            cantidad: startbucksRewords,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 17,
            descFormaPago: "SCAN&PAY",
            cantidad: scanypay,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 18,
            descFormaPago: "BKAPP",
            cantidad: bkapp,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 19,
            descFormaPago: "EVENTOSWALMART",
            cantidad: eventosWalmart,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 20,
            descFormaPago: "EVENTOSEMPRESARIALES",
            cantidad: eventosempresariales,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 21,
            descFormaPago: "EFECTIVOMXP",
            cantidad: efectivomxp,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 22,
            descFormaPago: "EFECTIVOUSD",
            cantidad: efectivouds,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 23,
            descFormaPago: "DIDIEFECTIVO",
            cantidad: didiEfectivo,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 24,
            descFormaPago: "UBEREFECTIVO",
            cantidad: uberEfectivo,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 25,
            descFormaPago: "RAPPIEFECTIVO",
            cantidad: rappiEfectivo,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 26,
            descFormaPago: "ANTICIPO/OTROSCREDITOS",
            cantidad: anticioOtros,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 27,
            descFormaPago: "OTROSCREDITOS",
            cantidad: otrosCreditos,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
          {
            formaPago: 28,
            descFormaPago: "DELIVERYSR",
            cantidad: deliverySr,
            fechaRegistro: fechaRegistro,
            fechaModificacion: fecha,
          },
        ],
        detallevidencia: [
          {
            tipoEvidencia: "1",
            descripcion: "Cierre Lote",
            comentario: cierreComentario,
            esEditable: cierreEseditable,
            statusEvidencia: cierreEvidencia,
            fechaEstatus: cierreFecha,
          },
          {
            tipoEvidencia: "2",
            descripcion: "Ficha Deposito",
            comentario: fichaComentario,
            esEditable: fichaEseditable,
            statusEvidencia: fichaEvidencia,
            fechaEstatus: fichaFecha,
          },
          {
            tipoEvidencia: "3",
            descripcion: "Entrega Valores",
            comentario: valoresComentario,
            esEditable: valoresEseditable,
            statusEvidencia: valoresEvidencia,
            fechaEstatus: valoresFecha,
          },
        ],
      },
    }
  }
  return axiosInstance(requestConfig);
}
