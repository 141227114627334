import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Input, Space, Col, Row, Switch, Modal,Image , Divider, Radio, } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from '@mui/icons-material/FilterList';
import { InputSelectWithInName } from "../components/utils/InputSelectWithInName";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { AdministracionAtbAddForm } from "../components/AdministracionAtbAddForm";
import { GetModuloAtb } from "../Api/GetModuloAtb"
import { GetFetchMarcaAtb } from  "../Api/Administracion/GetFetchMarcaAtb"
import { GetFetchTipoAtb } from  "../Api/Administracion/GetFetchTipoAtb"
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { GetFetchSucursalesByMarca } from "../Api/Administracion/GetFetchSucursalesByMarca"
import { GetFetchModeloAtbByMarcaAtb } from "../Api/Administracion/GetFetchModeloAtbByMarcaAtb"
import { AddFetchNotificaciones } from "../Api/Administracion/AddFetchNotificaciones"
import { fetchModuloAtbSendReporteEmail } from "../Api/fetchModuloAtbSendReporteEmail"
import { fetchModuloAtbEdith } from "../Api/fetchModuloAtbEdith"
import {  UseAdmin, UseUser, UseAuth } from "../hooks";
import "../theme/ReporteCierreDia.css";
import { remoteConfig } from "../firebase";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { ENVIROMENT } from "../utilities";
import icono from '../assets/iconsSearch.png';
import InputForm from "./utils/InputForm";
import moment from "moment";

export const  TableAdministracionRegistroATB =()=> {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRechazo, setIsLoadingRechazo] = useState(false);
  const {
    brandfiMarcaId,
    branchFiSucursalId,
    branchFiPaisId,
    rolId,
  } = UseAdmin();
  const {mail} = UseUser();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataMarca, setDataMarca] = useState([]);
  const [dataSucursal, setDataSucursales] = useState([]);
  const [dataTipoAtb, setDataTipoAtb] = useState([]);
  const [dataMarcaAtb, setDataMarcaAtb] = useState([]);
  const [dataModeloAtb, setDataModeloAtb] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalRechazo, setShowModalRechazo] = useState(false);
  const [selectDetailsRow, setSelectDetailsRow] = useState([]);
  const [isLoadingSucursal, setIsLoadingSucursal] = useState(false);
  const [isLoadingModeloAtb, setIsLoadingModeloAtb] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [configuracion, setConfiguracion] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [shouldShowButton, setShouldShowButton] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math.floor((new Date().getMonth() / 3)) + 1;
  const [modalKey, setModalKey] = useState(0);
  

  const objDefault = {
    FIPAISID: branchFiPaisId,
    FIMARCAID: brandfiMarcaId,
    FISUCURSALID: branchFiSucursalId,
    FIANIOID: currentYear, 
    FITRIMESTREID: currentQuarter, 
    FCNOSERIE: "",
    FITIPOATBID: 0,
    FIMARCAATBID: 0,
    FIMODELOATBID: 0,
    FCAFILIACION: "",
    FIMOTIVOID: "",
    FCCOMENTARIORECHAZO: "",
    FCANIOSTRIMESTRE:`${currentQuarter}${currentYear}`
  };
  const [formData, setFormData] = useState(objDefault);
  useEffect(() => {
    // Ejemplo de inicialización del estado después de obtener los valores
    setFormData(prevState => ({
      ...prevState,
      FIPAISID: branchFiPaisId,
      FIMARCAID: brandfiMarcaId,
      FISUCURSALID: branchFiSucursalId,
      FCANIOSTRIMESTRE: `${currentQuarter}${currentYear}`,
      // Puedes seguir inicializando otros campos aquí si es necesario
    }));
  }, [branchFiPaisId, brandfiMarcaId, branchFiSucursalId,currentYear,currentQuarter]);

  useEffect(() => {
    // Convertir los dígitos restantes de nuevo a un número
    const [firstDigit, ...restDigits] = formData.FCANIOSTRIMESTRE;
    const restNumber = parseInt(restDigits.join(''), 10);
    //(`Primer dígito: ${firstDigit}`);
    //console.log(`Resto del número: ${restNumber}`);
    setFormData(prevState => ({
      ...prevState,
      FITRIMESTREID:firstDigit,
      FIANIOID:restNumber,
    }));
  }, [formData.FCANIOSTRIMESTRE]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    if(rolId!==null){
      // Activa la recuperación de valores remotos
    remoteConfig.settings.minimumFetchIntervalMillis = 300000 // 5 minutos en milisegundos;
    fetchAndActivate(remoteConfig)
      .then(() => {
        // Obtén la configuración remota
        const env = `${
          ENVIROMENT == "dev"
            ? "roles_reject_evidences_dev"
            : "roles_reject_evidences"
        }`;
        const remoteConfigData = getValue(remoteConfig, env);
        //console.log("remoteConfigData", remoteConfigData);
        //console.log("rolId",rolId)
        const includePermision = remoteConfigData._value.includes(rolId);
        setConfiguracion(includePermision);
       // console.log("includePermision", includePermision);
      })
      .catch((error) => {
        console.error("Error al obtener la configuración remota:", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolId]);


  const getCurrentDateFormatted = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

   const getTrimestresOptions = () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const trimestres = [];
  
    const createOptions = (year) => {
      return [
        { value: `1${year}`, label: `Q1 (enero – marzo) ${year}` },
        { value: `2${year}`, label: `Q2 (abril – junio) ${year}` },
        { value: `3${year}`, label: `Q3 (julio – septiembre) ${year}` },
        { value: `4${year}`, label: `Q4 (octubre – diciembre) ${year}` }
      ];
    };
  
    trimestres.push(...createOptions(previousYear));
    trimestres.push(...createOptions(currentYear));
  
    return trimestres;
  };
  const trimestresOptions = getTrimestresOptions();

  const obtenerFechaEnFormato = () => {
    const ahora = moment();
    const fechaEnFormato = ahora.format("YYYY-MM-DD HH:mm:ss");
    return fechaEnFormato;
  };

  const fechaActualEnFormato = obtenerFechaEnFormato();
  

  useEffect(() => {
    const formattedDate = getCurrentDateFormatted();
    setCurrentDate(formattedDate);
  }, []);


  const toggleLoading = () => {
    setIsLoadingData(prevLoading => !prevLoading);
  };

   //table--->>
   const [searchText, setSearchText] = useState("");
   const [searchedColumn, setSearchedColumn] = useState("");
   const searchInput = useRef(null);
   const handleSearch = (selectedKeys, confirm, dataIndex) => {
     confirm();
     setSearchText(selectedKeys[0]);
     setSearchedColumn(dataIndex);
   };
   /*const handleReset = (clearFilters) => {
     clearFilters();
     setSearchText("");
   };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const Tag = ({ item }) => {
    return (
      (item === 1 ? (
          <div className="font-text-boldBlue center-text">Inicial</div>
        ) : item === 2 ? (
          <div className="font-text-boldRed center-text">Rechazado</div>
        ) : item === 3 ? (
          <div className="font-text-boldGreen center-text">Modificado</div>
        ) : item === 0 ? (
          <div className="font-text-bold center-text">Inactivo</div>
        ) : (
          ""
        ))
    );
  };
 
  const Images = ({item})=>{
    const urls = item.TAFOTOS.map(foto => foto.FCURLFOTOGRAFICA);
    return(
    <Image.PreviewGroup
     items={urls}
    >
      <Image
        width={32}
        src={icono}
        className="button-styles-detalais-catalogo"
      />
    </Image.PreviewGroup>
    )
  }
  const Trimestre = ({ item }) => {
    return (
      <div >
        {`Q${item.FITRIMESTREID}-${item.FIANIOID}`} {/* Mostrar FITRIMESTREID y FIANIOID concatenados */}
      </div>
    );
  };

  const HandleSwitch = ({ value, selectedItem }) => {
    // Considerar activo cualquier valor "1", "2" o "3"
    const [hs, setHs] = useState(["1", "2", "3"].includes(value.toString()));
    
    const handleChangeSwitch = (v) => {
      const newStatus = v ? 1 : 0; // Aquí decides qué valor considerar como activo (ajusta según tu lógica)
      const newObj = {
        ...selectedItem,
        FCNOSERIE: selectedItem.FCNOSERIE,
        FITEMPORALIDADID: Number(selectedItem.FITEMPORALIDADID),
        FIANIOID: Number(selectedItem.FIANIOID),
        FITIPOATBID: Number(selectedItem.FITIPOATBID),
        FITRIMESTREID: Number(selectedItem.FITRIMESTREID),
        FIMARCAATBID: Number(selectedItem.FIMARCAATBID),
        FIMODELOATBID: Number(selectedItem.FIMODELOATBID),
        FCAFILIACION: selectedItem.FCAFILIACION,
        FIESTATUSATB: Number(newStatus), // Actualizamos el estado aquí
        FIPAISID: Number(selectedItem.FIPAISID),
        FIMARCAID: Number(selectedItem.FIMARCAID),
        FISUCURSALID: Number(selectedItem.FISUCURSALID),
        FDFECHAREGISTRO: selectedItem.FDFECHAREGISTRO,
        FDFECHAACTUALIZACION: fechaActualEnFormato,
        FIBUENESTADO: Number(selectedItem.FIBUENESTADO),
        FIFUNCIONANDO: Number(selectedItem.FIFUNCIONANDO),
        FIMOTIVOID:formData.FIMOTIVOID,
        FCCOMENTARIORECHAZO: selectedItem.FCCOMENTARIORECHAZO,
        FITIPOFOTOIDFRONTAL: Number(selectedItem?.TAFOTOS[0]?.FITIPOFOTOID),
        FCDESCRIPCIONFRONTAL: selectedItem?.TAFOTOS[0]?.FCDESCRIPCION,
        FCURLFOTOGRAFICAFRONTAL: selectedItem?.TAFOTOS[0]?.FCURLFOTOGRAFICA,
        FDFECHAMODIFICACIONFRONTAL: selectedItem?.TAFOTOS[0]?.FDFECHAMODIFICACION,
        FITIPOFOTOIDTRASERA: Number(selectedItem?.TAFOTOS[1]?.FITIPOFOTOID),
        FCDESCRIPCIONTRASERA: selectedItem?.TAFOTOS[1]?.FCDESCRIPCION,
        FCURLFOTOGRAFICATRASERA: selectedItem?.TAFOTOS[1]?.FCURLFOTOGRAFICA,
        FDFECHAMODIFICACIONTRASERA: selectedItem?.TAFOTOS[1]?.FDFECHAMODIFICACION,
        FITIPOFOTOIDLATERALDER: Number(selectedItem?.TAFOTOS[2]?.FITIPOFOTOID),
        FCDESCRIPCIONLATERALDER: selectedItem?.TAFOTOS[2]?.FCDESCRIPCION,
        FCURLFOTOGRAFICALATELRADER: selectedItem?.TAFOTOS[2]?.FCURLFOTOGRAFICA,
        FDFECHAMODIFICACIONLATERALDER: selectedItem?.TAFOTOS[2]?.FDFECHAMODIFICACION,
        FITIPOFOTOIDLATERALIZ: Number(selectedItem?.TAFOTOS[3]?.FITIPOFOTOID),
        FCDESCRIPCIONLATERALIZ: selectedItem?.TAFOTOS[3]?.FCDESCRIPCION,
        FCURLFOTOGRAFICALATERALIZ: selectedItem?.TAFOTOS[3]?.FCURLFOTOGRAFICA,
        FDFECHAMODIFICACIONLATERALIZ: selectedItem?.TAFOTOS[3]?.FDFECHAMODIFICACION,
      };
  
      //console.log("selectedItem", selectedItem);
      //peticion....
      const onFailure = (error) => {
        //console.log("error", error);
        alert(error);
      };
      const onSuccess = ({ data }) => {
        //console.log("state", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 200) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      //console.log("Nuevo objeto a enviar", newObj);
      fetchModuloAtbEdith(newObj).then(onSuccess).catch(onFailure);
      setHs(v);
    };
  
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };
  

  const columns = [
    {
      title: "No serie",
      dataIndex: "FCNOSERIE",
      key: "FCNOSERIE",
      sorter: (a, b) => parseInt(a.FCNOSERIE) - parseInt(b.FCNOSERIE),
      ...getColumnSearchProps("FCNOSERIE"),
    },
    {
      title: "Trimestre",
      render: (item) => <Trimestre item={item} />,
    },
    {
      title: "Marca",
      dataIndex: "FCNOMBREMARCA",
      key: "FCNOMBREMARCA",
      ...getColumnSearchProps("FCNOMBREMARCA"),
      width: "8%",
    }, 
    {
      title: "CECO",
      dataIndex: "FISUCURSALID",
      key: "FISUCURSALID",
      ...getColumnSearchProps("FISUCURSALID"),
      width: "5%",
    },
    {
      title: "Sucursal",
      dataIndex: "FCNOMBRESUCURSAL",
      key: "FCNOMBRESUCURSAL",
      ...getColumnSearchProps("FCNOMBRESUCURSAL"),
      width: "10%",
    },
    {
      title: "Tipo",
      dataIndex: "FCNOMBRETIPO",
      key: "FCNOMBRETIPO",
      ...getColumnSearchProps("FCNOMBRETIPO"),
    },
    {
      title: "Marca dispositivo",
      dataIndex: "FCNOMBREMARCAATB",
      key: "FCNOMBREMARCAATB",
      ...getColumnSearchProps("FCNOMBREMARCAATB"),
    },
    {
      title: "Modelo",
      dataIndex: "FCNOMBREMODELO",
      key: "FCNOMBREMODELO",
      ...getColumnSearchProps("FCNOMBREMODELO"),
    },
    {
      title: "Afiliación",
      dataIndex: "FCAFILIACION",
      key: "FCAFILIACION",
      ...getColumnSearchProps("FCAFILIACION"),
    },
    {
      title: "Estado",
      dataIndex: "FIESTATUSATB",
      key: "FIESTATUSATB",
      render: (item) => <Tag item={item} />,
      width: "10%",
      filters: [
        { text: "Inicial", value: "1" },
        { text: "Rechazado", value: "2" },
        { text: "Modificado", value: "3" },
        { text: "Inactivo", value: "0" },
      ],
      defaultFilteredValue: ["1", "2", "3"],
      onFilter: (value, record) => record.FIESTATUSATB === Number(value),
    },
    {
      title: "Estatus",
      dataIndex: "FIESTATUSATB",
      key: "FIESTATUSATB",
      render: (item, selectedItem) => <HandleSwitch value={item} selectedItem={selectedItem} />,
      width: "8%"
    },
    {
      title: "Ver detalle",
      render: (item) => <Images item={item} />,
      fixed: "right",
      width: "8%",
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "8%",
    },
  ].filter(Boolean); // Filtra los valores falsy
  
  

  useEffect(() => {
    fetchDataMarca();
  }, []);

  useEffect(() => {
    fetchDataSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.FIMARCAID]);
  const fetchDataSucursal = async () => {
    if (formData.FIMARCAID !== null) {
      setIsLoadingSucursal(true);
      const onFailure = (error) => {
        setIsLoadingSucursal(false);
      };
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataSucursales(data.body.data);
        setIsLoadingSucursal(false);
      };
      GetFetchSucursalesByMarca(formData.FIMARCAID).then(onSuccess).catch(onFailure);
    }
  };
  const fetchDataMarca = async () => {
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  useEffect(() => {
    fetchTipoAtb()
    fetchMarcaAtb();
  }, [])

  const fetchTipoAtb = async () => {
      const onFailure = (error) => {
        //console.log(error);
      };
      const onSuccess = ({ data }) => {
        setDataTipoAtb(data.body);
      }
      GetFetchTipoAtb(true).then(onSuccess).catch(onFailure);
  };
  const fetchMarcaAtb = async () => {
      const onFailure = (error) => {
        //console.log(error);
      };
      const onSuccess = ({ data }) => {
        setDataMarcaAtb(data.body);
      }
      GetFetchMarcaAtb(true).then(onSuccess).catch(onFailure);
  };

  useEffect(() => {
    fetchDataModeloAtb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.FIMARCAATBID]);

  const fetchDataModeloAtb = async () => {
    if (formData.FIMARCAATBID !== 0) {
      setIsLoadingModeloAtb(true);
      const onFailure = (error) => {
        setIsLoadingModeloAtb(false);
      };
      const onSuccess = ({ data }) => {
        console.log(data.body);
        setDataModeloAtb(data.body);
        setIsLoadingModeloAtb(false);
      };
      GetFetchModeloAtbByMarcaAtb(formData.FIMARCAATBID).then(onSuccess).catch(onFailure);
    }
  };
  
  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };
  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAddRechazo = () => {
    if (selectDetailsRow.length === 0) {
      // Aquí puedes mostrar una alerta, mensaje de error, o tomar alguna acción necesaria
      alert('Por favor selecciona al menos una fila.');
      return; // Detenemos la ejecución si no hay filas seleccionadas
    }
    setShowModalRechazo(true);
  };

  const handleAddRechazoClose = () => {
    handleSendRechazos()
  };

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
   // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
   /// console.log('selectedRows: ', newSelectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectDetailsRow(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: isLoadingRechazo || record.FIESTATUSATB === 2 || record.FIESTATUSATB === 0,
    }),
  };


   const handleSendRechazos = ()=>{
    //('selectedRowsDatos rechazados:', selectDetailsRow);
    if (formData.FIMOTIVOID==="") {
      // Aquí puedes mostrar una alerta, mensaje de error, o tomar alguna acción necesaria
      alert('Por favor selecciona el motivo de rechazo');
      return; // Detenemos la ejecución si no hay filas seleccionadas
    }
    if (formData.FIMOTIVOID==="5" && formData.FCCOMENTARIORECHAZO==="") {
      // Aquí puedes mostrar una alerta, mensaje de error, o tomar alguna acción necesaria
      alert('Ingresa el moitvo de rechazo');
      return; // Detenemos la ejecución si no hay filas seleccionadas
    }
    setShowModalRechazo(false);
    alert("Notificación enviada exitosamente")
    setIsLoadingRechazo(true)
    selectDetailsRow.forEach((selectedItem) => {
      sentNotificaciones(selectedItem)
      handleSaveData(selectedItem)
    });
   }

   const sentNotificaciones = (selectedItem) => {
   const onFailure = (error) => {};
    const messageFetch =
      "Es necesario hacer la actualización del registro"+"-"+"Q"+selectedItem.FITEMPORALIDADID+"-"+selectedItem.FIANIOID;
    const formDataToSend = {
      countryId: Number(selectedItem.FIPAISID),
      brandId: Number(selectedItem.FIMARCAID),
      branches: [Number(selectedItem?.FISUCURSALID)],
      title: "Rechazo de ATB del registro" + "-" + selectedItem?.FCNOSERIE,
      message: messageFetch,
      url: "TerminalesBancarias",
      date: Number(selectedItem.FIPAISID) + "-" + Number(selectedItem.FIMARCAID) + "-" +  Number(selectedItem?.FISUCURSALID) + "-" + selectedItem?.FCNOSERIE +"-" + selectedItem?.FITRIMESTREID + "-" + selectedItem?.FIANIOID ,
    };

    //console.log("formDataToSend", formDataToSend);
    AddFetchNotificaciones(formDataToSend)
      .then(() => {
        //console.log("Petición enviada");
      })
      .catch(onFailure);
    setTimeout(() => {
      setFormData(objDefault);
      setSelectedRowKeys([]);
      setSelectDetailsRow([])
      //alert("Notificación enviada exitosamente");
    }, 100);
   };

   const handleSaveData = async (selectedItem) => {
      const onFailure = (error) => {
       // console.log(error)
        setIsLoadingRechazo(false)
      };
      const onSuccess = ({ data }) => {
        //console.log(data);
        setIsLoadingRechazo(false)
        fetchData()
      };
      const statusRechazo = selectedItem.FIESTATUSATB !== "2" ? "2" : selectedItem.FIESTATUSATB;
      const formDataToSend = {
            FCNOSERIE:selectedItem.FCNOSERIE,
            FITEMPORALIDADID:Number(selectedItem.FITEMPORALIDADID),
            FIANIOID: Number(selectedItem.FIANIOID),
            FITIPOATBID: Number(selectedItem.FITIPOATBID),
            FITRIMESTREID:Number(selectedItem.FITRIMESTREID),
            FIMARCAATBID: Number(selectedItem.FIMARCAATBID),
            FIMODELOATBID: Number(selectedItem.FIMODELOATBID),
            FCAFILIACION: selectedItem.FCAFILIACION,
            FIESTATUSATB: Number(statusRechazo),
            FIPAISID:Number(selectedItem.FIPAISID),
            FIMARCAID:Number(selectedItem.FIMARCAID),
            FISUCURSALID:Number(selectedItem.FISUCURSALID),
            FDFECHAREGISTRO:selectedItem.FDFECHAREGISTRO,
            FDFECHAACTUALIZACION:currentDate,
            FIBUENESTADO:Number(selectedItem.FIBUENESTADO),
            FIFUNCIONANDO:Number(selectedItem.FIFUNCIONANDO),
            FIMOTIVOID:formData.FIMOTIVOID,
            FCCOMENTARIORECHAZO:formData.FCCOMENTARIORECHAZO,
            FITIPOFOTOIDFRONTAL:Number(selectedItem?.TAFOTOS[0].FITIPOFOTOID),
            FCDESCRIPCIONFRONTAL:selectedItem?.TAFOTOS[0].FCDESCRIPCION,
            FCURLFOTOGRAFICAFRONTAL:selectedItem?.TAFOTOS[0].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONFRONTAL:selectedItem?.TAFOTOS[0].FDFECHAMODIFICACION,
            FITIPOFOTOIDTRASERA:Number(selectedItem?.TAFOTOS[1].FITIPOFOTOID),
            FCDESCRIPCIONTRASERA:selectedItem?.TAFOTOS[1].FCDESCRIPCION,
            FCURLFOTOGRAFICATRASERA:selectedItem.TAFOTOS[1].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONTRASERA:selectedItem.TAFOTOS[1].FDFECHAMODIFICACION,
            FITIPOFOTOIDLATERALDER:Number(selectedItem.TAFOTOS[2].FITIPOFOTOID),
            FCDESCRIPCIONLATERALDER:selectedItem.TAFOTOS[2].FCDESCRIPCION,
            FCURLFOTOGRAFICALATELRADER:selectedItem.TAFOTOS[2].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONLATERALDER:selectedItem.TAFOTOS[2].FDFECHAMODIFICACION,
            FITIPOFOTOIDLATERALIZ:Number(selectedItem.TAFOTOS[3].FITIPOFOTOID),
            FCDESCRIPCIONLATERALIZ:selectedItem.TAFOTOS[3].FCDESCRIPCION,
            FCURLFOTOGRAFICALATERALIZ:selectedItem.TAFOTOS[3].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONLATERALIZ:selectedItem.TAFOTOS[3].FDFECHAMODIFICACION,
      };
      fetchModuloAtbEdith(formDataToSend).then(onSuccess).catch(onFailure);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.FIPAISID,formData.FIMARCAID,formData.FISUCURSALID]);

  const fetchData = async () => {
    if(formData.FIPAISID !== null && formData.FIMARCAID!== null && formData.FISUCURSALID !== null  ){
      setIsLoading(true);
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };
      const onSuccess = ({ data }) => {
       // console.log("registros",data.body)
        setData(data.body);
        setIsLoading(false);
      };
      const serie = formData.FCNOSERIE===""?"0":formData.FCNOSERIE
      const afiliacion = formData.FCAFILIACION===""?"0":formData.FCAFILIACION
      GetModuloAtb(
         Number(formData.FIPAISID),
         Number(formData.FIMARCAID),
         Number(formData.FISUCURSALID),
         formData.FIANIOID,
         Number(formData.FITRIMESTREID),
         serie,
         Number(formData.FITIPOATBID),
         Number(formData.FIMARCAATBID),
         Number(formData.FIMODELOATBID),
         afiliacion,
      ).then(onSuccess).catch(onFailure);
    }
  };
  const fetchDataSendEmail = async () => {
    if(formData.FIPAISID !== null && formData.FIMARCAID!== null && formData.FISUCURSALID !== null  ){
      const serie = formData.FCNOSERIE===""?"0":formData.FCNOSERIE
      const afiliacion = formData.FCAFILIACION===""?"0":formData.FCAFILIACION
      const sentData = {
        FIPAISID: Number(formData.FIPAISID),
        FIMARCAID: Number(formData.FIMARCAID),
        FISUCURSALID: Number(formData.FISUCURSALID),
        FIANIOID: formData.FIANIOID,
        FITRIMESTREID: formData.FITRIMESTREID,
        FCNOSERIE: serie,
        FITIPOATBID: Number(formData.FITIPOATBID),
        FIMARCAATBID: Number(formData.FIMARCAATBID),
        FIMODELOATBID: Number(formData.FIMODELOATBID),
        FCAFILIACION: afiliacion,
        FCCORREO:mail
      }
      const onFailure = (error) => {};
      // Enviar la petición
      fetchModuloAtbSendReporteEmail(sentData);
      // Mostrar el alert después de un breve retraso
      setTimeout(() => {
        alert(
          "Tu solicitud está siendo procesada. Pronto recibirás un informe detallado en tu correo electrónico"
        );
      }, 2000); // 2000 milisegundos = 2 segundos de retraso

    }
  };
  
  useEffect(() => {
    // Verificar si la configuración está habilitada
    const isConfiguracionEnabled = configuracion !== false;
    //("Tienes permisos:", isConfiguracionEnabled);
    // Verificar si estamos en el trimestre actual
    const isCurrentTrimester = formData.FCANIOSTRIMESTRE === `${currentQuarter}${currentYear}`;
    ///console.log("Estamos en el trimestre y año:", isCurrentTrimester);
    // Determinar si se debe mostrar el botón
    const shouldShowButton = isConfiguracionEnabled && isCurrentTrimester;

    // Establecer el estado para mostrar el botón
    setShouldShowButton(shouldShowButton);
  }, [configuracion, formData.FCANIOSTRIMESTRE]);
  
  

 
  
    
  return (
    <>
     <Row justify="center">
      <Col xs={20} md={4} className="mt-3" al>
        {dataMarca && 
        <select
        className="input-content-select"
        value={formData.FIMARCAID}
        onChange={handleChange}
        name={"FIMARCAID"}
        disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
      >
        <option value="0">Todas las marcas</option>
        {dataMarca.map((option) => (
          <option
            key={option.FIMARCAID}
            value={option.FIMARCAID}
          >
            {option.FCNOMBRE}
          </option>
        ))}
      </select>
        }
      </Col>
      <Col xs={20} md={4} className="mt-3" al>
        {formData.FIMARCAID !== "" && (
              <>
                {isLoadingSucursal ? (
                  <LoadingSpinner />
                ) : (
                  <select
                    className="input-content-select"
                    value={formData.FISUCURSALID}
                    onChange={handleChange}
                    name={"FISUCURSALID"}
                    disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
                  >
                    <option value="0">Todas las sucursales</option>
                    {dataSucursal.map((option) => (
                      <option
                        key={option.FISUCURSALID}
                        value={option.FISUCURSALID}
                      >
                        {option.FISUCURSALID}-{option.FCNOMBRE}
                      </option>
                    ))}
                    
                  </select>
                )}
              </>
            )}
      </Col>
      <Col xs={20} md={4} className="mt-3" al>
        <select
          className="input-content-select"
          value={formData.FCANIOSTRIMESTRE}
          onChange={handleChange}
          name="FCANIOSTRIMESTRE"
        > 
          <option value="">Seleciona el Trimestre</option>
          {trimestresOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Col>
      <Col xs={20} md={4} className="mt-3" al>
        <button
              onClick={fetchData}
              className="button-styles-add-element-search"
              style={{ marginRight: "10px" }}
            >
              Buscar
            </button> 
      </Col>
      <Col xs={22} md={2} className="mt-3" al></Col>
        <Col xs={22} md={4} className="mt-3" al>
          <button
            onClick={handleAdd}
            className="button-styles-add-element"
            style={{ marginRight: "10px" }}
          >
            Agregar dispositivo
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={1} className="mt-3" al>
          <button
            onClick={toggleLoading}
            className="button-styles-add-element"
            style={{ marginRight: "10px" }}
          >
            <FilterListIcon />
          </button>
        </Col>
     {isLoadingData ? <>
      <Col xs={20} md={3} className="mt-3" al>
          <input
            type="text"
            placeholder="Serie"
            onChange={handleChange}
            className="input-content-select"
            name={"FCNOSERIE"}
            value={formData.FCNOSERIE}
          />
      </Col>
      <Col xs={20} md={3} className="mt-3" al>
          <select
                    className="input-content-select"
                    value={formData.FITIPOATBID}
                    onChange={handleChange}
                    name={"FITIPOATBID"}
                    //disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
                  >
                    <option value="0">Selecionar Todos</option>
                    {dataTipoAtb.map((option) => (
                      <option
                        key={option.FITIPOATBID}
                        value={option.FITIPOATBID}
                      >
                       {option.FCNOMBRETIPO}
                      </option>
                    ))}
                  </select>
      </Col>
      <Col xs={20} md={3} className="mt-3" al>
              <select
                    className="input-content-select"
                    value={formData.FIMARCAATBID}
                    onChange={handleChange}
                    name={"FIMARCAATBID"}
                    //disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
                  >
                    <option value="0">Selecionar Todos</option>
                    {dataMarcaAtb.map((option) => (
                      <option
                        key={option.FIMARCAATBID}
                        value={option.FIMARCAATBID}
                      >
                       {option.FCNOMBREMARCA}
                      </option>
                    ))}
                  </select>
      </Col>
      <Col xs={20} md={3} className="mt-3" al>
       {formData.FIMARCAATBID !== 0 && (
              <>
                {isLoadingModeloAtb ? (
                  <LoadingSpinner />
                ) : (
                  <select
                    className="input-content-select"
                    value={formData.FIMODELOATBID}
                    onChange={handleChange}
                    name={"FIMODELOATBID"}
                    //disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
                  >
                    <option value="0">Selecionar Todos</option>
                    {dataModeloAtb.map((option) => (
                      <option
                        key={option.FIMODELOATBID}
                        value={option.FIMODELOATBID}
                      >
                       {option.FCNOMBREMODELO}
                      </option>
                    ))}
                  </select>
                )}
              </>
            )}
      </Col>
      <Col xs={20} md={3} className="mt-3" al>
        <input
            type="text"
            placeholder="Afiliación"
            onChange={handleChange}
            className="input-content-select"
            name={"FCAFILIACION"}
            value={formData.FCAFILIACION}
          />
      </Col>
      <Col xs={20} md={2} className="mt-3" al>
      </Col>
      <Col xs={20} md={4} className="mt-3" al>
      {configuracion && 
       <>
        {formData.FIMARCAID !== 0 && formData.FISUCURSALID !== 0 && (
        <button
          onClick={fetchDataSendEmail}
          className="button-styles-add-element-search"
        >
          Generar CSV
        </button>
        )}
       </>
      }
      </Col>
     </>:<>
      <Col xs={20} md={3} className="mt-3" al>
        </Col>
        <Col xs={20} md={3} className="mt-3" al>
        </Col>
        <Col xs={20} md={3} className="mt-3" al>
        </Col>
        <Col xs={20} md={3} className="mt-3" al>
        </Col>
        <Col xs={20} md={3} className="mt-3" al>
        </Col>
        <Col xs={20} md={2} className="mt-3" al>
        </Col>
        <Col xs={20} md={4} className="mt-3" al>
        {configuracion && 
        <>
          {formData.FIMARCAID !== 0 && formData.FISUCURSALID !== 0 && (
          <button
            onClick={fetchDataSendEmail}
            className="button-styles-add-element-search"
          >
            Generar CSV
          </button>
          )}
        </>
      }
        </Col>
     </>
     }
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          {configuracion ?  <Table
              rowSelection={rowSelection}
              dataSource={data}
              rowKey={"FCNOSERIE"}
              columns={columns}
              loading={isLoading}
              scroll={{ x: 1400, y: 600 }}
            />: <Table
            dataSource={data}
            rowKey={"FCNOSERIE"}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 1400, y: 600 }}
          /> }
        </Col>
       
      </Row>
      <>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <AdministracionAtbAddForm
            selectedItem={selectedItem}
            setShowModal={setShowModal}
            marcaAtb={dataMarcaAtb}
            tipoAtb={dataTipoAtb}
            fetchData={fetchData}
            modalKey={modalKey}
          />
        </Modal>
        <Modal
          open={showModalRechazo}
          onCancel={() => setShowModalRechazo(false)}
          footer={null}
        > 
          <Row justify="space-around">
          <Col span={24}>
            <div className="input-content-titulo">
                Motivo de rechazo
            </div>
          </Col>
          <Col xs={24} md={22}>
            <div>
              <select
                className="input-content-select"
                value={formData.FIMOTIVOID}
                onChange={handleChange}
                name="FIMOTIVOID"
              > 
              <option value="">Seleciona el motivo de rechazados</option>
                <option value="1">Datos incorrectos </option>
                <option value="2">Equipo en otra tienda  </option>
                <option value="3">No. de serie erróneo  </option>
                <option value="4">Evidencia incorrecta </option>
                <option value="5">Otro</option>
              </select>
            </div>
          </Col>
          <Col xs={24} md={22}>
              <div>
              {formData.FIMOTIVOID === "5" &&
                  <InputForm
                  handleChange={handleChange}
                  value={formData.FCCOMENTARIORECHAZO}
                  name={"FCCOMENTARIORECHAZO"}
                  type={"text"}
                  label={"Comentario"}
                />
              }
            </div>
          </Col>
          </Row>
          <Col>
          </Col>
            <div className="modal-footer-edith">
            <button
              onClick={handleAddRechazoClose}
              type="submit"
              className={`button-modal ${
                isLoading ? "primary-button-disable" : "primary-button"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Guardar"}
            </button>
          </div>
        </Modal>
      </>
      {configuracion !== false &&
        <Row justify="center">
        {!shouldShowButton && <div><label className="text-context-inputs">Solo se permite enviar rechazos en el Trimestre actual</label></div>}
        </Row>
      }
      {shouldShowButton &&
         <Row justify="center">
         {configuracion !== false &&
           <Col xs={22} md={5} className="mt-3" al>
             <button
               onClick={handleAddRechazo}
               className="button-styles-add-element"
               style={{ marginRight: "10px" }}
             >
               Enviar rechazos
             </button>
           </Col> 
         }
       </Row>
      }
     
    </>
  )
}