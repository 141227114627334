import React, { useEffect, useMemo, useReducer } from "react";
import { AdminContext } from "./AdminContext";
import { getStoredValue, removeStoredValue, storeValue } from "../storage";
//import CookieManager from "@react-native-cookies/cookies";

const reducer = (prevState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        status: "restoreToken",
        brandfcNombre: action.brandfcNombre,
        brandfiMarcaId: action.brandfiMarcaId,
        brandfiBiId: action.brandfiBiId,
        brandfiEstatusId: action.brandfiEstatusId,
        branchFcNombre: action.branchFcNombre,
        branchFiSucursalId: action.branchFiSucursalId,
        branchFiPaisId: action.branchFiPaisId,
        rolId: action.rolId,
      };
    case "SIGN_OUT":
      return {
        status: "signOut",
        brandfcNombre: null,
        brandfiMarcaId: null,
        brandfiBiId: null,
        brandfiEstatusId: null,
        branchFcNombre: null,
        branchFiSucursalId: null,
        branchFiPaisId: null,
        rolId: null,
      };
    case "SIGN_IN":
      return {
        status: "signIn",
        brandfcNombre: action.brandfcNombre,
        brandfiMarcaId: action.brandfiMarcaId,
        brandfiBiId: action.brandfiBiId,
        brandfiEstatusId: action.brandfiEstatusId,
        branchFcNombre: action.branchFcNombre,
        branchFiSucursalId: action.branchFiSucursalId,
        branchFiPaisId: action.branchFiPaisId,
        rolId: action.rolId,
      };
  }
};

export function AdminProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    status: "idle",
    brandfcNombre: null,
    brandfiMarcaId: null,
    brandfiBiId: null,
    brandfiEstatusId: null,
    branchFcNombre: null,
    branchFiSucursalId: null,
    branchFiPaisId: null,
    rolId: null,
  });

  useEffect(() => {
    const loadAuthData = async () => {
      try {
        const brandfcNombre = await getStoredValue("brandfcNombre");
        const brandfiMarcaId = await getStoredValue("brandfiMarcaId");
        const brandfiBiId = await getStoredValue("brandfiBiId");
        const brandfiEstatusId = await getStoredValue("brandfiEstatusId");
        const branchFcNombre = await getStoredValue("branchFcNombre");
        const branchFiSucursalId = await getStoredValue("branchFiSucursalId");
        const branchFiPaisId = await getStoredValue("branchFiPaisId");
        const rolId = await getStoredValue("rolId");
        if (
          brandfcNombre !== null &&
          brandfiMarcaId !== null &&
          brandfiBiId !== null &&
          brandfiEstatusId !== null &&
          branchFcNombre !== null &&
          branchFiSucursalId !== null &&
          branchFiPaisId !== null &&
          rolId !== null
        ) {
          dispatch({
            type: "RESTORE_TOKEN",
            brandfcNombre,
            brandfiMarcaId,
            brandfiBiId,
            brandfiEstatusId,
            branchFcNombre,
            branchFiSucursalId,
            branchFiPaisId,
            rolId,
          });
        } else {
          dispatch({ type: "SIGN_OUT" });
        }
      } catch (e) {
        dispatch({ type: "SIGN_OUT" });
      }
    };
    if (state.status === "idle") {
      loadAuthData();
    }
  }, [state, dispatch]);

  const actions = useMemo(
    () => ({
      AdminIn: async (
        brandfcNombre,
        brandfiMarcaId,
        brandfiBiId,
        brandfiEstatusId,
        branchFcNombre,
        branchFiSucursalId,
        branchFiPaisId,
        rolId
      ) => {
        await Promise.all([
          storeValue("brandfcNombre", brandfcNombre),
          storeValue("brandfiMarcaId", brandfiMarcaId),
          storeValue("brandfiBiId", brandfiBiId),
          storeValue("brandfiEstatusId", brandfiEstatusId),
          storeValue("branchFcNombre", branchFcNombre),
          storeValue("branchFiSucursalId", branchFiSucursalId),
          storeValue("branchFiPaisId", branchFiPaisId),
          storeValue("rolId", rolId),
        ]);
        dispatch({
          type: "SIGN_IN",
          brandfcNombre,
          brandfiMarcaId,
          brandfiBiId,
          brandfiEstatusId,
          branchFcNombre,
          branchFiSucursalId,
          branchFiPaisId,
          rolId,
        });
      },
      AdminOut: async () => {
        await Promise.all([
          removeStoredValue("brandfcNombre"),
          removeStoredValue("brandfiMarcaId"),
          removeStoredValue("brandfiBiId"),
          removeStoredValue("brandfiEstatusId"),
          removeStoredValue("branchFcNombre"),
          removeStoredValue("branchFiSucursalId"),
          removeStoredValue("branchFiPaisId"),
          removeStoredValue("rolId"),
        ]);
        dispatch({ type: "SIGN_OUT" });
        // CookieManager.clearAll().then((success) => {
        //    console.log("CookieManager.clearAll =>", success);
        //    });
      },
    }),
    [dispatch]
  );

  return (
    <AdminContext.Provider value={{ ...state, ...actions }}>
      {children}
    </AdminContext.Provider>
  );
}
