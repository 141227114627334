import createAxiosInstance from "./LambdaApiIndicadores";
import { ENVIROMENT } from "../utilities"


export async function fetchIndicators(brandId, branchId, typeSearch, tokenProd) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/indicadores?brandId=${brandId}&branchId=${branchId}&typeSearch=${typeSearch}`: `home/indicadores?brandId=${brandId}&branchId=${branchId}&typeSearch=${typeSearch}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(requestConfig);
}
