import { createContext } from "react";

export const AdminContext = createContext({
  status: "idle",
  brandfcNombre: null,
  brandfiMarcaId: null,
  brandfiBiId: null,
  brandfiEstatusId: null,
  branchFcNombre: null,
  branchFiSucursalId: null,
  branchFiPaisId: null,
  rolId: null,
  AdminIn: () => Promise.resolve(),
  AdminOut: () => Promise.resolve(),
});
