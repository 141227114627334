import React from "react";
import "../theme/PolicasPrivacidad.css";

export const AvisoPrivacidad = () => {
  return (
    <div className="container-politicas">
      <div className="title-politicas">
        <h3>POLÍTICA DE PRIVACIDAD</h3>
      </div>
      <div className="box-info-politicas">
        <p>
          Centro de operaciones del gerente Alsea es una plataforma en línea que
          permite a los líderes o gerentes de los restaurantes de Grupo Alsea
          México (Organización) eficientar la operación de sus unidades
          agilizando los tiempos de solicitud, comunicación acceso y respuesta
          de distintos procesos.
        </p>
        <p>
          En esta plataforma se concentrarán y conectarán aquellas herramientas
          y/o servicios necesarios para la capacitación y servicio cotidiano de
          los restaurantes y líderes o gerentes de unidad (Usuario).
        </p>
        <p>
          Esta Política de privacidad describe cómo se recopila, utiliza y
          comparte su información cuando utiliza la Plataforma.
        </p>
        <p>
          La Organización empleadora del Usuario es la responsable de generar la
          cuenta, proporcionar y administrar los datos de acceso a la
          Plataforma, así como también de la recopilación y el uso de los datos
          que los usuarios envíen o proporcione a través de esta, dicho uso se
          rige por los términos acordados al momento de ingreso a la
          Organización, así como por el Aviso de privacidad que se pone a
          disposición de éstos para el tratamiento de sus datos personales, en
          el entendido de que la finalidad de esta Plataforma se encuentra
          contemplada en dicho Aviso puesto que es una herramienta de trabajo
          implementada por la Organización y solamente puede ser utilizada por
          el Usuario mientras exista una relación de trabajo o prestación de
          servicios.
        </p>
        <ol type="i">
          <li>
            ¿Qué tipo de información se recopila?
            <div>
              <p>
                Para el uso de la Plataforma se recopilará la siguiente
                información:
              </p>
              <ol>
                <li>Nombre de usuario y contraseña.</li>
                <li>
                  El contenido, las comunicaciones y otra información que
                  proporciona cuando usa la Plataforma, incluso cuando se
                  registra en una cuenta, crea o comparte contenido y envía
                  mensajes o se comunica con otros. Esto puede incluir
                  información en o sobre el contenido que proporciona;
                </li>
                <li>Comunicaciones con otros usuarios del Servicio;</li>
                <li>
                  Comunicaciones de usuarios, comentarios, solicitudes,
                  sugerencias e ideas enviadas a su Organización;
                </li>
              </ol>
            </div>
          </li>
          <li>
            ¿Cómo utiliza la Organización esta información?
            <div>
              <p>La información será utilizada por la Organización para:</p>
              <ol>
                <li>
                  Atender solicitudes, comunicar información importante para el
                  desempeño de sus funciones y actividades laborales.
                </li>
                <li>
                  Comunicarse con el usuario respecto al uso de la Plataforma;
                </li>
                <li>
                  Asociar la actividad en la Plataforma a través de diferentes
                  dispositivos operados por el mismo Usuario para mejorar el
                  funcionamiento general de la misma;
                </li>
                <li>
                  Identificar y corregir errores que puedan estar presentes; y
                </li>
                <li>
                  Realizar análisis de datos y sistemas, incluida la
                  investigación para mejorar el Servicio.
                </li>
              </ol>
            </div>
          </li>
          <li>
            Cambios en la Política de Privacidad
            <p>
              Esta Política de Privacidad puede ser actualizada de vez en
              cuando. La política de privacidad estará disponible en línea.
            </p>
          </li>
          <li>
            Contacto
            <p>
              Si tiene alguna pregunta sobre esta Política de privacidad o la
              Política de uso aceptable del lugar de trabajo, comuníquese con la
              Organización o a través del correo privacidadinfo@alsea.com.mx
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
