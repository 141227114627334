import createAxiosInstance from "../LambdaApiAdministracion";
import { ENVIROMENT } from "../../utilities"

export async function AddFetchBanner(body) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/admon/banner`: `/home/admon/banner`
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: body // Aquí cambiamos 'body' por 'data'
  };
  return axiosInstance(requestConfig);
}
