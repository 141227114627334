import createAxiosInstance from "./LambdaApiUsabilidad";
import { ENVIROMENT } from "../utilities"


export async function fetchBitacora(
  email,
  device,
  date,
  key,
  request,
  response,
  section,
  version,
  employee,
  brand,
  branch,
  country,
) {

  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/bitacora`: `home/bitacora`
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data:{
      mail: email,
      device: device,
      date_trans: date,
      key: key,
      request: request,
      response: response,
      section: section,
      version: version,
      employee: employee,
      brand: brand,
      branch: branch,
      country: country,
    }
  };
  
  return axiosInstance(requestConfig);

}
