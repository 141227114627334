import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { AddFetchCatalogoRecolecion } from "../Api/Administracion/AddFetchCatalogoRecolecion";
import { GetFetchSucursalesByMarca } from "../Api/Administracion/GetFetchSucursalesByMarca"
import { LoadingSpinner } from "../components/LoadingSpinner";
import axios from "axios";

export const AdministrationCatalogoRecolecionAdd = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  sucursal,
  modalKey
}) => {
  const objDefault = {
    pais: "",
    FIMARCAID: 0,
    nombreMarca: "",
    FISUCURSALID: "",
    nombreTienda: "",
    empresaValores: "",
    diaRecoleccion: "",
    estatus: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoadingSucursal, setIsLoadingSucursal] = useState(false);
  const [dataSucursal, setDataSucursales] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchDataSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.FIMARCAID]);

  const fetchDataSucursal = async () => {
      if (formData.FIMARCAID !== 0) {
        setIsLoadingSucursal(true);
        const onFailure = (error) => {
          setIsLoadingSucursal(false);
        };
        const onSuccess = ({ data }) => {
          //console.log(data.body.data);
          setDataSucursales(data.body.data);
          setIsLoadingSucursal(false);
        };
  
        GetFetchSucursalesByMarca(formData.FIMARCAID).then(onSuccess).catch(onFailure);
      }
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
        if (formData.FIMARCAID === "") {
          errors.FIMARCAID = "Seleciona la marca";
        } 
        if (formData.FISUCURSALID === "") {
          errors.FISUCURSALID = "Seleciona la surcursal";
        }
        if (formData.diaRecoleccion === "") {
          errors.diaRecoleccion = "Seleciona dia de recoleción";
        }
        if (formData.empresaValores === "") {
          errors.empresaValores = "Ingresa el nombre de la empresa";
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors("");
        } 
    setLoading(true)
    const onFailure = (error) => {
      //console.log("error", error);
      setLoading(false)
      alert(error);
    };
    const onSuccess = ({ data }) => {
      setLoading(false)
      //console.log(data);
      // eslint-disable-next-line no-lone-blocks
      if (data.statusCode === 200) {
        setFormData(objDefault);
        fetchData();
        setShowModal(false);
        alert("Envío exitoso");
      } else {
        alert(data.message);
      }
    };
    const nameBrand = marca.find(
      (it) => it.FIMARCAID === parseInt(formData.FIMARCAID)
    );

    const nameSucursal = sucursal.find(
      (it) => it.FISUCURSALID === parseInt(formData.FISUCURSALID)
    );

    AddFetchCatalogoRecolecion({
      code: 1 /*Obligatorio de tipo numérico entero*/,
      params: {
        pais: 1,
        marca: formData.FIMARCAID,
        nombreMarca: nameBrand.FCNOMBRE,
        sucursal: formData.FISUCURSALID,
        nombreTienda: nameSucursal.FCNOMBRE,
        empresaValores: formData.empresaValores,
        diaRecoleccion: formData.diaRecoleccion,
        estatus: 1,
      },
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const dataCatalogoDias = [
    {
      idDay: 1,
      name: "Lunes",
    },
    {
      idDay: 2,
      name: "Martes",
    },
    {
      idDay: 3,
      name: "Miercoles",
    },
    {
      idDay: 4,
      name: "Jueves",
    },
    {
      idDay: 5,
      name: "Viernes",
    },
    {
      idDay: 6,
      name: "Sabado",
    },
    {
      idDay: 7,
      name: "Domingo",
    },
  ];
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">Agregar Registro</div>
      </Col>
      <Col xs={24} md={10}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
        {errors && <div className="error-input-data">{errors.FIMARCAID}</div>}
      </Col>
      <Col xs={24} md={10}>
        {formData.FIMARCAID !== 0 && (
          <>
            {isLoadingSucursal ? (
              <LoadingSpinner />
            ) : (
              <>
                <label className="text-context-inputs">Sucursal:</label>
                <select
                  className="input-content"
                  value={formData.FISUCURSALID}
                  onChange={handleChange}
                  name={"FISUCURSALID"}
                  disabled={selectedItem ? true : false}
                >
                  <option value="0">Selecionar sucursal</option>
                  {dataSucursal.map((option) => (
                    <option
                      key={option.FISUCURSALID}
                      value={option.FISUCURSALID}
                    >
                      {option.FISUCURSALID}-{option.FCNOMBRE}
                    </option>
                  ))}
                </select>
                {errors && <div className="error-input-data">{errors.FISUCURSALID}</div>}
              </>
            )}
          </>
        )}
      </Col>

      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.empresaValores}
          name={"empresaValores"}
          type={"text"}
          label={"Empresa Valores"}
        />
         {errors && <div className="error-input-data">{errors.empresaValores}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputSelect
          value={formData.diaRecoleccion}
          handleChange={handleChange}
          options={dataCatalogoDias}
          label={"Dia de recoleción"}
          optionsKey={"idDay"}
          optionsName={"name"}
          name={"diaRecoleccion"}
        />
        {errors && <div className="error-input-data">{errors.diaRecoleccion}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
