import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { AddFetchTipoProducto } from "../Api/Administracion/AddFetchTipoProducto";
import { PutFetchTipoProducto } from "../Api/Administracion/PutFetchTipoProducto";

export const AdministrationTipoProductoAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  modalKey
}) => {
  const objDefault = {
    FITIPOPRODUCTOID: 0,
    FCDESCRIPCION: "",
    FIESTATUSID: 1,
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FITIPOPRODUCTOID: selectedItem.FITIPOPRODUCTOID,
        FCDESCRIPCION: selectedItem.FCDESCRIPCION,
        FIESTATUSID: selectedItem.FIESTATUSID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FITIPOPRODUCTOID: 0,
        FCDESCRIPCION: "",
        FIESTATUSID: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCDESCRIPCION" && value.length > 30) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCDESCRIPCION: "El nombre no debe exceder 30 caracteres.",
      }));
      return;
     }
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCDESCRIPCION === "") {
      errors.FCDESCRIPCION = "Ingresa el valor de nombre";
    } 
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }

    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };

      const onSuccess = ({ data }) => {
        //console.log(data);
        setLoading(false)
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      if (selectedItem) {
        PutFetchTipoProducto(formData).then(onSuccess).catch(onFailure);
      } else {
        AddFetchTipoProducto(formData).then(onSuccess).catch(onFailure);
      }
    }
      
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Tipo de producto
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCDESCRIPCION}
          name={"FCDESCRIPCION"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCDESCRIPCION}</div>}
      </Col>
      <div className="modal-footer-edith">
       <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
