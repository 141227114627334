import createAxiosInstance from "../LambdaApiAdministracion";
import { ENVIROMENT } from "../../utilities"

export async  function GetFetchSucursales(useFIESTATUS) {
  const axiosInstance = await createAxiosInstance();
  const baseUrl =  ENVIROMENT === "dev" ? `/admon/sucursal`: `/home/admon/sucursal`
  const url = useFIESTATUS ? `${baseUrl}?FIESTATUSID=1` : baseUrl;
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
