/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import "../theme/Administracion.css";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { PutFetchBanner } from "../Api/Administracion/PutFetchBanner";

export const AdministrationBannerEdithUrl = ({
  selectedItem,
  fetchData,
  setShowModalUrlEdith,
  modalKey
}) => {
  const objDefault = {
    FIMARCAID: 0,
    FIBANNERID: 0,
    FITIPOBANNERID: 1,
    FCLINKEXTERNO: "",
    FIROLID: 0,
    FCTITULO: "",
    FCDESCRIPCION: "",
    FCURLS3: "",
    FCEDITARLIGA: "",
    FIESTATUSID: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FIBANNERID: selectedItem.FIBANNERID,
        FITIPOBANNERID: selectedItem.FITIPOBANNERID,
        FCLINKEXTERNO: selectedItem.FCLINKEXTERNO,
        FIROLID: selectedItem.FIROLID,
        FCTITULO: selectedItem.FCTITULO,
        FCDESCRIPCION: selectedItem.FCDESCRIPCION,
        FCURLS3: selectedItem.FCURLS3,
        FCEDITARLIGA: true,
        FIESTATUSID: selectedItem.FIESTATUSID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: 0,
        FIBANNERID: 0,
        FITIPOBANNERID: 1,
        FCLINKEXTERNO: "",
        FIROLID: 0,
        FCTITULO: "",
        FCDESCRIPCION: "",
        FCURLS3: "",
        FCEDITARLIGA: "",
        FIESTATUSID: "",
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModalUrlEdith(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
    // Función para validar la estructura de una URL usando la interfaz URL
    const contieneHTTP = formData.FCURLS3.includes("http://") || formData.FCURLS3.includes("https://");
    if (formData.FCURLS3 === "") {
      errors.FCURLS3 = "Ingresa el valor de URL.";
    } else if (!contieneHTTP) {
      errors.FCURLS3 = "Ingresa una URL válida que comience con http:// o https://.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }

    function sendData(){
      setLoading(true);
      const onFailure = (error) => {
        // console.log("error", error);
        alert(error);
        setLoading(false);
      };

      const onSuccess = ({ data }) => {
        // console.log(data);
        setLoading(false);
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModalUrlEdith(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      PutFetchBanner(formData).then(onSuccess).catch(onFailure);
    }
      
  };

  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">Editar Url</div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCURLS3}
          name={"FCURLS3"}
          type={"text"}
          label={"Url"}
        />
         {errors && <div className="error-input-data">{errors.FCURLS3}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
