import React, { useState, useEffect } from "react";
import AveracheIcon from "../assets/3.png";
import "../theme/Indicadores.css";
import numbro from "numbro";

export const TableAvegache = (props) => {
  const { averageData } = props;
  const [ventaReal, setventaReal] = useState();
  const [ventaPtto, setventaPtto] = useState();
  const [ventaAA, setventaAA] = useState();
  const [ventaAAAvg, setventaAAAvg] = useState();
  const [ventaPttoAvg, setventaPttoAvg] = useState();

  useEffect(() => {
    const VReal =
      averageData.fnticket_prom == null || averageData.fnticket_prom === 0
        ? 0
        : numbro(averageData.fnticket_prom).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaReal(VReal);
    const Vppto =
      averageData.fnticket_prom_ppto == null ||
      averageData.fnticket_prom_ppto === 0
        ? 0
        : numbro(averageData.fnticket_prom_ppto).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaPtto(Vppto);

    const VRaa =
      averageData.fnticket_prom_aa == null || averageData.fnticket_prom_aa === 0
        ? 0
        : numbro(averageData.fnticket_prom_aa).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaAA(VRaa);

    //average
    const ptto =
      averageData.fnticket_prom_ppto === null ||
      averageData.fnticket_prom_ppto === 0
        ? 0
        : (averageData.fnticket_prom * 100) / averageData.fnticket_prom_ppto;
    setventaPttoAvg(Math.round(ptto));

    const aa =
      averageData.fnticket_prom_aa === null ||
      averageData.fnticket_prom_aa === 0
        ? 0
        : (averageData.fnticket_prom * 100) / averageData.fnticket_prom_aa;
    setventaAAAvg(Math.round(aa));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-table-sale-right">
      <div className="box-top-table-sale-right">
        <div className="box-sale-icon-right">
          <img className="IconImage" src={AveracheIcon} alt="Ventas" />
          <div className="title-top-table-indicadores">Ticket Promedio</div>
        </div>
        <div className="title-top-table-indicadores-real">Real</div>
        <div className="text-tota-real">${ventaReal} </div>
      </div>
      <div className="box-body-table-sale-right">
        <div className="item-box-table-sales1-right">
          <div className="text-vs">Vs PPTO</div>
          <div className="text-porcent">{ventaPttoAvg}%</div>
          <div className="text-presupues">${ventaPtto}</div>
        </div>
        <div className="item-box-table-sales3-right">
          <div className="text-vs">Vs AA</div>
          <div className="text-porcent"> {ventaAAAvg}%</div>
          <div className="text-presupues">${ventaAA}</div>
        </div>
      </div>
    </div>
  );
};
