import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchUploadUrl(
  branchFiSucursalId,
  brandfcNombre,
  fecha,
  typeimage,
  imagenW,
  tokenProd
) {

  const axiosInstance = await createAxiosInstance();

  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: "/reclasificacion/upload-image",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenProd}`,
    },
    data: {
      sucursal: branchFiSucursalId,
      nombreTienda: brandfcNombre,
      fechaRegistro: fecha,
      nombreFicha: typeimage,
      imagen: imagenW,
    },
  };
  
  return axiosInstance(requestConfig);
}
