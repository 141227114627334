import React, { useEffect, useMemo, useReducer } from "react";
import { UserContext } from "./UserContext";
import { getStoredValue, removeStoredValue, storeValue } from "../storage";

const reducer = (prevState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        status: "restoreToken",
        mail: action.mail,
        displayName: action.displayName,
        jobTitle: action.jobTitle,
        contry: action.contry,
        ceco: action.ceco,
        company: action.company,
        userEnable: action.userEnable,
        employeeId: action.employeeId,
        device: action.device,
        usuario: action.usuario,
      };
    case "SIGN_OUT":
      return {
        status: "signOut",
        mail: null,
        displayName: null,
        jobTitle: null,
        contry: null,
        ceco: null,
        company: null,
        userEnable: null,
        employeeId: null,
        device: null,
        usuario: null,
      };
    case "SIGN_IN":
      return {
        status: "signIn",
        mail: action.mail,
        displayName: action.displayName,
        jobTitle: action.jobTitle,
        contry: action.contry,
        ceco: action.ceco,
        company: action.company,
        userEnable: action.userEnable,
        employeeId: action.employeeId,
        device: action.device,
        usuario: action.usuario,
      };
  }
};

export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    status: "idle",
    mail: null,
    displayName: null,
    jobTitle: null,
    contry: null,
    ceco: null,
    company: null,
    userEnable: null,
    employeeId: null,
    device: null,
    usuario: null,
  });

  useEffect(() => {
    const loadAuthData = async () => {
      try {
        const mail = await getStoredValue("mail");
        const displayName = await getStoredValue("displayName");
        const jobTitle = await getStoredValue("jobTitle");
        const contry = await getStoredValue("contry");
        const ceco = await getStoredValue("ceco");
        const company = await getStoredValue("company");
        const userEnable = await getStoredValue("userEnable");
        const employeeId = await getStoredValue("employeeId");
        const device = await getStoredValue("device");
        const usuario = await getStoredValue("usuario");
        if (
          mail !== null &&
          displayName !== null &&
          jobTitle !== null &&
          contry !== null &&
          ceco != null &&
          company !== null &&
          userEnable !== null &&
          employeeId !== null &&
          device !== null &&
          usuario !== null
        ) {
          dispatch({
            type: "RESTORE_TOKEN",
            mail,
            displayName,
            jobTitle,
            contry,
            ceco,
            company,
            userEnable,
            employeeId,
            device,
            usuario,
          });
        } else {
          dispatch({ type: "SIGN_OUT" });
        }
      } catch (e) {
        dispatch({ type: "SIGN_OUT" });
      }
    };
    if (state.status === "idle") {
      loadAuthData();
    }
  }, [state, dispatch]);

  const actions = useMemo(
    () => ({
      userIn: async (
        mail,
        displayName,
        jobTitle,
        contry,
        ceco,
        company,
        userEnable,
        employeeId,
        device,
        usuario
      ) => {
        await Promise.all([
          storeValue("mail", mail),
          storeValue("displayName", displayName),
          storeValue("jobTitle", jobTitle),
          storeValue("contry", contry),
          storeValue("ceco", ceco),
          storeValue("company", company),
          storeValue("userEnable", userEnable),
          storeValue("employeeId", employeeId),
          storeValue("device", device),
          storeValue("usuario", usuario),
        ]);
        dispatch({
          type: "SIGN_IN",
          mail,
          displayName,
          jobTitle,
          contry,
          ceco,
          company,
          userEnable,
          employeeId,
          device,
          usuario,
        });
      },
      userOut: async () => {
        await Promise.all([
          removeStoredValue("mail"),
          removeStoredValue("displayName"),
          removeStoredValue("jobTitle"),
          removeStoredValue("contry"),
          removeStoredValue("ceco"),
          removeStoredValue("company"),
          removeStoredValue("userEnable"),
          removeStoredValue("employeeId"),
          removeStoredValue("device"),
          removeStoredValue("usuario"),
        ]);
        dispatch({ type: "SIGN_OUT" });
      },
    }),
    [dispatch]
  );

  return (
    <UserContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserContext.Provider>
  );
}
