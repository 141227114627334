/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { GetFetchMarcaAtb } from "../Api/Administracion/GetFetchMarcaAtb.js";
import EditIcon from "@mui/icons-material/Edit";
import { PutFetchMarcaAtb } from "../Api/Administracion/PutFetchMarcaAtb.js"
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { Table, Button, Input, Space, Col, Row, Switch, Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { AdministrationAtbAddMarca } from "./AdministrationAtbAddMarca";
import { SearchOutlined } from "@ant-design/icons";

export const TableAdministracionMarcaATB = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };

      const onSuccess = ({ data }) => {
        setData(data.body);
        setIsLoading(false);
      };

      GetFetchMarcaAtb(false).then(onSuccess).catch(onFailure);
    
  };

  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

 

  const HandleSwitch = ({ value, all }) => {
    const [hs, setHs] = useState(parseInt(value) === 1);
    const handleChangeSwitch = (v) => {
      setIsLoading(true)
      const newObj = {
        FIMARCAATBID: parseInt(all.FIMARCAATBID),
        FCNOMBREMARCA: all.FCNOMBREMARCA,
        FIESTATUS: v ? 1 : 0,
      };

      //console.log("all", all);
      //peticion....
      const onFailure = (error) => {
        setIsLoading(false)
        //console.log("error", error);
        alert(error);
      };
      const onSuccess = ({ data }) => {
        setIsLoading(false)
        //console.log("state", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 200) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
     // console.log("Nuevo objeto a enviar", newObj);
      PutFetchMarcaAtb(newObj).then(onSuccess).catch(onFailure);

      setHs(v);
    };
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };

  //table -->>>
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID Marca",
      dataIndex: "FIMARCAATBID",
      key: "FIMARCAATBID",
      sorter: (a, b) => parseInt(a.FCKEY) - parseInt(b.FCKEY),
      ...getColumnSearchProps("FIMARCAATBID"),
    },
    {
      title: "Nombre",
      dataIndex: "FCNOMBREMARCA",
      key: "FCNOMBREMARCA",
      ...getColumnSearchProps("FCNOMBREMARCA"),
    },
    {
      title: "Estatus",
      dataIndex: "FIESTATUS",
      key: "FIESTATUS",
      render: (item, all) => <HandleSwitch value={item} all={all} />,
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"], // Aquí estableces que los elementos activos estén seleccionados por defecto
      onFilter: (value, record) => record.FIESTATUS === Number(value),
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "10%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd}  className="button-styles-add-element">
            Agregar Marca
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={"FIMARCAATBID"}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 600, y: 600 }}
          />
        </Col>
      </Row>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <AdministrationAtbAddMarca
          selectedItem={selectedItem}
          fetchData={fetchData}
          setShowModal={setShowModal}
          //marca={dataMarca}
         // secciones={dataSecciones}
         modalKey={modalKey}
        />
      </Modal>
    </>
  );
};