import axios from "axios";
import { SelectToken } from "./SelectToken";

// Variable para almacenar la promesa de solicitud del token
let tokenPromise = null;

// Función para obtener un nuevo token sin reintentos
export const TokenCentralizado = async (AuthRemotes, ClientRemote, baseUrllocal) => {
  // Si ya hay una solicitud de token en curso, retorna la misma promesa
  if (tokenPromise) {
    console.log("Esperando a que se complete la solicitud de token en curso...");
    return tokenPromise;
  }

  // Crear y almacenar la promesa de la solicitud de token
  tokenPromise = (async () => {
    //console.log("Solicitando nuevo token...");
    try {
      const response = await fetch(AuthRemotes, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${ClientRemote}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (!response.ok) {
        throw new Error(`Error de red: ${response.statusText}`);
      }

      const data = await response.json();
      const accessToken = data.access_token;
      //console.log("accessToken-aws", accessToken);

      const identificador = await SelectToken(baseUrllocal);
      console.log("identificador", identificador);
      localStorage.setItem(identificador, accessToken);

      return accessToken;
    } catch (error) {
      console.error(`Error al obtener el nuevo token`, error);
      throw error; // Lanza el error después del último intento
    } finally {
      // Restablece la promesa para permitir futuras solicitudes de token
      tokenPromise = null;
    }
  })();

  return tokenPromise;
};
