import React from "react";

export const TextCierredeDiaFormSeparador = ({ textSeparador }) => {
  return (
    <div className="item-row-reclasificacion-separador">
      <div className="text-item-reclasificacion-separador ">
        {textSeparador}
      </div>
    </div>
  );
};
