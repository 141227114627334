import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { AddFetchSeccion } from "../Api/Administracion/AddFetchSeccion";
import { PutFetchSeccion } from "../Api/Administracion/PutFetchSeccion";

const AdministrationSeccionesAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  modalKey
}) => {
  const objDefault = {
    sec_key: "",
    sec_jsonseccion2: "",
    sec_jsonseccion3: "",
    sec_linkseccion1: "",
    sec_linkseccion2: "",
    sec_linkseccion3: "",
    sec_primeraseccion: "",
    sec_segundaseccion: "",
    sec_stackseccion2: "",
    sec_stackseccion3: "",
    sec_terceraseccion: "",
    sec_tiposeccion2: "",
    sec_urlimagen1: "",
    sec_urlimagen2: "",
    sec_urlimagen3: "",
    sec_versionminimaseccion2: "",
    sec_versionminimaseccion3: "",
    sec_estatus: "1",
    sec_ordenseccion2: "",
    sec_ordenseccion3: "",
    sec_primeraseccionid: "",
    sec_segundaseccionid: "",
    sec_terceraseccionid: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!selectedItem) {
      const stringPrimerId = formData.sec_primeraseccionid.toString();
      const idPrimerConCero =
        stringPrimerId.length === 1 ? `0${stringPrimerId}` : stringPrimerId;
      const stringsegundoId = formData.sec_segundaseccionid.toString();
      const idsegundoConCero =
        stringsegundoId.length === 1 ? `0${stringsegundoId}` : stringsegundoId;
      const stringTercerId = formData.sec_terceraseccionid.toString();
      const idtercerConCero =
        stringTercerId.length === 1 ? `0${stringTercerId}` : stringTercerId;
      const nuevoValorAcsKey = `${idPrimerConCero}${idsegundoConCero}${idtercerConCero}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        sec_key: nuevoValorAcsKey,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.sec_primeraseccionid,
    formData.sec_segundaseccionid,
    formData.sec_terceraseccionid,
  ]);

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        sec_key: selectedItem.FCKEY,
        sec_jsonseccion2: selectedItem.FCJSONSECCION2,
        sec_jsonseccion3: selectedItem.FCJSONSECCION3,
        sec_linkseccion1: selectedItem.FCLINKSECCION1,
        sec_linkseccion2: selectedItem.FCLINKSECCION2,
        sec_linkseccion3: selectedItem.FCLINKSECCION3,
        sec_primeraseccion: selectedItem.FCPRIMERASECCION,
        sec_segundaseccion: selectedItem.FCSEGUNDASECCION,
        sec_stackseccion2: selectedItem.FCSTACKSECCION2,
        sec_stackseccion3: selectedItem.FCSTACKSECCION3,
        sec_terceraseccion: selectedItem.FCTERCERASECCION,
        sec_tiposeccion2: selectedItem.FCTIPOSECCION2,
        sec_urlimagen1: selectedItem.FCURLIMAGEN1,
        sec_urlimagen2: selectedItem.FCURLIMAGEN2,
        sec_urlimagen3: selectedItem.FCURLIMAGEN3,
        sec_versionminimaseccion2: selectedItem.FCVERSIONMINIMASECCION2,
        sec_versionminimaseccion3: selectedItem.FCVERSIONMINIMASECCION3,
        sec_estatus: selectedItem.FIESTATUS,
        sec_ordenseccion2: selectedItem.FIORDENSECCION2,
        sec_ordenseccion3: selectedItem.FIORDENSECCION3,
        sec_primeraseccionid: selectedItem.FIPRIMERASECCIONID,
        sec_segundaseccionid: selectedItem.FISEGUNDASECCIONID,
        sec_terceraseccionid: selectedItem.FITERCERASECCION,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        sec_key: "",
        sec_jsonseccion2: "",
        sec_jsonseccion3: "",
        sec_linkseccion1: "",
        sec_linkseccion2: "",
        sec_linkseccion3: "",
        sec_primeraseccion: "",
        sec_segundaseccion: "",
        sec_stackseccion2: "",
        sec_stackseccion3: "",
        sec_terceraseccion: "",
        sec_tiposeccion2: "",
        sec_urlimagen1: "",
        sec_urlimagen2: "",
        sec_urlimagen3: "",
        sec_versionminimaseccion2: "",
        sec_versionminimaseccion3: "",
        sec_estatus: "1",
        sec_ordenseccion2: "",
        sec_ordenseccion3: "",
        sec_primeraseccionid: "",
        sec_segundaseccionid: "",
        sec_terceraseccionid: "",
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Validar que solo sean números y máximo 2 dígitos para los campos específicos
    if (
      (name === "sec_primeraseccionid" ||
        name === "sec_segundaseccionid" ||
        name === "sec_terceraseccionid") &&
      !/^\d{0,2}$/.test(value)
    ) {
      // No actualizar el estado si la validación no se cumple para estos campos
      // Esto evita que se ingresen más de 2 dígitos
      return;
    }

    // Actualizar el estado con el nuevo valor
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
      const errors = {};
      if (formData.sec_jsonseccion2 === "") {
        errors.sec_jsonseccion2 = "Por favor, agrega JSON de la segunda sección.";
      }
      if (formData.sec_jsonseccion3 === "") {
        errors.sec_jsonseccion3 = "Por favor, agrega JSON de la tercera sección.";
      }
      if (formData.sec_linkseccion1 === "") {
        errors.sec_linkseccion1 = "Por favor, agrega link de la primera sección.";
      }
      if (formData.sec_linkseccion2 === "") {
        errors.sec_linkseccion2 = "Por favor, agrega link de la segunda sección.";
      }
      if (formData.sec_linkseccion3 === "") {
        errors.sec_linkseccion3 = "Por favor, agrega link de la tercera sección.";
      }
      if (formData.sec_primeraseccion === "") {
        errors.sec_primeraseccion = "Por favor, agrega la primera sección.";
      }
      if (formData.sec_segundaseccion === "") {
        errors.sec_segundaseccion = "Por favor, agrega la segunda sección.";
      }
      if (formData.sec_stackseccion2 === "") {
        errors.sec_stackseccion2 = "Por favor, agrega stack de la segunda sección.";
      }
      if (formData.sec_stackseccion3 === "") {
        errors.sec_stackseccion3 = "Por favor, agrega stack de la tercera sección.";
      }
      if (formData.sec_terceraseccion === "") {
        errors.sec_terceraseccion = "Por favor, agrega la tercera sección.";
      }
      if (formData.sec_tiposeccion2 === "") {
        errors.sec_tiposeccion2 = "Por favor, agrega tipo de la segunda sección.";
      }
      const contieneHTTP = formData.sec_urlimagen1.includes("http://") || formData.sec_urlimagen1.includes("https://");
      if (formData.sec_urlimagen1 === "") {
        errors.sec_urlimagen1 = "Por favor, agrega URL de la imagen .";
      }else if (!contieneHTTP) {
        errors.sec_urlimagen1 = "Ingresa una URL válida que comience con http:// o https://.";
      }
      const contieneHTTP1 = formData.sec_urlimagen2.includes("http://") || formData.sec_urlimagen2.includes("https://");
      if (formData.sec_urlimagen2 === "") {
        errors.sec_urlimagen2 = "Por favor, agrega URL de la imagen .";
      }else if (!contieneHTTP1) {
        errors.sec_urlimagen2 = "Ingresa una URL válida que comience con http:// o https://.";
      }
      const contieneHTTP2 = formData.sec_urlimagen3.includes("http://") || formData.sec_urlimagen3.includes("https://");
      if (formData.sec_urlimagen3 === "") {
        errors.sec_urlimagen3 = "Por favor, agrega URL de la imagen .";
      }else if (!contieneHTTP2) {
        errors.sec_urlimagen3 = "Ingresa una URL válida que comience con http:// o https://.";
      }
      if (formData.sec_versionminimaseccion2 === "") {
        errors.sec_versionminimaseccion2 = "Por favor, agrega versión mínima de la segunda sección.";
      }
      if (formData.sec_versionminimaseccion3 === "") {
        errors.sec_versionminimaseccion3 = "Por favor, agrega versión mínima de la tercera sección.";
      }
      if (formData.sec_ordenseccion2 === "") {
        errors.sec_ordenseccion2 = "Por favor, agrega orden de la segunda sección.";
      }
      if (formData.sec_ordenseccion3 === "") {
        errors.sec_ordenseccion3 = "Por favor, agrega orden de la tercera sección.";
      }
      if (formData.sec_primeraseccionid === "") {
        errors.sec_primeraseccionid = "Por favor, agrega ID de la primera sección.";
      }
      if (formData.sec_segundaseccionid === "") {
        errors.sec_segundaseccionid = "Por favor, agrega ID de la segunda sección.";
      }
      if (formData.sec_terceraseccionid === "") {
        errors.sec_terceraseccionid = "Por favor, agrega ID de la tercera sección.";
      }
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors("");
        sendData();
      }

      function sendData(){
        setLoading(true)
        const onFailure = (error) => {
          //console.log("error", error);
          setLoading(false)
          alert(error);
        };

        const onSuccess = ({ data }) => {
          setLoading(false)
          // console.log(data);
          // eslint-disable-next-line no-lone-blocks

          if (data.statusCode === 201) {
            setFormData(objDefault);
            fetchData();
            setShowModal(false);
            alert("Envio exitoso");
          } else {
            alert(data.error);
          }
        };

        if (selectedItem) {
          PutFetchSeccion(formData).then(onSuccess).catch(onFailure);
        } else {
          AddFetchSeccion(formData).then(onSuccess).catch(onFailure);
        }
      }
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Sección
        </div>
      </Col>
      <Col xs={24} md={10}>
        <>
          <label className="text-context-inputs">Key:</label>
          <input
            type={"text"}
            name={"sec_key"}
            onChange={handleChange}
            value={formData.sec_key}
            disabled={true}
            className="input-content"
          />
        </>
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_jsonseccion2}
          name={"sec_jsonseccion2"}
          type={"text"}
          label={"Json segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_jsonseccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_jsonseccion3}
          name={"sec_jsonseccion3"}
          type={"text"}
          label={"Json tercera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_jsonseccion3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_linkseccion1}
          name={"sec_linkseccion1"}
          type={"text"}
          label={"Link primera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_linkseccion1}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_linkseccion2}
          name={"sec_linkseccion2"}
          type={"text"}
          label={"Link segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_linkseccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_linkseccion3}
          name={"sec_linkseccion3"}
          type={"text"}
          label={"Link tercera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_linkseccion3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_primeraseccion}
          name={"sec_primeraseccion"}
          type={"text"}
          label={"Nombre primera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_primeraseccion}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_segundaseccion}
          name={"sec_segundaseccion"}
          type={"text"}
          label={"Nombre segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_segundaseccion}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_terceraseccion}
          name={"sec_terceraseccion"}
          type={"text"}
          label={"Nombre tercera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_terceraseccion}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_stackseccion2}
          name={"sec_stackseccion2"}
          type={"text"}
          label={"Stack segunda sección"}
        />
         {errors && <div className="error-input-data">{errors.sec_stackseccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_stackseccion3}
          name={"sec_stackseccion3"}
          type={"text"}
          label={"Stack tercera sección"}
        />
         {errors && <div className="error-input-data">{errors.sec_stackseccion3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_tiposeccion2}
          name={"sec_tiposeccion2"}
          type={"text"}
          label={"Tipo segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_tiposeccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_urlimagen1}
          name={"sec_urlimagen1"}
          type={"text"}
          label={"Url imagen primera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_urlimagen1}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_urlimagen2}
          name={"sec_urlimagen2"}
          type={"text"}
          label={"Url imagen segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_urlimagen2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_urlimagen3}
          name={"sec_urlimagen3"}
          type={"text"}
          label={"Url imagen tercera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_urlimagen3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_versionminimaseccion2}
          name={"sec_versionminimaseccion2"}
          type={"text"}
          label={"Version minima segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_versionminimaseccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_versionminimaseccion3}
          name={"sec_versionminimaseccion3"}
          type={"text"}
          label={"Version minima tercera sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_versionminimaseccion3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_ordenseccion2}
          name={"sec_ordenseccion2"}
          type={"text"}
          label={"Orden segunda sección"}
        />
        {errors && <div className="error-input-data">{errors.sec_ordenseccion2}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_ordenseccion3}
          name={"sec_ordenseccion3"}
          type={"text"}
          label={"Orden tercera sección"}
        />
         {errors && <div className="error-input-data">{errors.sec_ordenseccion3}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_primeraseccionid}
          name={"sec_primeraseccionid"}
          type={"number"}
          label={"Id primera seccion"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
        />
         {errors && <div className="error-input-data">{errors.sec_primeraseccionid}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_segundaseccionid}
          name={"sec_segundaseccionid"}
          type={"number"}
          label={"Id segunda seccion"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
        />
        {errors && <div className="error-input-data">{errors.sec_segundaseccionid}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.sec_terceraseccionid}
          name={"sec_terceraseccionid"}
          type={"number"}
          label={"Id tercera seccion"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
        />
        {errors && <div className="error-input-data">{errors.sec_terceraseccionid}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};

export default AdministrationSeccionesAddForm;
