import createAxiosInstance from "./LambdaReclasificacionApi";

export async function fetchGetItemReporteCierreDia(
  branchFiPaisId,
  brandfiMarcaId,
  brandfiSucursalId,
  fechaRegistro
) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: `/reclasificacion`,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      code: 2,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: brandfiSucursalId,
        fechaRegistro: fechaRegistro,
      },
    },
  };
  
  return axiosInstance(requestConfig);
}
