import createAxiosInstance from "./LambdaApiAtb";
import { ENVIROMENT } from "../utilities"

export async function fetchModuloAtbAdd(
  formDataToSend
){
    const axiosInstance = await createAxiosInstance();
    const url =  ENVIROMENT === "dev" ? `/atb/terminalbancaria`: `/atb/terminalbancaria`
    const requestConfig = {
      method: "POST", // Cambia el método si es necesario
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
          FCNOSERIE: formDataToSend.FCNOSERIE,
          FITEMPORALIDADID: formDataToSend.FITEMPORALIDADID,
          FIANIOID: formDataToSend.FIANIOID,
          FITRIMESTREID: formDataToSend.FITRIMESTREID,
          FITIPOATBID: formDataToSend.FITIPOATBID,
          FIMARCAATBID: formDataToSend.FIMARCAATBID,
          FIMODELOATBID: formDataToSend.FIMODELOATBID,
          FCAFILIACION:formDataToSend.FCAFILIACION,
          FIESTATUSATB: formDataToSend.FIESTATUSATB,
          FIPAISID: formDataToSend.FIPAISID,
          FIMARCAID: formDataToSend.FIMARCAID,
          FISUCURSALID: formDataToSend.FISUCURSALID,
          FDFECHAREGISTRO: formDataToSend.FDFECHAREGISTRO,
          FDFECHAACTUALIZACION: formDataToSend.FDFECHAACTUALIZACION,
          FIBUENESTADO: formDataToSend.FIBUENESTADO,
          FIFUNCIONANDO: formDataToSend.FIFUNCIONANDO,
          FCCOMENTARIORECHAZO: formDataToSend.FCCOMENTARIORECHAZO,
          TAFOTOS: [
              {
                  FITIPOFOTOID: formDataToSend.FITIPOFOTOIDFRONTAL,
                  FCDESCRIPCION: formDataToSend.FCDESCRIPCIONFRONTAL,
                  FCURLFOTOGRAFICA: formDataToSend.FCURLFOTOGRAFICAFRONTAL,
                  FDFECHAMODIFICACION: formDataToSend.FDFECHAMODIFICACIONFRONTAL
              },
              {
                  FITIPOFOTOID: formDataToSend.FITIPOFOTOIDTRASERA,
                  FCDESCRIPCION: formDataToSend.FCDESCRIPCIONTRASERA,
                  FCURLFOTOGRAFICA: formDataToSend.FCURLFOTOGRAFICATRASERA,
                  FDFECHAMODIFICACION: formDataToSend.FDFECHAMODIFICACIONTRASERA
              },
              {
                  FITIPOFOTOID: formDataToSend.FITIPOFOTOIDLATERALIZ,
                  FCDESCRIPCION: formDataToSend.FCDESCRIPCIONLATERALIZ,
                  FCURLFOTOGRAFICA: formDataToSend.FCURLFOTOGRAFICALATERALIZ,
                  FDFECHAMODIFICACION: formDataToSend.FDFECHAMODIFICACIONLATERALIZ
              },
              {
                  FITIPOFOTOID: formDataToSend.FITIPOFOTOIDLATERALDER,
                  FCDESCRIPCION: formDataToSend.FCDESCRIPCIONLATERALDER,
                  FCURLFOTOGRAFICA:formDataToSend.FCURLFOTOGRAFICALATELRADER,
                  FDFECHAMODIFICACION: formDataToSend.FDFECHAMODIFICACIONLATERALDER
              }
          ]
      },
    };
    
    return axiosInstance(requestConfig);
  }
  