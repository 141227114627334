import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/fe51472c-6660-4ae5-995a-ac0f14ef2b07",
    clientId: "c7657147-6aaf-4823-a61f-98b01746067e",
    //redirectUri: "https://dgb4fs7wtnrk4.cloudfront.net",
    //redirectUri: "http://localhost:3000",
    redirectUri: "https://centro.operaciones.alsea.net",
    // redirectUri: "https://dx78ko4g1087t.cloudfront.net",
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },

  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Param
const authenticationParameters = {
  scopes: ["openid", "profile", "user.read"],
};

// Options

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
