import React, { useState, useEffect } from "react";
import { TableSales } from "../components/TableSales";
import { TableOrders } from "../components/TableOrders";
import { TableAvegache } from "../components/TableAvegache";
import { UseAdmin } from "../hooks/UseAdmin";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { fetchIndicators } from "../Api/fetchIndicators";
import { LoadingSpinner } from "./LoadingSpinner";

export const IndicatorsLastTwelve = () => {
  const [isLoading, setLoading] = useState(false);
  const { brandfiBiId, branchFiSucursalId } = UseAdmin();
  const { tokenProd } = UseTokenGlobal();
  const typeIndicator = "last12weeks";
  const [salesData, setSalesData] = useState(null);
  const [ordersData, setOrdesData] = useState(null);
  const [averageData, setAverageData] = useState(null);

  useEffect(() => {
    getDataIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataIndicators = async () => {
    setLoading(true);
    if (
      brandfiBiId != null &&
      branchFiSucursalId != null &&
      typeIndicator != null 
    ) {
      const onFailure = (error) => {
        // console.log(error);
        setLoading(false);
      };

      const onSuccess = async ({ data }) => {
        // console.log(data);
        setSalesData(data.body.ventas[0]);
        setOrdesData(data.body.ordenes[0]);
        setAverageData(data.body.ticket_promedio[0]);
        setLoading(false);
      };

      fetchIndicators(brandfiBiId, branchFiSucursalId, typeIndicator, tokenProd)
        .then(onSuccess)
        .catch(onFailure);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="container-indicators">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {salesData != null && ordersData != null && averageData != null ? (
            <>
              <div className="conetent-company-date-row">
                <div className="text-company">
                  <div className="day-style">{branchFiSucursalId}</div>
                </div>
                <div className="text-company-date">
                  <div className="day-style">Últimas 12 semanas</div>
                </div>
              </div>
              <div className="container-table">
                <div className="row-table-left">
                  {salesData !== null && <TableSales salesData={salesData} />}
                </div>
                <div className="row-table-right">
                  {ordersData !== null && (
                    <TableOrders ordersData={ordersData} />
                  )}
                  {averageData !== null && (
                    <TableAvegache averageData={averageData} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div>No hay datos por mostrar</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
