/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import "../theme/Reclasificacion.css";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import imgCierre from "../assets/cargaTusCierresdeLote.png";
import imgDeposito from "../assets/cargaFichaDeposito.png";
import imgEntrega from "../assets/cargaFichaEntregaVaores.png";
import { TextCierredeDiaFormSeparador } from "./TextCierredeDiaFormSeparador";
//import Modal from "react-responsive-modal";
import { fetchUploadUrl } from "../Api/fetchUploadUrl";
import { LoadingSpinner } from "./LoadingSpinner";
import { fetchBitacora } from "../Api/fetchBitacora";
import { fetchReclasificacionEdit } from "../Api/fetchReclasificacionEdit";
import { fetchReclasificacionCatalogoDias } from "../Api/fetchReclasificacionCatalogoDias";
import { Modal, Image } from "antd";
import {
  UseAdmin,
  UseUser,
  UseAuth,
} from "../hooks";
import axios from "axios";
import Switch from "react-switch";
import { NumericFormat } from "react-number-format";
import { VERSION_APP } from "../utilities";
registerLocale("es", es);

export const ReclasificacionIngresosFormEdith = (props) => {
  const { info, formasPago, fecha } = props;
  const [errors, setErrors] = useState({});
  const { uniqueId } = UseAuth();
  const { brandfiMarcaId, branchFiSucursalId, branchFiPaisId, branchFcNombre } =
    UseAdmin();
  const { employeeId, mail, device } = UseUser();
  ///Datos de dispositivo
  const [deviceInfo, setDeviceInfo] = useState(null);
  ///Datos de location
  const [locationLatitude, setLocationLatitude] = useState(null);
  const [locationLongitude, setLocationLongitude] = useState(null);

  ///Datos para dia de recolecion
  const [isDiaRecolecion, setIsDiaRecolecion] = useState();

  //Datos para imagenes
  const imageUrlnoNull =
    info.records.urlCierreLote === "Null" ? null : info.records.urlCierreLote;

  const imagenValoresUrlnoNull =
    info.records.urlImagenEntregaValores === "Null"
      ? null
      : info.records.urlImagenEntregaValores;

  const imagenDepositoUrlNull =
    info.records.urlImagenFichaDeposito === "Null"
      ? null
      : info.records.urlImagenFichaDeposito;

  const [isShowImage, setIsShowImage] = useState(false);
  const [isShowImageDep, setIsShowImageDep] = useState(false);
  const [isShowImageVal, setIsShowImageVal] = useState(false);

  const [modalVisibleFicha, setModalVisibleFicha] = useState(false);
  const [modalVisibleValores, setModalVisibleValores] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageURL, setImageURL] = useState(imageUrlnoNull);
  const [imageDepositoURL, setImageDepositoURL] = useState(
    imagenDepositoUrlNull
  );
  const [imageValoresURL, setImageValoresURL] = useState(
    imagenValoresUrlnoNull
  );
  const [image, setImage] = useState(null);
  const [imageValores, setImageValores] = useState(null);
  const [imageDeposito, setImageDeposito] = useState(null);
  const enableTicket = imagenValoresUrlnoNull !== null ? false : true;
  const [checked, setChecked] = useState(enableTicket);
  const [suma, setSuma] = useState();
  const [efectivoDepositar, setEfectivoDepositar] = useState();
  const [propina, setPropina] = useState(info.records.propina);
  const [nombre, setNombre] = useState(info.records.nombreGerente);
  const [formDataLoaded, setFormDataLoaded] = useState(false);
  const [datosEnviados, setDatosEnviados] = useState(false);
  const textComent =
    info.records.comentarios === "None" ||
    info.records.comentarios === null ||
    info.records.comentarios === undefined
      ? ""
      : info.records.comentarios;
  const dataTicket =
  info.records.noTicket === "None" ||
  info.records.noTicket === null ||
  info.records.noTicket === undefined ? "": info.records.noTicket;   
  const [comentario, setComentario] = useState(textComent);
  const [noTicket, setnoTicket] = useState(dataTicket);
  const [ImagenChangeCierre, setImagenChangeCierre] = useState(false);
  const [ImagenChangeFicha, setImagenChangeFicha] = useState(false);
  const [ImagenChangeValores, setImagenChangeValores] = useState(false);

  const [formData, setFormData] = useState({
    amex: info.records.formaPagoReclasificacion[0].cantidad,
    tpv: info.records.formaPagoReclasificacion[1].cantidad,
    pinpad: info.records.formaPagoReclasificacion[2].cantidad,
    vales: info.records.formaPagoReclasificacion[3].cantidad,
    valescdmx: info.records.formaPagoReclasificacion[4].cantidad,
    delivery: info.records.formaPagoReclasificacion[5].cantidad,
    didi: info.records.formaPagoReclasificacion[6].cantidad,
    didicredito: info.records.formaPagoReclasificacion[7].cantidad,
    rappi: info.records.formaPagoReclasificacion[8].cantidad,
    rapicargo: info.records.formaPagoReclasificacion[9].cantidad,
    uber: info.records.formaPagoReclasificacion[10].cantidad,
    uberdirect: info.records.formaPagoReclasificacion[11].cantidad,
    mercadopago: info.records.formaPagoReclasificacion[12].cantidad,
    wow: info.records.formaPagoReclasificacion[13].cantidad,
    mop: info.records.formaPagoReclasificacion[14].cantidad,
    startbuckRegords: info.records.formaPagoReclasificacion[15].cantidad,
    scanpay: info.records.formaPagoReclasificacion[16].cantidad,
    bkapp: info.records.formaPagoReclasificacion[17].cantidad,
    eventosWalmart: info.records.formaPagoReclasificacion[18].cantidad,
    eventosEmpresariales: info.records.formaPagoReclasificacion[19].cantidad,
    efectivomxp: info.records.formaPagoReclasificacion[20].cantidad,
    efectivousd: info.records.formaPagoReclasificacion[21].cantidad,
    didiEfectivo:
      info.records.formaPagoReclasificacion.length >= 23
        ? info.records.formaPagoReclasificacion[22].cantidad
        : "",
    uberEfectivo:
      info.records.formaPagoReclasificacion.length >= 24
        ? info.records.formaPagoReclasificacion[23].cantidad
        : "",
    rappiEfectivo:
      info.records.formaPagoReclasificacion.length >= 25
        ? info.records.formaPagoReclasificacion[24].cantidad
        : "",
    anticipoOtrosCreditos:
      info.records.formaPagoReclasificacion.length >= 26
        ? info.records.formaPagoReclasificacion[25].cantidad
        : "",
    otrosCreditos:
      info.records.formaPagoReclasificacion.length >= 27
        ? info.records.formaPagoReclasificacion[26].cantidad
        : "",
    deliverySr:
      info.records.formaPagoReclasificacion.length >= 28
        ? info.records.formaPagoReclasificacion[27].cantidad
        : "",
  });

  const handleChangCheck = () => {
    setChecked(!checked);
  };

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  };

  ///formate de hora
  const date = new Date();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let segundos = date.getSeconds();
  hour = hour < 10 ? `0${hour}` : hour;
  minute = minute < 10 ? `0${minute}` : minute;
  segundos = segundos < 10 ? `0${segundos}` : segundos;

  const hoyfecha = `${fecha} ${hour}:${minute}:${segundos}`;


  const handleValueChange = (values, nombreCampo) => {
    setFormData({
      ...formData,
      [nombreCampo]: values.floatValue,
    });
  };

  const [formDataNoSuma, setformDataNoSuma] = useState({
    VTotal: info.records.ventaTotal,
    depostitoTombola: info.records.depositoTombola,
    propinpor: info.records.porcentajePropina,
  });

  const handleChangeNoSuma = (values, nombreCampo) => {
    console.log("values",values)
    console.log("nombreCampo",nombreCampo)
    setformDataNoSuma({
      ...formDataNoSuma,
      [nombreCampo]: values.floatValue,
    });
  };
  

  const handleChangeComentario = (event) => {
    const inputValue = event.target.value;
    setComentario(inputValue.slice(0, 300));
  };
  const handleChangeTicket = (event) => {
    const inputValue = event.target.value;
    setnoTicket(inputValue.slice(0, 12));
  };

  useEffect(() => {
    // Calcula la suma total cuando cambian los valores de los campos
    const total = Object.values(formData).reduce((acumulador, valor) => {
      // Convierte el valor a número (usando parseFloat para valores decimales)
      const numeroValor = parseFloat(valor);

      // Verifica si es un número válido antes de sumarlo
      if (!isNaN(numeroValor)) {
        return acumulador + numeroValor;
      } else {
        return acumulador;
      }
    }, 0);

    /*// Resta el valor VTotal (asegúrate de convertirlo también)
    const VTotalNumero = parseFloat(formDataNoSuma.VTotal);
    const depositoTombola = parseFloat(formDataNoSuma.depostitoTombola);

    const sumaTotal =
      !isNaN(VTotalNumero) && !isNaN(depositoTombola)
        ? total - VTotalNumero - depositoTombola
        : total;*/

    setSuma(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    setPropina((Number(formDataNoSuma.propinpor) * Number(suma)) / 100);
  }, [suma, formDataNoSuma.propinpor]);

  useEffect(() => {
    setEfectivoDepositar(
      Number(formData.efectivomxp) +
        Number(formData.efectivousd) -
        Number(formDataNoSuma.depostitoTombola)
    );
  }, [
    formData.efectivomxp,
    formDataNoSuma.depostitoTombola,
    formData.efectivousd,
  ]);

  useEffect(() => {
    // Función para cargar los datos del formulario al abrir la aplicación
    const loadFormData = async () => {
      try {
        // Obtener los datos del formulario guardados en AsyncStorage
        const saveDataLocal = `formData-${fecha}`;
        const savedFormData = localStorage.getItem(saveDataLocal);

        if (savedFormData) {
          // Si se encuentran datos guardados, analizarlos y establecerlos en el estado
          const parsedFormData = JSON.parse(savedFormData);
          setFormData(parsedFormData.formData);
          setImageURL(parsedFormData.imageURL);
          //setImage(parsedFormData.imageURL);
          setImageDepositoURL(parsedFormData.imageDepositoURL);
          // setImageDeposito(parsedFormData.imageDepositoURL);
          setImageValoresURL(parsedFormData.imageValoresURL);
          //setImageValores(parsedFormData.imageValoresURL);
          setNombre(parsedFormData.nombre);
          //setComentario(parsedFormData.comentario);
          setformDataNoSuma(parsedFormData.formDataNoSuma);
          alert("Datos de cierre de día pendientes por enviar");
        }
      } catch (error) {
        //console.error("Error al cargar los datos del formulario:", error);
      } finally {
        setFormDataLoaded(true);
      }
    };

    if (!formDataLoaded && !datosEnviados) {
      loadFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataLoaded, datosEnviados]);

  useEffect(() => {
    // Guardar los datos del formulario en AsyncStorage
    if (formDataLoaded && !datosEnviados) {
      if (
        Object.values(formData).some((value) => value !== null) ||
        imageDepositoURL !== null ||
        imageValoresURL !== null ||
        imageURL !== null ||
        nombre !== null ||
        comentario !== "" ||
        Object.values(formDataNoSuma).some((value) => value !== null)
      ) {
        // Solo guarda los datos si al menos uno de ellos está presente
        saveDataLocally();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData,
    formDataNoSuma,
    imageDepositoURL,
    imageValoresURL,
    imageURL,
    nombre,
    comentario,
    formDataLoaded,
    datosEnviados,
  ]);

  const saveDataLocally = async () => {
    try {
      // Guardar los datos del formulario en AsyncStorage
      const totalData = {
        formData,
        formDataNoSuma,
        imageURL,
        imageDepositoURL,
        imageValoresURL,
        nombre,
        fecha: hoyfecha,
      };
      const saveDataLocal = `formData-${fecha}`;
      localStorage.setItem(saveDataLocal, JSON.stringify(totalData));
      //console.log(`Datos guardados correctamente en ${saveDataLocal}`);
    } catch (error) {
      //console.error("Error al guardar los datos:", error);
    }
  };

  ///UseEfect para catalogo de recolecion
  useEffect(() => {
    getCatalogoRecolecion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fecha]);
  const getCatalogoRecolecion = () => {
    if(branchFiPaisId!== null && brandfiMarcaId !== null && branchFiSucursalId !== null){
      const onFailure = (error) => {
        // console.log(error);
      };
      // console.log(fecha);
      const onSuccess = ({ data }) => {
        //console.log("data", data);
        const filteredRecords = data.records.filter(record => record.estatus === 1);
        const numerosDiaRecoleccion = filteredRecords.map(record => record.diaRecoleccion);
        //console.log(numerosDiaRecoleccion);
        ///Obtener  day format
        const dateParts = fecha.split("/");
        const year = parseInt(dateParts[2], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const day = parseInt(dateParts[0], 10);
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
       // console.log("Numero dia", dayOfWeek);
        const encontrado = numerosDiaRecoleccion.some((item) => item === dayOfWeek);
       // console.log("dia de recolecion", encontrado);
        setIsDiaRecolecion(encontrado);
      };
      fetchReclasificacionCatalogoDias(
        Number(branchFiPaisId),
        Number(brandfiMarcaId),
        Number(branchFiSucursalId)
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  useEffect(() => {
    const getPublicIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setDeviceInfo(`Ip publica ${response.data.ip}`);
      } catch (error) {
        console.error("Error al obtener la IP pública:", error);
      }
    };

    getPublicIP();
  }, []);

  useEffect(() => {
    // Función para obtener la ubicación
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationLatitude(position.coords.latitude);
            setLocationLongitude(position.coords.longitude);
          },
          (error) => alert("Error al obtener la ubicación:", error)
        );
      } else {
        alert("Geolocalización no es soportada por este navegador.");
      }
    };

    // Llamamos a la función para obtener la ubicación al montar el componente
    getLocation();
  }, []);

  const handleImageChange = (event, setImageCallback) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setImageCallback(base64String);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeFicha = (event) => {
    handleImageChange(event, setImage);
  };

  const handleImageChangeDeposito = (event) => {
    handleImageChange(event, setImageDeposito);
  };

  const handleImageChangeValores = (event) => {
    handleImageChange(event, setImageValores);
  };

  const uploadImage = (
    type,
    imageFun,
    setImageURLFuntion,
    setIsShowImageFuntion,
    setModalVisibleFuntion
  ) => {
    setIsShowImageFuntion(true);
    const onFailure = ({ error, config }) => {
      // console.log(error);
      getBitacora(config, "Subir imagen", error);
      alert(error);
      setIsShowImageFuntion(false);
    };
    const onSuccess = ({ data, config }) => {
      //  console.log(data);
      getBitacora(config, "Subir imagen", data);
      setImageURLFuntion(data[type]);
      setModalVisibleFuntion(false);
      setIsShowImageFuntion(false);
      {
        type === "Cierre_Lote"
          ? setImagenChangeCierre(true)
          : type === "Ficha_Deposito"
          ? setImagenChangeFicha(true)
          : type === "Ficha_Entrega_Valores"
          ? setImagenChangeValores(true)
          : "";
      }
    };
    fetchUploadUrl(
      Number(branchFiSucursalId),
      branchFcNombre,
      hoyfecha,
      type,
      imageFun,
    )
      .then(onSuccess)
      .catch(onFailure);
  };

  const saveImage = () => {
    uploadImage(
      "Cierre_Lote",
      image,
      setImageURL,
      setIsShowImage,
      setModalVisible
    );
  };

  const saveImageDeposito = () => {
    uploadImage(
      "Ficha_Deposito",
      imageDeposito,
      setImageDepositoURL,
      setIsShowImageDep,
      setModalVisibleFicha
    );
  };

  const saveImageValores = () => {
    uploadImage(
      "Ficha_Entrega_Valores",
      imageValores,
      setImageValoresURL,
      setIsShowImageVal,
      setModalVisibleValores
    );
  };

  const getBitacora = async (
    requestStore,
    description,
    responseData,
  ) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      requestStore != null &&
      responseData != null
    ) {
      const onFailure = (error) => {
        // console.log("bitacora", error);
      };

      const onSuccess = ({ data }) => {
        // console.log(description, data);
      };

      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        requestStore,
        responseData,
        description,
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  ///Funcion para Enviar a guardar el dato

  const setSaveReclasificacionEdith = () => {
    //  console.log("suma", suma);

    if (!isDiaRecolecion) {
      // console.log("no dia de recolecion");
      setFormToFetch();
    } else {
      setFormToFetch();
    }

    function setFormToFetch() {
      // Validar los campos del formulario
      const errors = {};
      if (formasPago.some((item) => item === 1) && formData.amex == null) {
        //alert("Ingresa el valor de Amex");
        errors.amex = "Ingresa el valor de Amex";
        console.log(errors.amex);
      }
      if (formasPago.some((item) => item === 2) && formData.tpv == null) {
        //alert("Ingresa el valor de TPV");
        errors.tpv = "Ingresa el valor de TPV";
      }
      if (formasPago.some((item) => item === 3) && formData.pinpad == null) {
        //alert("Ingresa el valor de PinPad");
        errors.pinpad = "Ingresa el valor de PinPad";
      }
      if (formasPago.some((item) => item === 4) && formData.vales == null) {
        //alert("Ingresa el valor de Vales");
        errors.vales = "Ingresa el valor de Vales";
      }
      if (formasPago.some((item) => item === 5) && formData.valescdmx == null) {
        //alert("Ingresa el valor de Vales Cdmx");
        errors.valescdmx = "Ingresa el valor de Vales CDMX";
      }
      if (formasPago.some((item) => item === 6) && formData.delivery == null) {
        //alert("Ingresa el valor Delivery Alse");
        errors.delivery = "Ingresa el valor de Delivery Alsea";
      }
      if (formasPago.some((item) => item === 7) && formData.didi == null) {
        //alert("Ingresa el valor de Didi");
        errors.didi = "Ingresa el valor de Didi";
      }
      if (
        formasPago.some((item) => item === 8) &&
        formData.didicredito == null
      ) {
        // alert("Ingresa el valor de Didi credito");
        errors.didicredito = "Ingresa el valor de Didi Creditos";
      }
      if (formasPago.some((item) => item === 9) && formData.rappi == null) {
        // alert("Ingresa el valor de Rappi");
        errors.rappi = "Ingresa el valor de Rappi";
      }
      if (
        formasPago.some((item) => item === 10) &&
        formData.rapicargo == null
      ) {
        //alert("Ingresa el valor de Rappi cargo");
        errors.rapicargo = "Ingresa el valor de Rappi Cargo";
      }
      if (formasPago.some((item) => item === 11) && formData.uber == null) {
        //7alert("Ingresa el valor de Uber");
        errors.uber = "Ingresa el valor de Uber";
      }
      if (
        formasPago.some((item) => item === 12) &&
        formData.uberdirect == null
      ) {
        //alert("Ingresa el valor de Uber Direct");
        errors.uberdirect = "Ingresa el valor de Uber direct";
      }
      if (
        formasPago.some((item) => item === 13) &&
        formData.mercadopago == null
      ) {
        //alert("Ingresa el valor de Mercado pago");
        errors.mercadopago = "Ingresa el valor de  Mercado Pago";
      }
      if (formasPago.some((item) => item === 14) && formData.wow == null) {
        //alert("Ingresa el valor de WOw");
        errors.wow = "Ingresa el valor de Wow";
      }
      if (formasPago.some((item) => item === 15) && formData.mop == null) {
        //alert("Ingresa el valor de MOP");
        errors.mop = "Ingresa el valor de MOP";
      }
      if (
        formasPago.some((item) => item === 16) &&
        formData.startbuckRegords == null
      ) {
        //alert("Ingresa el valor de StarBucks Rewords");
        errors.startbuckRegords = "Ingresa el valor de StarBucks Rewards";
      }
      if (formasPago.some((item) => item === 17) && formData.scanpay == null) {
        //alert("Ingresa el valor de Scan & Pay");
        errors.scanpay = "Ingresa el valor de  Scan & Pay";
      }
      if (formasPago.some((item) => item === 18) && formData.bkapp == null) {
        //alert("Ingresa el valor de BK App");
        errors.bkapp = "Ingresa el valor de  BK App";
      }
      if (
        formasPago.some((item) => item === 19) &&
        formData.eventosWalmart == null
      ) {
        // alert("Ingresa el valor de Eventos Walmart");
        errors.eventosWalmart = "Ingresa el valor de Eventos Walmart";
      }
      if (
        formasPago.some((item) => item === 20) &&
        formData.eventosEmpresariales == null
      ) {
        // alert("Ingresa el valor de Eventos Empresariales");
        errors.eventosEmpresariales =
          "Ingresa el valor de Eventos Empresariales";
      }
      if (
        formasPago.some((item) => item === 21) &&
        formData.efectivomxp == null
      ) {
        //  alert("Ingresa el valor de Efectivo MXP");
        errors.efectivomxp = "Ingresa el valor de Efectivo MXP";
      }
      if (
        formasPago.some((item) => item === 22) &&
        formData.efectivousd == null
      ) {
        // alert("Ingresa el valor de Efectivo USD");
        errors.efectivousd = "Ingresa el valor de Efectivo USD";
      }
      if (
        formasPago.some((item) => item === 23) &&
        formDataNoSuma.propinpor == null
      ) {
        // alert("Ingresa el porcentaje de propina");
        errors.propinpor = "Ingresa el porcentaje de propina";
      }
      if (
        formasPago.some((item) => item === 24) &&
        formData.didiEfectivo == null
      ) {
        // alert("Ingresa el valor de Didi Efectivo");
        errors.didiEfectivo = "Ingresa el valor de Didi Efectivo";
      }
      if (
        formasPago.some((item) => item === 25) &&
        formData.uberEfectivo == null
      ) {
        // alert("Ingresa el valor de Uber Efectivo");
        errors.uberEfectivo = "Ingresa el valor de Uber Efectivo";
      }
      if (
        formasPago.some((item) => item === 26) &&
        formData.rappiEfectivo == null
      ) {
        ///alert("Ingresa el valor de Rappi Efectivo");
        errors.rappiEfectivo = "Ingresa el valor de Rappi Efectivo";
      }
      if (
        formasPago.some((item) => item === 27) &&
        formData.anticipoOtrosCreditos == null
      ) {
        // alert("Ingresa el valor de Anticipo y otros creditos");
        errors.anticipoOtrosCreditos =
          "Ingresa el valor de Anticipo y otros creditos";
      }
      if (
        formasPago.some((item) => item === 28) &&
        formData.otrosCreditos == null
      ) {
        //alert("Ingresa el valor de Otros creditos");
        errors.otrosCreditos = "Ingresa el valor de Otros creditos";
      }
      if (
        formasPago.some((item) => item === 29) &&
        formData.deliverySr == null
      ) {
        // alert("Ingresa el valor de delivery SR");
        errors.deliverySr = "Ingresa el valor de Delivery SR";
      }
      if (formDataNoSuma.VTotal == null) {
        // alert("Ingresa el valor de Venta total");
        errors.VTotal = "Ingresa el valor de Venta total";
      }
      if (nombre == null) {
        // alert("Ingresa el valor de nombre el gerente enturno");
        errors.nombre = "Ingresa en nombre el gerente en turno";
      }
      if (imageURL == null) {
        alert("Ingresa evidencia de cierre de lote");
        // errors.imageURL = "Evidencia de cierre de lote es requerida";
        return
      }
      if (formDataNoSuma.depostitoTombola == null) {
        //alert("Ingresa el valor de deposito en tombola");
        errors.depostitoTombola = "Ingresa el valor de Deposito en tombola";
      }
      if (formData.efectivomxp > 0 && imageDepositoURL == null) {
        alert("Ingresa evidencia de ficha depósito");
        //errors.imageDepositoURL = "Evidencia de ficha depósito es requerida";
        return
      }
      if (isDiaRecolecion) {
        //console.log("es dia de recolecion")
        if (imageValoresURL != null || !isNaN(parseInt(noTicket, 10))) {
          // Aquí deberías poner lo que quieres hacer si se cumple la condición
          //console.log(imageValoresURL != null || !isNaN(parseInt(noTicket, 10)))
        } else {
          alert(
            "Ingresar una evidencia de entrega a valores o el número de #ticket"
          );
          return
        }
      } 

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors("");
        validaIguales();
      }

      function validaIguales() {
        ///suma nueva
        //console.log("total suma", suma);
       // console.log("vtotal", formDataNoSuma.VTotal);

        const vnTotal = formDataNoSuma.VTotal;
        const nTotal = Number(suma.toFixed(2));

       // console.log(vnTotal);
       // console.log(nTotal);
        if (Number(vnTotal) !== nTotal) {
          alert("Venta Total y Total deben ser iguales");
        } else {
          allValidateReadytoSet();
        }
      }

      function allValidateReadytoSet() {
        const valoresImg = imageValoresURL !== null ? imageValoresURL : "Null";
        const ticketno = !noTicket
          ? "None"
          : noTicket;

        const depositoImg =
          imageDepositoURL !== null ? imageDepositoURL : "Null";

        const mop = formData.mop !== null ? formData.mop : 0;
        const startbuckRe =
          formData.startbuckRegords !== null ? formData.startbuckRegords : 0;
        const scanypay = formData.scanpay !== null ? formData.scanpay : 0;
        const bkapp = formData.bkapp !== null ? formData.bkapp : 0;
        const eventosWalmart =
          formData.eventosWalmart !== null ? formData.eventosWalmart : 0;
        const eventosEmpresariales =
          formData.eventosEmpresariales !== null
            ? formData.eventosEmpresariales
            : 0;
        const efectivousd =
          formData.efectivousd !== null ? formData.efectivousd : 0;

        const porpropina =
          formDataNoSuma.propinpor !== null ? formDataNoSuma.propinpor : 0;
        const propinatotal = propina !== null ? propina : 0;
        const didiEfectivo =
          formData.didiEfectivo !== null ? formData.didiEfectivo : 0;
        const uberEfectivo =
          formData.uberEfectivo !== null ? formData.uberEfectivo : 0;
        const rappiEfectivo =
          formData.rappiEfectivo !== null ? formData.rappiEfectivo : 0;
        const anticioOtros =
          formData.anticipoOtrosCreditos !== null
            ? formData.anticipoOtrosCreditos
            : 0;
        const otrosCreditos =
          formData.otrosCreditos !== null ? formData.otrosCreditos : 0;
        const deliverySr =
          formData.deliverySr !== null ? formData.deliverySr : 0;
        const comentText = comentario !== null ? comentario : "None";
        const cierreFecha =
          ImagenChangeCierre === false
            ? info.records?.detallevidencia[0].fechaEstatus
            : hoyfecha;
        const fichaFecha =
          ImagenChangeCierre === false
            ? info.records?.detallevidencia[1].fechaEstatus
            : hoyfecha;
        const valoresFecha =
          ImagenChangeCierre === false
            ? info.records?.detallevidencia[2].fechaEstatus
            : hoyfecha;

        const cierreType =
          ImagenChangeCierre === false
            ? info.records?.detallevidencia[0].statusEvidencia
            : info.records?.detallevidencia[0].statusEvidencia === "1"
            ? "2"
            : info.records?.detallevidencia[0].statusEvidencia;
        const fichaType =
          ImagenChangeFicha === false
            ? info.records?.detallevidencia[1].statusEvidencia
            : info.records?.detallevidencia[1].statusEvidencia === "1"
            ? "2"
            : info.records?.detallevidencia[1].statusEvidencia;
        const valoresType =
          ImagenChangeValores === false
            ? info.records?.detallevidencia[2].statusEvidencia
            : info.records?.detallevidencia[2].statusEvidencia === "1"
            ? "2"
            : info.records?.detallevidencia[2].statusEvidencia;

        const onFailure = ({ error, config }) => {
          alert("Ocurrio un error", error);
          //console.log(error);
          getBitacora(
            config,
            "Editar cierre de dia",
            error
          );
        };
        const onSuccess = ({ data, config }) => {
          getBitacora(
            config,
            "Editar cierre de dia",
            data
          );
          {
            data.statusCode !== 200
              ? alert(data.error)
              : (alert("Envio exitoso"),
                setDatosEnviados(true),
                setFormData({
                  VTotal: "",
                  ticket: "",
                  depostitoTombola: "",
                  propinpor: "",
                  nombre: "",
                  amex: "",
                  tpv: "",
                  pinpad: "",
                  vales: "",
                  valescdmx: "",
                  delivery: "",
                  didi: "",
                  didicredito: "",
                  rappi: "",
                  rapicargo: "",
                  uber: "",
                  uberdirect: "",
                  mercadopago: "",
                  wow: "",
                  mop: "",
                  startbuckRegords: "",
                  scanpay: "",
                  bkapp: "",
                  eventosWalmart: "",
                  eventosEmpresariales: "",
                  efectivomxp: "",
                  efectivousd: "",
                  didiEfectivo: "",
                  uberEfectivo: "",
                  rappiEfectivo: "",
                  anticipoOtrosCreditos: "",
                  otrosCreditos: "",
                  deliverySr: "",
                }),
                setformDataNoSuma({
                  VTotal: "",
                  ticket: "",
                  depostitoTombola: "",
                  propinpor: "",
                }),
                setImageDepositoURL(null),
                setImageValoresURL(null),
                setImageURL(null),
                setNombre(""),
                setComentario(""),
                setnoTicket(""),
                setSuma(""));
              
          }
          try {
            const saveDataLocal = `formData-${fecha}`;
            localStorage.removeItem(saveDataLocal);
            // console.log(`Datos borrandos de formData-${fecha}`);
          } catch (error) {
            // console.log("problemas al borrar los datos");
          }
        };

        fetchReclasificacionEdit(
          Number(branchFiPaisId),
          Number(brandfiMarcaId),
          Number(branchFiSucursalId),
          branchFcNombre,
          Number(formDataNoSuma.VTotal),
          Number(formDataNoSuma.depostitoTombola),
          Number(efectivoDepositar),
          nombre,
          info.records.fechaRegistro,
          hoyfecha,
          deviceInfo,
          Number(propinatotal),
          Number(porpropina),
          imageURL,
          depositoImg,
          valoresImg,
          ticketno,
          Number(locationLatitude),
          Number(locationLongitude),
          comentText,
          Number(formData.amex),
          Number(formData.tpv),
          Number(formData.pinpad),
          Number(formData.vales),
          Number(formData.valescdmx),
          Number(formData.delivery),
          Number(formData.didi),
          Number(formData.didicredito),
          Number(formData.rappi),
          Number(formData.rapicargo),
          Number(formData.uber),
          Number(formData.uberdirect),
          Number(formData.mercadopago),
          Number(formData.wow),
          Number(mop),
          Number(startbuckRe),
          Number(scanypay),
          Number(bkapp),
          Number(eventosWalmart),
          Number(eventosEmpresariales),
          Number(formData.efectivomxp),
          Number(efectivousd),
          Number(didiEfectivo),
          Number(uberEfectivo),
          Number(rappiEfectivo),
          Number(anticioOtros),
          Number(otrosCreditos),
          Number(deliverySr),
          info.records?.detallevidencia[0].comentario,
          ImagenChangeCierre,
          cierreType,
          cierreFecha,
          info.records?.detallevidencia[1].comentario,
          ImagenChangeFicha,
          fichaType,
          fichaFecha,
          info.records?.detallevidencia[2].comentario,
          ImagenChangeValores,
          valoresType,
          valoresFecha,
        )
          .then(onSuccess)
          .catch(onFailure);
      }
    }
  };

  return (
    <div className="container-table-reclasificacion">
      <div className="row-content-reclasificacion-grit">
        <div className="conteiner-totales-reclasificacion">
          <div className="content-row-reclasificacion-separador">
            <div className="item-row-reclasificacion ">
              <div className="text-item-reclasificacion">Venta Total:</div>
              <NumericFormat
                name="VTotal"
                value={formDataNoSuma.VTotal}
                thousandSeparator={true} // Separador de miles
                decimalScale={2} // Cantidad de decimales
                fixedDecimalScale={true} // Fijar la cantidad de decimales
                prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                onValueChange={(values) => handleChangeNoSuma(values, "VTotal")}
                className={`input-item-reclasificacion ${
                  errors.VTotal ? "input-item-reclasificacion-error" : ""
                }`}
                required
              />
              {errors && (
                <div className="error-input-data">{errors.VTotal}</div>
              )}
            </div>
          </div>

          <TextCierredeDiaFormSeparador textSeparador={"Formas de pago"} />
          <TextCierredeDiaFormSeparador textSeparador={"Tarjetas"} />

          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 1) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">AMEX:</div>
                <NumericFormat
                  name="amex"
                  value={formData.amex}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "amex")}
                  className={`input-item-reclasificacion ${
                    errors.amex ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.amex}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 2) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">TPV:</div>
                <NumericFormat
                  name="tpv"
                  value={formData.tpv}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "tpv")}
                  className={`input-item-reclasificacion ${
                    errors.tpv ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && <div className="error-input-data">{errors.tpv}</div>}
              </div>
            )}

            {formasPago.some((item) => item === 3) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">PIN PAD:</div>
                <NumericFormat
                  name="pinpad"
                  value={formData.pinpad}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "pinpad")
                  }
                  className={`input-item-reclasificacion ${
                    errors.pinpad ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.pinpad}</div>
                )}
              </div>
            )}
          </div>
          <TextCierredeDiaFormSeparador textSeparador={"Vales fisícos"} />
          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 4) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">VALES:</div>
                <NumericFormat
                  name="vales"
                  value={formData.vales}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "vales")}
                  className={`input-item-reclasificacion ${
                    errors.vales ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.vales}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 5) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">VALES CDMX:</div>
                <NumericFormat
                  name="valescdmx"
                  value={formData.valescdmx}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "valescdmx")
                  }
                  className={`input-item-reclasificacion ${
                    errors.valescdmx ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.valescdmx}</div>
                )}
              </div>
            )}
          </div>

          <TextCierredeDiaFormSeparador textSeparador={"Agregadores"} />
          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 6) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">DELIVERY ALSEA:</div>
                <NumericFormat
                  name="delivery"
                  value={formData.delivery}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "delivery")
                  }
                  className={`input-item-reclasificacion ${
                    errors.delivery ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.delivery}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 7) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">DIDI:</div>
                <NumericFormat
                  name="didi"
                  value={formData.didi}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "didi")}
                  className={`input-item-reclasificacion ${
                    errors.didi ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.didi}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 8) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">DIDI CREDITOS:</div>
                <NumericFormat
                  name="didicredito"
                  value={formData.didicredito}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "didicredito")
                  }
                  className={`input-item-reclasificacion ${
                    errors.didicredito ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.didicredito}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 24) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  DIDI EFECTIVO:
                </div>
                <NumericFormat
                  name="didiEfectivo"
                  value={formData.didiEfectivo}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "didiEfectivo")
                  }
                  className={`input-item-reclasificacion ${
                    errors.didiEfectivo
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.didiEfectivo}</div>
                )}
              </div>
            )}

            {formasPago.some((item) => item === 9) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">RAPPI:</div>
                <NumericFormat
                  name="rappi"
                  value={formData.rappi}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "rappi")}
                  className={`input-item-reclasificacion ${
                    errors.rappi ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.rappi}</div>
                )}
              </div>
            )}

            {formasPago.some((item) => item === 10) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">RAPPI CARGO:</div>
                <NumericFormat
                  name="rapicargo"
                  value={formData.rapicargo}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "rapicargo")
                  }
                  className={`input-item-reclasificacion ${
                    errors.rapicargo ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.rapicargo}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 26) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  RAPPI EFECTIVO:
                </div>
                <NumericFormat
                  name="rappiEfectivo"
                  value={formData.rappiEfectivo}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "rappiEfectivo")
                  }
                  className={`input-item-reclasificacion ${
                    errors.rappiEfectivo
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.rappiEfectivo}</div>
                )}
              </div>
            )}

            {formasPago.some((item) => item === 11) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">UBER:</div>
                <NumericFormat
                  name="uber"
                  value={formData.uber}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "uber")}
                  className={`input-item-reclasificacion ${
                    errors.uber ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.uber}</div>
                )}
              </div>
            )}

            {formasPago.some((item) => item === 12) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">UBER DIRECT:</div>
                <NumericFormat
                  name="uberdirect"
                  value={formData.uberdirect}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "uberdirect")
                  }
                  className={`input-item-reclasificacion ${
                    errors.uberdirect ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.uberdirect}</div>
                )}
              </div>
            )}

            {formasPago.some((item) => item === 25) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  UBER EFECTIVO:
                </div>
                <NumericFormat
                  name="uberEfectivo"
                  value={formData.uberEfectivo}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "uberEfectivo")
                  }
                  className={`input-item-reclasificacion ${
                    errors.uberEfectivo
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.uberEfectivo}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 29) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  DELIVERY SR:
                </div>
                <NumericFormat
                  name="deliverySr"
                  value={formData.deliverySr}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "deliverySr")
                  }
                  className={`input-item-reclasificacion ${
                    errors.deliverySr ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.deliverySr}</div>
                )}
              </div>
            )}
          </div>

          <TextCierredeDiaFormSeparador textSeparador={"Otros"} />
          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 13) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">MERCADO PAGO:</div>
                <NumericFormat
                  name="mercadopago"
                  value={formData.mercadopago}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "mercadopago")
                  }
                  className={`input-item-reclasificacion ${
                    errors.mercadopago ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.mercadopago}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 14) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">WOW:</div>
                <NumericFormat
                  name="wow"
                  value={formData.wow}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "wow")}
                  className={`input-item-reclasificacion ${
                    errors.wow ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && <div className="error-input-data">{errors.wow}</div>}
              </div>
            )}

            {formasPago.some((item) => item === 15) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">MOP:</div>
                <NumericFormat
                  name="mop"
                  value={formData.mop}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "mop")}
                  className={`input-item-reclasificacion ${
                    errors.mop ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && <div className="error-input-data">{errors.mop}</div>}
              </div>
            )}
            {formasPago.some((item) => item === 16) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">
                  STARBUCKS REWARDS:
                </div>
                <NumericFormat
                  name="startbuckRegords"
                  value={formData.startbuckRegords}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "startbuckRegords")
                  }
                  className={`input-item-reclasificacion ${
                    errors.startbuckRegords
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">
                    {errors.startbuckRegords}
                  </div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 17) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">SCAN & PAY:</div>
                <NumericFormat
                  name="scanpay"
                  value={formData.scanpay}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "scanpay")
                  }
                  className={`input-item-reclasificacion ${
                    errors.scanpay ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.scanpay}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 18) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">BK APP:</div>
                <NumericFormat
                  name="bkapp"
                  value={formData.bkapp}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) => handleValueChange(values, "bkapp")}
                  className={`input-item-reclasificacion ${
                    errors.bkapp ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.bkapp}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 19) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">
                  EVENTOS WALMART:
                </div>
                <NumericFormat
                  name="eventosWalmart"
                  value={formData.eventosWalmart}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "eventosWalmart")
                  }
                  className={`input-item-reclasificacion ${
                    errors.eventosWalmart
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">
                    {errors.eventosWalmart}
                  </div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 20) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">
                  EVENTOS EMPRESARIALES:
                </div>
                <NumericFormat
                  name="eventosEmpresariales"
                  value={formData.eventosEmpresariales}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "eventosEmpresariales")
                  }
                  className={`input-item-reclasificacion ${
                    errors.eventosEmpresariales
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">
                    {errors.eventosEmpresariales}
                  </div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 27) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">
                  ANTICIPO/ OTROS CREDITOS:
                </div>
                <NumericFormat
                  name="anticipoOtrosCreditos"
                  value={formData.anticipoOtrosCreditos}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "anticipoOtrosCreditos")
                  }
                  className={`input-item-reclasificacion ${
                    errors.anticipoOtrosCreditos
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">
                    {errors.anticipoOtrosCreditos}
                  </div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 28) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">OTROS CREDITOS:</div>
                <NumericFormat
                  name="otrosCreditos"
                  value={formData.otrosCreditos}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "otrosCreditos")
                  }
                  className={`input-item-reclasificacion ${
                    errors.otrosCreditos
                      ? "input-item-reclasificacion-error"
                      : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.otrosCreditos}</div>
                )}
              </div>
            )}
          </div>
          <TextCierredeDiaFormSeparador textSeparador={"Efectivo"} />
          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 21) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">EFECTIVO MXP:</div>
                <NumericFormat
                  name="efectivomxp"
                  value={formData.efectivomxp}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "efectivomxp")
                  }
                  className={`input-item-reclasificacion ${
                    errors.efectivomxp ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.efectivomxp}</div>
                )}
              </div>
            )}
            {formasPago.some((item) => item === 22) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion">EFECTIVO USD:</div>
                <NumericFormat
                  name="efectivousd"
                  value={formData.efectivousd}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleValueChange(values, "efectivousd")
                  }
                  className={`input-item-reclasificacion ${
                    errors.efectivousd ? "input-item-reclasificacion-error" : ""
                  }`}
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.efectivousd}</div>
                )}
              </div>
            )}
          </div>

          <TextCierredeDiaFormSeparador
            textSeparador={"Total formas de pago"}
          />
          <div className="content-row-reclasificacion-separador">
            <div className="item-row-reclasificacion ">
              <div className="text-item-reclasificacion-totales-title">
                Total:
              </div>
              <NumericFormat
                name="didiEfectivo"
                value={suma}
                thousandSeparator={true} // Separador de miles
                decimalScale={2} // Cantidad de decimales
                fixedDecimalScale={true} // Fijar la cantidad de decimales
                prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                className="input-item-reclasificacion"
                required
              />
            </div>
          </div>

          <TextCierredeDiaFormSeparador textSeparador={"Depósitos Efectivo"} />
          <div className="content-row-reclasificacion-separador">
            {formasPago.some((item) => item === 23) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  % PROPINA:
                </div>
                <NumericFormat
                  name="propinpor"
                  value={formDataNoSuma.propinpor}
                  decimalScale={2} // Cantidad de decimales
                  // fixedDecimalScale={true} // Fijar la cantidad de decimales
                  //Sprefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  onValueChange={(values) =>
                    handleChangeNoSuma(values, "propinpor")
                  }
                  className="input-item-reclasificacion"
                  required
                />
              </div>
            )}
            {formasPago.some((item) => item === 23) && (
              <div className="item-row-reclasificacion">
                <div className="text-item-reclasificacion-totales-title">
                  $ PROPINA:
                </div>
                <NumericFormat
                  name="propina"
                  value={propina}
                  thousandSeparator={true} // Separador de miles
                  decimalScale={2} // Cantidad de decimales
                  fixedDecimalScale={true} // Fijar la cantidad de decimales
                  prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                  className="input-item-reclasificacion"
                  required
                />
              </div>
            )}

            <div className="item-row-reclasificacion">
              <div className="text-item-reclasificacion-totales-title">
                DEPOSITOS EN TOMBOLA:
              </div>
              <NumericFormat
                name="depostitoTombola"
                value={formDataNoSuma.depostitoTombola}
                thousandSeparator={true} // Separador de miles
                decimalScale={2} // Cantidad de decimales
                fixedDecimalScale={true} // Fijar la cantidad de decimales
                prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                onValueChange={(values) =>
                  handleChangeNoSuma(values, "depostitoTombola")
                }
                className={`input-item-reclasificacion ${
                  errors.depostitoTombola
                    ? "input-item-reclasificacion-error"
                    : ""
                }`}
                required
              />
              {errors && (
                <div className="error-input-data">
                  {errors.depostitoTombola}
                </div>
              )}
            </div>

            <div className="item-row-reclasificacion">
              <div className="text-item-reclasificacion-totales-title">
                EFECTIVO A DEPOSITAR:
              </div>
              <NumericFormat
                name="efectivoDepositar"
                value={efectivoDepositar}
                thousandSeparator={true} // Separador de miles
                decimalScale={2} // Cantidad de decimales
                fixedDecimalScale={true} // Fijar la cantidad de decimales
                prefix={"$"} // Prefijo (en este caso, el símbolo de pesos)
                className={`input-item-reclasificacion ${
                  errors.efectivoDepositar
                    ? "input-item-reclasificacion-error"
                    : ""
                }`}
                required
              />
              {errors && (
                <div className="error-input-data">
                  {errors.efectivoDepositar}
                </div>
              )}
            </div>
          </div>

          <div className="botton-part-form-reclasificacion">
            <div className="botton-part-form-reclasificacion-item-row">
              <div>
                <textarea
                  value={comentario}
                  onChange={handleChangeComentario}
                  placeholder="Escribe aquí tu comentario"
                  rows={5}
                  maxLength={300}
                  className={`input-comentario ${
                    comentario.length >= 300 ? "input-comentario-error" : ""
                  }`}
                />
                {comentario.length >= 300 && (
                  <div className="text-error">
                    Deben ser menos de 300 caracteres
                  </div>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="nombre"
                  value={nombre}
                  onChange={handleChangeNombre}
                  placeholder="Nombre del Gerente en turno"
                  className="input-name"
                  required
                />
                {errors && (
                  <div className="error-input-data">{errors.nombre}</div>
                )}
              </div>
            </div>

            <div className="botton-part-form-reclasificacion-item-row">
              <div className="item-row-attract-files">
                <div className="button-row-evidencias">
                  <button
                    className="button-icon-images"
                    onClick={() => setModalVisible(true)}
                  >
                    <img
                      className="icon-reclasificacin"
                      src={imgCierre}
                      alt="Logo"
                    />
                    <div className="spam-text">Cierre de Pin Pad</div>
                  </button>
                  <>
                    {isShowImage ? (
                      <LoadingSpinner />
                    ) : (
                      <div>
                        {imageURL && (
                          <>
                            <Image
                              width={80}
                              src={`${imageURL}?${new Date().getTime()}`}
                              alt="Logo"
                            />
                            <div className="font-text-bold">Estatus:</div>
                            {info.records?.detallevidencia[0]
                              .statusEvidencia === "0" ? (
                              <div className="font-text-bold">Inicial</div>
                            ) : info.records?.detallevidencia[0]
                                .statusEvidencia === "1" ? (
                              <div className="font-text-boldRed">Rechazado</div>
                            ) : info.records?.detallevidencia[0]
                                .statusEvidencia === "2" ? (
                              <div className="font-text-boldGreen">
                                Reeenviado
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                </div>

                <div className="button-row-evidencias">
                  <button
                    className="button-icon-images"
                    onClick={() => setModalVisibleFicha(true)}
                  >
                    <img
                      className="icon-reclasificacin"
                      src={imgDeposito}
                      alt="Logo"
                    />
                    <div className="spam-text">Ficha de Depósito</div>
                  </button>
                  <>
                    {isShowImageDep ? (
                      <LoadingSpinner />
                    ) : (
                      <div>
                        {imageDepositoURL && (
                          <>
                            <Image
                              width={80}
                              src={`${imageDepositoURL}?${new Date().getTime()}`}
                              alt="Logo"
                            />
                            <div className="font-text-bold">Estatus:</div>
                            {info.records?.detallevidencia[1]
                              .statusEvidencia === "0" ? (
                              <div className="font-text-bold">Inicial</div>
                            ) : info.records?.detallevidencia[1]
                                .statusEvidencia === "1" ? (
                              <div className="font-text-boldRed">Rechazado</div>
                            ) : info.records?.detallevidencia[1]
                                .statusEvidencia === "2" ? (
                              <div className="font-text-boldGreen">
                                Reeenviado
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                </div>

                <div className="button-row-evidencias">
                  <button
                    className="button-icon-images"
                    onClick={() => setModalVisibleValores(true)}
                  >
                    <img
                      className="icon-reclasificacin"
                      src={imgEntrega}
                      alt="Logo"
                    />
                    <div className="spam-text">Entrega de Valores</div>
                  </button>
                  <>
                    {isShowImageVal ? (
                      <LoadingSpinner />
                    ) : (
                      <div>
                        {imageValoresURL && (
                          <>
                            <Image
                              width={80}
                              src={`${imageValoresURL}?${new Date().getTime()}`}
                              alt="Logo"
                            />
                            <div className="font-text-bold">Estatus:</div>
                            {info.records?.detallevidencia[2]
                              .statusEvidencia === "0" ? (
                              <div className="font-text-bold">Inicial</div>
                            ) : info.records?.detallevidencia[2]
                                .statusEvidencia === "1" ? (
                              <div className="font-text-boldRed">Rechazado</div>
                            ) : info.records?.detallevidencia[2]
                                .statusEvidencia === "2" ? (
                              <div className="font-text-boldGreen">
                                Reeenviado
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-button-reclasificacion">
        <button
          onClick={setSaveReclasificacionEdith}
          className="button-styles-reclasificacion"
        >
          Guardar
        </button>
      </div>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="conteiner-modal-body-evidencias">
          <div>
            {image == null && (
              <>
                <div>
                  La imagen seleccionada debe estar en formato JPG y tener un
                  tamaño inferior a 1 MB
                </div>
                <label
                  htmlFor="image-inputPut"
                  style={{
                    background: "#41b6e6", // Color de fondo
                    color: "#fff", // Color del texto
                    padding: "10px 15px", // Espaciado interno
                    borderRadius: "5px", // Borde redondeado
                    cursor: "pointer", // Cursor al pasar sobre el botón
                  }}
                >
                  Seleccionar Imagen
                </label>
                <input
                  type="file"
                  onChange={handleImageChangeFicha}
                  accept=".jpg, .jpeg"
                  id="image-inputPut" // Añade un ID al input
                  style={{
                    display: "none", // Oculta el input
                  }}
                />
              </>
            )}

            {image && (
              <>
                <div>
                  <img
                    src={image}
                    alt="Selected Image"
                    className="image-pick-recolecion"
                  />
                </div>
                <div>
                  <div className="file-select" id="src-file1">
                    <input
                      type="file"
                      accept=".jpg, .jpeg"
                      onChange={handleImageChangeFicha}
                      className="input-content-img"
                    />
                  </div>

                  <button className="file-button-save" onClick={saveImage}>
                    Guardar
                  </button>
                </div>
                <div>Comentario</div>
                <div>{info.records?.detallevidencia[0].comentario}</div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={modalVisibleFicha}
        onCancel={() => setModalVisibleFicha(false)}
        footer={null}
      >
        <div className="conteiner-modal-body-evidencias">
          <div>
            {imageDeposito == null && (
              <>
                <div>
                  La imagen seleccionada debe estar en formato JPG y tener un
                  tamaño inferior a 1 MB
                </div>
                <label
                  htmlFor="image-inputDepositoPut"
                  style={{
                    background: "#41b6e6", // Color de fondo
                    color: "#fff", // Color del texto
                    padding: "10px 15px", // Espaciado interno
                    borderRadius: "5px", // Borde redondeado
                    cursor: "pointer", // Cursor al pasar sobre el botón
                  }}
                >
                  Seleccionar Imagen
                </label>
                <input
                  type="file"
                  id="image-inputDepositoPut" // Añade un ID al input
                  onChange={handleImageChangeDeposito}
                  accept=".jpg, .jpeg"
                  style={{
                    display: "none", // Oculta el input
                  }}
                />
              </>
            )}

            {imageDeposito && (
              <>
                <div>
                  <img
                    src={imageDeposito}
                    alt="Selected Image"
                    className="image-pick-recolecion"
                  />
                </div>
                <div>
                  <div className="file-select" id="src-file1">
                    <input
                      type="file"
                      accept=".jpg, .jpeg"
                      onChange={handleImageChangeDeposito}
                      className="input-content-img"
                    />
                  </div>

                  <button
                    className="file-button-save"
                    onClick={saveImageDeposito}
                  >
                    Guardar
                  </button>
                </div>
                <div>Comentario</div>
                <div>{info.records?.detallevidencia[1].comentario}</div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={modalVisibleValores}
        onCancel={() => setModalVisibleValores(false)}
        footer={null}
      >
        <div className="conteiner-modal-body-evidencias">
          <label>
            <Switch
              onChange={handleChangCheck}
              checked={checked}
              className="react-switch"
            />
          </label>

          <div>
            {checked ? (
              <div>
                <div className="text-top-image-modal">
                  No recoleción de valores
                </div>
                <div className="center-selecionar-image-valores">
                  <div className="text-top-image-modal">
                    Coloca # ticket de Mesa de Servicio
                  </div>
                  <input
                    type="text"
                    name="ticket"
                    onChange={handleChangeTicket}
                    value={noTicket}
                    className="input-item-reclasificacion"
                    required
                  ></input>
                  <button
                    onClick={() => setModalVisibleValores(false)}
                    className="button-styles-reclasificacion "
                  >
                    Guardar
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {imageValores == null && (
                    <>
                      <div>
                        La imagen seleccionada debe estar en formato JPG y tener
                        un tamaño inferior a 1 MB
                      </div>
                      <label
                        htmlFor="image-inputValoresPut"
                        style={{
                          background: "#41b6e6", // Color de fondo
                          color: "#fff", // Color del texto
                          padding: "10px 15px", // Espaciado interno
                          borderRadius: "5px", // Borde redondeado
                          cursor: "pointer", // Cursor al pasar sobre el botón
                        }}
                      >
                        Seleccionar Imagen
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChangeValores}
                        accept=".jpg, .jpeg"
                        id="image-inputValoresPut" // Añade un ID al input
                        style={{
                          display: "none", // Oculta el input
                        }}
                      />
                    </>
                  )}

                  {imageValores && (
                    <>
                      <div>
                        <img
                          src={imageValores}
                          alt="Selected Image"
                          className="image-pick-recolecion"
                        />
                      </div>
                      <div>
                        <div className="file-select" id="src-file1">
                          <input
                            type="file"
                            accept=".jpg, .jpeg"
                            onChange={handleImageChangeValores}
                            className="input-content-img"
                          />
                        </div>

                        <button
                          className="file-button-save"
                          onClick={saveImageValores}
                        >
                          Guardar
                        </button>
                      </div>
                      <div>Comentario</div>
                      <div>{info.records?.detallevidencia[2].comentario}</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
