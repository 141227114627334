import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Table, Col, Row, Modal } from "antd";
import { format } from "date-fns";
import { UseUser } from "../hooks";
import "react-datepicker/dist/react-datepicker.css";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { GetFetchReporteCierreDia } from "../Api/GetReporteCierreDia";
import EditIcon from "@mui/icons-material/Edit";
import { FormDetailsReport } from "./FormDetailsReport";
import "react-responsive-modal/styles.css";
import "../theme/ReporteCierreDia.css";
import "../theme/Pagination.css";
import { InputSelectWithInName } from "./utils/InputSelectWithInName";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { fetchGetItemReporteCierreDia } from "../Api/fetchGetItemReporteCierreDia";
import { fetReporteCvsCierreDia } from "../Api/fetReporteCvsCierreDia";
import { GetFetchSucursalesByMarca } from "../Api/Administracion/GetFetchSucursalesByMarca"

export const TableReportes = () => {
  const [isLoadingSucursal, setIsLoadingSucursal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { mail } = UseUser();

  const [data, setData] = useState();
  const [dataMarca, setDataMarca] = useState([]);
  const [dataSucursal, setDataSucursales] = useState([]);

  const [formData, setFormData] = useState({
    pais: 1,
    marca: 0,
    sucursal: 0,
  });
  const [formDataDate, setFormDataDate] = useState({
    inicio: "",
    fin: "",
  });

  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    today.getDate()
  );
  let fechaFin = new Date(today);

  if (formDataDate.inicio) {
    const maxEndDate = new Date(formDataDate.inicio);
    maxEndDate.setDate(formDataDate.inicio.getDate() + 6); // Establece la fecha de fin 7 días después de la fecha de inicio
    fechaFin = maxEndDate > today ? today : maxEndDate; // Si la fecha máxima excede el día actual, establece la fecha de fin como el día actual
  }

  const handleChangeDate = (date, type) => {
    setFormDataDate({ ...formDataDate, [type]: date });
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy");
  };

  const handleChange = (event, name) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    } else {
      // Si no hay event.target, asumimos que estamos manejando un evento de fecha
      setFormData({ ...formData, [name]: event });
    }
  };

  ///console.log(formatDate(fechaInicio));

  useEffect(() => {
    fetchDataMarca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataMarca = async () => {
      const onFailure = (error) => {
        console.log(error)
      };
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  useEffect(() => {
    fetchDataSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.marca]);

  const fetchDataSucursal = async () => {
    if (formData.marca !== 0) {
      setIsLoadingSucursal(true);
      const onFailure = (error) => {
        setIsLoadingSucursal(false);
      };
      const onSuccess = ({ data }) => {
        console.log(data.body.data);
        setDataSucursales(data.body.data);
        setIsLoadingSucursal(false);
      };

      GetFetchSucursalesByMarca(formData.marca).then(onSuccess).catch(onFailure);
    }
  };

  

  const getReportData = () => {
    setLoading(true);
    if (
      formData.marca !== 0 &&
      formDataDate.inicio !== "" &&
      formDataDate.fin !== ""
    ) {
      const inicio = formatDate(formDataDate.inicio);
      const fin = formatDate(formDataDate.fin);
      const marca = Number(formData.marca);
      const sucursal =
        formData.sucursal === 0 ? Number("0") : Number(formData.sucursal);
      const onFailure = (error) => {
        setLoading(false);
      };
      const onSuccess = ({ data }) => {
        console.log("fetchDataSearch", data);
        setData(data.records);
        setLoading(false);
      };
      GetFetchReporteCierreDia(
        Number(formData.pais),
        marca,
        sucursal,
        inicio,
        fin,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const getReportDataCvs = () => {
    if (
      formData.marca !== 0 &&
      formDataDate.inicio !== "" &&
      formDataDate.fin !== ""
    ) {
      const inicio = formatDate(formDataDate.inicio);
      const fin = formatDate(formDataDate.fin);
      const marca = Number(formData.marca);
      const sucursal =
        formData.sucursal === 0 ? Number("0") : Number(formData.sucursal);
      // Enviar la petición
      fetReporteCvsCierreDia(
        Number(formData.pais),
        marca,
        sucursal,
        inicio,
        fin,
        mail,
      );

      // Mostrar el alert después de un breve retraso
      setTimeout(() => {
        alert(
          "Tu solicitud está siendo procesada. Pronto recibirás un informe detallado en tu correo electrónico"
        );
      }, 2000); // 2000 milisegundos = 2 segundos de retraso
    }
  };

  const getItemReportData = (item) => {
    if (item) {
      const partes = item?.fechaRegistro.split(" "); // Dividir la cadena en fecha y hora
      const fechaRegistro = partes[0]; // Obtener la parte de la fecha

      const marca = Number(item.marca);
      const sucursal = Number(item.sucursal);
      const onFailure = (error) => {
        console.log("error",error)
      };
      const onSuccess = ({ data }) => {
        console.log("DataPOrItem", data);
        handleOpenModal(data.records);
      };
      fetchGetItemReporteCierreDia(
        Number(formData.pais),
        marca,
        sucursal,
        fechaRegistro,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const FormateData = ({ item }) => {
    // Separar la fecha completa en fecha y tiempo
    const [datePart] = item.split(" ");
    // Separar el día, mes y año de la fecha
    const [day, month, year] = datePart.split("/");
    // Formatear la fecha sin tiempo
    const formattedDate = `${day}/${month}/${year}`;
    return <span>{formattedDate}</span>;
  };

  const FormatCurrency = ({ value }) => {
    // Formatear el valor como moneda mexicana
    const formattedCurrency = parseFloat(value).toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    return <span>{formattedCurrency}</span>;
  };

  const columns = [
    {
      title: "Fecha Registro",
      dataIndex: "fechaRegistro",
      key: "fechaRegistro",
      render: (item) => <FormateData item={item} />,
    },
    {
      title: "Fecha Modificacion",
      dataIndex: "fechaModificacion",
      key: "fechaModificacion",
      //...getColumnSearchProps("fechaModificacion"),
    },
    {
      title: "CECO",
      dataIndex: "sucursal",
      key: "sucursal",
      // ...getColumnSearchProps("sucursal"),
    },
    {
      title: "Nombre Tienda",
      dataIndex: "nombreTienda",
      key: "nombreTienda",
    },
    {
      title: "Venta Total",
      dataIndex: "ventaTotal",
      key: "ventaTotal",
      render: (value) => <FormatCurrency value={value} />,
    },

    {
      title: "Deposito Tombola",
      dataIndex: "depositoTombola",
      key: "depositoTombola",
      render: (value) => <FormatCurrency value={value} />,
    },
    {
      title: "Efectivo a Depositar",
      dataIndex: "efectivoADepositar",
      key: "efectivoADepositar",
      render: (value) => <FormatCurrency value={value} />,
    },

    {
      title: "Ver Detalle",
      render: (item) => (
        <button
          onClick={() => getItemReportData(item)}
          //onClick={}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "10%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={22} md={3} className="mt-3" al>
          <InputSelectWithInName
            value={formData.marca}
            handleChange={handleChange}
            options={dataMarca}
            label="Marca"
            optionsKey={"FIMARCAID"}
            optionsName={"FCNOMBRE"}
            name={"marca"}
          />
        </Col>
        <Col xs={22} md={3} className="mt-3" al>
          {formData.marca !== 0 && (
            <>
              {isLoadingSucursal ? (
                <LoadingSpinner />
              ) : (
                <select
                  className="input-content-select"
                  value={formData.sucursal}
                  onChange={handleChange}
                  name={"sucursal"}
                >
                  <option value="0">Selecionar Todas</option>
                  {dataSucursal.map((option) => (
                    <option
                      key={option.FISUCURSALID}
                      value={option.FISUCURSALID}
                    >
                      {option.FISUCURSALID}-{option.FCNOMBRE}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </Col>
        <Col xs={22} md={3} className="mt-3" al>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            //showIcon
            locale="es"
            selected={formDataDate.inicio}
            onChange={(date) => handleChangeDate(date, "inicio")}
            selectsStart
            startDate={formDataDate.inicio}
            endDate={formDataDate.fin}
            minDate={minDate}
            maxDate={fechaFin}
            placeholderText="Fecha Inicio"
            className="input-content-select-picker"
          />
        </Col>
        <Col xs={22} md={3} className="mt-3" al>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            //showIcon
            locale="es"
            selected={formDataDate.fin}
            onChange={(date) => handleChangeDate(date, "fin")}
            selectsEnd
            startDate={formDataDate.inicio}
            endDate={formDataDate.fin}
            minDate={
              formDataDate.inicio ? new Date(formDataDate.inicio) : minDate
            }
            maxDate={fechaFin}
            placeholderText="Fecha Fin"
            className="input-content-select-picker"
          />
        </Col>
        <Col xs={22} md={3} className="mt-3" al>
          {formData.marca !== 0 &&
            formDataDate.inicio !== "" &&
            formDataDate.fin !== "" && (
              <button
                className="button-styles-add-element"
                type="primary"
                onClick={getReportData}
              >
                Consultar
              </button>
            )}
        </Col>
        <Col xs={22} md={4} className="mt-3" al></Col>
        <Col xs={22} md={3} className="mt-3" al>
            {formData.marca !== 0 &&
            formDataDate.inicio !== "" &&
            formDataDate.fin !== "" && (
              <button
              className="button-styles-add-element-search"
              type="primary"
              onClick={getReportDataCvs}
            >
              Generar CSV
            </button>
            )}
        </Col>
      </Row>

      <>
        <Row justify="center">
          <Col xs={22} md={22} className="mt-3">
            <Table
              dataSource={data}
              rowKey={`${data?.sucursal}${data?.fechaRegistro}${data?.fechaModificacion}`}
              columns={columns}
              loading={isLoading}
              scroll={{ x: 1400, y: 600 }}
              //onChange={handleTableChange}
            />
          </Col>
        </Row>
        <>
          <Modal
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
            width={1000}
            style={{ left: "0" }}
          >
            <FormDetailsReport
              selectedItem={selectedItem}
              getReportData={getReportData}
              setShowModal={setShowModal}
            />
          </Modal>
        </>
      </>
    </>
  );
};
