import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { AddFetchBanner } from "../Api/Administracion/AddFetchBanner";
import { PutFetchBanner } from "../Api/Administracion/PutFetchBanner";
import {GetFetchRolByMarca } from "../Api/Administracion/GetFetchRolByMarca"

export const AdministrationBannerAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  rol,
  modalKey
}) => {
  const objDefault = {
    FIMARCAID: "",
    FIBANNERID: 0,
    FITIPOBANNERID: 1,
    FCLINKEXTERNO: "",
    FIROLID: "",
    FCTITULO: "",
    FCDESCRIPCION: "",
    FCURLS3: "",
    FCEDITARLIGA: "",
    FIESTATUSID: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [dataRoles, setdataRoles] = useState("");
  const [isLoadingRol, setIsLoadingRol] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FIBANNERID: selectedItem.FIBANNERID,
        FITIPOBANNERID: selectedItem.FITIPOBANNERID,
        FCLINKEXTERNO: selectedItem.FCLINKEXTERNO,
        FIROLID: selectedItem.FIROLID,
        FCTITULO: selectedItem.FCTITULO,
        FCDESCRIPCION: selectedItem.FCDESCRIPCION,
        FCURLS3: selectedItem.FCURLS3,
        FCEDITARLIGA: false,
        FIESTATUSID: selectedItem.FIESTATUSID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: "",
        FIBANNERID: 0,
        FITIPOBANNERID: 1,
        FCLINKEXTERNO: "",
        FIROLID: "",
        FCTITULO: "",
        FCDESCRIPCION: "",
        FCURLS3: "",
        FCEDITARLIGA: false,
        FIESTATUSID: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "FCURLS3" && files && files.length > 0) {
      const imageFile = files[0];
      if (imageFile.size > 1024 * 1024) {
        alert("El archivo no debe exceder 1 MB.");
        event.target.value = ''; // Restablecer el valor del input
        return;
      }
      if (imageFile && imageFile.type.includes("image/jpeg")) {
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
          setFormData({ ...formData, [name]: reader.result });
          // setImage(reader.result);
        };
        event.target.value = ''; // Restablecer el valor del input
      } else {
        alert("Por favor selecciona un archivo JPG válido.");
        event.target.value = ''; // Restablecer el valor del input
      }
    } else {
      // Verificar que FCTITLE tenga menos de 30 caracteres
      if (name === "FCTITULO" && value.length > 50) {
        setErrors((prevFormData) => ({
            ...prevFormData,
            FCTITULO: "El título no debe exceder 50 caracteres.",
        }));
        return;
       }
      // Verificar que FCDESCRIPCION tenga menos de 250 caracteres
      if (name === "FCDESCRIPCION" && value.length > 300) {
        setErrors((prevFormData) => ({
          ...prevFormData,
          FCDESCRIPCION: "La descripción no debe exceder 300 caracteres.",
        }));
        return;
      }
      // Parsea el valor a un número si el nombre de la propiedad es uno de los especificados
      const parsedValue =
        name === "FIROLID" ||
        name === "FIMARCAID" ||
        name === "FITIPOBANNERID" ||
        name === "FIBANNERID"
          ? parseInt(value, 10)
          : value;
      setFormData({ ...formData, [name]: parsedValue });
    }
  };
  
 

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCTITULO === "") {
      errors.FCTITULO = "Ingresa el valor de título";
    } 
    if (formData.FITIPOBANNERID === "") {
      errors.FITIPOBANNERID = "Ingresa valor de tipo de banner";
    }
    if (formData.FIMARCAID === "") {
      errors.FIMARCAID = "Ingresa el valor de marca";
    }
    if (formData.FIROLID === "") {
      errors.FIROLID = "Ingresa el valor de rol";
    }
    if (formData.FCURLS3 === "") {
      errors.FCURLS3 = "Selecciona una imagen";
    }
    const contieneHTTP = formData.FCLINKEXTERNO.includes("http://") || formData.FCLINKEXTERNO.includes("https://");
    if(formData.FCLINKEXTERNO!==""){
      if(!contieneHTTP){
        errors.FCLINKEXTERNO = "Ingresa una URL válida que comience con http:// o https://.";
      }
    }
    if (formData.FCDESCRIPCION.length > 300){
      errors.FCDESCRIPCION = "La descripción no debe exceder 300 caracteres.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }
    function sendData(){
      setLoading(true);
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false);
        alert(error);
      };

      const onSuccess = ({ data }) => {
        //console.log("datafetch", data);
        setLoading(false);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      // Realiza la validación del campo FCURLIMAGEN con la expresión regular
      const contieneHTTP =
        formData.FCURLS3.includes("http://") ||
        formData.FCURLS3.includes("https://");

      // Si es válida, envía el campo FCURLIMAGEN como una cadena vacía, de lo contrario, envía el valor actual
      const formDataToSend = {
        ...formData,
        FCURLS3: contieneHTTP ? "" : formData.FCURLS3,
      };

      if (selectedItem) {
        PutFetchBanner(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
        //("formData", formDataToSend);
      } else {
        AddFetchBanner(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
       /// console.log("formData", formDataToSend);
      }
    }
    
  };

  useEffect(() => {
    fetchDataRol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.FIMARCAID]);

  const fetchDataRol = async () => {
    if (formData.FIMARCAID !== 0) {
      setIsLoadingRol(true);
      const onFailure = (error) => {
        setIsLoadingRol(false);
      };
      const onSuccess = ({ data }) => {
        //console.log("data roles by marca",data.body.data);
        setdataRoles(data.body.data);
        setIsLoadingRol(false);
      };

      GetFetchRolByMarca(formData.FIMARCAID).then(onSuccess).catch(onFailure);
    }
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Banner
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCTITULO}
          name={"FCTITULO"}
          type={"text"}
          label={"Titulo"}
        />
        {errors && <div className="error-input-data">{errors.FCTITULO}</div>}
      </Col>
      <Col xs={24} md={22}>
        <label className="text-context-inputs">Tipo de banner: </label>
        <select
          className="input-content"
          value={formData.FITIPOBANNERID}
          onChange={handleChange}
        >
          <option value="1">General</option>
        </select>
        {errors && <div className="error-input-data">{errors.FITIPOBANNERID}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
         {errors && <div className="error-input-data">{errors.FIMARCAID}</div>}
      </Col>
      <Col xs={24} md={10}>
        {formData.FIMARCAID !== "" && (
          <>
            {isLoadingRol ? (
              <LoadingSpinner />
            ) : (
              <>
                <label className="text-context-inputs">Roles:</label>
                <select
                  className="input-content"
                  value={formData.FIROLID}
                  onChange={handleChange}
                  name={"FIROLID"}
                  //disabled={selectedItem ? true : false}
                >
                  <option value="0">Selecionar Rol</option>
                  {dataRoles.map((option) => (
                    <option key={option.FIROLID} value={option.FIROLID}>
                      {option.FCNOMBRE}
                    </option>
                  ))}
                </select>
              </>
            )}
             {errors && <div className="error-input-data">{errors.FIROLID}</div>}
          </>
        )}
       
      </Col>
      <Col xs={24} md={22}>
        <label className="text-context-inputs">Imagen:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLS3 !== "" && (
              <div>
                {formData.FCURLS3.includes("http://") ||
                formData.FCURLS3.includes("https://") ? (
                  <img
                    src={`${formData.FCURLS3}?${new Date().getTime()}`}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
                ) : (
                  <img
                    src={formData.FCURLS3}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="file-selectadd" id="src-fileadd">
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              className="input-content-img"
              name={"FCURLS3"}
            />
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLS3}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCLINKEXTERNO}
          name={"FCLINKEXTERNO"}
          type={"text"}
          label={"Enlace"}
        />
        {errors && <div className="error-input-data">{errors.FCLINKEXTERNO}</div>}
      </Col>

      <Col xs={24} md={22}>
        <label className="text-context-inputs">Descripción:</label>
        <textarea
          type="text"
          name="FCDESCRIPCION"
          onChange={handleChange}
          value={formData.FCDESCRIPCION}
          className="input-content"
        ></textarea>
        {errors && <div className="error-input-data">{errors.FCDESCRIPCION}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
