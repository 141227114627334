import createAxiosInstance from "./LambdaApiUsabilidad";
import { ENVIROMENT } from "../utilities"

export async function fetchSendReporteEmail(email, date) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/reclasificacion/reporte/usabilidad?email=${email}&date=${date}`: `home/reclasificacion/reporte/usabilidad?email=${email}&date=${date}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(requestConfig);
}
