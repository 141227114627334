export const tenantID = "fe51472c-6660-4ae5-995a-ac0f14ef2b07";
export const URL_STORE_APP =
  "https://apps.apple.com/mx/app/centro-operativo-del-gerente/id1672676029";
export const URL_STORE_ANDROID =
  "https://play.google.com/store/apps/details?id=com.alsea.appmanager";
export const API_GRAPH =
  "https://graph.microsoft.com/v1.0/me?$select=employeeID,displayName,givenName,jobTitle,mail,surname,companyName,onPremisesExtensionAttributes,lastPasswordChangeDateTime,accountEnabled";
export const REVOKE_URL =
  "https://graph.microsoft.com/v1.0/me/revokeSignInSessions";
export const VERSION_APP = "11.3.12";
export const BUILD_NUMBER = "144";
export const POLITICAS_PRIVACIDAD =
  "https://centro.operaciones.alsea.net/Politicas_de_privacidad";
export const ALSE_PAGE = "https://alsea.net/home";

export const SITE_KEY = "6LflvTYoAAAAAMZWXgWk3ldmopRVDIuRlGRPpcWj";
export const SECRET_KEY = "6LflvTYoAAAAADfBvzPE8UTVbXBZU2WdPR5qFIWC";



export const DEV_OLD_AUTH_CONSOLE = "https://htwcnqlh13.execute-api.us-east-1.amazonaws.com/dev/home/";
export const DEV_NEW_AUTH_CONSOLE = "https://ch1q6ibky0.execute-api.us-east-1.amazonaws.com/v1/home/";
export const DEV_FRECUENCIAS_AUTH_CONSOLE = "https://ch1q6ibky0.execute-api.us-east-1.amazonaws.com/v1/home/";
export const DEV_RECLASIFICACION_AUTH_CONSOLE ="https://ch1q6ibky0.execute-api.us-east-1.amazonaws.com/v1/home/"
export const PROD_OLD_AUTH_CONSOLE = "https://api.centro.operaciones.alsea.net/v1/";
export const PROD_NEW_AUTH_CONSOLE = "https://auffj3jfw9.execute-api.us-east-1.amazonaws.com/v1/home/";
export const PROD_FRECUENCIAS_AUTH_CONSOLE = "https://7to4qh3896.execute-api.us-east-1.amazonaws.com/prod";
export const PROD_RECLASIFICACION_AUTH_CONSOLE = "https://vnnzq3me97.execute-api.us-east-1.amazonaws.com/prod" 

export const ENVIROMENT = "prod";
