import React, { useState, useEffect } from "react";
import salesIcon from "../assets/1.png";
import "../theme/Indicadores.css";
import numbro from "numbro";


export const TableSales = (props) => {
  const { salesData } = props;
  const [ventaReal, setventaReal] = useState();
  const [ventaPtto, setventaPtto] = useState();
  const [ventaCuota, setventaCuota] = useState();
  const [ventaAAAvg, setventaAAAvg] = useState();
  const [ventaPttoAvg, setventaPttoAvg] = useState();
  const [ventaCuotaAvg, setventaCuotaAvg] = useState();
  const [ventaAA, setventaAA] = useState();

  useEffect(() => {
    const VReal =
      salesData.fnventa_real == null || salesData.fnventa_real === 0
        ? 0
        : numbro(salesData.fnventa_real).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaReal(VReal);
    const Vppto =
      salesData.fnventa_ppto == null || salesData.fnventa_ppto === 0
        ? 0
        : numbro(salesData.fnventa_ppto).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaPtto(Vppto);
    const VCuota =
      salesData.fncuota_ventas == null || salesData.fncuota_ventas === 0
        ? 0
        : numbro(salesData.fncuota_ventas).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaCuota(VCuota);

    const VRaa =
      salesData.fnventa_real_aa == null || salesData.fnventa_real_aa === 0
        ? 0
        : numbro(salesData.fnventa_real_aa).format({
            thousandSeparated: true,
            mantissa: 1,
          });
    setventaAA(VRaa);

    //average
    const ptto =
      salesData.fnventa_ppto === null || salesData.fnventa_ppto === 0
        ? 0
        : (salesData.fnventa_real * 100) / salesData.fnventa_ppto;
    setventaPttoAvg(Math.round(ptto));
    const cuota =
      salesData.fncuota_ventas == null || salesData.fncuota_ventas === 0
        ? 0
        : (salesData.fnventa_real * 100) / salesData.fncuota_ventas;
    setventaCuotaAvg(Math.round(cuota));
    const aa =
      salesData.fnventa_real_aa == null || salesData.fnventa_real_aa === 0
        ? 0
        : (salesData.fnventa_real * 100) / salesData.fnventa_real_aa;
    setventaAAAvg(Math.round(aa));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-table-sale">
      <div className="box-top-table-sale">
        <div className="box-sale-icon">
          <img className="IconImage" src={salesIcon} alt="Ventas" />
          <div className="title-top-table-indicadores">Ventas</div>
        </div>
        <div className="title-top-table-indicadores-real">Real</div>
        <div className="text-tota-real-price">${ventaReal}</div>
      </div>
      <div className="box-body-table-sale">
        <div className="item-box-table-sales1">
          <div className="text-vs-sale">Vs PPTO</div>
          <div className="text-porcent">{ventaPttoAvg}%</div>
          <div className="text-presupues-sale">${ventaPtto}</div>
        </div>
        <div className="item-box-table-sales">
          <div className="text-vs-sale">Vs Cuota</div>
          <div className="text-porcent">{ventaCuotaAvg}%</div>
          <div className="text-presupues-sale">${ventaCuota}</div>
        </div>
        <div className="item-box-table-sales3">
          <div className="text-vs-sale">Vs AA</div>
          <div className="text-porcent">{ventaAAAvg}%</div>
          <div className="text-presupues-sale">${ventaAA}</div>
        </div>
      </div>
    </div>
  );
};
