/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Row, Col, Switch, Input, Space, Modal } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { UseTokenGlobal, UseAdmin } from "../hooks";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { fetchFormasPago } from "../Api/FetchFormasPago";
import { AdministrationFormasPagoForm } from "./AdministrationFormasPagoForm";
import { fetchFormasPagoEdith } from "../Api/fetchFormasPagoEdith";

import "react-datepicker/dist/react-datepicker.css";
import "../theme/AdministracionCatalogo.css";
import "../theme/Pagination.css";

export const TableFormasPago = () => {
  const [data, setData] = useState([]);
  const [dataUnique, setDataUnique] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataMarca, setDataMarca] = useState([]);
  const { branchFiPaisId } = UseAdmin();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchFiPaisId]);

  const fetchData = async () => {
    setIsLoading(true);

    if (branchFiPaisId != null) {
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };

      const onSuccess = ({ data }) => {
        setData(data);
        setIsLoading(false);
        uniqueJsonData(data);
      };

      fetchFormasPago(Number(branchFiPaisId), Number(0))
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  useEffect(() => {
    fetchDataMarca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataMarca = async () => {
    const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        setDataMarca(data.body.data);
      };
      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
  };

  /*const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
  };*/

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
  };

  const HandleSwitch = ({ value, all }) => {
    const [hs, setHs] = useState(parseInt(value) === 1);
    const handleChangeSwitch = (v) => {
      const newObj = {
        FIMARCAID: parseInt(all.FIMARCAID),
        FCNOMBRE: all.FCNOMBRE,
        FIFORMAPAGOID: parseInt(all.FIFORMAPAGOID),
        FIESTATUS: v ? "1" : "0",
      };

      //console.log("all", all);
      //peticion....
      const onFailure = (error) => {
        //console.log("error", error);
        alert(error);
      };
      const onSuccess = ({ data }) => {
       // console.log("data", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      //console.log("Nuevo objeto a enviar", newObj);
      fetchFormasPagoEdith(newObj).then(onSuccess).catch(onFailure);

      setHs(v);
    };
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };

  const uniqueJsonData = (completejson) => {
    const uniqueObjects = completejson.reduce((unique, obj) => {
      if (!unique[obj.FIFORMAPAGOID]) {
        unique[obj.FIFORMAPAGOID] = obj;
      }
      return unique;
    }, {});
    // Convertir los valores del objeto único en un array
    const uniqueJsonData = Object.values(uniqueObjects);
    setDataUnique(uniqueJsonData);
   // console.log("uniqueJsonData", uniqueJsonData);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      title: "ID Forma pago",
      dataIndex: "FIFORMAPAGOID",
      key: "FIFORMAPAGOID",
      sorter: (a, b) => parseInt(a.FIFORMAPAGOID) - parseInt(b.FIFORMAPAGOID),
      ...getColumnSearchProps("FIFORMAPAGOID"),
    },
    {
      title: "Nombre",
      dataIndex: "FCNOMBRE",
      key: "FCNOMBRE",
      ...getColumnSearchProps("FCNOMBRE"),
    },
    {
      title: "Marca ID",
      dataIndex: "FIMARCAID",
      key: "FIMARCAID",
      ...getColumnSearchProps("FIMARCAID"),
    },
    {
      title: "Estatus",
      dataIndex: "FIESTATUS",
      key: "FIESTATUS",
      render: (item, all) => <HandleSwitch value={item} all={all} />,
      fixed: "right",
      width: "10%",
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"], // Aquí estableces que los elementos activos estén seleccionados por defecto
      onFilter: (value, record) => record.FIESTATUS === value,
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd} className="button-styles-add-element">
            Agregar
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={(record) =>
              record.FIFORMAPAGOID +
              record.FIMARCAID +
              record.FCNOMBRE +
              record.FIESTATUS
            }
            columns={columns}
            loading={isLoading}
            scroll={{ x: 1000, y: 600 }}
          />
        </Col>
      </Row>
      <>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <AdministrationFormasPagoForm
            selectedItem={selectedItem}
            marca={dataMarca}
            fetchData={fetchData}
            setShowModal={setShowModal}
            formasPagoUnicas={dataUnique}
          />
        </Modal>
      </>
    </>
  );
};
