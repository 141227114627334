import createAxiosInstance from "../LambdaApiAtb";
import { ENVIROMENT } from "../../utilities"

export async function PutFetchModeloAtb(body) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/atb/modeloatb`: `/atb/modeloatb`
  const requestConfig = {
    method: "PUT", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: body
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
