import React, { useState, useEffect } from "react";
import "../theme/ReporteCierreDia.css";
import { Image, Checkbox } from "antd";
import { UseTokenGlobal, UseAdmin } from "../hooks";
import { AddFetchNotificaciones } from "../Api/Administracion/AddFetchNotificaciones";
import { fetchReclasificacionEdit } from "../Api/fetchReclasificacionEdit";
import { remoteConfig } from "../firebase";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { ENVIROMENT } from "../utilities";

export const FormDetailsReport = ({
  selectedItem,
  setShowModal,
  getReportData,
}) => {
  const { tokenProd } = UseTokenGlobal();
  const { rolId } = UseAdmin();
  const objDefault = {
    countryId: 1,
    brandId: 0,
    title: "Rechazo de evidencias",
    message: "",
    url: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [checkedValues, setCheckedValues] = useState([false, false, false]);
  const [disableRejection, setdisableRejection] = useState(false);
  const objDefaultComent = {
    comentarioCierre: "",
    comentarioFicha: "",
    comentarioValores: "",
  };
  const [formDataMensaje, setFormDataMensaje] = useState(objDefaultComent);
  const [configuracion, setConfiguracion] = useState(false);
  const [isRechazo1, setisRechazo1] = useState(false);
  const [isRechazo2, setisRechazo2] = useState(false);
  const [isRechazo3, setisRechazo3] = useState(false);

  // Obtener la fecha de hoy
  const today = new Date();

  // Obtener los componentes de la fecha y hora
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");

  // Formatear la fecha en el formato deseado
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  const newCheckedValues = [false, false, false];

  useEffect(() => {
    const objDefaultComent = {
      comentarioCierre: selectedItem?.detallevidencia[0]?.comentario ?? "",
      comentarioFicha: selectedItem?.detallevidencia[1]?.comentario ?? "",
      comentarioValores: selectedItem?.detallevidencia[2]?.comentario ?? "",
    };
    setFormDataMensaje(objDefaultComent);
    setCheckedValues(newCheckedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    checkFecha();
    checkRechazo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    // Activa la recuperación de valores remotos
    remoteConfig.settings.minimumFetchIntervalMillis = 900000; // Intervalo mínimo de 1 hora entre las solicitudes de configuración
    fetchAndActivate(remoteConfig)
      .then(() => {
        // Obtén la configuración remota
        const env = `${
          ENVIROMENT === "dev"
            ? "roles_reject_evidences_dev"
            : "roles_reject_evidences"
        }`;

        const remoteConfigData = getValue(remoteConfig, env);
        //console.log("remoteConfigData", remoteConfigData);
        const includePermision = remoteConfigData._value.includes(rolId);
        setConfiguracion(includePermision);
        // console.log("includePermision", includePermision);
      })
      .catch((error) => {
        /// console.error("Error al obtener la configuración remota:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const checkFecha = () => {
    const esEditable1 = selectedItem?.detallevidencia[0].esEditable ?? false;
    const esEditable2 = selectedItem?.detallevidencia[1].esEditable ?? false;
    const esEditable3 = selectedItem?.detallevidencia[2].esEditable ?? false;
    const esEditable = esEditable1 || esEditable2 || esEditable3;
    ///console.log("esEditable", esEditable);
    setdisableRejection(esEditable);
  };

  const checkRechazo = () => {
    const esRechazo1 =
      selectedItem?.detallevidencia[0].statusEvidencia === "1" ? true : false;
    setisRechazo1(esRechazo1);
    const esRechazo2 =
      selectedItem?.detallevidencia[1].statusEvidencia === "1" ? true : false;
    setisRechazo2(esRechazo2);
    const esRechazo3 =
      selectedItem?.detallevidencia[2].statusEvidencia === "1" ? true : false;
    setisRechazo3(esRechazo3);
  };

  const onChange = (index) => (e) => {
    const newCheckedValues = [...checkedValues];
    newCheckedValues[index] = e.target.checked;
    setCheckedValues(newCheckedValues);
    ///console.log("newCheckedValues", newCheckedValues);
  };

  const handleChangeMensaje = (event) => {
    const { name, value } = event.target;
    if (value.length <= 50) {
      setFormDataMensaje({ ...formDataMensaje, [name]: value });
    } else {
      // Si el mensaje tiene más de 50 caracteres, truncarlo a 50 caracteres
      // y luego actualizar el estado.
      setFormDataMensaje({ ...formDataMensaje, [name]: value.slice(0, 50) });
    }
  };

  const sentNotificaciones = () => {
      const onFailure = (error) => {};

      const partes = selectedItem?.fechaRegistro.split(" "); // Dividir la cadena en fecha y hora
      const fechaFormateada = partes[0]; // Obtener la parte de la fecha

      const numeroNumerico = parseInt(selectedItem?.sucursal);
      // Suponiendo que tienes los datos checkedValues definidos correctamente
      const evidenciasSeleccionadas = [];

      // Verificar si checkedValues[0] es true y agregar la evidencia correspondiente
      if (checkedValues[0]) {
        evidenciasSeleccionadas.push(" Evidencia de cierre de lote ");
      }

      // Verificar si checkedValues[1] es true y agregar la evidencia correspondiente
      if (checkedValues[1]) {
        evidenciasSeleccionadas.push(" Evidencia ficha de depósito");
      }

      // Verificar si checkedValues[2] es true y agregar la evidencia correspondiente
      if (checkedValues[2]) {
        evidenciasSeleccionadas.push(" Evidencia de entrega de valores ");
      }

      // Construir la cadena de texto solo si hay evidencias seleccionadas
      const evidenciasParaEnviar =
        evidenciasSeleccionadas.length > 0
          ? evidenciasSeleccionadas.join(", ")
          : null;

      // Ahora puedes usar la constante evidenciasParaEnviar según sea necesario

      const messageFetch =
        "Tienes 24 horas para reenviar " + evidenciasParaEnviar;
      const formDataToSend = {
        countryId: 1,
        brandId: parseInt(selectedItem.marca),
        branches: [numeroNumerico],
        title: formData.title + "-" + fechaFormateada,
        message: messageFetch,
        url: "Reclasificacion",
        date: fechaFormateada,
      };

      //console.log("formDataToSend", formDataToSend);
      AddFetchNotificaciones(formDataToSend)
        .then(() => {
          ///console.log("Petición enviada");
        })
        .catch(onFailure);
      setTimeout(() => {
        setFormData(objDefault);

        alert("Notificación enviada exitosamente");
      }, 100);
    
  };

  const sentEdithReclasificacion = (item) => {
      const didiEfectivo =
        selectedItem.formaPagoReclasificacion.length >= 23
          ? Number(selectedItem.formaPagoReclasificacion[22].cantidad)
          : Number(0);
      const uberEfectivo =
        selectedItem.formaPagoReclasificacion.length >= 24
          ? Number(selectedItem.formaPagoReclasificacion[23].cantidad)
          : Number(0);
      const rappiEfectivo =
        selectedItem.formaPagoReclasificacion.length >= 25
          ? Number(selectedItem.formaPagoReclasificacion[24].cantidad)
          : Number(0);
      const anticipoOtrosCreditos =
        selectedItem.formaPagoReclasificacion.length >= 26
            ? Number(selectedItem.formaPagoReclasificacion[25].cantidad)
            : Number(0);
      const otrosCreditos = 
        selectedItem.formaPagoReclasificacion.length >= 27
            ? Number(selectedItem.formaPagoReclasificacion[26].cantidad)
            : Number(0);
      const  deliverySr=
      selectedItem.formaPagoReclasificacion.length >= 28
            ? Number(selectedItem.formaPagoReclasificacion[27].cantidad)
            : Number(0);
      const cierreFecha =
        checkedValues[0] === false
          ? selectedItem?.detallevidencia[0].fechaEstatus
          : formattedDate;
      const fichaFecha =
        checkedValues[1] === false
          ? selectedItem?.detallevidencia[1].fechaEstatus
          : formattedDate;
      const valoresFecha =
        (checkedValues[2] === false) === false
          ? selectedItem?.detallevidencia[2].fechaEstatus
          : formattedDate;

      const cierreStatus =
        checkedValues[0] === false
          ? selectedItem?.detallevidencia[0].statusEvidencia
          : "1";
      const fichaStatus =
        checkedValues[1] === false
          ? selectedItem?.detallevidencia[1].statusEvidencia
          : "1";
      const valoresStatus =
        checkedValues[2] === false
          ? selectedItem?.detallevidencia[2].statusEvidencia
          : "1";

      const textComent =
        selectedItem?.comentarios === "None" ||
        selectedItem?.comentarios === null ||
        selectedItem?.comentarios === undefined
          ? ""
          : selectedItem?.comentarios;

      const onFailure = (error) => {
        //console.log("error", error);
      };
      const onSuccess = ({ data }) => {
        //console.log("envio de put rechazo", data);
        setShowModal(false);
        getReportData();
        setFormDataMensaje(objDefaultComent);
        setCheckedValues(newCheckedValues);
      };
      fetchReclasificacionEdit(
        Number(selectedItem.pais),
        Number(selectedItem.marca),
        Number(selectedItem.sucursal),
        selectedItem.nombreTienda,
        Number(selectedItem.ventaTotal),
        Number(selectedItem.depositoTombola),
        Number(selectedItem.efectivoADepositar),
        selectedItem.nombreGerente,
        selectedItem.fechaRegistro,
        formattedDate,
        selectedItem.dispositivo,
        Number(selectedItem.propina),
        Number(selectedItem.porcentajePropina),
        selectedItem.urlCierreLote,
        selectedItem.urlImagenFichaDeposito,
        selectedItem.urlImagenEntregaValores,
        selectedItem.noTicket,
        Number(selectedItem.latitud),
        Number(selectedItem.longitud),
        textComent,
        Number(selectedItem.formaPagoReclasificacion[0].cantidad),
        Number(selectedItem.formaPagoReclasificacion[1].cantidad),
        Number(selectedItem.formaPagoReclasificacion[2].cantidad),
        Number(selectedItem.formaPagoReclasificacion[3].cantidad),
        Number(selectedItem.formaPagoReclasificacion[4].cantidad),
        Number(selectedItem.formaPagoReclasificacion[5].cantidad),
        Number(selectedItem.formaPagoReclasificacion[6].cantidad),
        Number(selectedItem.formaPagoReclasificacion[7].cantidad),
        Number(selectedItem.formaPagoReclasificacion[8].cantidad),
        Number(selectedItem.formaPagoReclasificacion[9].cantidad),
        Number(selectedItem.formaPagoReclasificacion[10].cantidad),
        Number(selectedItem.formaPagoReclasificacion[11].cantidad),
        Number(selectedItem.formaPagoReclasificacion[12].cantidad),
        Number(selectedItem.formaPagoReclasificacion[13].cantidad),
        Number(selectedItem.formaPagoReclasificacion[14].cantidad),
        Number(selectedItem.formaPagoReclasificacion[15].cantidad),
        Number(selectedItem.formaPagoReclasificacion[16].cantidad),
        Number(selectedItem.formaPagoReclasificacion[17].cantidad),
        Number(selectedItem.formaPagoReclasificacion[18].cantidad),
        Number(selectedItem.formaPagoReclasificacion[19].cantidad),
        Number(selectedItem.formaPagoReclasificacion[20].cantidad),
        Number(selectedItem.formaPagoReclasificacion[21].cantidad),
        didiEfectivo,
        uberEfectivo,
        rappiEfectivo,
        anticipoOtrosCreditos,
        otrosCreditos,
        deliverySr,
        formDataMensaje.comentarioCierre,
        checkedValues[0],
        cierreStatus,
        cierreFecha,
        formDataMensaje.comentarioFicha,
        checkedValues[1],
        fichaStatus,
        fichaFecha,
        formDataMensaje.comentarioValores,
        checkedValues[2],
        valoresStatus,
        valoresFecha,
      )
        .then(onSuccess)
        .catch(onFailure);
    
  };

  const handleFuntions = () => {
    const todasSonFalse = checkedValues.every((value) => value === false);
    const errors = [];

    if (todasSonFalse) {
      errors.push(
        "Es necesario marcar una casilla para rechazar una evidencia"
      );
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }
    sentNotificaciones();
    sentEdithReclasificacion();
  };

  return (
    <>
      <div className="container-box-modal">
        <div className="Title-report-modal">
          <div className="title-item-modal-reporte">
            {selectedItem?.sucursal}
          </div>
          <div className="title-item-modal-reporte">
            {selectedItem?.nombreTienda}
          </div>
        </div>
        <hr />
        <div className="row-seccion-detail-reporte">
          <div className="info-formas-pago-content-reporte">
            <div className="row-formas">
              <div className="row-fecha-reporte">
                <div>
                  <div className="font-text-bold">Fecha registro</div>
                  <div className="font-text-bold">
                    {selectedItem?.fechaRegistro}
                  </div>
                </div>
                <div>
                  <div className="font-text-bold">Fecha modificación</div>
                  <div className="font-text-bold">
                    {selectedItem?.fechaModificacion}
                  </div>
                </div>
              </div>
              <hr />
              <div className="info-date-register-reporte">
                <div className="font-text-bold">Ventas Total:</div>
                <div className="text-fomaspago-reporte">
                  {parseFloat(selectedItem?.ventaTotal).toLocaleString(
                    "es-MX",
                    {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }
                  )}
                </div>
              </div>
              <div className="info-date-register-reporte">
                <div className="font-text-bold">Deposito en Tombola:</div>
                <div className="text-fomaspago-reporte">
                  {parseFloat(selectedItem?.depositoTombola).toLocaleString(
                    "es-MX",
                    {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }
                  )}
                </div>
              </div>
              <div className="info-date-register-reporte">
                <div className="font-text-bold">Efectivo a depositar:</div>
                <div className="text-fomaspago-reporte">
                  {parseFloat(selectedItem?.efectivoADepositar).toLocaleString(
                    "es-MX",
                    {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }
                  )}
                </div>
              </div>
              <hr />
              <div>
                <div className="font-text-bold">Gerente en turno:</div>
                <div className="text-fomaspago-reporte-info">
                  {selectedItem?.nombreGerente}
                </div>
                <div className="font-text-bold">Información dispositivo:</div>
                <div className="text-fomaspago-reporte-info">
                  {selectedItem?.dispositivo}
                </div>
                <div className="row-location">
                  <div className="row-location-item">
                    <div className="font-text-bold">Latitud:</div>
                    <div className="text-fomaspago-reporte-info">
                      {selectedItem?.latitud}
                    </div>
                  </div>
                  <div className="row-location-item">
                    <div className="font-text-bold">Longitud:</div>
                    <div className="text-fomaspago-reporte-info">
                      {selectedItem?.longitud}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="font-text-bold">No Ticket:</div>
                  {selectedItem?.noTicket !== "None" &&
                    selectedItem?.noTicket !== "Null" && (
                      <div className="text-fomaspago-reporte-info">
                        {selectedItem?.noTicket}
                      </div>
                    )}
                </div>
                <div className="box-formaspago-reporte-info-comentarios">
                  <div className="font-text-bold">Comentario:</div>
                  {selectedItem?.comentarios !== "None" && (
                    <div className="comment-container">
                      {selectedItem?.comentarios}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row-formas">
              {selectedItem?.formaPagoReclasificacion.map((item, index) => (
                <div className="item-formapago-reporte" key={item?.formaPago}>
                  <div className="text-fomaspago-reporte-descrip">
                    {item?.descFormaPago}
                  </div>
                  <div className="text-fomaspago-reporte">
                    {parseFloat(item?.cantidad).toLocaleString("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="box-images-report-modal">
            <div className="font-text-bold-flags-tag">
              {configuracion === false && (
                <div>
                  No cuentas con los permisos necesarios para rechazar las
                  evidencias en este momento.
                </div>
              )}
              {disableRejection ? (
                <div>Marca la casilla si deseas rechazar una evidencia</div>
              ) : (
                <div>El período de rechazo ha superado el límite permitido</div>
              )}
            </div>
            <div className="box-images-report-modal-detailes">
              {selectedItem?.urlCierreLote !== "Null" && (
                <div className="box-images-report-modal-detailes-item">
                  <div className="font-text-bold-title-evidence">
                    {selectedItem?.detallevidencia[0].descripcion}
                  </div>
                  <div className="box-row-status-details-checkbox">
                    <div className="image-evidence-reporte">
                      <Image
                        width={70}
                        src={`${
                          selectedItem?.urlCierreLote
                        }?${new Date().getTime()}`}
                        alt={selectedItem?.urlCierreLote}
                      />
                    </div>

                    <div className="box-row-status-details-checkbox-informacion">
                      <div className="font-text-bold">Estatus:</div>
                      {selectedItem?.detallevidencia[0].statusEvidencia ===
                      "0" ? (
                        <div className="font-text-bold">Inicial</div>
                      ) : selectedItem?.detallevidencia[0].statusEvidencia ===
                        "1" ? (
                        <div className="font-text-boldRed">Rechazado</div>
                      ) : selectedItem?.detallevidencia[0].statusEvidencia ===
                        "2" ? (
                        <div className="font-text-boldGreen">Reenviado</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="font-text-bold">Rechazar:</div>
                      <Checkbox
                        onChange={onChange(0)}
                        checked={checkedValues[0]}
                        disabled={
                          !selectedItem?.detallevidencia[0].esEditable ||
                          !configuracion ||
                          isRechazo1
                        }
                      ></Checkbox>
                    </div>
                    <div>
                      <div className="font-text-bold">Motivo de rechazo:</div>
                      <textarea
                        type="text"
                        name="comentarioCierre"
                        onChange={handleChangeMensaje}
                        value={formDataMensaje.comentarioCierre}
                        className={`input-content-report-evidencia ${
                          formDataMensaje.comentarioCierre.length >= 50
                            ? "input-comentario-error"
                            : ""
                        }`}
                        disabled={
                          !selectedItem?.detallevidencia[0].esEditable ||
                          !configuracion ||
                          isRechazo1
                        }
                      ></textarea>

                      {formDataMensaje.comentarioCierre.length >= 50 && (
                        <div className="text-error-evidencia">
                          Deben ser menos de 50 caracteres
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectedItem?.urlImagenFichaDeposito !== "Null" && (
                <div className="box-images-report-modal-detailes-item">
                  <div className="font-text-bold-title-evidence">
                    {selectedItem?.detallevidencia[1].descripcion}
                  </div>
                  <div className="box-row-status-details-checkbox">
                    <div className="image-evidence-reporte">
                      <Image
                        width={70}
                        src={`${
                          selectedItem?.urlImagenFichaDeposito
                        }?${new Date().getTime()}`}
                        alt={selectedItem?.urlImagenFichaDeposito}
                      />
                    </div>

                    <div className="box-row-status-details-checkbox-informacion">
                      <div className="font-text-bold">Estatus:</div>
                      {selectedItem?.detallevidencia[1].statusEvidencia ===
                      "0" ? (
                        <div className="font-text-bold">Inicial</div>
                      ) : selectedItem?.detallevidencia[1].statusEvidencia ===
                        "1" ? (
                        <div className="font-text-boldRed">Rechazado</div>
                      ) : selectedItem?.detallevidencia[1].statusEvidencia ===
                        "2" ? (
                        <div className="font-text-boldGreen">Reenviado</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="font-text-bold">Rechazar:</div>
                      <Checkbox
                        onChange={onChange(1)}
                        checked={checkedValues[1]}
                        disabled={
                          !selectedItem?.detallevidencia[1].esEditable ||
                          !configuracion ||
                          isRechazo2
                        }
                      ></Checkbox>
                    </div>
                    <div>
                      <div className="font-text-bold">Motivo de rechazo:</div>
                      <textarea
                        type="text"
                        name="comentarioFicha"
                        onChange={handleChangeMensaje}
                        value={formDataMensaje.comentarioFicha}
                        className={`input-content-report-evidencia ${
                          formDataMensaje.comentarioFicha.length >= 50
                            ? "input-comentario-error"
                            : ""
                        }`}
                        disabled={
                          !selectedItem?.detallevidencia[1].esEditable ||
                          !configuracion ||
                          isRechazo2
                        }
                      ></textarea>

                      {formDataMensaje.comentarioFicha.length >= 50 && (
                        <div className="text-error-evidencia">
                          Deben ser menos de 50 caracteres
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectedItem?.urlImagenEntregaValores !== "Null" && (
                <div className="box-images-report-modal-detailes-item">
                  <div className="font-text-bold-title-evidence">
                    {selectedItem?.detallevidencia[2].descripcion}
                  </div>
                  <div className="box-row-status-details-checkbox">
                    <div className="image-evidence-reporte">
                      <Image
                        width={70}
                        src={`${
                          selectedItem?.urlImagenEntregaValores
                        }?${new Date().getTime()}`}
                        alt={selectedItem?.urlImagenEntregaValores}
                      />
                    </div>
                    <div className="box-row-status-details-checkbox-informacion">
                      <div className="font-text-bold">Estatus:</div>
                      {selectedItem?.detallevidencia[2].statusEvidencia ===
                      "0" ? (
                        <div className="font-text-bold">Inicial</div>
                      ) : selectedItem?.detallevidencia[2].statusEvidencia ===
                        "1" ? (
                        <div className="font-text-boldRed">Rechazado</div>
                      ) : selectedItem?.detallevidencia[2].statusEvidencia ===
                        "2" ? (
                        <div className="font-text-boldGreen">Reenviado</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="font-text-bold">Rechazar:</div>
                      <Checkbox
                        onChange={onChange(2)}
                        checked={checkedValues[2]}
                        disabled={
                          !selectedItem?.detallevidencia[2].esEditable ||
                          !configuracion ||
                          isRechazo3
                        }
                      ></Checkbox>
                    </div>
                    <div>
                      <div className="font-text-bold">Motivo de rechazo:</div>
                      <textarea
                        type="text"
                        name="comentarioValores"
                        onChange={handleChangeMensaje}
                        value={formDataMensaje.comentarioValores}
                        className={`input-content-report-evidencia ${
                          formDataMensaje.comentarioValores.length >= 50
                            ? "input-comentario-error"
                            : ""
                        }`}
                        disabled={
                          !selectedItem?.detallevidencia[2].esEditable ||
                          !configuracion ||
                          isRechazo3
                        }
                      ></textarea>

                      {formDataMensaje.comentarioValores.length >= 50 && (
                        <div className="text-error-evidencia">
                          Deben ser menos de 50 caracteres
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer-edith">
          <button
            onClick={handleFuntions}
            // className="primary-button button-modal"
            className={`button-modal ${
              !configuracion || !disableRejection
                ? "primary-button-disable"
                : "primary-button"
            }`}
            disabled={!disableRejection || !configuracion}
          >
            Rechazar
          </button>
        </div>
      </div>
    </>
  );
};
