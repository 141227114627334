import React, { useEffect, useMemo, useReducer } from "react";
import { TokenReclasificacionContext } from "./TokenReclasificacionContext";
import { getStoredValue, removeStoredValue, storeValue } from "../storage";
//import CookieManager from "@react-native-cookies/cookies";

const reducer = (prevState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        status: "restoreToken",
        tokenResiclasificacion: action.tokenResiclasificacion,
      };
    case "SIGN_OUT":
      return {
        status: "signOut",
        tokenResiclasificacion: null,
      };
    case "SIGN_IN":
      return {
        status: "signIn",
        tokenResiclasificacion: action.tokenResiclasificacion,
      };
  }
};

export function TokenReclasificacionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    status: "idle",
    tokenResiclasificacion: null,
  });

  useEffect(() => {
    const loadAuthData = async () => {
      try {
        const tokenResiclasificacion = await getStoredValue(
          "tokenResiclasificacion"
        );

        if (tokenResiclasificacion !== null) {
          dispatch({
            type: "RESTORE_TOKEN",
            tokenResiclasificacion,
          });
        } else {
          dispatch({ type: "SIGN_OUT" });
        }
      } catch (e) {
        dispatch({ type: "SIGN_OUT" });
      }
    };
    if (state.status === "idle") {
      loadAuthData();
    }
  }, [state, dispatch]);

  const actions = useMemo(
    () => ({
      TokenReclasificacionIn: async (tokenResiclasificacion) => {
        await Promise.all([
          storeValue("tokenResiclasificacion", tokenResiclasificacion),
        ]);
        dispatch({
          type: "SIGN_IN",
          tokenResiclasificacion,
        });
      },
      TokenReclasificacionOut: async () => {
        await Promise.all([removeStoredValue("tokenResiclasificacion")]);
        dispatch({ type: "SIGN_OUT" });
        // CookieManager.clearAll().then((success) => {
        //    console.log("CookieManager.clearAll =>", success);
        //    });
      },
    }),
    [dispatch]
  );

  return (
    <TokenReclasificacionContext.Provider value={{ ...state, ...actions }}>
      {children}
    </TokenReclasificacionContext.Provider>
  );
}
