import React from "react";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ListadeHorarios } from "./ListadeHorarios";

export const VistaHorarios = () => {
  const navigate = useNavigate();
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div>
              <div className="title-name-admin">
                <div className="icon-goback-title-admin">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div>Vista de Horarios</div>
                </div>
              </div>
              <div className="box-table-contantent-reportes">
                <ListadeHorarios />
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
