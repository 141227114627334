import createAxiosInstance from "./LambdaApiCierreDia";


export async function fetchFormasPagoAdd(body) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: "/reclasificacion/available-fields",
    data: body
  };
  return axiosInstance(requestConfig);
}
