import createAxiosInstance from "./LambdaApiAdministracion";
import { ENVIROMENT } from "../utilities"

export async function fetchRecaptcha(token) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/recaptcha?origin=web&captcha=${token}`: `home/recaptcha?origin=web&captcha=${token}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
        "Content-Type": "application/json",
    },
    
  };

  return axiosInstance(requestConfig);

}
