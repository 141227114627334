import React from 'react';
import {
  ENVIROMENT,
  DEV_OLD_AUTH_CONSOLE,
  DEV_NEW_AUTH_CONSOLE,
  DEV_FRECUENCIAS_AUTH_CONSOLE,
  DEV_RECLASIFICACION_AUTH_CONSOLE,
  PROD_OLD_AUTH_CONSOLE,
  PROD_NEW_AUTH_CONSOLE,
  PROD_FRECUENCIAS_AUTH_CONSOLE,
  PROD_RECLASIFICACION_AUTH_CONSOLE,
} from "../utilities";

export const SelectToken = async (AuthRemotes) => {
  let identificadorToken = ""; // Declaramos la variable antes de los bloques condicionales.

  if (ENVIROMENT === "dev") {
    if (AuthRemotes === DEV_OLD_AUTH_CONSOLE) {
      identificadorToken = "accessTokenIndicadores";
    } else if (AuthRemotes === DEV_NEW_AUTH_CONSOLE) {
      identificadorToken = "accessTokenAtb";
    } else if (AuthRemotes === DEV_FRECUENCIAS_AUTH_CONSOLE) {
      identificadorToken = "accessTokenFrecuencias";
    } else if (AuthRemotes === DEV_RECLASIFICACION_AUTH_CONSOLE) {
      identificadorToken = "accessTokenReclasificacion";
    } else {
      //console.log("no identificado dev");
    }
  } else {
    if (AuthRemotes === PROD_OLD_AUTH_CONSOLE) {
      identificadorToken = "accessTokenIndicadores";
    } else if (AuthRemotes === PROD_NEW_AUTH_CONSOLE) {
      identificadorToken = "accessTokenAtb";
    } else if (AuthRemotes === PROD_FRECUENCIAS_AUTH_CONSOLE) {
      identificadorToken = "accessTokenFrecuencias";
    } else if (AuthRemotes === PROD_RECLASIFICACION_AUTH_CONSOLE) {
      identificadorToken = "accessTokenReclasificacion";
    } else {
      //console.log("no identificado prod");
    }
  }

  //console.log(identificadorToken);
  return identificadorToken; // Ahora la variable siempre tiene un valor definido.
};
