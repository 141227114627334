import React from "react";
import "../theme/Administracion.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TableAdministracionSucursal } from "../components/TableAdministracionSucursal";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";

export const AdminPageSucursal = () => {
  const navigate = useNavigate();
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="body-color-admin">
              <div className="title-name-admin">
                <div className="icon-goback-title-admin">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div> Administración de Sucursales </div>
                </div>
              </div>

              <TableAdministracionSucursal />
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
