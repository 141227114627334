/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { GetFetchSucursales } from "../Api/Administracion/GetFetchSucursales";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import "../theme/AdministracionCatalogo.css";
import "../theme/Pagination.css";
import EditIcon from "@mui/icons-material/Edit";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import "react-datepicker/dist/react-datepicker.css";
import { AdministrationSucursalAddForm } from "./AdministrationSucursalAddForm";
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Space,
  Descriptions,
  Tag,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const Schedule = ({ obj, all }) => {
  return (
    <>
      <p>
        Estatus:{" "}
        <Tag color={parseInt(all.FIESTATUSID) === 1 ? "green" : "volcano"}>
          {parseInt(all.FIESTATUSID) === 1 ? "Encendida" : "Apagada"}
        </Tag>
      </p>
      {obj?.LUNES_VIERNES && (
        <>
          <Descriptions
            title="Lunes - Viernes"
            items={[
              {
                key: "1",
                label: "Apertura",
                children: obj.LUNES_VIERNES?.HORA_APERTURA,
              },
              {
                key: "2",
                label: "Cierre",
                children: obj.LUNES_VIERNES?.HORA_CIERRE,
              },
            ]}
          />
        </>
      )}
      {obj?.SABADO && (
        <>
          <Descriptions
            title="SABADO"
            items={[
              {
                key: "1",
                label: "Apertura",
                children: obj.SABADO?.HORA_APERTURA,
              },
              {
                key: "2",
                label: "Cierre",
                children: obj.SABADO?.HORA_CIERRE,
              },
            ]}
          />
        </>
      )}
      {obj?.DOMINGO && (
        <>
          <Descriptions
            title="Domingo"
            items={[
              {
                key: "1",
                label: "Apertura",
                children: obj.DOMINGO?.HORA_APERTURA,
              },
              {
                key: "2",
                label: "Cierre",
                children: obj.DOMINGO?.HORA_CIERRE,
              },
            ]}
          />
        </>
      )}
      {obj?.FESTIVO && (
        <>
          <Descriptions
            title="Festivo"
            items={[
              {
                key: "1",
                label: "Apertura",
                children: obj.FESTIVO?.HORA_APERTURA,
              },
              {
                key: "2",
                label: "Cierre",
                children: obj.FESTIVO?.HORA_CIERRE,
              },
            ]}
          />
        </>
      )}

      {!obj?.LUNES_VIERNES &&
        !obj?.SABADO &&
        !obj?.DOMINGO &&
        !obj?.FESTIVO && <>Sin configuración.</>}
    </>
  );
};

export const TableAdministracionSucursal = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataMarca, setDataMarca] = useState([]);
  const [modalKey, setModalKey] = useState(0);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

   
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };

      const onSuccess = ({ data }) => {
        //console.log("productos", data.body.data);
        setData(data.body.data);
        setIsLoading(false);
      };

      GetFetchSucursales(false).then(onSuccess).catch(onFailure);
    
  };

  useEffect(() => {
    fetchDataMarca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataMarca = async () => {
    
      const onFailure = (error) => {};

      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),

    onFilter: (value, record) => {
      const dataIndexValue = record[dataIndex];

      // Verificar si el campo existe antes de intentar acceder a sus propiedades
      if (dataIndexValue !== undefined && dataIndexValue !== null) {
        return dataIndexValue
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }

      // Si el campo no existe o es nulo, no incluir en el filtro
      return false;
    },

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID Sucursal",
      dataIndex: "FISUCURSALID",
      key: "FISUCURSALID",
      sorter: (a, b) => parseInt(a.FISUCURSALID) - parseInt(b.FISUCURSALID),
      ...getColumnSearchProps("FISUCURSALID"),
    },
    {
      title: "Nombre",
      dataIndex: "FCNOMBRE",
      key: "FCNOMBRE",
      ...getColumnSearchProps("FCNOMBRE"),
    },
    {
      title: "Email",
      dataIndex: "FCEMAIL",
      key: "FCEMAIL",
      ...getColumnSearchProps("FCEMAIL"),
    },
    {
      title: "UTC",
      dataIndex: "FCHORAUTC",
      key: "FCHORAUTC",
      //      ...getColumnSearchProps('FCMARCANOMBRE'),
    },
     {
      title: "Marca",
      dataIndex: "FCNOMBREMARCA",
      key: "FCNOMBREMARCA",
      ...getColumnSearchProps("FCNOMBREMARCA"),
    },
    {
      title: "País",
      dataIndex: "FCNOMBREPAIS",
      key: "FCNOMBREPAIS",
      ...getColumnSearchProps("FCNOMBREPAIS"),
    },
    {
      title: "Equivalencia",
      dataIndex: "FCEQUIVALENCIA",
      key: "FCEQUIVALENCIA",
      ...getColumnSearchProps("FCEQUIVALENCIA"),
    },
    {
      title: "Horarios",
      dataIndex: "FCHORARIO",
      key: "FCHORARIO",
      width: "30%",
      render: (item, all) => <Schedule obj={item} all={all} />,
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"],
      onFilter: (value, record) => record.FIESTATUSID === Number(value),
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "10%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd} className="button-styles-add-element">
            Agregar Sucursal
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={(record) =>
              record.FISUCURSALID +
              record.FCNOMBRE +
              record.FCEMAIL +
              record.FCHORAUTC +
              record.FCEQUIVALENCIA
            }
            columns={columns}
            loading={isLoading}
            bordered={true}
            scroll={{ x: 1400, y: 600 }}
          />
        </Col>
      </Row>
      <>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <AdministrationSucursalAddForm
            fetchData={fetchData}
            setShowModal={setShowModal}
            selectedItem={selectedItem}
            marca={dataMarca}
            modalKey={modalKey}
          />
        </Modal>
      </>
    </>
  );
};
