import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchFormasPago(paisId, brandId) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: `/reclasificacion/available-fields?countryId=${paisId}&brandId=${brandId}`,
  };

  return axiosInstance(requestConfig);
}
