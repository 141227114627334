import { useState, useEffect } from "react";
import { Row, Col, Switch } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { AddFetchSucursal } from "../Api/Administracion/AddFetchSucursal";
import { PutFetchSucursal } from "../Api/Administracion/PutFetchSucursal";

export const AdministrationSucursalAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  modalKey
}) => {

  const pais =[
    {
      FIPAISID:1,
      FCNOMBRE:"MEXÍCO"
    }
  ]
  
  const objDefault = {
    FIMARCAID: "",
    FISUCURSALID: 0,
    FCEMAIL: "",
    FCNOMBRE: "",
    FIESTATUSID: 1,
    FIPAISID: 1,
    FCEQUIVALENCIA: "",
    FCHORAUTC: "",
    FCHORARIO: {
      LUNES_VIERNES: {
        HORA_APERTURA: "",
        HORA_CIERRE: "",
      },
      SABADO: {
        HORA_APERTURA: "",
        HORA_CIERRE: "",
      },
      FESTIVO: {
        HORA_APERTURA: "",
        HORA_CIERRE: "",
      },
      DOMINGO: {
        HORA_APERTURA: "",
        HORA_CIERRE: "",
      },
    },
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FISUCURSALID: selectedItem.FISUCURSALID,
        FCEMAIL: selectedItem.FCEMAIL,
        FCNOMBRE: selectedItem.FCNOMBRE,
        FIESTATUSID: selectedItem.FIESTATUSID,
        FIPAISID: selectedItem.FIPAISID,
        FCEQUIVALENCIA: selectedItem.FCEQUIVALENCIA,
        FCHORAUTC: selectedItem.FCHORAUTC,
        FCHORARIO: {
          LUNES_VIERNES: {
            HORA_APERTURA:
              selectedItem.FCHORARIO?.LUNES_VIERNES?.HORA_APERTURA ?? "",
            HORA_CIERRE:
              selectedItem.FCHORARIO?.LUNES_VIERNES?.HORA_CIERRE ?? "",
          },
          SABADO: {
            HORA_APERTURA: selectedItem.FCHORARIO?.SABADO?.HORA_APERTURA ?? "",
            HORA_CIERRE: selectedItem.FCHORARIO?.SABADO?.HORA_CIERRE ?? "",
          },
          FESTIVO: {
            HORA_APERTURA: selectedItem.FCHORARIO?.FESTIVO?.HORA_APERTURA ?? "",
            HORA_CIERRE: selectedItem.FCHORARIO?.FESTIVO?.HORA_CIERRE ?? "",
          },
          DOMINGO: {
            HORA_APERTURA: selectedItem.FCHORARIO?.DOMINGO?.HORA_APERTURA ?? "",
            HORA_CIERRE: selectedItem.FCHORARIO?.DOMINGO?.HORA_CIERRE ?? "",
          },
        },
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: "",
        FISUCURSALID: "",
        FCEMAIL: "",
        FCNOMBRE: "",
        FIESTATUSID: 1,
        FIPAISID: 1,
        FCEQUIVALENCIA: "",
        FCHORAUTC: "",
        FCHORARIO: {
          LUNES_VIERNES: {
            HORA_APERTURA: "",
            HORA_CIERRE: "",
          },
          SABADO: {
            HORA_APERTURA: "",
            HORA_CIERRE: "",
          },
          FESTIVO: {
            HORA_APERTURA: "",
            HORA_CIERRE: "",
          },
          DOMINGO: {
            HORA_APERTURA: "",
            HORA_CIERRE: "",
          },
        },
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(name === "FISUCURSALID"){
      const numericValue = value.replace(/[^0-9]/g, "");
        setErrors((prevFormData) => ({
          ...prevFormData,
          FISUCURSALID: "La sucursal Id solo debe contener 10 digitos",
        }));
        setFormData({ ...formData, [name]: numericValue.slice(0, 10) });
        return
    }
    if (name === "FCNOMBRE" && value.length > 50) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOMBRE: "El nombre no debe exceder 50 caracteres.",
      }));
      return;
     }
    // Realiza una copia profunda del estado actual
    const updatedFormData = JSON.parse(JSON.stringify(formData));
    // Divide el nombre del campo en partes usando el punto como delimitador
    const fieldNames = name.split(".");
    if (fieldNames.length === 1) {
      // Si solo hay un nivel, actualiza directamente el campo principal
      updatedFormData[name] = value;
    } else {
      // Si hay más de un nivel, actualiza el campo anidado
      let currentObject = updatedFormData;
      for (let i = 0; i < fieldNames.length - 1; i++) {
        const fieldName = fieldNames[i];
        currentObject = currentObject[fieldName];
      }
      const lastFieldName = fieldNames[fieldNames.length - 1];
      currentObject[lastFieldName] = value;
    }
    // Establece el nuevo estado
    setFormData(updatedFormData);
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const validateUTCOffset = (offsetString) => {
      const utcOffsetRegex = /^UTC([+-](0[0-9]|1[0-2]))$/;
      return utcOffsetRegex.test(offsetString);
    };
    const esHoraValida = (hora) => {
      const patron = /^([01]\d|2[0-3]):([0-5]\d)$/;
      return patron.test(hora);
    };
    if (formData.FISUCURSALID === "") {
      errors.FISUCURSALID = "Ingresa el valor de sucursal Id";
    } 
    if (formData.FCNOMBRE == "") {
      errors.FCNOMBRE = "Ingresa el valor de nombre";
    }
    if (formData.FCEMAIL == "") {
      errors.FCEMAIL = "Ingresa el valor de email";
    }else if (!isValidEmail(formData.FCEMAIL)) {
      errors.FCEMAIL = "Por favor ingresa un correo electrónico válido.";
    }
    if (formData.FIPAISID == "") {
      errors.FIPAISID = "Seleciona el país";
    }
    if (formData.FCHORAUTC == "") {
      errors.FCHORAUTC = "Ingresa el valor de UTC";
    }else if(!validateUTCOffset(formData.FCHORAUTC)){
      errors.FCHORAUTC = "Por favor ingresa un UTC válido. \nEjemplo UTC-06";
    }
    if (formData.FIMARCAID == "") {
      errors.FIMARCAID = "Seleciona la marca";
    }
    if (formData.FCEQUIVALENCIA == "") {
      errors.FCEQUIVALENCIA = "Ingresa el valor de equivalencia";
    }
    if(formData.FCHORARIO.LUNES_VIERNES.HORA_APERTURA !== ""){
      if (!esHoraValida(formData.FCHORARIO.LUNES_VIERNES.HORA_APERTURA)) {
        errors.LUNES_VIERNES_HORA_APERTURA = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.LUNES_VIERNES.HORA_CIERRE !== ""){
      if (!esHoraValida(formData.FCHORARIO.LUNES_VIERNES.HORA_CIERRE)) {
        errors.LUNES_VIERNES_HORA_CIERRE = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.SABADO.HORA_APERTURA !== ""){
      if (!esHoraValida(formData.FCHORARIO.SABADO.HORA_APERTURA)) {
        errors.SABADO_HORA_APERTURA = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.SABADO.HORA_CIERRE !== ""){
      if (!esHoraValida(formData.FCHORARIO.SABADO.HORA_CIERRE)) {
        errors.SABADO_HORA_CIERRE = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.SABADO.HORA_APERTURA !== ""){
      if (!esHoraValida(formData.FCHORARIO.SABADO.HORA_APERTURA)) {
        errors.SABADO_HORA_APERTURA = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.SABADO.HORA_CIERRE !== ""){
      if (!esHoraValida(formData.FCHORARIO.SABADO.HORA_CIERRE)) {
        errors.SABADO_HORA_CIERRE = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.DOMINGO.HORA_APERTURA !== ""){
      if (!esHoraValida(formData.FCHORARIO.DOMINGO.HORA_APERTURA)) {
        errors.DOMINGO_HORA_APERTURA = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.DOMINGO.HORA_CIERRE !== ""){
      if (!esHoraValida(formData.FCHORARIO.DOMINGO.HORA_CIERRE)) {
        errors.DOMINGO_HORA_CIERRE = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.FESTIVO.HORA_APERTURA !== ""){
      if (!esHoraValida(formData.FCHORARIO.FESTIVO.HORA_APERTURA)) {
        errors.FESTIVO_HORA_APERTURA = "La hora no es válida.";
      }
    }
    if(formData.FCHORARIO.FESTIVO.HORA_CIERRE !== ""){
      if (!esHoraValida(formData.FCHORARIO.FESTIVO.HORA_CIERRE)) {
        errors.FESTIVO_HORA_CIERRE = "La hora no es válida.";
      }
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    } 
    
      function sendData(){
        setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };

      const onSuccess = ({ data }) => {
        // console.log(data);
        // eslint-disable-next-line no-lone-blocks
        setLoading(false)
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      const newObj = {
        ...formData,
        FIMARCAID: parseInt(formData.FIMARCAID),
        FIPAISID: "1",
        FISUCURSALID: parseInt(formData.FISUCURSALID),
        FIESTATUSID: parseInt(formData.FIESTATUSID),
      };
      if (selectedItem) {
        PutFetchSucursal(newObj).then(onSuccess).catch(onFailure);
      } else {
        AddFetchSucursal(newObj).then(onSuccess).catch(onFailure);
      }
      }
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Sucursal
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FISUCURSALID}
          name={"FISUCURSALID"}
          type={"number"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          label={"Sucursal ID"}
        />
        {errors && <div className="error-input-data">{errors.FISUCURSALID}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBRE}
          name={"FCNOMBRE"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCNOMBRE}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCEMAIL}
          name={"FCEMAIL"}
          type={"email"}
          label={"Email"}
        />
        {errors && <div className="error-input-data">{errors.FCEMAIL}</div>}
      </Col>
      <Col xs={24} md={22}>
       <InputSelect
          value={formData.FIPAISID}
          handleChange={handleChange}
          options={pais}
          label="País"
          optionsKey={"FIPAISID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIPAISID"}
        />
         {errors && <div className="error-input-data">{errors.FIPAISID}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCHORAUTC}
          name={"FCHORAUTC"}
          type={"text"}
          label={"UTC"}
          placeholder={"UTC-06"}
        />
         {errors && <div className="error-input-data">{errors.FCHORAUTC}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
        {errors && <div className="error-input-data">{errors.FIMARCAID}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCEQUIVALENCIA}
          name={"FCEQUIVALENCIA"}
          type={"text"}
          label={"Equivalencia"}
        />
         {errors && <div className="error-input-data">{errors.FCEQUIVALENCIA}</div>}
      </Col>
      <Col xs={24} md={22} className="mt-2 mb-2">
        Estatus (
        {parseInt(formData.FIESTATUSID) === 1 ? "Encendida" : "Apagada"}):
        <Switch
          className="ml-2"
          checked={parseInt(formData.FIESTATUSID) === 1}
          defaultValue={parseInt(formData.FIESTATUSID) === 1}
          onChange={(sta) =>
            setFormData({ ...formData, FIESTATUSID: sta ? 1 : 0 })
          }
        />
      </Col>

      <Row justify="center" xs={24} md={22}>
        <label className="text-context-inputs">Horarios</label>
      </Row>

      <Row justify="center" xs={24} md={24}>
        <Col xs={24} md={5}>
          <label className="text-context-inputs">Lun a Vie</label>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.LUNES_VIERNES.HORA_APERTURA}
              name={"FCHORARIO.LUNES_VIERNES.HORA_APERTURA"}
              type={"text"}
              label={"Apertura"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.LUNES_VIERNES_HORA_APERTURA}</div>}
          </Col>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.LUNES_VIERNES.HORA_CIERRE}
              name={"FCHORARIO.LUNES_VIERNES.HORA_CIERRE"}
              type={"text"}
              label={"Cierre"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.LUNES_VIERNES_HORA_CIERRE}</div>}
          </Col>
        </Col>
        <Col xs={24} md={5}>
          <label className="text-context-inputs">Sabado</label>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.SABADO.HORA_APERTURA}
              name={"FCHORARIO.SABADO.HORA_APERTURA"}
              type={"text"}
              label={"Apertura"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.SABADO_HORA_APERTURA}</div>}
          </Col>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.SABADO.HORA_CIERRE}
              name={"FCHORARIO.SABADO.HORA_CIERRE"}
              type={"text"}
              label={"Cierre"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.SABADO_HORA_CIERRE}</div>}
          </Col>
        </Col>
        <Col xs={24} md={5}>
          <label className="text-context-inputs">Domingo</label>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.DOMINGO.HORA_APERTURA}
              name={"FCHORARIO.DOMINGO.HORA_APERTURA"}
              type={"text"}
              label={"Apertura"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.DOMINGO_HORA_APERTURA}</div>}
          </Col>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.DOMINGO.HORA_CIERRE}
              name={"FCHORARIO.DOMINGO.HORA_CIERRE"}
              type={"text"}
              label={"Cierre"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.DOMINGO_HORA_CIERRE}</div>}
          </Col>
        </Col>
        <Col xs={24} md={5}>
          <label className="text-context-inputs">Festivo</label>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.FESTIVO.HORA_APERTURA}
              name={"FCHORARIO.FESTIVO.HORA_APERTURA"}
              type={"text"}
              label={"Apertura"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.FESTIVO_HORA_APERTURA}</div>}
          </Col>
          <Col>
            <InputForm
              handleChange={handleChange}
              value={formData.FCHORARIO.FESTIVO.HORA_CIERRE}
              name={"FCHORARIO.FESTIVO.HORA_CIERRE"}
              type={"text"}
              label={"Cierre"}
              placeholder={"08:00"}
            />
            {errors && <div className="error-input-data">{errors.FESTIVO_HORA_CIERRE}</div>}
          </Col>
        </Col>
      </Row>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
