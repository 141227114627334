import React, { useEffect } from "react";

export const PlatformRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href =
        "https://apps.apple.com/mx/app/centro-operativo-del-gerente/id1672676029";
    } else if (/Android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.alsea.appmanager";
    } else {
      window.location.href = "https://centro.operaciones.alsea.net/";
    }
  }, []);

  return (
    <div>
      {/* No se muestra contenido aquí, ya que el redireccionamiento se realizará */}
    </div>
  );
};
