import React, { useEffect, useState } from "react";
import "../theme/Reclasificacion.css";
import DatePicker from "react-datepicker";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { format } from "date-fns";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ReclasificacionIngresosForm } from "../components/ReclasificacionIngresosForm";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import { fetchReclasificacionGet } from "../Api/FetchReclasificacionGet";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ReclasificacionIngresosFormEdith } from "../components/ReclasificacionIngresosFormEdith";
import { fetchFormasPago } from "../Api/FetchFormasPago";
import { fetchBitacora } from "../Api/fetchBitacora";
import {  UseAdmin, UseUser, UseAuth } from "../hooks";
import { VERSION_APP } from "../utilities";

export const ReclasificacionPage = () => {
  const [info, setInfo] = useState();
  const [fecha, setFecha] = useState(new Date());
  const [fechaFormat, setFechaFormat] = useState(null);
  const [formasPago, setFormasPago] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { brandfiMarcaId, branchFiSucursalId, branchFiPaisId } = UseAdmin();
  const { mail, employeeId, device } = UseUser();
  const { uniqueId } = UseAuth();
  const navigate = useNavigate();

  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fecha,branchFiPaisId,brandfiMarcaId,branchFiSucursalId]);

  const getData = () => {
    const fechaDate = formatDate(fecha);
    setFechaFormat(fechaDate);
    if (
      fechaDate != null &&
      brandfiMarcaId != null &&
      branchFiSucursalId != null &&
      branchFiPaisId != null 
    ) {
      setLoading(true);
      const onFailure = ({ error, config }) => {
        console.log("getData-error",error);
        alert("Error al obtener información de cierre de dia.")
        getBitacora(
          config,
          "Get-cierre por dia",
          error,
        );
      };

      const onSuccess = ({ data, config }) => {
        console.log("getData",data);
        // console.log(fechaDate);
        getFormasPago()
        getBitacora(
          config,
          "Get-cierre por dia",
          data
        );
        setInfo(data);
        setShowForm(data.statusCode);
        console.log(data.statusCode);
      };

      fetchReclasificacionGet(
        fechaDate,
        Number(branchFiPaisId),
        Number(brandfiMarcaId),
        Number(branchFiSucursalId)
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  /*useEffect(() => {
    getFormasPago();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fecha,branchFiPaisId,brandfiMarcaId]);*/

  const getFormasPago = () => {
    if (brandfiMarcaId != null && branchFiPaisId != null ) {
      const onFailure = ({ error, config }) => {
        // console.log(error);
        setLoading(false)
        getBitacora(
          config,
          "Get-Formas de pago",
          error,
        );
      };

      const onSuccess = ({ data, config }) => {
        const campoDeseado = "FIFORMAPAGOID";
        const nuevoArray = data.map((item) => item[campoDeseado]);
        setFormasPago(nuevoArray);
        console.log("nuevo", nuevoArray);
        setLoading(false)
        getBitacora(
          config,
          "Get-Formas de pago",
          data
        );
      };

      fetchFormasPago(Number(branchFiPaisId), Number(brandfiMarcaId))
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const getBitacora = async (
    requestStore,
    description,
    responseData,
  ) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      requestStore != null &&
      responseData != null
    ) {
      const onFailure = (error) => {
        // console.log(description, error);
      };
      const onSuccess = ({ data }) => {
      };
      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        requestStore,
        responseData,
        description,
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="body-color">
              <div className="title-name-admin">
                <div className="icon-goback-title-admin">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div> Cierre de día </div>
                </div>
              </div>

              <div>
                <div className="container-picker-reclasificacion">
                  <div className="text-picker">Fecha:</div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="es"
                    className="text-picker"
                    selected={fecha}
                    onChange={(date) => setFecha(date)}
                  />
                </div>
                {
                  isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    showForm !== null && formasPago !== null && (
                      showForm !== 200 ? (
                        <ReclasificacionIngresosForm
                          fecha={fechaFormat}
                          formasPago={formasPago}
                        />
                      ) : (
                        <ReclasificacionIngresosFormEdith
                          info={info}
                          fecha={fechaFormat}
                          formasPago={formasPago}
                        />
                      )
                    )
                  )
                }
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
