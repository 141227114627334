import React from "react";
import { authProvider } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TableReportes } from "../components/TableReportes";
import "../theme/ReporteCierreDia.css";

export const ReportePage = () => {
  const navigate = useNavigate();

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div>
              <div className="title-name-admin">
                <div className="icon-goback-title-admin">
                  <button
                    className="button-goback-style"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div> Reporte cierre de día</div>
                </div>
              </div>
              <TableReportes />
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
