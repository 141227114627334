import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { AddFetchTipoAtb } from "../Api/Administracion/AddFetchTipoAtb";
import { PutFetchTipoAtb } from "../Api/Administracion/PutFetchTipoAtb";

export const AdministrationAtbAddTipo = ({
  selectedItem,
  fetchData,
  setShowModal,
  modalKey
}) => {
  const objDefault = {
    FITIPOATBID: 0,
    FCNOMBRETIPO: "",
    FIESTATUS: 1,
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FITIPOATBID: selectedItem.FITIPOATBID,
        FCNOMBRETIPO: selectedItem.FCNOMBRETIPO,
        FIESTATUS: selectedItem.FIESTATUS,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FITIPOATBID: 0,
        FCNOMBRETIPO: "",
        FIESTATUS: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCNOMBRETIPO" && value.length > 30) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOMBRETIPO: "El nombre no debe exceder 30 caracteres.",
      }));
      return;
     }
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
        const errors = {};
        if (formData.FCNOMBRETIPO === "") {
          errors.FCNOMBRETIPO = "Ingresa el nombre";
        } else if (formData.FCNOMBRETIPO.length < 3 || formData.FCNOMBRETIPO.length > 30) {
          errors.FCNOMBRETIPO = "El nombre de tipo d debe tener entre 1 y 30 caracteres.";
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors("");
        } 
      setIsLoading(true) 
      const onFailure = (error) => {
        //console.log("error", error);
        alert(error);
        setIsLoading(false)
      };

      const onSuccess = ({ data }) => {
        //console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 200) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          setIsLoading(false)
          alert("Envio exitoso");
        } else {
          alert(data.error);
          setIsLoading(false)
        }
      };

      if (selectedItem) {
        PutFetchTipoAtb(formData).then(onSuccess).catch(onFailure);
      } else {
        AddFetchTipoAtb(formData).then(onSuccess).catch(onFailure);
      }
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Tipo
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBRETIPO}
          name={"FCNOMBRETIPO"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCNOMBRETIPO}</div>}
      </Col>
      
      <Col xs={24} md={22}></Col>
      <Col xs={24} md={22}></Col>

      <div className="modal-footer-edith">
         <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
            <button
              onClick={fetchHandleUpdate}
              type="submit"
              className={`button-modal ${
                isLoading ? "primary-button-disable" : "primary-button"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Guardar"}
            </button>
      </div>
    </Row>
  );
};