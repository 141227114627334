import React from "react";

export const InputSelectWithInName = ({
  value,
  handleChange,
  options,
  label,
  optionsKey,
  optionsName,
  disabled,
  name,
}) => {
  return (
    <>
      <select
        className="input-content-select"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        name={name}
      >
        <option value="0" disabled>
          Seleccionar {label}
        </option>
        {options.map((option) => (
          <option key={option[optionsKey]} value={option[optionsKey]}>
            {option[optionsName]}
          </option>
        ))}
      </select>
    </>
  );
};
