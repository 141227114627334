import React, { useEffect, useState } from "react";
import "../theme/Header.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../context/authProvider";
import moment from "moment";
import "moment/locale/es";
import { VERSION_APP } from "../utilities";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import logo from "../assets/alseaTransparente.png";
import { UseAdmin, UseAuth, UseUser, UseTokenGlobal } from "../hooks";
import { fetchSecciones } from "../Api/fetchSecciones";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [isReporteShow, setIsReportShow] = useState(false);
  const [isAdminShow, setIsAdmintShow] = useState(false);
  const [reporteData, setReportData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const { jobTitle } = UseUser();
  const { branchFiSucursalId, brandfiMarcaId, branchFiPaisId, rolId } =
    UseAdmin();
  const navigate = useNavigate();

  const hoyEs = moment().format("LL");
  const { signOut } = UseAuth();
  const { AdminOut } = UseAdmin();
  const { userOut } = UseUser();
  const { TokenOut, tokenProd } = UseTokenGlobal();

  const handleTokenExpiration = () => {
    authProvider.logout();
    signOut();
    userOut();
    AdminOut();
    TokenOut();
    localStorage.clear();
  };

  useEffect(() => {
    getDataSeccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    branchFiPaisId,
    brandfiMarcaId,
    jobTitle,
    branchFiSucursalId,
    rolId,
  ]);

  const getDataSeccion = () => {
    if (
      branchFiPaisId !== null &&
      branchFiSucursalId !== null &&
      brandfiMarcaId !== null &&
      jobTitle !== null &&
      rolId !== null 
    ) {
      const onFailure = ({ error }) => {};
      const onSuccess = ({ data }) => {
        const extractedData = data.body.seccion.CHILD1.map((item) => ({
          FCJSONSECCION2: item.FCJSONSECCION2,
          FCLINKSECCION2: item.FCLINKSECCION2,
          FCSEGUNDASECCION: item.FCSEGUNDASECCION,
          FCURLIMAGEN2: item.FCURLIMAGEN2,
          FISEGUNDASECCIONID: item.FISEGUNDASECCIONID,
          FCSTACKSECCION2: item.FCSTACKSECCION2,
          FCCHIL2: item.CHILD2,
        }));
        const contieneReportes = extractedData.some(
          (item) => item.FCSEGUNDASECCION === "Reportes"
        );
        setIsReportShow(contieneReportes);

        const contieneAdmin = extractedData.some(
          (item) => item.FCSEGUNDASECCION === "Administración"
        );
        setIsAdmintShow(contieneAdmin);

        if (contieneReportes) {
          const soloReportes = data.body.seccion.CHILD1.filter(
            (item) => item.FCSEGUNDASECCION === "Reportes"
          );
          setReportData(soloReportes);
        }

        if (contieneAdmin) {
          const soloAministracion = data.body.seccion.CHILD1.filter(
            (item) => item.FCSEGUNDASECCION === "Administración"
          );
          setAdminData(soloAministracion);
        }
      };

      fetchSecciones(
        branchFiPaisId,
        brandfiMarcaId,
        jobTitle,
        branchFiSucursalId,
        VERSION_APP,
        Number(rolId),
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const handleClickToReporte = () => {
    // Función para manejar el clic del botón
    navigate("/Reporte", { state: { dataRouter: reporteData } });
  };
  const handleClickToAdministracion = () => {
    // Función para manejar el clic del botón
    navigate("/Administracion", { state: { dataRouter: adminData } });
  };

  return (
    <AzureAD provider={authProvider}>
      {({ logout, authenticationState }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div className="App-header App-header-phone">
              <a className="image" href={`/Home`}>
                <img className="image-logo" src={logo} alt="Logo" />
              </a>

              <div className="content-header-buttons">
                {isAdminShow && (
                  <button
                    onClick={handleClickToAdministracion}
                    className="button-top-header"
                  >
                    Administración
                  </button>
                )}

                {isReporteShow && (
                  <button
                    onClick={handleClickToReporte}
                    className="button-top-header"
                  >
                    Reporte
                  </button>
                )}
              </div>

              <div className="App-header-row">
                <div className="App-header-date">
                  <div className="Aling-date-icons">
                    <CalendarMonthIcon />
                    <span> {hoyEs}</span>
                  </div>

                  <Button href={`/Comunicados`}>
                    <NotificationsIcon sx={{ color: grey[50] }} />
                  </Button>
                </div>
                <div>
                  <button
                    className="App-logout-button"
                    onClick={handleTokenExpiration}
                  >
                    <LogoutIcon sx={{ color: grey[50] }} />
                  </button>
                </div>
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
