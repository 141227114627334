/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import logo from "../assets/alseaTransparente.png";
import { authProvider } from "../context/authProvider";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { fetchRecaptcha } from "../Api/fetchRecaptcha";
import { SITE_KEY,ENVIROMENT } from "../utilities";
import { remoteConfig } from "../firebase";
import { getValue } from "firebase/remote-config";

export const LoginPage = () => {
  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then((status) => {
      //console.log(status);
    });
  }, []);

  const handleLogin = () => {
    ReactRecaptcha3.getToken().then(
      (token) => {
        //console.log("token de recaptcha",token);
        // send request to backend
        validateRecapcha(token);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  const validateRecapcha = (token) => {
    const onFailure = (error) => {
      // console.log(error);
    };
    const onSuccess = ({ data }) => {
      //console.log("respuesta de recaptcha",data);
      {
        data.statusCode === 200 ? authProvider.login() : alert(data.body);
      }
    };
    fetchRecaptcha(token).then(onSuccess).catch(onFailure);
  };

  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, error, login }) => {
        const Unauthenticated =
          authenticationState === AuthenticationState.Unauthenticated;

        if (Unauthenticated) {
          return (
            <div>
              {error && (
                <p>
                  <span>
                    An error occured during authentication, please try again!
                  </span>
                </p>
              )}

              <div className="App-body-login">
                <img className="logo" src={logo} alt="Logo" />
                <br />
                <span>Centro Operativo del Gerente</span>
                <br />
                <button className="App-login-button" onClick={handleLogin}>
                  Ingresar
                </button>

                <div>
                  <a
                    className="avisoprivacidad"
                    href={`/Politicas_de_privacidad`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aviso de privacidad
                  </a>
                </div>
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
