import createAxiosInstance from "./LambdaApiAdministracion";
import { ENVIROMENT } from "../utilities"

export async function fetchAdmin(company, ceco, rol) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/admon?company=${company}&ceco=${ceco}&rol=${rol}`: `home/admon?company=${company}&ceco=${ceco}&rol=${rol}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(requestConfig);
}
