import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyCaHAjT1Xgv5BqUEFbEKSnosudNrpRfzdY",
  authDomain: "centro-operativo-del-gerente.firebaseapp.com",
  projectId: "centro-operativo-del-gerente",
  storageBucket: "centro-operativo-del-gerente.appspot.com",
  messagingSenderId: "839672826654",
  appId: "1:839672826654:web:715b0e7376982129fc970f",
  measurementId: "G-D4PZRQFJPC",
};

const app = initializeApp(firebaseConfig);

export const remoteConfig = getRemoteConfig(app);
