import React, { useState, useEffect } from "react";
import { TableSales } from "../components/TableSales";
import { TableOrders } from "../components/TableOrders";
import { TableAvegache } from "../components/TableAvegache";
import { UseUser, UseAdmin, UseAuth } from "../hooks";
import { fetchIndicators } from "../Api/fetchIndicators";
import { LoadingSpinner } from "./LoadingSpinner";
import moment from "moment";
import { fetchBitacora } from "../Api/fetchBitacora";
import { VERSION_APP } from "../utilities";

export const IndicatorsDay = () => {
  const [indicatorDayDateSales, setIndicatorDayDateSales] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { brandfiBiId, branchFiSucursalId, branchFiPaisId, brandfiMarcaId } =
    UseAdmin();
 
  const typeIndicator = "daily";
  const [salesData, setSalesData] = useState(null);
  const [ordersData, setOrdesData] = useState(null);
  const [averageData, setAverageData] = useState(null);
  const { uniqueId } = UseAuth();
  const { employeeId, mail, device } = UseUser();
  

  

  useEffect(() => {
    getDataIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataIndicators = async () => {
    setLoading(true);
    if (
      brandfiBiId != null &&
      branchFiSucursalId != null &&
      typeIndicator != null 
    ) {
      const onFailure = ({ error, config }) => {
        // console.log(error);
        setLoading(false);
        getBitacora(error, config);
      };

      const onSuccess = async ({ data, config }) => {
        console.log(data);
        getBitacora(data, config);
        setSalesData(data.body.ventas[0]);
        setOrdesData(data.body.ordenes[0]);
        setAverageData(data.body.ticket_promedio[0]);
        const arr = data.body.fdiddia.split(" ");
        setIndicatorDayDateSales(arr[0]);
        setLoading(false);
      };

      fetchIndicators(brandfiBiId, branchFiSucursalId, typeIndicator)
        .then(onSuccess)
        .catch(onFailure);
    } else {
      setLoading(false);
    }
  };


  const getBitacora = async (responsedata, requestConfig) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const responseSet = JSON.stringify(responsedata);
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };

    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      requestConfig != null &&
      responsedata != null
    ) {
      const onFailure = (error) => {
        //console.log("bitacora-admin-error", error);
      };
      const onSuccess = ({ data }) => {
      };

      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        requestConfig,
        responseSet,
        "Indicadores",
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return (
    <div className="container-indicators">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {salesData != null && ordersData != null && averageData != null ? (
            <>
              <div className="conetent-company-date-row">
                <div className="text-company">
                  <div className="day-style">{branchFiSucursalId}</div>
                </div>
                <div className="text-company-date">
                  <div className="day-style">{indicatorDayDateSales}</div>
                </div>
              </div>
              <div className="container-table">
                <div className="row-table-left">
                  {salesData !== null && <TableSales salesData={salesData} />}
                </div>
                <div className="row-table-right">
                  {ordersData !== null && (
                    <TableOrders ordersData={ordersData} />
                  )}
                  {averageData !== null && (
                    <TableAvegache averageData={averageData} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div>No hay datos por mostrar</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
