import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { fetchBitacora } from "../Api/fetchBitacora";
import { Carousel } from "react-responsive-carousel";
import {
  UseUser,
  UseAdmin,
  UseAuth,
} from "../hooks";
import { fetchAdmin } from "../Api/fetchAdmin";
import { VERSION_APP } from "../utilities";
import { LoadingSpinner } from "../components/LoadingSpinner";
import "../theme/Carrucel.css";

export const CarrucelBanner = () => {
  const [currentIndex, setCurrentIndex] = useState();
  const [isLoading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const { company, ceco, jobTitle, employeeId, mail, device } = UseUser();
  const { AdminIn, brandfiMarcaId, branchFiSucursalId, branchFiPaisId } =
    UseAdmin();
  const { uniqueId } = UseAuth();
  
  function handleChange(index) {
    setCurrentIndex(index);
  }
  useEffect(() => {
    getDataBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, ceco, jobTitle]);

  const getDataBanner = async () => {
    //console.log("getDataBanner")
    if (
      company != null &&
      ceco != null &&
      jobTitle != null 
    ) {
      const onFailure = ({ error, config }) => {
        getBitacora(error, config);
        //console.log(error);
        setLoading(false);
      };
      const onSuccess = async ({ data, config }) => {
        setBanners(data.body.banners);
        try {
          await AdminIn(
              data.body.brand.FCNOMBRE.toString(),
              data.body.brand.FIMARCAID.toString(),
              data.body.brand.FIBIID.toString(),
              data.body.brand.FIESTATUSID.toString(),
              data.body.branch.FCNOMBRE.toString(),
              data.body.branch.FISUCURSALID.toString(),
              data.body.branch.FIPAISID.toString(),
              data.body.rol.FIROLID.toString()
          )
          } catch (error) {
            console.log("error al guardar datos",error)
        }
        {
          // eslint-disable-next-line no-unused-expressions
          data.statusCode === 500
            ? alert("Mensaje: " + JSON.stringify(data.body.mensaje))
            : null;
        }
        setLoading(false);
        getBitacora(data, config);
      };

      fetchAdmin(company, ceco, jobTitle)
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const getBitacora = async (data, config) => {
    //console.log("entro a bitacora");
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
    const fechaActualEnFormato = obtenerFechaEnFormato();

    if (
      brandfiMarcaId != null &&
      config != null &&
      data != null
    ) {
      const onFailure = (error) => {
        //console.log("bitacora-admin-error", error);
      };

      const onSuccess = ({ data }) => {
      };

      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        config,
        data,
        "Admin",
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const renderSlides = banners.map((image) => (
    <div className="carrucel-row" key={image.FIBANNERID}>
      <img
        className="image-carrucel"
        src={`${image.FCURLS3}?${new Date().getTime()}`}
        alt={image.FIBANNERID}
      />
      <div className="text-carrucel">
        <div className="title-banner-home">{image.FCTITULO}</div>
        <div className="description-banner-home">{image.FCDESCRIPCION}</div>
        <a
          href={image.FCLINKEXTERNO}
          target="_blank"
          rel="noopener noreferrer"
          className="enlace-url"
        >
          Abrir enlace
        </a>
      </div>
    </div>
  ));

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Carousel
          showIndicators={false}
          showThumbs={false}
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          selectedItem={banners[currentIndex]}
          onChange={handleChange}
          ///className="carousel-container"
        >
          {renderSlides}
        </Carousel>
      )}
    </div>
  );
};
