import React from 'react'
import { useState, useEffect } from "react";
import { Row, Col,Modal,Image } from "antd";
import moment from "moment";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ModalSelectImageAtb } from "../components/ModalSelectImageAtb"
import { GetFetchModeloAtbByMarcaAtb } from "../Api/Administracion/GetFetchModeloAtbByMarcaAtb"
import { UseAdmin } from "../hooks/index"
import { fetchModuloAtbAdd } from "../Api/fetchModuloAtbAdd"
import { fetchModuloAtbEdith } from "../Api/fetchModuloAtbEdith"

export const  AdministracionAtbAddForm =({
    selectedItem,
    setShowModal,
    marcaAtb,
    tipoAtb,
    fetchData,
    modalKey
})=> {
  
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [dataModeloAtb, setDataModeloAtb] = useState([]);
    const [isLoadingModeloAtb, setIsLoadingModeloAtb] = useState(false);
    const {brandfiMarcaId,branchFiSucursalId,branchFiPaisId} = UseAdmin()
    const [errors, setErrors] = useState({});
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() / 3)) + 1;
    const [shouldShowButton, setShouldShowButton] = useState("");


   function getCurrentQuarter() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let quarter;
    if (month >= 1 && month <= 3) {
      quarter = 1;
    } else if (month >= 4 && month <= 6) {
      quarter = 2;
    } else if (month >= 7 && month <= 9) {
      quarter = 3;
    } else if (month >= 10 && month <= 12) {
      quarter = 4;
    }
    return { quarter, year };
  }
  const obtenerFechaEnFormato = () => {
    const ahora = moment();
    const fechaEnFormato = ahora.format("YYYY-MM-DD HH:mm:ss");
    return fechaEnFormato;
  };
  const fechaActualEnFormato = obtenerFechaEnFormato();
  
  const { quarter, year } = getCurrentQuarter();
    const objDefault = {
      FCNOSERIE:"",
      FITEMPORALIDADID:1,
      FIANIOID: year,
      FITRIMESTREID: quarter,
      FITIPOATBID: "",
      FIMARCAATBID: "",
      FIMODELOATBID: "",
      FCAFILIACION: "",
      FIESTATUSATB: "1",
      FIPAISID:branchFiPaisId,
      FIMARCAID:brandfiMarcaId,
      FISUCURSALID:branchFiSucursalId,
      FDFECHAREGISTRO:fechaActualEnFormato,
      FDFECHAACTUALIZACION:fechaActualEnFormato,
      FIBUENESTADO:"",
      FIFUNCIONANDO:"",
      FIMOTIVOID:"",
      FCCOMENTARIORECHAZO:"",
      FITIPOFOTOIDFRONTAL:"1",
      FCDESCRIPCIONFRONTAL:"Foto_Frontal",
      FCURLFOTOGRAFICAFRONTAL:"",
      FDFECHAMODIFICACIONFRONTAL:fechaActualEnFormato,
      FITIPOFOTOIDTRASERA:"2",
      FCDESCRIPCIONTRASERA:"Foto_Lateral",
      FCURLFOTOGRAFICATRASERA:"",
      FDFECHAMODIFICACIONTRASERA:fechaActualEnFormato,
      FITIPOFOTOIDLATERALDER:"3",
      FCDESCRIPCIONLATERALDER:"Foto_Lateral_Derecha",
      FCURLFOTOGRAFICALATELRADER:"",
      FDFECHAMODIFICACIONLATERALDER:fechaActualEnFormato,
      FITIPOFOTOIDLATERALIZ:"4",
      FCDESCRIPCIONLATERALIZ:"Foto_Lateral_Izquierda",
      FCURLFOTOGRAFICALATERALIZ:"",
      FDFECHAMODIFICACIONLATERALIZ:fechaActualEnFormato,
    };
    const [formData, setFormData] = useState(objDefault);
    

  
    useEffect(() => {
        if (selectedItem) {
          //const change = selectedItem.FIESTATUSATB == "2"?"3":selectedItem.FIESTATUSATB
          const newObj = {
            FCNOSERIE:selectedItem.FCNOSERIE,
            FITEMPORALIDADID:selectedItem.FITEMPORALIDADID,
            FIANIOID: selectedItem.FIANIOID,
            FITRIMESTREID: selectedItem.FITRIMESTREID,
            FITIPOATBID: selectedItem.FITIPOATBID,
            FIMARCAATBID: selectedItem.FIMARCAATBID,
            FIMODELOATBID: selectedItem.FIMODELOATBID,
            FCAFILIACION: selectedItem.FCAFILIACION,
            FIESTATUSATB: selectedItem.FIESTATUSATB,
            FIPAISID:selectedItem.FIPAISID,
            FIMARCAID:selectedItem.FIMARCAID,
            FISUCURSALID:selectedItem.FISUCURSALID,
            FDFECHAREGISTRO:selectedItem.FDFECHAREGISTRO,
            FDFECHAACTUALIZACION:selectedItem.FDFECHAACTUALIZACION,
            FIBUENESTADO:selectedItem.FIBUENESTADO,
            FIFUNCIONANDO:selectedItem.FIFUNCIONANDO,
            FIMOTIVOID:selectedItem.FIMOTIVOID,
            FCCOMENTARIORECHAZO:selectedItem?.FCCOMENTARIORECHAZO,
            FITIPOFOTOIDFRONTAL:selectedItem?.TAFOTOS[0].FITIPOFOTOID,
            FCDESCRIPCIONFRONTAL:selectedItem?.TAFOTOS[0].FCDESCRIPCION,
            FCURLFOTOGRAFICAFRONTAL:selectedItem?.TAFOTOS[0].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONFRONTAL:selectedItem?.TAFOTOS[0].FDFECHAMODIFICACION,
            FITIPOFOTOIDTRASERA:selectedItem?.TAFOTOS[1].FITIPOFOTOID,
            FCDESCRIPCIONTRASERA:selectedItem?.TAFOTOS[1].FCDESCRIPCION,
            FCURLFOTOGRAFICATRASERA:selectedItem.TAFOTOS[1].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONTRASERA:selectedItem.TAFOTOS[1].FDFECHAMODIFICACION,
            FITIPOFOTOIDLATERALDER:selectedItem.TAFOTOS[2].FITIPOFOTOID,
            FCDESCRIPCIONLATERALDER:selectedItem.TAFOTOS[2].FCDESCRIPCION,
            FCURLFOTOGRAFICALATELRADER:selectedItem.TAFOTOS[2].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONLATERALDER:selectedItem.TAFOTOS[2].FDFECHAMODIFICACION,
            FITIPOFOTOIDLATERALIZ:selectedItem.TAFOTOS[3].FITIPOFOTOID,
            FCDESCRIPCIONLATERALIZ:selectedItem.TAFOTOS[3].FCDESCRIPCION,
            FCURLFOTOGRAFICALATERALIZ:selectedItem.TAFOTOS[3].FCURLFOTOGRAFICA,
            FDFECHAMODIFICACIONLATERALIZ:selectedItem.TAFOTOS[3].FDFECHAMODIFICACION,
          };
          setFormData(newObj);
        } else {
          const { quarter, year } = getCurrentQuarter();
          const objDefault = {
            FCNOSERIE:"",
            FITEMPORALIDADID:1,
            FIANIOID: year,
            FITRIMESTREID: quarter,
            FITIPOATBID: "",
            FIMARCAATBID: "",
            FIMODELOATBID: "",
            FCAFILIACION: "",
            FIESTATUSATB: "1",
            FIPAISID:branchFiPaisId,
            FIMARCAID:brandfiMarcaId,
            FISUCURSALID:branchFiSucursalId,
            FDFECHAREGISTRO:fechaActualEnFormato,
            FDFECHAACTUALIZACION:fechaActualEnFormato,
            FIBUENESTADO:"",
            FIFUNCIONANDO:"",
            FIMOTIVOID:"",
            FCCOMENTARIORECHAZO:"",
            FITIPOFOTOIDFRONTAL:"1",
            FCDESCRIPCIONFRONTAL:"Foto_Frontal",
            FCURLFOTOGRAFICAFRONTAL:"",
            FDFECHAMODIFICACIONFRONTAL:fechaActualEnFormato,
            FITIPOFOTOIDTRASERA:"2",
            FCDESCRIPCIONTRASERA:"Foto_Lateral",
            FCURLFOTOGRAFICATRASERA:"",
            FDFECHAMODIFICACIONTRASERA:fechaActualEnFormato,
            FITIPOFOTOIDLATERALDER:"3",
            FCDESCRIPCIONLATERALDER:"Foto_Lateral_Derecha",
            FCURLFOTOGRAFICALATELRADER:"",
            FDFECHAMODIFICACIONLATERALDER:fechaActualEnFormato,
            FITIPOFOTOIDLATERALIZ:"4",
            FCDESCRIPCIONLATERALIZ:"Foto_Lateral_Izquierda",
            FCURLFOTOGRAFICALATERALIZ:"",
            FDFECHAMODIFICACIONLATERALIZ:fechaActualEnFormato,
          };
          setFormData(objDefault);
        }
        //console.log("entrao  aqui")
    }, [selectedItem,modalKey]);

    useEffect(() => {
      setErrors({})
    }, [selectedItem,modalKey])
    

    const handleChange = (event) => {
      const { name, value } = event.target;
      if (name === "FCNOSERIE") {
        // Permitir solo letras y números, y limitar a 30 caracteres
        const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
        setFormData({ ...formData, [name]: alphanumericValue.slice(0, 30) });
        setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOSERIE: "La serie solo debe contener letras y números y entre 8 y 30 caracteres",
        }));
      } else if (name === "FCAFILIACION") {
        // Permitir solo números, y limitar a 7 caracteres
        const numericValue = value.replace(/[^0-9]/g, "");
        setFormData({ ...formData, [name]: numericValue.slice(0, 7) });
        setErrors((prevFormData) => ({
          ...prevFormData,
          FCAFILIACION: "La serie solo debe contener 7 digitos",
        }));
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
    
    

      const openModal = (FITIPOFOTOID, FCURLFOTOGRAFICA, FCNOSERIE,FITRIMESTREID,FIANIOID) => {
        setSelectedImage({FITIPOFOTOID, FCURLFOTOGRAFICA ,FCNOSERIE,FITRIMESTREID,FIANIOID});
        setModalIsOpen(true);
      };
    

      const fetchHandleUpdateCancel = () => {
        setFormData(objDefault);
        setErrors({})
        setShowModal(false);
      };
    

      useEffect(() => {
        handleImageUpload()
      }, [setModalIsOpen])
      
      const handleImageUpload = (url) => {
        if(url){
          const regex = /foto-(\d+)/;
          const match = url.match(regex);
          const imageID = match[1];
          if (imageID === "1") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              FCURLFOTOGRAFICAFRONTAL: url,
            }));
          } else if (imageID === "2") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              FCURLFOTOGRAFICATRASERA: url,
            }));
          } else if (imageID === "3") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              FCURLFOTOGRAFICALATELRADER: url,
            }));
          } else if (imageID === "4") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              FCURLFOTOGRAFICALATERALIZ: url,
            }));
          }
        }
      };

      useEffect(() => {
        fetchDataModeloAtb();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [formData.FIMARCAATBID]);
    
      const fetchDataModeloAtb = async () => {
        if (formData.FIMARCAATBID !== "") {
          setIsLoadingModeloAtb(true);
          const onFailure = (error) => {
            setIsLoadingModeloAtb(false);
          };
          const onSuccess = ({ data }) => {
            //console.log(data.body);
            setDataModeloAtb(data.body);
            setIsLoadingModeloAtb(false);
          };
          GetFetchModeloAtbByMarcaAtb(formData.FIMARCAATBID).then(onSuccess).catch(onFailure);
        }
      };

      const getModifiedFields = () => {
        if (selectedItem === null) {
          // Si no hay datos iniciales, todo lo que haya en formData es un cambio
          const modifiedFields = {};
          for (const key in formData) {
            if (formData[key] !== "" && formData[key] !== null && formData[key] !== undefined) {
              modifiedFields[key] = {
                original: null,
                modified: formData[key]
              };
            }
          }
          return modifiedFields;
        }
        const selectedItemData = {
          FCNOSERIE:selectedItem.FCNOSERIE,
          FITEMPORALIDADID:selectedItem.FITEMPORALIDADID,
          FIANIOID: selectedItem.FIANIOID,
          FITRIMESTREID: selectedItem.FITRIMESTREID,
          FITIPOATBID: selectedItem.FITIPOATBID,
          FIMARCAATBID: selectedItem.FIMARCAATBID,
          FIMODELOATBID: selectedItem.FIMODELOATBID,
          FCAFILIACION: selectedItem.FCAFILIACION,
          FIESTATUSATB: selectedItem.FIESTATUSATB,
          FIPAISID:selectedItem.FIPAISID,
          FIMARCAID:selectedItem.FIMARCAID,
          FISUCURSALID:selectedItem.FISUCURSALID,
          FDFECHAREGISTRO:selectedItem.FDFECHAREGISTRO,
          FDFECHAACTUALIZACION:selectedItem.FDFECHAACTUALIZACION,
          FIBUENESTADO:selectedItem.FIBUENESTADO,
          FIFUNCIONANDO:selectedItem.FIFUNCIONANDO,
          FIMOTIVOID:selectedItem.FIMOTIVOID,
          FCCOMENTARIORECHAZO:selectedItem?.FCCOMENTARIORECHAZO,
          FITIPOFOTOIDFRONTAL:selectedItem?.TAFOTOS[0].FITIPOFOTOID,
          FCDESCRIPCIONFRONTAL:selectedItem?.TAFOTOS[0].FCDESCRIPCION,
          FCURLFOTOGRAFICAFRONTAL:selectedItem?.TAFOTOS[0].FCURLFOTOGRAFICA,
          FDFECHAMODIFICACIONFRONTAL:selectedItem?.TAFOTOS[0].FDFECHAMODIFICACION,
          FITIPOFOTOIDTRASERA:selectedItem?.TAFOTOS[1].FITIPOFOTOID,
          FCDESCRIPCIONTRASERA:selectedItem?.TAFOTOS[1].FCDESCRIPCION,
          FCURLFOTOGRAFICATRASERA:selectedItem.TAFOTOS[1].FCURLFOTOGRAFICA,
          FDFECHAMODIFICACIONTRASERA:selectedItem.TAFOTOS[1].FDFECHAMODIFICACION,
          FITIPOFOTOIDLATERALDER:selectedItem.TAFOTOS[2].FITIPOFOTOID,
          FCDESCRIPCIONLATERALDER:selectedItem.TAFOTOS[2].FCDESCRIPCION,
          FCURLFOTOGRAFICALATELRADER:selectedItem.TAFOTOS[2].FCURLFOTOGRAFICA,
          FDFECHAMODIFICACIONLATERALDER:selectedItem.TAFOTOS[2].FDFECHAMODIFICACION,
          FITIPOFOTOIDLATERALIZ:selectedItem.TAFOTOS[3].FITIPOFOTOID,
          FCDESCRIPCIONLATERALIZ:selectedItem.TAFOTOS[3].FCDESCRIPCION,
          FCURLFOTOGRAFICALATERALIZ:selectedItem.TAFOTOS[3].FCURLFOTOGRAFICA,
          FDFECHAMODIFICACIONLATERALIZ:selectedItem.TAFOTOS[3].FDFECHAMODIFICACION,
        };
      
        const modifiedFields = {};
        // Comparar cada campo individualmente
        for (const key in selectedItemData) {
          if (formData[key] !== selectedItemData[key]) {
            modifiedFields[key] = {
              original: selectedItemData[key],
              modified: formData[key]
            };
          }
        }
      
        return modifiedFields;
      };


    
      const handleSaveData = async () => {
        const modifiedFields = getModifiedFields();
        if (Object.keys(modifiedFields).length === 0) {
          alert("No se ha realizado ningún cambio en los datos.");
          return;
        }
        const errors = {};
        if (formData.FCNOSERIE === "") {
          errors.FCNOSERIE = "Ingresa el valor de serie";
        } else if (formData.FCNOSERIE.length < 8 || formData.FCNOSERIE.length > 30) {
          errors.FCNOSERIE = "Serie debe tener entre 8 y 30 caracteres.";
        } else if (/\s/.test(formData.FCNOSERIE)) { // Verifica si hay espacios en blanco
          errors.FCNOSERIE = "La serie no debe contener espacios en blanco.";
        } else if (!/^[a-zA-Z0-9]+$/.test(formData.FCNOSERIE)) { // Verifica que solo contenga letras y números
          errors.FCNOSERIE = "La serie solo debe contener letras y números.";
        }
        if (formData.FITIPOATBID === "") {
          errors.FITIPOATBID = "Ingresa el valor de Tipo";
        }
        if (formData.FIMARCAATBID === "") {
          errors.FIMARCAATBID = "Ingresa el valor de Marca";
        }
        if (formData.FIMODELOATBID === "") {
          errors.FIMODELOATBID = "Ingresa el valor de Modelo";
        }
        if (formData.FCURLFOTOGRAFICAFRONTAL === "") {
          errors.FCURLFOTOGRAFICAFRONTAL = "Selecciona una fotográfica frontal ";
        }
        if (formData.FCURLFOTOGRAFICATRASERA === "") {
          errors.FCURLFOTOGRAFICATRASERA = "Selecciona una fotográfica trasera ";
        }
        if (formData.FCURLFOTOGRAFICALATELRADER === "") {
          errors.FCURLFOTOGRAFICALATELRADER = "Selecciona una fotográfica lateral derecha ";
        }
        if (formData.FCURLFOTOGRAFICALATERALIZ === "") {
          errors.FCURLFOTOGRAFICALATERALIZ = "Selecciona una fotográfica lateral izquierda ";
        }
        if (formData.FCAFILIACION === "") {
          errors.FCAFILIACION = "Ingresa el valor de afiliación";
        } else if (!/^[0-9]{7}$/.test(formData.FCAFILIACION)) {
          errors.FCAFILIACION = "Afiliación debe contener solo 7 números.";
        }else if (/\s/.test(formData.FCAFILIACION)) { // Verifica si hay espacios en blanco
          errors.FCAFILIACION = "La afiliación no debe contener espacios en blanco.";
        }
        if (formData.FIBUENESTADO === ""  ) {
          errors.FIBUENESTADO = "Selecciona el estado";
        }
        if (formData.FIFUNCIONANDO === "" ) {
          errors.FIFUNCIONANDO = "Selecciona el funcionamiento";
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors("");
          sendData();
        }

        function sendData(){
          setIsLoading(true);
          const onFailure = (error) => {
            //console.log("data-envio de registo error" ,error)
            setIsLoading(false);
          };
          const onSuccess = ({ data }) => {
            //console.log("data-envio de registo",data);
            if (data.statusCode === 200) {
              setIsLoading(false);
              setShowModal(false)
              fetchData();
              setFormData(objDefault);
              setErrors({})
              alert("Envio exitoso");
            } else {
              setIsLoading(false);
              alert(data.message);
              
            }
          };
          const change = formData.FIESTATUSATB === "2"?"3":formData.FIESTATUSATB
          const formDataToSend = {
            ...formData,
            FITEMPORALIDADID:Number(formData.FITEMPORALIDADID),
            FIANIOID: Number(formData.FIANIOID),
            FITIPOATBID: Number(formData.FITIPOATBID),
            FITRIMESTREID:Number(formData.FITRIMESTREID),
            FIMARCAATBID: Number(formData.FIMARCAATBID),
            FIMODELOATBID: Number(formData.FIMODELOATBID),
            FIESTATUSATB: Number(change),
            FIPAISID:Number(formData.FIPAISID),
            FIMARCAID:Number(formData.FIMARCAID),
            FISUCURSALID:Number(formData.FISUCURSALID),
            FIBUENESTADO:Number(formData.FIBUENESTADO),
            FIFUNCIONANDO:Number(formData.FIFUNCIONANDO),
            FDFECHAACTUALIZACION:fechaActualEnFormato,
            FIMOTIVOID:formData.FIMOTIVOID,
            FITIPOFOTOIDFRONTAL:Number(formData.FITIPOFOTOIDFRONTAL),
            FITIPOFOTOIDTRASERA:Number(formData.FITIPOFOTOIDTRASERA),
            FITIPOFOTOIDLATERALDER:Number(formData.FITIPOFOTOIDLATERALDER),
            FITIPOFOTOIDLATERALIZ:Number(formData.FITIPOFOTOIDLATERALIZ),
          };
          //console.log("selectedItem",selectedItem)
          if (selectedItem) {
            fetchModuloAtbEdith(formDataToSend).then(onSuccess).catch(onFailure);
          } else {
            fetchModuloAtbAdd(formDataToSend).then(onSuccess).catch(onFailure);
          }
        }

      };

      useEffect(() => {
        const isCurrentTrimester = formData.FITRIMESTREID === currentQuarter;
        //console.log("Estamos en el trimestre:", isCurrentTrimester);
        const isCurrentAño = formData.FIANIOID === currentYear;
        //7console.log("Estamos en el año:", isCurrentAño);
        // Determinar si se debe mostrar el botón
        const shouldShowButton = isCurrentTrimester && isCurrentAño;
        // Establecer el estado para mostrar el botón
        setShouldShowButton(shouldShowButton);
      }, [formData.FITRIMESTREID,formData.FIANIOID,currentQuarter,currentYear])
      

  return (
    <>
    <Row justify="space-around">
     <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} dispositivo
        </div>
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOSERIE}
          name={"FCNOSERIE"}
          type={"text"}
          label={"Número de serie"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
        />
        {errors && <div className="error-input-data">{errors.FCNOSERIE}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <InputSelect
            value={formData.FITIPOATBID}
            handleChange={handleChange}
            options={tipoAtb}
            label="Tipo"
            optionsKey={"FITIPOATBID"}
            optionsName={"FCNOMBRETIPO"}
            //disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
            name={"FITIPOATBID"}
        />
        {errors && <div className="error-input-data">{errors.FITIPOATBID}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <InputSelect
              value={formData.FIMARCAATBID}
              handleChange={handleChange}
              options={marcaAtb}
              label="Marca"
              optionsKey={"FIMARCAATBID"}
              optionsName={"FCNOMBREMARCA"}
              //disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
              name={"FIMARCAATBID"}
          />
         {errors && <div className="error-input-data">{errors.FIMARCAATBID}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
          {formData.FIMARCAATBID !== 0 && (
              <>
                {isLoadingModeloAtb ? (
                  <LoadingSpinner />
                ) : (
                  <>
                  <label className="text-context-inputs">Modelo:</label>
                  <select
                    className="input-content"
                    value={formData.FIMODELOATBID}
                    onChange={handleChange}
                    name={"FIMODELOATBID"}
                    //disabled={selectedItem ? true : false}
                    //disabled={!configuracion ? true : false} //deshabilita en caso de que sea edit
                  >
                    <option value="0">Selecionar Modelo</option>
                    {dataModeloAtb.map((option) => (
                      <option
                        key={option.FIMODELOATBID}
                        value={option.FIMODELOATBID}
                      >
                       {option.FCNOMBREMODELO}
                      </option>
                    ))}
                  </select>
                  {errors && <div className="error-input-data">{errors.FIMODELOATBID}</div>}
                  </>
                )}
              </>
            )}
      </Col>
      <Col xs={20} md={22} className="mt-3" al>
        <InputForm
          handleChange={handleChange}
          value={formData.FCAFILIACION}
          name={"FCAFILIACION"}
          type={"text"}
          label={"Afiliación"}
        />
         {errors && <div className="error-input-data">{errors.FCAFILIACION}</div>}
      </Col>

      <Col xs={20} md={10} className="mt-3" al>
        <label className="text-context-inputs">Frontal:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLFOTOGRAFICAFRONTAL !== "" && (
              <div>
                 <Image
                    src={`${formData.FCURLFOTOGRAFICAFRONTAL}?${new Date().getTime()}`}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
              </div>
            )}
          </div>
          <div >
             <button className='primary-button button-modal-imagen'  onClick={() => openModal(formData.FITIPOFOTOIDFRONTAL,formData.FCURLFOTOGRAFICAFRONTAL,formData.FCNOSERIE,formData.FITRIMESTREID,formData.FIANIOID)} >Seleccionar Imagen</button>
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLFOTOGRAFICAFRONTAL}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <label className="text-context-inputs">Trasera:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLFOTOGRAFICATRASERA !== "" && (
              <div>
                 <Image
                    src={`${formData.FCURLFOTOGRAFICATRASERA}?${new Date().getTime()}`}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
              </div>
            )}
          </div>
          <div >
             <button className='primary-button button-modal-imagen'  onClick={() => openModal(formData.FITIPOFOTOIDTRASERA,formData.FCURLFOTOGRAFICATRASERA,formData.FCNOSERIE,formData.FITRIMESTREID,formData.FIANIOID)} >Seleccionar Imagen</button>
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLFOTOGRAFICATRASERA}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <label className="text-context-inputs">Lateral derecha:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLFOTOGRAFICALATELRADER !== "" && (
              <div>
                 <Image
                    src={`${formData.FCURLFOTOGRAFICALATELRADER}?${new Date().getTime()}`}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
              </div>
            )}
          </div>
          <div >
             <button className='primary-button button-modal-imagen'  onClick={() => openModal(formData.FITIPOFOTOIDLATERALDER,formData.FCURLFOTOGRAFICALATELRADER,formData.FCNOSERIE,formData.FITRIMESTREID,formData.FIANIOID)} >Seleccionar Imagen</button>
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLFOTOGRAFICALATELRADER}</div>}
      </Col>
      <Col xs={20} md={10} className="mt-3" al>
        <label className="text-context-inputs">Lateral izquierda:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLFOTOGRAFICALATERALIZ !== "" && (
              <div>
                 <Image
                    src={`${formData.FCURLFOTOGRAFICALATERALIZ}?${new Date().getTime()}`}
                    alt="Imagen seleccionada"
                    style={{
                      maxWidth: "100px",
                      height: "50px",
                    }}
                  />
              </div>
            )}
          </div>
          <div >
             <button className='primary-button button-modal-imagen'  onClick={() => openModal(formData.FITIPOFOTOIDLATERALIZ,formData.FCURLFOTOGRAFICALATERALIZ,formData.FCNOSERIE,formData.FITRIMESTREID,formData.FIANIOID)} >Seleccionar Imagen</button>
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLFOTOGRAFICALATERALIZ}</div>}
      </Col>
      <Col xs={20} md={22} className="mt-3" al>
        <label className="text-context-inputs">¿Este dispositivo se encuentra en buen estado? </label>
        <select
          className="input-content"
          value={formData.FIBUENESTADO}
          onChange={handleChange}
          name={"FIBUENESTADO"}
        > 
          <option value="">Seleciona si esta en buen estado  </option>
          <option value="1">Si  </option>
          <option value="0">No  </option>
        </select>
        {errors && <div className="error-input-data">{errors.FIBUENESTADO}</div>}
      </Col>
      <Col xs={20} md={22} className="mt-3" al>
        <label className="text-context-inputs">¿El dispositivo se encuentra funcionando? </label>
        <select
          className="input-content"
          value={formData.FIFUNCIONANDO}
          onChange={handleChange}
          name={"FIFUNCIONANDO"}
        >
          <option value="">Seleciona si esta funcionando  </option>
          <option value="1">Si  </option>
          <option value="0">No  </option>
        </select>
        {errors && <div className="error-input-data">{errors.FIFUNCIONANDO}</div>}
      </Col>

      {!shouldShowButton && <div><label className="text-context-inputs">Solo se permite enviar actualizar en el Trimestre actual</label></div>}
      {shouldShowButton && 
        <div className="modal-footer-edith">
         <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={handleSaveData}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>}
      <Modal
          open={modalIsOpen}
          onCancel={() => setModalIsOpen(false)}
          footer={null}
      >
        <ModalSelectImageAtb tipoImage={selectedImage} setModalIsOpen={setModalIsOpen} onImageUpload={handleImageUpload} selectedItem={selectedItem} />
      </Modal>
    </Row>
    </>
  )
}
