import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { AddFetchModeloAtb } from "../Api/Administracion/AddFetchModeloAtb";
import { PutFetchModeloAtb } from "../Api/Administracion/PutFetchModeloAtb";

export const AdministrationAtbAddModelo = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  modalKey
}) => {
  const objDefault = {
    FIMODELOATBID: 0,
    FCNOMBREMODELO: "",
    FIMARCAATBID:"",
    FIESTATUS: 1,
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMODELOATBID: selectedItem.FIMODELOATBID,
        FCNOMBREMODELO: selectedItem.FCNOMBREMODELO,
        FIMARCAATBID: selectedItem.FIMARCAATBID,
        FIESTATUS: selectedItem.FIESTATUS,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMODELOATBID: 0,
        FCNOMBREMODELO: "",
        FIMARCAATBID:"",
        FIESTATUS: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCNOMBREMODELO" && value.length > 30) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOMBREMODELO: "El nombre no debe exceder 30 caracteres.",
      }));
      return;
     }
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
        const errors = {};
        if (formData.FCNOMBREMODELO === "") {
          errors.FCNOMBREMODELO = "Ingresa el nombre";
        } else if (formData.FCNOMBREMODELO.length < 3 || formData.FCNOMBREMODELO.length > 30) {
          errors.FCNOMBREMODELO = "El nombre de modelo d debe tener entre 1 y 30 caracteres.";
        }
        if(formData.FIMARCAATBID === ""){
          errors.FIMARCAATBID = "Seleciona la marca";
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors("");
        } 
      setIsLoading(true) 
      const onFailure = (error) => {
        //console.log("error", error);
        alert(error);
        setIsLoading(false) 
      };

      const onSuccess = ({ data }) => {
        //console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 200) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          setIsLoading(false) 
          alert("Envio exitoso");
        } else {
          alert(data.error);
          setIsLoading(false) 
        }
      };

      if (selectedItem) {
        PutFetchModeloAtb(formData).then(onSuccess).catch(onFailure);
      } else {
        AddFetchModeloAtb(formData).then(onSuccess).catch(onFailure);
      }
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Tipo
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBREMODELO}
          name={"FCNOMBREMODELO"}
          type={"text"}
          label={"Nombre"}
        />
         {errors && <div className="error-input-data">{errors.FCNOMBREMODELO}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputSelect
            value={formData.FIMARCAATBID}
            handleChange={handleChange}
            options={marca}
            label="Marca"
            optionsKey={"FIMARCAATBID"}
            optionsName={"FCNOMBREMARCA"}
            disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
            name={"FIMARCAATBID"}/>
           {errors && <div className="error-input-data">{errors.FIMARCAATBID}</div>}
      </Col>
      <Col xs={24} md={22}></Col>

      <div className="modal-footer-edith">
          <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
           <button
              onClick={fetchHandleUpdate}
              type="submit"
              className={`button-modal ${
                isLoading ? "primary-button-disable" : "primary-button"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Guardar"}
            </button>
      </div>
    </Row>
  );
};