import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchReclasificacionGet(
  fecha,
  branchFiPaisId,
  brandfiMarcaId,
  branchFiSucursalId,
  tokenProd
) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: "/reclasificacion",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      code: 2,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: branchFiSucursalId,
        fechaRegistro: fecha,
      },
    },
  };

  return axiosInstance(requestConfig);
}