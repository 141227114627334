/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import "../theme/Home.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { useNavigate } from "react-router-dom";
import { CarrucelBanner } from "../components/CarrucelBanner";
import { fetchSecciones } from "../Api/fetchSecciones";
import { authProvider } from "../context/authProvider";
import { UseAuth, UseUser, UseAdmin } from "../hooks";
import { fetchBitacora } from "../Api/fetchBitacora";
import { VERSION_APP, ENVIROMENT } from "../utilities";
import moment from "moment";
import { remoteConfig } from "../firebase";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { useLocation } from 'react-router-dom';

export const HomePage = () => {
  const location = useLocation();
  const { signIn,status } = UseAuth();
  const [dataHome, setDataHome] = useState();
  const { jobTitle, employeeId, mail, device, usuario, userIn } = UseUser();
  const {
    brandfiMarcaId,
    branchFiSucursalId,
    branchFiPaisId,
    brandfiBiId,
    rolId,
  } = UseAdmin();
  const { uniqueId } = UseAuth();
  const navigate = useNavigate();
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  );
  const pathname = location.pathname;
 
  useEffect(() => {
    if (pathname === "/Home") {
      console.log("Estamos en home");
      console.log("status");
      if (status === "signIn" || status === "restoreToken") {
        getRemoteconfig();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, status]);

  /*useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityState(document.visibilityState);
      if (document.visibilityState === "visible") {
        console.log("Home está en primer plano");
        if (status === "signIn" || status === "restoreToken") {
          getRemoteconfig();
        }
      } else {
       // console.log("La página está en segundo plano");
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState]);*/

  const getRemoteconfig = async()=>{
    console.log("Obtener informacion de variables de Remoteconfig")
    try {
      remoteConfig.settings.minimumFetchIntervalMillis = 60000;
      await fetchAndActivate(remoteConfig);
      const adminitracion = ENVIROMENT === "dev" ? "url_domain_api_administracion_dev" : "url_domain_api_administracion";
      const atb = ENVIROMENT === "dev" ? "url_domain_api_moduloAtb_dev" : "url_domain_api_moduloAtb";
      const cierreDia = ENVIROMENT === "dev" ? "url_domain_api_cierreDia_dev" : "url_domain_api_cierreDia";
      const frecuencias = ENVIROMENT === "dev" ? "url_domain_api_frecuencias_dev" : "url_domain_api_frecuencias";
      const horariosempleados = ENVIROMENT === "dev" ? "url_domain_api_horariosempleados_dev" : "url_domain_api_horariosempleados";
      const indicadores = ENVIROMENT === "dev" ? "url_domain_api_indicadores_dev" : "url_domain_api_indicadores";
      const usabilidad = ENVIROMENT === "dev" ? "url_domain_api_usabilidad_dev" : "url_domain_api_usabilidad";
      const reclasificacion = ENVIROMENT === "dev" ? "url_domain_api_reclasif_dev" : "url_domain_api_reclasif";

      const baseUrlRemoteAdminitracion = getValue(remoteConfig, adminitracion)._value;
      const baseUrlRemoteAtb = getValue(remoteConfig, atb)._value;
      const baseUrlRemoteCierreDia = getValue(remoteConfig, cierreDia)._value;
      const baseUrlRemoteFrecuencias = getValue(remoteConfig, frecuencias)._value;
      const baseUrlRemoteHorariosEmpleados = getValue(remoteConfig, horariosempleados)._value;
      const baseUrlRemoteIndicadores = getValue(remoteConfig, indicadores)._value;
      const baseUrlRemoteUsabilidad = getValue(remoteConfig, usabilidad)._value;
      const baseUrlRemoteReclasificacion = getValue(remoteConfig, reclasificacion)._value;
    
      localStorage.setItem('baseUrlAdministracion', baseUrlRemoteAdminitracion);
      localStorage.setItem('baseUrlAtb', baseUrlRemoteAtb);
      localStorage.setItem('baseUrlCierreDia', baseUrlRemoteCierreDia);
      localStorage.setItem('baseUrlfrecuencias', baseUrlRemoteFrecuencias);
      localStorage.setItem('baseUrlHorariosEmpleados', baseUrlRemoteHorariosEmpleados);
      localStorage.setItem('baseUrlIndicadores', baseUrlRemoteIndicadores);
      localStorage.setItem('baseUrlUsabilidad', baseUrlRemoteUsabilidad);
      localStorage.setItem('baseUrlReclasificacion', baseUrlRemoteReclasificacion);

      //console.log("baseUrlRemoteAdminitracion", baseUrlRemoteAdminitracion);
      //console.log("baseUrlRemoteAtb", baseUrlRemoteAtb);
      //console.log("baseUrlRemoteCierreDia", baseUrlRemoteCierreDia);
     // console.log("baseUrlRemoteFrecuencias", baseUrlRemoteFrecuencias);
     // console.log("baseUrlRemoteHorariosEmpleados", baseUrlRemoteHorariosEmpleados);
     // console.log("baseUrlRemoteIndicadores", baseUrlRemoteIndicadores);
    //  console.log("baseUrlRemoteUsabilidad", baseUrlRemoteUsabilidad);
    //  console.log("baseUrlRemoteReclasificacion", baseUrlRemoteReclasificacion);

    } catch (error) {
      console.error('Error al obtener los valore de remoteconfig:', error);
      throw error;
    }
  }

  useEffect(() => {
    if (!authProvider) {
      return;
    }
    const getAccessToken = async () => {
      const response = await authProvider.acquireTokenSilent({
        scopes: ["openid", "profile", "email", "offline_access", "user.read"],
        account: authProvider.accountInfo, // Replace with the scopes required for your API
      });
      const expiration = response.idTokenClaims.iat.toString();
      const token = await authProvider.getAccessToken();
      //console.log("token", response);
      const idToken = token.accessToken;
      // console.log("idToken", idToken);
      await signIn(idToken, expiration, response.uniqueId);

      const responseToken = await fetch(
        ` https://graph.microsoft.com/v1.0/me?$select=employeeID,displayName,givenName,jobTitle,mail,surname,companyName,onPremisesExtensionAttributes,lastPasswordChangeDateTime,accountEnabled,userPrincipalName`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const responseJson = await responseToken.json();
      //console.log(responseJson);

      const pais =
        responseJson.onPremisesExtensionAttributes.extensionAttribute1 != null
          ? responseJson.onPremisesExtensionAttributes.extensionAttribute1
          : "Mexico";

      const ceco =
        responseJson.onPremisesExtensionAttributes.extensionAttribute6 != null
          ? responseJson.onPremisesExtensionAttributes.extensionAttribute6
          : responseJson.employeeId != null
          ? responseJson.employeeId
          : "0";

      const enable = responseJson.accountEnabled;
      const userEnable = enable.toString();

      const employeeId =
        responseJson.onPremisesExtensionAttributes.extensionAttribute8 != null
          ? responseJson.onPremisesExtensionAttributes.extensionAttribute8
          : ceco;

      const usuario = responseJson.userPrincipalName.split("@")[0];
      //console.log(usuario);

      var sBrowser,
        sUsrAg = navigator.userAgent;

      if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
      } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "Opera";
      } else if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
      } else if (sUsrAg.indexOf("MSIE") > -1) {
        sBrowser = "Microsoft Internet Explorer";
      }

      const device = `${sBrowser}-${navigator.platform}`;

      await userIn(
        responseJson.mail,
        responseJson.displayName,
        responseJson.jobTitle,
        pais,
        ceco,
        responseJson.companyName,
        userEnable,
        employeeId,
        device,
        usuario
      );
      // console.log(responseJson);
    };

    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataSeccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchFiPaisId, brandfiMarcaId, jobTitle, branchFiSucursalId, rolId]);

  const getDataSeccion = () => {
    if (
      branchFiPaisId !== null &&
      branchFiSucursalId !== null &&
      brandfiMarcaId !== null &&
      jobTitle !== null &&
      rolId !== null
    ) {
      const onFailure = ({ error, config }) => {
        console.log(error);
        getBitacora(error, config,"Admin Secciones");
      };
      const onSuccess = ({ data, config }) => {
        //console.log("secciones", data);
        getBitacora(data, config,"Admin Secciones");

        const extractedDataWithReporte = data.body.seccion.CHILD1.map(
          (item) => ({
            FCJSONSECCION2: item.FCJSONSECCION2,
            FCLINKSECCION2: item.FCLINKSECCION2,
            FCSEGUNDASECCION: item.FCSEGUNDASECCION,
            FCURLIMAGEN2: item.FCURLIMAGEN2,
            FISEGUNDASECCIONID: item.FISEGUNDASECCIONID,
            FCSTACKSECCION2: item.FCSTACKSECCION2,
            FCCHIL2: item.CHILD2,
          })
        ).filter(
          (item) =>
            item.FCSEGUNDASECCION !== "Reportes" &&
            item.FCSEGUNDASECCION !== "Administración"
        );

        //console.log(extractedDataWithReporte);
        setDataHome(extractedDataWithReporte);
      };

      fetchSecciones(
        branchFiPaisId,
        brandfiMarcaId,
        jobTitle,
        branchFiSucursalId,
        VERSION_APP,
        Number(rolId)
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  
  const getBitacora = async (data, config, description) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      // Agrega 5 segundos a la fecha actual
      ahora.add(2, "seconds");
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      config != null &&
      data != null
    ) {
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {};
      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        config,
        data,
        description,
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  const navigateToIndicadores = () => {
    navigate("/Indicadores");
  };

  const navigateToHUb = (url, child2) => {
    navigate("/Hub", { state: { dataRouter: child2 } });
  };

  const navigateToMesaServicios = (url, child2) => {
    navigate("/MesaServiciosPage", { state: { dataRouter: child2 } });
  };
  const navigateToAsistencia = (url, child2) => {
    navigate(`/${url} `, { state: { dataRouter: child2 } });
  };

  const navigateToEvaluame = (url) => {
    window.open(url, "_blank");
  };

  const navigateToEnlace = (url) => {
    window.open(url, "_blank");
  };

  const navigateToCAEA = (url) => {
    window.open(url, "_blank");
  };

  const navigateToTerminalesBancarias = (url) => {
    const interpolatedString = url.replace(
      /\${([^}]+)}/g,
      (match, variable) => {
        switch (variable) {
          case "branchFiPaisId":
            return branchFiPaisId;
          case "brandfiMarcaId":
            return brandfiMarcaId;
          case "branchFiSucursalId":
            return branchFiSucursalId;
          case "brandfiBiId":
            return brandfiBiId;
          case "usuario":
            return usuario;
          default:
            return match;
        }
      }
    );
    window.open(interpolatedString, "_blank");
  };

  const navigateToDecisionCenter = (url) => {
    const interpolatedString = url.replace(
      /\${([^}]+)}/g,
      (match, variable) => {
        switch (variable) {
          case "branchFiPaisId":
            return branchFiPaisId;
          case "brandfiMarcaId":
            return brandfiMarcaId;
          case "branchFiSucursalId":
            return branchFiSucursalId;
          case "brandfiBiId":
            return brandfiBiId;
          case "usuario":
            return usuario;
          default:
            return match;
        }
      }
    );
    window.open(interpolatedString, "_blank");
  };

  const navigateToChildsMesaServicios = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  ///funciones nuevas
  const navigateToFuncionUrl = (url) => {
    window.open(url, "_blank");
  };
  const navigateToFuncionWithSSO = (url) => {
    window.open(url, "_blank");
  };
  const navigateToFuncionWithData = (url) => {
    const interpolatedString = url.replace(
      /\${([^}]+)}/g,
      (match, variable) => {
        switch (variable) {
          case "branchFiPaisId":
            return branchFiPaisId;
          case "brandfiMarcaId":
            return brandfiMarcaId;
          case "branchFiSucursalId":
            return branchFiSucursalId;
          case "brandfiBiId":
            return brandfiBiId;
          case "usuario":
            return usuario;
          default:
            return match;
        }
      }
    );
    window.open(interpolatedString, "_blank");
  };
  const navigateToFuncionStack = (url, child2) => {
    navigate(`/${url} `, { state: { dataRouter: child2 } });
  };

  const handleClickLink = (itemPress, urlPress, child2) => {
    if (itemPress === "navigateToIndicadores") {
      navigateToIndicadores(urlPress);
    } else if (itemPress === "navigateToHUb") {
      navigateToHUb(urlPress, child2);
    } else if (itemPress === "navigateToEvaluame") {
      navigateToEvaluame(urlPress);
    } else if (itemPress === "navigateToEnlace") {
      navigateToEnlace(urlPress);
    } else if (itemPress === "navigateToCAEA") {
      navigateToCAEA(urlPress);
    } else if (itemPress === "navigateToAsistencia") {
      navigateToAsistencia(urlPress, child2);
    } else if (itemPress === "navigateToTerminalesBancarias") {
      navigateToTerminalesBancarias(urlPress);
    } else if (itemPress === "navigateToDecisionCenter") {
      navigateToDecisionCenter(urlPress);
    } else if (itemPress === "navigateToMesaServicios") {
      navigateToMesaServicios(urlPress, child2);
    } else if (itemPress === "navigateToChildsMesaServicios") {
      navigateToChildsMesaServicios(urlPress);
    } else if (itemPress === "navigateToFuncionUrl") {
      navigateToFuncionUrl(urlPress);
    } else if (itemPress === "navigateToFuncionWithSSO") {
      navigateToFuncionWithSSO(urlPress);
    } else if (itemPress === "navigateToFuncionWithData") {
      navigateToFuncionWithData(urlPress);
    } else if (itemPress === "navigateToFuncionStack") {
      navigateToFuncionStack(urlPress, child2);
    }
  };

  const handleButtonPress = (item) => {
    //console.log("item.FCSEGUNDASECCION",item.FCSEGUNDASECCION)
    handleClickLink(
      item.FCJSONSECCION2,
      item.FCLINKSECCION2,
      item.FCCHIL2,
    );
    getBitacora("code 200",item.FCJSONSECCION2,item.FCSEGUNDASECCION)
  };
  const handleClick = (item) => {
    getBitacora("code 200", item.FCJSONSECCION2, item.FCSEGUNDASECCION);
  };

  const IconItemBox = (props) => {
    const { item } = props;

    return (
      <div className="icon-item-box" key={item.FISEGUNDASECCIONID}>
        {item.FCJSONSECCION2 === "navigateToChildsMesaServicios" ||
        item.FCJSONSECCION2 === "navigateToFuncionWithData" ? (
          (() => {
            const interpolatedString = item.FCLINKSECCION2.replace(
              /\${([^}]+)}/g,
              (match, variable) => {
                switch (variable) {
                  case "branchFiPaisId":
                    return branchFiPaisId;
                  case "brandfiMarcaId":
                    return brandfiMarcaId;
                  case "branchFiSucursalId":
                    return branchFiSucursalId;
                  case "brandfiBiId":
                    return brandfiBiId;
                  case "usuario":
                    return usuario;
                  default:
                    return match;
                }
              }
            );

            return (
              <a
                href={interpolatedString}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleClick(item)
                }
              >
                <img
                  className="img-home-icons"
                  src={item.FCURLIMAGEN2}
                  alt={item.FCSEGUNDASECCION}
                />
                <div className="text-button">{item.FCSEGUNDASECCION}</div>
              </a>
            );
          })()
        ) : (
          <button
            className="custom-button"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleButtonPress(item)
            }
          >
            <img
              className="img-home-icons"
              src={item.FCURLIMAGEN2}
              alt={item.FCSEGUNDASECCION}
            />
            <div className="text-button">{item.FCSEGUNDASECCION}</div>
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="body-home-background">
      <AzureAD provider={authProvider}>
        {({ authenticationState, accountInfo }) => {
          const isAuthenticated =
            authenticationState === AuthenticationState.Authenticated;

          if (isAuthenticated) {
            return (
              <div className="body-home">
                <div className="welcone-name">
                  <span>Bienvenid@ {accountInfo.account.name}</span>
                </div>
                <div>
                  <CarrucelBanner />

                  {dataHome && (
                    <div className="container-body">
                      <div className="icon-box">
                        {dataHome.map((item) => (
                          <IconItemBox
                            item={item}
                            key={item.FISEGUNDASECCIONID}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        }}
      </AzureAD>
    </div>
  );
};