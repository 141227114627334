import axios from "axios";
import { remoteConfig } from "../firebase";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { ENVIROMENT } from "../utilities";
import { TokenCentralizado } from "./tokenCentralizado";
import { SelectToken } from "./SelectToken";
import attachInterceptor from "../Api/authInterceptor"

let tokenPromise = null;
let baseUrlPromise = null;

const getBaseUrl = async () => {
  if (!baseUrlPromise) {
    baseUrlPromise = (async () => {
      let baseUrl = localStorage.getItem('baseUrlReclasificacion');
      if (!baseUrl) {
        try {
          remoteConfig.settings.minimumFetchIntervalMillis = 60000;
          await fetchAndActivate(remoteConfig);
          const env = ENVIROMENT === "dev" ? "url_domain_api_reclasif_dev" : "url_domain_api_reclasif";
          const baseUrlRemote = getValue(remoteConfig, env)._value;
          localStorage.setItem('baseUrlReclasificacion', baseUrlRemote);
          baseUrl = baseUrlRemote;
        } catch (error) {
          //console.error("Error al obtener la baseURL desde Remote Config:", error);
          throw error;
        }
      }
      return baseUrl;
    })();
  }
  return baseUrlPromise;
};

const getToken = async (baseUrl) => {
  const identificador = await SelectToken(baseUrl);
  return localStorage.getItem(identificador);
};

const refreshToken = async () => {
  if (!tokenPromise) {
    tokenPromise = (async () => {
      try {
        console.log("Renovando token...");
        remoteConfig.settings.minimumFetchIntervalMillis = 3600;
        const env = ENVIROMENT === "dev" ? "url_domain_auth_reclasif_dev" : "url_domain_auth_reclasif";
        const envClient = ENVIROMENT === "dev" ? "clientId_auth_reclasif_dev" : "clientId_auth_reclasif";
        const authUrl = getValue(remoteConfig, env)._value;
        const clientId = getValue(remoteConfig, envClient)._value;
        const baseUrl = await getBaseUrl();
        const nuevoToken = await TokenCentralizado(authUrl, clientId, baseUrl);
        return nuevoToken;
      } catch (error) {
        console.error("Error al renovar el token:", error);
        throw error;
      } finally {
        tokenPromise = null;
      }
    })();
  }
  return tokenPromise;
};

const createAxiosInstance = async () => {
  const lambdaReclasificacionApi = axios.create();
  
  try {
    const baseUrl = await getBaseUrl();
    lambdaReclasificacionApi.defaults.baseURL = baseUrl;

    const token = await getToken(baseUrl);
    if (token) {
      lambdaReclasificacionApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    // Interceptor para manejar la renovación del token en caso de error 401
   attachInterceptor(lambdaReclasificacionApi, refreshToken, 'isRefreshingReclasificacion');


    lambdaReclasificacionApi.interceptors.request.use(
      async (config) => {
        const token = await getToken(await getBaseUrl());
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

  } catch (error) {
    console.error("Error al crear instancia de Axios:", error);
  }

  return lambdaReclasificacionApi;
};

export default createAxiosInstance;