/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { GetFetchAccesos } from "../Api/Administracion/GetFetchAccesos";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { GetFetchRol } from "../Api/Administracion/GetFetchRol";
import { GetFetchSucursales } from "../Api/Administracion/GetFetchSucursales";
import { GetFetchSeccionesActivos } from "../Api/Administracion/GetFetchSeccionesActivos";
import "../theme/AdministracionCatalogo.css";
import "../theme/Pagination.css";
import EditIcon from "@mui/icons-material/Edit";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import "react-datepicker/dist/react-datepicker.css";
import { AdministrationAccesoAddForm } from "./AdministrationAccesoAddForm";
import { Table, Button, Row, Col, Input, Space, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const TableAdministracionAccesos = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataMarca, setDataMarca] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);
  const [dataSucursal, setDataSucursal] = useState([]);
  const [dataSecciones, setDataSecciones] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);


  const SegundaSeccion = ({ item }) => {
    const f = dataSecciones.find((fi) => fi?.FCKEY === item);
    if (f) {
      return <>{f?.FCSEGUNDASECCION}</>;
    }
    return null;
  };

  const TerceraSeccion = ({ item }) => {
    const f = dataSecciones.find((fi) => fi?.FCKEY === item);
    if (f) {
      return <>{f?.FCTERCERASECCION}</>;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };

      const onSuccess = ({ data }) => {
        setData(data.body);
        setIsLoading(false);
      };

      GetFetchAccesos().then(onSuccess).catch(onFailure);
    
  };

  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  useEffect(() => {
    fetchDataMarca();
    fetchDataSecciones();
    fetchDataRoles()
    fetchDataSucursalAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataMarca = async () => {
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  const fetchDataSecciones = async () => {
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
          //console.log("data",data)
        setDataSecciones(data.body);
      };
      GetFetchSeccionesActivos().then(onSuccess).catch(onFailure);
    
  };
  const fetchDataRoles = async () => {
    const onFailure = (error) => {};
    const onSuccess = ({ data }) => {
      setDataRoles(data.body.data);
    };

    GetFetchRol(true).then(onSuccess).catch(onFailure);
 };

 const fetchDataSucursalAll = async () => {
  const onFailure = (error) => {};
  const onSuccess = ({ data }) => {
    //console.log("data-all", data);
    setDataSucursal(data.body.data);
  };

  GetFetchSucursales(false).then(onSuccess).catch(onFailure);

};

  //table -->>>
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID Acceso",
      dataIndex: "FCKEY",
      key: "FCKEY",
      sorter: (a, b) => parseInt(a.FCKEY) - parseInt(b.FCKEY),
      ...getColumnSearchProps("FCKEY"),
    },
    {
      title: "Id Sección",
      dataIndex: "FCKEYSECCIONES",
      key: "FCKEYSECCIONES",
      ...getColumnSearchProps("FCKEYSECCIONES"),
    },
    {
      title: "Segunda sección",
      dataIndex: "FCKEYSECCIONES",
      key: "FCKEYSECCIONES",
      render: (item) => <SegundaSeccion item={item} />,
    },
    {
      title: "Tercera sección",
      dataIndex: "FCKEYSECCIONES",
      key: "FCKEYSECCIONES",
      render: (item) => <TerceraSeccion item={item} />,
      //      ...getColumnSearchProps('FCKEYSECCIONES'),
    },
    {
      title: "Marca",
      dataIndex: "FCMARCANOMBRE",
      key: "FCMARCANOMBRE",
      ...getColumnSearchProps("FCMARCANOMBRE"),
    },
    {
      title: "Plataforma",
      dataIndex: "FCPLATAFORMA",
      key: "FCPLATAFORMA",
    },
    {
      title: "Roles",
      dataIndex: "FCROLACCESOS",
      key: "FCROLACCESOS",
    },
    {
      title: "Sucursales",
      dataIndex: "FCSUCURSALES",
      key: "FCSUCURSALES",
    },
    {
      title: "Tipo de acceso",
      dataIndex: "FCTIPOACCESO",
      key: "FCTIPOACCESO",
    },
    {
      title: "Fecha inicio",
      dataIndex: "FDFECHAINI",
      key: "FDFECHAINI",
    },
    {
      title: "Fecha fin",
      dataIndex: "FDFECHAFIN",
      key: "FDFECHAFIN",
    },
    {
      title: "Visualizaciones",
      dataIndex: "FCVISUALIZACIONES",
      key: "FCVISUALIZACIONES",
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "5%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd} className="button-styles-add-element">
            Agregar Acceso
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={"FCKEYSECCIONES"}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 2000, y: 600 }}
          />
        </Col>
      </Row>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <AdministrationAccesoAddForm
          selectedItem={selectedItem}
          fetchData={fetchData}
          setShowModal={setShowModal}
          marca={dataMarca}
          secciones={dataSecciones}
          modalKey={modalKey}
          roles={dataRoles}
          sucursales={dataSucursal}
        />
      </Modal>
    </>
  );
};
