import React, { useState } from "react";
import { UseUser } from "../hooks";
import { DatePicker, Result, Button } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import "dayjs/locale/es-mx";
import moment from "moment";
import "moment/locale/es";
import { fetchSendReporteEmail } from "../Api/fetchSendReporteEmail";

export const TableReportesUsabilidad = () => {
  const [date, setdate] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [key, setKey] = useState(0); // Agregar estado para la clave única
  const { mail } = UseUser();

  const onChange = (date, dateString) => {
    setdate(dateString); // Actualiza el estado de la fecha
    setStatusCode(null); // Reinicia el estado del código de estado
  };

  const sentReport = () => {
    if (date === null || date === "") {
      alert("Es importante seleccionar una fecha para enviar el reporte");
      return;
    }

    const fechaFormateada = moment(date).format("YY/MM/DD");
    setLoadings(true);
      const onFailure = (error) => {
        setLoadings(false);
      };

      const onSuccess = ({ data }) => {
        setStatusCode(data.statusCode);
        setLoadings(false);
        setStatusCode(200); // Simulando un envío exitoso
        setdate(null); // Reiniciar la fecha después del envío exitoso
        setKey((prevKey) => prevKey + 1); // Cambiar la clave única para reiniciar el DatePicker
      };

      fetchSendReporteEmail(mail, fechaFormateada)
        .then(onSuccess)
        .catch(onFailure);
  
    // Reinicia el estado de la fecha después de enviar el reporte
  };

  // Función para deshabilitar fechas futuras
  const disabledDate = (current) => {
    // Obtener la fecha actual
    const today = moment().endOf("day");
    // Deshabilitar si es después de la fecha actual
    return current && current > today;
  };

  // Función para deshabilitar horas futuras en el rango de fechas
  const disabledTime = (current, type) => {
    // Deshabilitar si es después de la fecha actual
    if (type === "start" && current.isSame(moment(), "day")) {
      return {
        disabledHours: () => [...Array(moment().hour()).keys()],
        disabledMinutes: () => [...Array(moment().minute()).keys()],
        disabledSeconds: () => [...Array(moment().second()).keys()],
      };
    }
    return {};
  };

  const successResult = (
    <Result
      status="success"
      title="¡Reporte enviado con éxito!"
      subTitle="Revisa tu email para descargarlo."
      style={{ fontFamily: "Roboto-slab" }}
    />
  );

  const failureResult = (
    <Result
      status="error"
      title="Error al enviar reporte"
      extra={[
        <Button type="primary" key="try-again" onClick={sentReport}>
          Intentar de nuevo
        </Button>,
      ]}
      style={{ fontFamily: "Roboto-slab" }}
    />
  );

  const resultComponent = statusCode === 200 ? successResult : failureResult;

  return (
    <>
      <div className="container-body-reclasificacion-table">
        <Result
          icon={<FileTextOutlined />}
          title="Enviar reporte de usabilidad"
          style={{ fontFamily: "Roboto-slab" }}
          extra={
            <div>
              <p>
                <label>{mail}</label>
              </p>
              <DatePicker
                key={key} // Utilizar la clave única para reiniciar el DatePicker
                locale={locale}
                onChange={onChange}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
              />
              <Button
                style={{ fontFamily: "Roboto-slab" }}
                type="primary"
                onClick={sentReport}
                loading={loadings}
              >
                Enviar
              </Button>
            </div>
          }
        />
        <>{statusCode === null ? null : resultComponent}</>
      </div>
    </>
  );
};
