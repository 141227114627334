
import createAxiosInstance from "../LambdaApiFrecuencias";

export async  function AddFetchCatalogoRecolecion(body) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: `/frecuencias`,
    headers: {
      "Content-Type": "application/json"
    },
    data:body
  };
  return axiosInstance(requestConfig);
}
