import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import axios from "axios";
import { Table, Button, Input, Space, Col, Row, Switch, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { InputSelectWithInName } from "./utils/InputSelectWithInName";
import { GetFetchFrecuencias } from "../Api/Administracion/GetFetchFrecuencias";
import { PutFetchCatalogoRecolecion } from "../Api/Administracion/PutFetchCatalogoRecolecion";
import { AdministrationCatalogoRecolecionAdd } from "../components/AdministracionCatalogoRecolecionAdd";
import { GetFetchMarcas } from "../Api/Administracion/GetFetchMarcas";
import { GetFetchSucursales } from "../Api/Administracion/GetFetchSucursales";
import { GetFetchSucursalesByMarca } from "../Api/Administracion/GetFetchSucursalesByMarca"
import { LoadingSpinner } from "../components/LoadingSpinner";

export const TableAdministracionCatalogo = () => {
  const [data, setData] = useState();
  const [dataMarca, setDataMarca] = useState([]);
  const [dataSucursal, setDataSucursales] = useState([]);
  const [dataSucursalAll, setDataSucursalesAll] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingSucursal, setIsLoadingSucursal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalKey, setModalKey] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [formData, setFormData] = useState({
    pais: 1,
    marca: 0,
    sucursal: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const HandleSwitch = ({ value, all }) => {
    const [hs, setHs] = useState(parseInt(value) === 1);
    const handleChangeSwitch = (v) => {
      const newObj = {
        ...all,
        estatus: v ? 1 : 0,
      };
      //("Nuevo objeto a enviar", newObj);
      //peticion....
      const onFailure = (error) => {
        //console.log("error", error);
        alert(error);
      };
      const onSuccess = ({ data }) => {
       // console.log("data update state", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 200) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      PutFetchCatalogoRecolecion({
        params: {
          pais: newObj.pais /*Obligatorio de tipo numérico entero*/,
          marca: newObj.marca /*Obligatorio de tipo numérico entero*/,
          sucursal: newObj.sucursal /*Obligatorio de tipo numérico entero*/,
          empresaValores:
            newObj.empresaValores /*Obligatorio de cadena - string - texto */,
          diaRecoleccion:
            newObj.diaRecoleccion /*Obligatorio de tipo numérico entero, rango del 1 al 7*/,
          estatus:
            newObj.estatus === 1 ? newObj.estatus : String(newObj.estatus),
        },
      })
        .then(onSuccess)
        .catch(onFailure);

      setHs(v);
    };
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  useEffect(() => {
    fetchDataMarca();
    fetchDataSucursalAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.marca]);

  const fetchDataSucursal = async () => {
    if (formData.marca !== 0) {
      setIsLoadingSucursal(true);
      const onFailure = (error) => {
        setIsLoadingSucursal(false);
      };
      const onSuccess = ({ data }) => {
        console.log(data.body.data);
        setDataSucursales(data.body.data);
        setIsLoadingSucursal(false);
      };

      GetFetchSucursalesByMarca(formData.marca).then(onSuccess).catch(onFailure);
    }
  };

  const fetchDataMarca = async () => {
   
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        //console.log(data.body.data);
        setDataMarca(data.body.data);
      };

      GetFetchMarcas(true).then(onSuccess).catch(onFailure);
    
  };

  const fetchDataSucursalAll = async () => {
  
      const onFailure = (error) => {};
      const onSuccess = ({ data }) => {
        console.log("data-all", data.body.data);
        setDataSucursalesAll(data.body.data);
      };

      GetFetchSucursales(true).then(onSuccess).catch(onFailure);
    
  };

  //table--->>
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const fetchData = async () => {
    setLoading(true);
    if (formData.marca !== 0 && formData.sucursal !== 0) {
      const onFailure = (error) => {
        setLoading(false);
      };
      const onSuccess = ({ data }) => {
        console.log("fetchDataSearch", data.records);
        setData(data.records);
        setLoading(false);
        setTableParams({
          pagination: {
            current: 1,
            pageSize: 10,
          },
        });
      };
      GetFetchFrecuencias({
        code: 4,
        params: {
          pais: formData.pais,
          marca: formData.marca,
          sucursal: formData.sucursal,
        },
      })
        .then(onSuccess)
        .catch(onFailure);
    } else {
      const response = await GetFetchFrecuencias({
        code: 3,
        params: {
          noPagina: tableParams.pagination.current,
          noRegistros: tableParams.pagination.pageSize,
        },
      });

      //      console.log("Response de la tabla", response.data.data.items, response.data.data.totalItems, response.data.data.totalPages)
      setData(response.data.data.items);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.data.totalItems,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    }
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Sucursal",
      dataIndex: "sucursal",
      key: "sucursal",
      ...getColumnSearchProps("sucursal"),
    },
    {
      title: "Marca ID",
      dataIndex: "marca",
      key: "marca",
    },
    {
      title: "Marca",
      dataIndex: "nombreMarca",
      key: "nombreMarca",
      ...getColumnSearchProps("nombreMarca"),
    },
    {
      title: "Tienda",
      dataIndex: "nombreTienda",
      key: "nombreTienda",
    },
    {
      title: "Empresa Valores",
      dataIndex: "empresaValores",
      key: "empresaValores",
    },
    {
      title: "Día Recolección",
      dataIndex: "diaRecoleccion",
      key: "diaRecoleccion",
    },
    {
      title: "Día Recolección",
      dataIndex: "nombreDiaRecoleccion",
      key: "nombreDiaRecoleccion",
    },
    {
      title: "País",
      dataIndex: "pais",
      key: "pais",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      key: "estatus",
      render: (item, all) => <HandleSwitch value={item} all={all} />,
      fixed: "right",
      width: "10%",
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"],
      onFilter: (value, record) => record.estatus === Number(value),
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter, action) => {
    if ("filter" === action.action) return;
    console.log("pagination", pagination, "filters", filters, "sorter", sorter);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col xs={22} md={4} className="mt-3" al>
          <InputSelectWithInName
            value={formData.marca}
            handleChange={handleChange}
            options={dataMarca}
            label="Marca"
            optionsKey={"FIMARCAID"}
            optionsName={"FCNOMBRE"}
            disabled={selectedItem ? true : false}
            name={"marca"}
          />
        </Col>
        <Col xs={22} md={4} className="mt-3" al>
          {formData.marca !== 0 && (
            <>
              {isLoadingSucursal ? (
                <LoadingSpinner />
              ) : (
                <select
                  className="input-content-select"
                  value={formData.sucursal}
                  onChange={handleChange}
                  name={"sucursal"}
                  disabled={selectedItem ? true : false}
                >
                  <option value="0">Selecionar sucursal</option>
                  {dataSucursal.map((option) => (
                    <option
                      key={option.FISUCURSALID}
                      value={option.FISUCURSALID}
                    >
                      {option.FISUCURSALID}-{option.FCNOMBRE}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </Col>
        <Col xs={22} md={4} className="mt-3" al>
          {formData.marca !== 0 && formData.sucursal !== 0 && (
            <button
              onClick={fetchData}
              className="button-styles-add-element-search"
            >
              Buscar
            </button>
          )}
        </Col>
        <Col xs={22} md={6} className="mt-3" al></Col>
        <Col xs={22} md={4} className="mt-3" al>
          <button
            onClick={handleAdd}
            className="button-styles-add-element"
            style={{ marginRight: "10px" }}
          >
            Agregar
          </button>
        </Col>
      </Row>
      
      <Row justify="center">
        <Col xs={22} md={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={"key"}
            columns={columns}
            loading={isLoading}
            pagination={tableParams.pagination}
            scroll={{ x: 1400, y: 600 }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>

      <>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <AdministrationCatalogoRecolecionAdd
            selectedItem={selectedItem}
            fetchData={fetchData}
            setShowModal={setShowModal}
            marca={dataMarca}
            sucursal={dataSucursalAll}
            modalKey={modalKey}
          />
        </Modal>
      </>
    </>
  );
};
