import createAxiosInstance from "../Api/LambdaApiAtb";
import { ENVIROMENT } from "../utilities"

export async function fetchModuloAtbSendReporteEmail(body) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/atb/sendmail`: `/atb/sendmail`
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: body // Aquí cambiamos 'body' por 'data'
  };

  // Realizar la solicitud Axios usando la instancia personalizada
  return axiosInstance(requestConfig);
}
