import { useContext } from "react";
import { TokenGlobalContext } from "../context/TokenGlobalContext";

export function UseTokenGlobal() {
  const context = useContext(TokenGlobalContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
