import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthLoginProvider } from "./context/AuthLoginProvider";
import { UserProvider } from "./context/UserProvider";
import { TokenGlobalProvider } from "./context/TokenGlobalProvider";
import { AdminProvider } from "./context/AdminProvider";
import { TokenReclasificacionProvider } from "./context/TokenResiclasificacionProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthLoginProvider>
      <UserProvider>
        <TokenGlobalProvider>
          <TokenReclasificacionProvider>
            <AdminProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AdminProvider>
          </TokenReclasificacionProvider>
        </TokenGlobalProvider>
      </UserProvider>
    </AuthLoginProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
