import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import "../theme/HorariosView.css";
import { ListaEmpleadosHoras } from "../components/ListaEmpleadosHoras";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { fetchVistaHorarios } from "../Api/fetchVistaHorarios";
import { fetchBitacora } from "../Api/fetchBitacora";
import { UseAdmin, UseUser, UseAuth } from "../hooks";
import { VERSION_APP } from "../utilities";

export const ListadeHorarios = () => {
  const currentDate = moment();
  const currentWeekNumber = currentDate.week();
  const currentYear = currentDate.format("YYYY");
  const [listEmpleados, setListEmpleados] = useState([]);
  const [changeDate, setChangeDate] = useState("LUN");
  const [selectedOption, setselectedOption] = useState(currentWeekNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const { employeeId, mail, device } = UseUser();
  const {
    branchFiSucursalId,
    brandfiBiId,
    branchFcNombre,
    brandfiMarcaId,
    branchFiPaisId,
  } = UseAdmin();
  const { uniqueId } = UseAuth();

  useEffect(() => {
    const handleResize = () => {
      setShowDiv(window.innerWidth <= 590);
    };

    // Agregar el evento de escucha al cargar el componente
    window.addEventListener("resize", handleResize);

    // Comprobar el tamaño de la ventana al cargar el componente
    handleResize();

    // Eliminar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const weekStartDate = moment()
    .weekYear(currentYear)
    .week(currentWeekNumber)
    .startOf("week");
  const weekEndDate = moment()
    .weekYear(currentYear)
    .week(currentWeekNumber)
    .endOf("week");

  useEffect(() => {
    fetchDataHorario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const nextWeekNumber = moment().add(1, "week").week();
  const weekStartDateNext = moment()
    .weekYear(currentYear)
    .week(nextWeekNumber)
    .startOf("week");
  const weekEndDateNext = moment()
    .weekYear(currentYear)
    .week(nextWeekNumber)
    .endOf("week");

  const previousWeekNumber = moment().subtract(1, "week").week();
  const weekStartDatePrevios = moment()
    .weekYear(currentYear)
    .week(previousWeekNumber)
    .startOf("week");
  const weekEndDatePrevios = moment()
    .weekYear(currentYear)
    .week(previousWeekNumber)
    .endOf("week");

  const fetchDataHorario = async () => {
    const week = selectedOption === null ? currentWeekNumber : selectedOption;
    const dateConsult = `${currentYear}${week}`;
    if ( dateConsult !== null && brandfiBiId!== null && branchFiSucursalId !== null) {
      setIsLoading(true);
      const onFailure = ({ error, config }) => {
        //  console.log(error);
        getBitacora(error, config);
        setIsLoading(false);
      };

      const onSuccess = ({ data, config }) => {
        console.log(data);
        getBitacora(data, config);
        setListEmpleados(data.body);
        setIsLoading(false);
      };

      fetchVistaHorarios(
        dateConsult,
        brandfiBiId,
        branchFiSucursalId
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  

  const getBitacora = async (dataResponse, saveRequest) => {
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
  
    const fechaActualEnFormato = obtenerFechaEnFormato();

    if (
      brandfiMarcaId != null &&
      saveRequest != null &&
      dataResponse != null
    ) {
      const onFailure = (error) => {
        //console.log("bitacora", error);
      };
      const onSuccess = ({ data }) => {
        //console.log("bitacora", data);
      };

      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        saveRequest,
        dataResponse,
        "Horarios",
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return (
    <div className="container-body-horarios">
      <div className="row-body-horarios-right">
        <div className="content-contraler-info">
          <div className="info-body-horarios">
            <div className="text-info-horarios">Tienda</div>
            <div className="text-data-horarios">{branchFcNombre}</div>
          </div>
          <div className="info-body-horarios">
            <div className="text-info-horarios">Periodo Actual</div>
            <div className="text-data-horarios">
              Semana {currentWeekNumber} {""}
              {""} {""} {weekStartDate.format("DD/MM/YYYY")}
              {""} - {""}
              {weekEndDate.format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="info-body-horarios">
            <div className="text-data-horarios-weeks">
              {selectedOption === previousWeekNumber ? (
                <div className="button-felchas-select-semana">
                  <div className="text-semana-item-button-select">
                    Semana {previousWeekNumber}
                    {!showDiv && (
                      <div className="diverder-row-week">
                        {weekStartDatePrevios.format("DD/MM/YYYY")}
                        {""} - {""}
                        {weekEndDatePrevios.format("DD/MM/YYYY")}
                      </div>
                    )}
                  </div>
                  <button
                    className="button-select-semana-horarios"
                    onClick={() => setselectedOption(currentWeekNumber)}
                  >
                    <ArrowForwardIosSharpIcon />
                  </button>
                </div>
              ) : selectedOption === currentWeekNumber ? (
                <div className="button-felchas-select-semana">
                  <button
                    className="button-select-semana-horarios"
                    onClick={() => setselectedOption(previousWeekNumber)}
                  >
                    <ArrowBackIosNewSharpIcon />
                  </button>
                  <div className="text-semana-item-button-select">
                    Semana {currentWeekNumber}
                    {!showDiv && (
                      <div className="diverder-row-week">
                        {weekStartDate.format("DD/MM/YYYY")}
                        {""} - {""}
                        {weekEndDate.format("DD/MM/YYYY")}
                      </div>
                    )}
                  </div>
                  <button
                    className="button-select-semana-horarios"
                    onClick={() => setselectedOption(nextWeekNumber)}
                  >
                    <ArrowForwardIosSharpIcon />
                  </button>
                </div>
              ) : (
                <div className="button-felchas-select-semana">
                  <button
                    className="button-select-semana-horarios"
                    onClick={() => setselectedOption(currentWeekNumber)}
                  >
                    <ArrowBackIosNewSharpIcon />
                  </button>
                  <div className="text-semana-item-button-select">
                    Semana {nextWeekNumber}
                    {!showDiv && (
                      <div className="diverder-row-week">
                        {weekStartDateNext.format("DD/MM/YYYY")}
                        {""} - {""}
                        {weekEndDateNext.format("DD/MM/YYYY")}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="row-body-horarios-left">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {listEmpleados.length === 0 ? (
              <div className="no-data-onlits">No hay datos por mostrar</div>
            ) : (
              <>
                <div className="content-button-days">
                  <button
                    className={`${
                      changeDate === "LUN"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("LUN")}
                  >
                    LUN
                  </button>
                  <button
                    className={`${
                      changeDate === "MAR"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("MAR")}
                  >
                    MAR
                  </button>
                  <button
                    className={`${
                      changeDate === "MIE"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("MIE")}
                  >
                    MIE
                  </button>
                  <button
                    className={`${
                      changeDate === "JUE"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("JUE")}
                  >
                    JUE
                  </button>
                  <button
                    className={`${
                      changeDate === "VIE"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("VIE")}
                  >
                    VIE
                  </button>
                  <button
                    className={`${
                      changeDate === "SAB"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("SAB")}
                  >
                    SAB
                  </button>
                  <button
                    className={`${
                      changeDate === "DOM"
                        ? "button-days-active"
                        : "button-days"
                    }`}
                    onClick={() => setChangeDate("DOM")}
                  >
                    DOM
                  </button>
                </div>
                {changeDate === "LUN" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_lunes"}
                    salida={"fc_horario_salida_lunes"}
                    semana={selectedOption}
                    dia="Lunes"
                  />
                ) : changeDate === "MAR" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_martes"}
                    salida={"fc_horario_salida_martes"}
                    semana={selectedOption}
                    dia="Martes"
                  />
                ) : changeDate === "MIE" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_miercoles"}
                    salida={"fc_horario_salida_miercoles"}
                    semana={selectedOption}
                    dia="Miercoles"
                  />
                ) : changeDate === "JUE" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_jueves"}
                    salida={"fc_horario_salida_jueves"}
                    semana={selectedOption}
                    dia="Jueves"
                  />
                ) : changeDate === "VIE" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_viernes"}
                    salida={"fc_horario_salida_viernes"}
                    semana={selectedOption}
                    dia="Viernes"
                  />
                ) : changeDate === "SAB" ? (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_sabado"}
                    salida={"fc_horario_salida_sabado"}
                    semana={selectedOption}
                    dia="Sabado"
                  />
                ) : (
                  <ListaEmpleadosHoras
                    week={currentWeekNumber}
                    horarios={listEmpleados}
                    entrada={"fc_horario_entrada_domingo"}
                    salida={"fc_horario_salida_lunes_domingo"}
                    semana={selectedOption}
                    dia="Domingo"
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
