import createAxiosInstance from "../Api/LambdaApiAtb";
import { ENVIROMENT } from "../utilities"

export async function fetchUploadUrlAtb(body){
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/atb/uploadfile`: `/atb/uploadfile`
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: body,
  };
  
  return axiosInstance(requestConfig);
}
