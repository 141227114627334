import React from "react";
import imgBackgraund from "../assets/portada-web.jpg";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../context/authProvider";

export const LoadingPage = () => {
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, accountInfo }) => {
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;

        if (isAuthenticated) {
          return (
            <div
              className="App-body-login"
              style={{
                backgroundImage: `url(${imgBackgraund})`,
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};
