import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { authProvider } from "./context/authProvider";
import { AppRouter } from "../src/router/AppRouter";
import { fetchGraph } from "./Api/fetchGraph";
import { fetchBitacora } from "./Api/fetchBitacora";
import { VERSION_APP } from "./utilities";
import {
  UseAdmin,
  UseAuth,
  UseUser,
  UseTokenGlobal,
} from "./hooks";

export const AppWraper = () => {
  const [response, setResponse] = useState(null);
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  );

  const { accessToken, expiration, signOut, status, uniqueId } = UseAuth();
  const { AdminOut, brandfiMarcaId, branchFiSucursalId, branchFiPaisId } =
    UseAdmin();
  const { userOut, employeeId, mail, device } = UseUser();
  const { TokenOut } = UseTokenGlobal();
  
  const getCurrentUnixTime = () => Math.floor(Date.now() / 1000);
  // Guardar la hora actual en localStorage
  const storeCurrentTime = () => {
    const currentTime = getCurrentUnixTime();
    //console.log("currentTime",currentTime)
    localStorage.setItem('storedTime', currentTime);
  };


  const has50MinutesPassed = () => {
    const storedTime = localStorage.getItem('storedTime');
    if (!storedTime) {
      // No se ha almacenado ninguna hora
      return false;
    }
    const currentTime = getCurrentUnixTime();
    const elapsedTime = currentTime - parseInt(storedTime, 10); // Tiempo transcurrido en segundos
    // 50 minutos en segundos
    const fiftyMinutesInSeconds = 50 * 60;
    return elapsedTime >= fiftyMinutesInSeconds;
  };


  

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityState(document.visibilityState);

      if (document.visibilityState === "visible") {
        //console.log("app wraper está en primer plano");
        if (status === "signIn" || status === "restoreToken") {
          validateToken();
          /*const tokenold = localStorage.getItem('storedTime');
          //console.log("storedTime",tokenold)
          if(tokenold){
            if (has50MinutesPassed()) {
              //console.log('Han pasado 50 minutos o más.');
              getToken();
              getTokenReclasificacon();
            } else {
              //console.log('No han pasado 50 minutos aún.');
            }
            //console.log("esta el token")
          }else{
            getToken();
            getTokenReclasificacon();
          }*/
        }
      } else {
       // console.log("La página está en segundo plano");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState]);

  const handleTokenExpiration = () => {
    console.log("handleTokenExpiration")
    authProvider.logout();
    signOut();
    userOut();
    AdminOut();
    TokenOut();
  };


  const validateToken = () => {
    if (accessToken !== null) {
      const onFailure = (error) => {
        //  console.log(error);
        setResponse(error);
        getBitacora();
      };

      const onSuccess = ({ data }) => {
        //console.log(data);
        getBitacora();
        const ceco =
          data.onPremisesExtensionAttributes.extensionAttribute6 != null
            ? data.onPremisesExtensionAttributes.extensionAttribute6
            : data.employeeId != null
            ? data.employeeId
            : "0";

        const responseGraph = `Company: ${data.companyName}, JobTitle:${data.jobTitle} EmployeeId:${data.onPremisesExtensionAttributes.extensionAttribute8}, Ceco:${ceco}, EnableUser: ${data.accountEnabled} , LastPasswordChange:${data.lastPasswordChangeDateTime}`;
        setResponse(responseGraph);
        const timestampUnix =
          new Date(data.lastPasswordChangeDateTime).getTime() / 1000;

        if (timestampUnix > expiration || !data.accountEnabled) {
          handleTokenExpiration();
          //console.log(
          //  `${timestampUnix} es mayor que ${expiration} cambio de contraseña`
          //  );
        } else {
          //console.log(`${timestampUnix} es menor que ${expiration}, `);
        }
      };

      fetchGraph(accessToken).then(onSuccess).catch(onFailure);
    }
  };

  // console.log(response);

  const getBitacora = async () => {
    const request = localStorage.getItem("Request-fetchGraph");
    const deviceWeb = `${uniqueId}-${device}`;
    const key = `${uniqueId}-${device}-${employeeId}`;
    const obtenerFechaEnFormato = () => {
      const ahora = moment();
      const fechaEnFormato = ahora.format("YY/MM/DD HH:mm:ss.SSS");
      return fechaEnFormato;
    };
  
    const fechaActualEnFormato = obtenerFechaEnFormato();
    if (
      brandfiMarcaId != null &&
      request != null &&
      response != null
    ) {
      const onFailure = (error) => {
        //console.log("bitacora", error);
      };

      const onSuccess = ({ data }) => {
        //.log("bitacora-graph",data);
        // console.log("envio a bitacora");
      };

      fetchBitacora(
        mail,
        deviceWeb,
        fechaActualEnFormato,
        key,
        request,
        response,
        "Graph",
        VERSION_APP,
        employeeId,
        brandfiMarcaId,
        branchFiSucursalId,
        branchFiPaisId,
      )
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return <AppRouter />;
};