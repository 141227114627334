/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import "../theme/Administracion.css";
import { PutFetchAccesos } from "../Api/Administracion/PutFetchAccesos";
import { AddFetchAccesos } from "../Api/Administracion/AddFetchAccesos";
import { Row, Col,Select } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import '../theme/custom-select.css'; // Importa el archivo CSS personalizado

export const AdministrationAccesoAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  secciones,
  modalKey,
  roles,
  sucursales
}) => {
  const objDefault = {
    acs_key: "",
    acs_keysecciones: "",
    acs_plataforma: "",
    acs_rolaccesos: [],
    acs_sucursales: [],
    acs_tipoacceso: "",
    acs_visualizaciones: "",
    acs_fechafin: "",
    acs_fechaini: "",
    acs_distritoid: 0,
    acs_estatus: 1,
    acs_marcaid: "",
    acs_paisid: 1,
    acs_regionid: 0,
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

 
  useEffect(() => {
    const marcaidString = formData.acs_marcaid.toString();
    const marcaidConCero =
      marcaidString.length === 1 ? `0${marcaidString}` : marcaidString;
    const nuevoValorAcsKey = `0${formData.acs_paisid}${marcaidConCero}0${formData.acs_regionid}0${formData.acs_distritoid}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      acs_key: nuevoValorAcsKey,
    }));
  }, [
    formData.acs_paisid,
    formData.acs_marcaid,
    formData.acs_regionid,
    formData.acs_distritoid,
  ]);

  useEffect(() => {
    if (selectedItem) {
      const parts = selectedItem.FCROLACCESOS.split(',');
      const resultAccesos = [parts[0], ...parts.slice(1)];
      //console.log("selectedItem.FCROLACCESOS",resultAccesos);
      const partssucursales = selectedItem.FCSUCURSALES.split(',');
      const resultsucursales = [partssucursales[0], ...partssucursales.slice(1)];
      //console.log("selectedItem.FCSUCURSALES",resultsucursales)
      const newObj = {
        acs_key: selectedItem.FCKEY,
        acs_keysecciones: selectedItem.FCKEYSECCIONES,
        acs_plataforma: selectedItem.FCPLATAFORMA,
        acs_rolaccesos: resultAccesos,
        acs_sucursales: resultsucursales,
        acs_tipoacceso: selectedItem?.FCTIPOACCESO,
        acs_visualizaciones: selectedItem.FCVISUALIZACIONES,
        acs_fechafin: selectedItem.FDFECHAFIN,
        acs_fechaini: selectedItem.FDFECHAINI,
        acs_distritoid: selectedItem.FIDISTRITOID,
        acs_estatus: selectedItem.FIESTATUS,
        acs_marcaid: selectedItem.FIMARCAID,
        acs_paisid: selectedItem.FIPAISID,
        acs_regionid: selectedItem.FIREGIONID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        acs_key: "",
        acs_keysecciones: "",
        acs_plataforma: "",
        acs_rolaccesos: [],
        acs_sucursales: [],
        acs_tipoacceso: "",
        acs_visualizaciones: "",
        acs_fechafin: "",
        acs_fechaini: "",
        acs_distritoid: 0,
        acs_estatus: 1,
        acs_marcaid: "",
        acs_paisid: 1,
        acs_regionid: 0,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const options = [
    { label: "*", value: "*" },
    ...roles.map((option) => ({
      label: `${option.FIROLID}-${option.FCNOMBRE}`,
      value: String(option.FIROLID),
    })),
  ];
  const optionsSucursales = [
    { label: "*", value: "*" },
    ...sucursales.map((optionsucursal) => ({
      label: `${optionsucursal.FISUCURSALID}-${optionsucursal.FCNOMBRE}`,
      value: String(optionsucursal.FISUCURSALID),
    })),
  ];

  const handleChangeSelec = (selectedValues) => {
    //console.log("selectedValues",selectedValues)
    if (selectedValues.includes("*")) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        acs_rolaccesos: ["*"],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        acs_rolaccesos: selectedValues,
      }));
    }
  };
  const handleChangeSelectSucursales = (selectedValuessucursales) => {
    //console.log("selectedValuessucursales",selectedValuessucursales)
    if (selectedValuessucursales.includes("*")) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        acs_sucursales: ["*"],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        acs_sucursales: selectedValuessucursales,
      }));
    }
  };

  const fetchHandleUpdate = async () => {
    
     /*if (formData.acs_marcaid === "") {
        alert("Por favor, seleccione una marca.");
        return; // No hacer nada más si acs_marcaid es un espacio en blanco
      }*/
      const errors = {};
      if (formData.acs_keysecciones === "") {
        errors.acs_keysecciones = "Seleciones la sección";
      } 
      if (formData.acs_plataforma === "") {
        errors.acs_plataforma = "Ingresa valor de plataforma";
      }
      if (formData.acs_rolaccesos === "") {
        errors.acs_rolaccesos = "Ingresa el valor de rol";
      }
      if (formData.acs_sucursales === "") {
        errors.acs_sucursales = "Ingresa el valor de sucursal";
      }
      if (formData.acs_tipoacceso === "") {
        errors.acs_tipoacceso = "Ingresa el valor de tipo de acceso";
      }
      if (formData.acs_marcaid === "") {
        errors.acs_marcaid = "Por favor, seleccione una marca.";
      }
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors("");
        sendData();
      }
      function sendData(){
        setLoading(true)
      const onFailure = (error) => {
        setLoading(false)
        alert(error);
      };
      const onSuccess = ({ data }) => {
        setLoading(false)
        if (data.statusCode === 201) {
          setShowModal(false);
          setFormData(objDefault);
          fetchData();

          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      const formDataToSend = {
        ...formData,
        acs_distritoid: parseInt(formData.acs_distritoid),
        acs_marcaid: parseInt(formData.acs_marcaid),
        acs_paisid: parseInt(formData.acs_paisid),
        acs_estatus: formData.acs_estatus.toString(),
        acs_regionid: formData.acs_regionid.toString(),
        acs_rolaccesos:formData.acs_rolaccesos.join(','),
        acs_sucursales:formData.acs_sucursales.join(','),
        acs_visualizaciones: formData.acs_visualizaciones,
      };
      //console.log("formDataToSend",formDataToSend)
      if (selectedItem) {
        PutFetchAccesos(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      } else {
        AddFetchAccesos(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      }
      }
    
  };

  

  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Acceso
        </div>
      </Col>
      <Col xs={24} md={10}>
        <>
          <label className="text-context-inputs">Key:</label>
          <input
            type={"text"}
            name={"acs_key"}
            onChange={handleChange}
            value={formData.acs_key}
            disabled={true}
            className="input-content"
          />
        </>
      </Col>
      <Col xs={24} md={10}>
        <label className="text-context-inputs">Secciónes:</label>
        <select
          className="input-content"
          value={formData.acs_keysecciones}
          onChange={handleChange}
          name={"acs_keysecciones"}
          disabled={selectedItem ? true : false}
        >
          <option value="0">Selecionar sección</option>
          {secciones.map((option) => (
            <option key={option.FCKEY} value={option.FCKEY}>
              {option.FCKEY}/{option.FCSEGUNDASECCION}/{option.FCTERCERASECCION}
            </option>
          ))}
        </select>
        {errors && <div className="error-input-data">{errors.acs_keysecciones}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.acs_plataforma}
          name={"acs_plataforma"}
          type={"text"}
          label={"Plataformas"}
        />
         {errors && <div className="error-input-data">{errors.acs_plataforma}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.acs_tipoacceso}
          name={"acs_tipoacceso"}
          type={"text"}
          label={"Tipo de acceso"}
        />
         {errors && <div className="error-input-data">{errors.acs_tipoacceso}</div>}
      </Col>
      <Col xs={24} md={22}>
           <label className="text-context-inputs">Roles:</label>
           <Select
              name="acs_rolaccesos"
              mode="tags"
              allowClear
              onChange={handleChangeSelec}
              tokenSeparators={[","]}
              options={options}
              value={formData.acs_rolaccesos}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 || String(option.value).indexOf(input) >= 0
              }
            />
         {errors && <div className="error-input-data">{errors.acs_rolaccesos}</div>}
      </Col>
      <Col xs={24} md={22}>
        <label className="text-context-inputs">Sucursales:</label>
           <Select
              name="acs_sucursales"
              mode="tags"
              allowClear
              onChange={handleChangeSelectSucursales}
              tokenSeparators={[","]}
              options={optionsSucursales}
              value={formData.acs_sucursales}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 || String(option.value).indexOf(input) >= 0
              }
            />
        {errors && <div className="error-input-data">{errors.acs_sucursales}</div>}
      </Col>
      
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.acs_visualizaciones}
          name={"acs_visualizaciones"}
          type={"text"}
          label={"Visualizaciones"}
        />
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.acs_fechaini}
          name={"acs_fechaini"}
          type={"text"}
          label={"Fecha inicio"}
        />
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.acs_fechafin}
          name={"acs_fechafin"}
          type={"text"}
          label={"Fecha fin"}
        />
      </Col>

      <Col xs={24} md={10}>
        <InputSelect
          value={formData.acs_marcaid}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"acs_marcaid"}
        />
        {errors && <div className="error-input-data">{errors.acs_marcaid}</div>}
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
