import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchReclasificacionAdd(
  branchFiPaisId,
  brandfiMarcaId,
  branchFiSucursalId,
  brandfcNombre,
  Vtotal,
  depositoTombola,
  efectivoADepositar,
  nombre,
  fecha,
  deviceinfo,
  propina,
  porpropina,
  imgCierre,
  imgDeposito,
  imgValores,
  noTicket,
  latitude,
  longitude,
  comentario,
  amex,
  tpv,
  pinpad,
  vales,
  valescdmx,
  delivery,
  didi,
  didicredito,
  rappi,
  rapicargo,
  uber,
  uberdirect,
  mercadopago,
  wow,
  mop,
  startbucksRewords,
  scanypay,
  bkapp,
  eventosWalmart,
  eventosempresariales,
  efectivomxp,
  efectivouds,
  didiEfectivo,
  uberEfectivo,
  rappiEfectivo,
  anticipoOtrosCreditos,
  otrosCreditos,
  deliverySr,
  tokenProd
) {
  const axiosInstance = await createAxiosInstance();
  const requestConfig = {
    method: "POST", // Cambia el método si es necesario
    url: `/reclasificacion`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenProd}`, //the token is a variable which holds the token
    },
    data: {
      code: 1,
      params: {
        pais: branchFiPaisId,
        marca: brandfiMarcaId,
        sucursal: branchFiSucursalId,
        nombreTienda: brandfcNombre,
        ventaTotal: Vtotal,
        depositoTombola: depositoTombola,
        efectivoADepositar: efectivoADepositar,
        nombreGerente: nombre,
        fechaRegistro: fecha,
        fechaModificacion: "00/00/0000 00:00:00",
        estatus: 1,
        descEstatus: "REGISTRO",
        dispositivo: deviceinfo,
        propina: propina,
        porcentajePropina: porpropina,
        Cierre_Lote: imgCierre,
        Ficha_Deposito: imgDeposito,
        Ficha_Entrega_Valores: imgValores,
        recoleccion: "0",
        noTicket: noTicket,
        latitud: latitude,
        longitud: longitude,
        comentarios: comentario,
        formaPagoReclasificacion: [
          {
            formaPago: 1,
            descFormaPago: "AMEX",
            cantidad: amex,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 2,
            descFormaPago: "TPV",
            cantidad: tpv,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 3,
            descFormaPago: "PINPAD",
            cantidad: pinpad,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 4,
            descFormaPago: "VALES",
            cantidad: vales,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 5,
            descFormaPago: "VALESCDMX",
            cantidad: valescdmx,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 6,
            descFormaPago: "DELIVERYALSEA",
            cantidad: delivery,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 7,
            descFormaPago: "DIDI",
            cantidad: didi,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 8,
            descFormaPago: "DIDICREDITOS",
            cantidad: didicredito,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 9,
            descFormaPago: "RAPPI",
            cantidad: rappi,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 10,
            descFormaPago: "RAPPICARG0",
            cantidad: rapicargo,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 11,
            descFormaPago: "UBER",
            cantidad: uber,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 12,
            descFormaPago: "UBERDIRECT",
            cantidad: uberdirect,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 13,
            descFormaPago: "MERCADOPAGO",
            cantidad: mercadopago,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 14,
            descFormaPago: "WOW",
            cantidad: wow,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 15,
            descFormaPago: "MOP",
            cantidad: mop,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 16,
            descFormaPago: "STARBUCKSREWARDS",
            cantidad: startbucksRewords,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 17,
            descFormaPago: "SCAN&PAY",
            cantidad: scanypay,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 18,
            descFormaPago: "BKAPP",
            cantidad: bkapp,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 19,
            descFormaPago: "EVENTOSWALMART",
            cantidad: eventosWalmart,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 20,
            descFormaPago: "EVENTOSEMPRESARIALES",
            cantidad: eventosempresariales,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 21,
            descFormaPago: "EFECTIVOMXP",
            cantidad: efectivomxp,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 22,
            descFormaPago: "EFECTIVOUSD",
            cantidad: efectivouds,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 23,
            descFormaPago: "DIDIEFECTIVO",
            cantidad: didiEfectivo,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 24,
            descFormaPago: "UBEREFECTIVO",
            cantidad: uberEfectivo,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 25,
            descFormaPago: "RAPPIEFECTIVO",
            cantidad: rappiEfectivo,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 26,
            descFormaPago: "ANTICIPO/OTROSCREDITOS",
            cantidad: anticipoOtrosCreditos,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 27,
            descFormaPago: "OTROSCREDITOS",
            cantidad: otrosCreditos,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
          {
            formaPago: 28,
            descFormaPago: "DELIVERYSR",
            cantidad: deliverySr,
            fechaRegistro: fecha,
            fechaModificacion: "00/00/0000 00:00:00",
          },
        ],
        detallevidencia: "",
      },
    },
  };

  return axiosInstance(requestConfig);
}
