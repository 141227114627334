import React, { useState, useEffect } from "react";
import OrderIcon from "../assets/2.png";
import "../theme/Indicadores.css";
import numbro from "numbro";

export const TableOrders = (props) => {
  const { ordersData } = props;
  const [ventaReal, setventaReal] = useState();
  const [ventaPtto, setventaPtto] = useState();
  const [ventaAA, setventaAA] = useState();
  const [ventaAAAvg, setventaAAAvg] = useState();
  const [ventaPttoAvg, setventaPttoAvg] = useState();

  useEffect(() => {
    const nReal =
      ordersData.fnordenes_real == null || ordersData.fnordenes_real === 0
        ? 0
        : Math.round(ordersData.fnordenes_real);
    const VReal = numbro(nReal).format({ thousandSeparated: true });
    setventaReal(VReal);
    const nppto =
      ordersData.fnordenes_ppto == null || ordersData.fnordenes_ppto === 0
        ? 0
        : Math.round(ordersData.fnordenes_ppto);
    const Vppto = numbro(nppto).format({ thousandSeparated: true });
    setventaPtto(Vppto);
    const nRaa =
      ordersData.fnordenes_real_aa == null || ordersData.fnordenes_real_aa === 0
        ? 0
        : Math.round(ordersData.fnordenes_real_aa);
    const VRaa = numbro(nRaa).format({ thousandSeparated: true });
    setventaAA(VRaa);

    //average
    const ptto =
      ordersData.fnordenes_ppto === null || ordersData.fnordenes_ppto === 0
        ? 0
        : (ordersData.fnordenes_real * 100) /
          Math.round(ordersData.fnordenes_ppto);

    setventaPttoAvg(Math.round(ptto));
    const aa =
      ordersData.fnordenes_real_aa === null ||
      ordersData.fnordenes_real_aa === 0
        ? 0
        : (ordersData.fnordenes_real * 100) / ordersData.fnordenes_real_aa;
    setventaAAAvg(Math.round(aa));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-table-sale-right">
      <div className="box-top-table-sale-right">
        <div className="box-sale-icon-right">
          <img className="IconImage" src={OrderIcon} alt="Ventas" />
          <div className="title-top-table-indicadores">
            Transaciones /Ordenes
          </div>
        </div>
        <div className="title-top-table-indicadores-real">Real</div>
        <div className="text-tota-real">{ventaReal} </div>
      </div>
      <div className="box-body-table-sale-right">
        <div className="item-box-table-sales1-right">
          <div className="text-vs">Vs PPTO</div>
          <div className="text-porcent">{ventaPttoAvg}%</div>
          <div className="text-presupues">{ventaPtto}</div>
        </div>
        <div className="item-box-table-sales3-right">
          <div className="text-vs">Vs AA</div>
          <div className="text-porcent"> {ventaAAAvg}%</div>
          <div className="text-presupues">{ventaAA}</div>
        </div>
      </div>
    </div>
  );
};
