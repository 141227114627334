/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import EditIcon from "@mui/icons-material/Edit";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  Button,
  Row,
  Col,
  Image,
  Input,
  Space,
  Modal,
  Switch,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AdministrationSeccionesAddForm from "./AdministrationSeccionesAddForm";
import { GetFetchSecciones } from "../Api/Administracion/GetFetchSecciones";
import { PutFetchSeccion } from "../Api/Administracion/PutFetchSeccion";

export const TableAdministracionSecciones = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

  
      const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false);
      };

      const onSuccess = ({ data }) => {
        //console.log("secion", data);
        setData(data.body);
        setIsLoading(false);
      };
      GetFetchSecciones().then(onSuccess).catch(onFailure);
    
  };

  const handleOpenModal = (dato) => {
    setSelectedItem(dato);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setShowModal(true);
    setModalKey(modalKey + 1);  // Cambiar el valor de modalKey cada vez que se abre el modal
  };

  //table--->>
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /*const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };*/

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          {/*           <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const HandleSwitch = ({ value, selectedItem }) => {
    const [hs, setHs] = useState(parseInt(value) === 1);
    const handleChangeSwitch = (v) => {
      setIsLoading(true)
      //console.log("selectedItem", selectedItem);
      const newObj = {
        sec_key: selectedItem.FCKEY,
        sec_jsonseccion2: selectedItem.FCJSONSECCION2,
        sec_jsonseccion3: selectedItem.FCJSONSECCION3,
        sec_linkseccion1: selectedItem.FCLINKSECCION1,
        sec_linkseccion2: selectedItem.FCLINKSECCION2,
        sec_linkseccion3: selectedItem.FCLINKSECCION3,
        sec_primeraseccion: selectedItem.FCPRIMERASECCION,
        sec_segundaseccion: selectedItem.FCSEGUNDASECCION,
        sec_stackseccion2: selectedItem.FCSTACKSECCION2,
        sec_stackseccion3: selectedItem.FCSTACKSECCION3,
        sec_terceraseccion: selectedItem.FCTERCERASECCION,
        sec_tiposeccion2: selectedItem.FCTIPOSECCION2,
        sec_urlimagen1: selectedItem.FCURLIMAGEN1,
        sec_urlimagen2: selectedItem.FCURLIMAGEN2,
        sec_urlimagen3: selectedItem.FCURLIMAGEN3,
        sec_versionminimaseccion2: selectedItem.FCVERSIONMINIMASECCION2,
        sec_versionminimaseccion3: selectedItem.FCVERSIONMINIMASECCION3,
        sec_estatus: v ? "1" : "0",
        sec_ordenseccion2: selectedItem.FIORDENSECCION2,
        sec_ordenseccion3: selectedItem.FIORDENSECCION3,
        sec_primeraseccionid: selectedItem.FIPRIMERASECCIONID,
        sec_segundaseccionid: selectedItem.FISEGUNDASECCIONID,
        sec_terceraseccionid: selectedItem.FITERCERASECCION,
      };

     //console.log("all", selectedItem);
      //peticion....
      const onFailure = (error) => {
        setIsLoading(false)
        //console.log("error", error);
        alert(error);
      };
      const onSuccess = ({ data }) => {
        setIsLoading(false)
       // console.log("state", data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          fetchData();
          //alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };
      //console.log("Nuevo objeto a enviar", newObj);
      PutFetchSeccion(newObj).then(onSuccess).catch(onFailure);

      setHs(v);
    };
    return <Switch checked={hs} value={hs} onChange={handleChangeSwitch} />;
  };

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      title: "ID Sección",
      dataIndex: "FCKEY",
      key: "FCKEY",
      sorter: (a, b) => parseInt(a.FCKEY) - parseInt(b.FCKEY),
      ...getColumnSearchProps("FCKEY"),
    },
    {
      title: "Sección 2",
      dataIndex: "FCSEGUNDASECCION",
      key: "FCSEGUNDASECCION",
      ...getColumnSearchProps("FCSEGUNDASECCION"),
    },
    {
      title: "Sección 3",
      dataIndex: "FCTERCERASECCION",
      key: "FCTERCERASECCION",
    },
    {
      title: "Función 2",
      dataIndex: "FCJSONSECCION2",
      key: "FCJSONSECCION2",
    },
    {
      title: "Función 3",
      dataIndex: "FCJSONSECCION3",
      key: "FCJSONSECCION3",
    },
    {
      title: "Imagen 2",
      dataIndex: "FCURLIMAGEN2",
      key: "FCURLIMAGEN2",
      render: (item) => <Image src={item} width={50} />,
    },
    {
      title: "Imagen 3",
      dataIndex: "FCURLIMAGEN3",
      key: "FCURLIMAGEN3",
      render: (item) => <Image src={item} width={50} />,
    },
    {
      title: "Orden Sec 2",
      dataIndex: "FIORDENSECCION2",
      key: "FIORDENSECCION2",
    },
    {
      title: "Orden Sec 3",
      dataIndex: "FIORDENSECCION3",
      key: "FIORDENSECCION3",
    },
    {
      title: "ID Sec 1",
      dataIndex: "FIPRIMERASECCIONID",
      key: "FIPRIMERASECCIONID",
    },
    {
      title: "ID Sec 2",
      dataIndex: "FISEGUNDASECCIONID",
      key: "FISEGUNDASECCIONID",
    },
    {
      title: "ID Sec 3",
      dataIndex: "FITERCERASECCION",
      key: "FITERCERASECCION",
    },
    {
      title: "Json Sec 2",
      dataIndex: "FCJSONSECCION2",
      key: "FCJSONSECCION2",
    },
    {
      title: "Json Sec 3",
      dataIndex: "FCJSONSECCION3",
      key: "FCJSONSECCION3",
    },
    {
      title: "Stack Sec 2",
      dataIndex: "FCSTACKSECCION2",
      key: "FCSTACKSECCION2",
    },
    {
      title: "Stack Sec 3",
      dataIndex: "FCSTACKSECCION3",
      key: "FCSTACKSECCION3",
    },
    {
      title: "Link Sec 1",
      dataIndex: "FCLINKSECCION1",
      key: "FCLINKSECCION1",
    },
    {
      title: "Link Sec 2",
      dataIndex: "FCLINKSECCION2",
      key: "FCLINKSECCION2",
    },
    {
      title: "Link Sec 3",
      dataIndex: "FCLINKSECCION3",
      key: "FCLINKSECCION3",
    },
    {
      title: "Url Imagen 1",
      dataIndex: "FCURLIMAGEN1",
      key: "FCURLIMAGEN1",
    },
    {
      title: "Url Imagen 2",
      dataIndex: "FCURLIMAGEN2",
      key: "FCURLIMAGEN2",
    },
    {
      title: "Url Imagen 3",
      dataIndex: "FCURLIMAGEN3",
      key: "FCURLIMAGEN3",
    },
    {
      title: "Versión Sec 2",
      dataIndex: "FCVERSIONMINIMASECCION2",
      key: "FCVERSIONMINIMASECCION2",
    },
    {
      title: "Versión Sec 3",
      dataIndex: "FCVERSIONMINIMASECCION3",
      key: "FCVERSIONMINIMASECCION3",
    },
    {
      title: "Estatus",
      dataIndex: "FIESTATUS",
      key: "FIESTATUS",
      render: (item, selectedItem) => (
        <HandleSwitch value={item} selectedItem={selectedItem} />
      ),
      filters: [
        {
          text: "Activo",
          value: "1",
        },
        {
          text: "Inactivo",
          value: "0",
        },
      ],
      defaultFilteredValue: ["1"], // Aquí estableces que los elementos activos estén seleccionados por defecto
      onFilter: (value, record) => record.FIESTATUS === value,
    },
    {
      title: "Editar",
      render: (item) => (
        <button
          onClick={() => handleOpenModal(item)}
          className="button-styles-detalais-catalogo"
        >
          <EditIcon style={{ fontSize: 20 }} />
        </button>
      ),
      fixed: "right",
      width: "3%",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col xs={11} md={18} className="mt-3" al></Col>
        <Col xs={11} md={4} className="mt-3" al>
          <button onClick={handleAdd} className="button-styles-add-element">
            Agregar Sección
          </button>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22} className="mt-3">
          <Table
            dataSource={data}
            rowKey={"FCKEY"}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 3600, y: 600 }}
          />
        </Col>
      </Row>

      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <AdministrationSeccionesAddForm
          selectedItem={selectedItem}
          fetchData={fetchData}
          setShowModal={setShowModal}
          modalKey={modalKey}
        />
      </Modal>
    </>
  );
};
