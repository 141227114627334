import createAxiosInstance from "./LambdaApiCierreDia";

export async function fetchFormasPagoEdith(body) {
  const axiosInstance = await createAxiosInstance();

  const requestConfig = {
    method: "PUT", // Cambia el método si es necesario
    url: "/reclasificacion/available-fields",
    headers: {
      "Content-Type": "application/json",
    },
    data: body
  };
  return axiosInstance(requestConfig);
}
