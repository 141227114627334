import createAxiosInstance from "./LambdaApiAdministracion";
import { ENVIROMENT } from "../utilities"

export async function fetchSecciones(
  country,
  brand,
  jobTitle,
  branch,
  version,
  rolId
) {
  const axiosInstance = await createAxiosInstance();
  const url =  ENVIROMENT === "dev" ? `/admon/secciones?country=${country}&brand=${brand}&region=00&district=00&access_rol=${jobTitle}&branch=${branch}&origin=web&version=${version}&rol_id=${rolId}`: 
  `/home/admon/secciones?country=${country}&brand=${brand}&region=00&district=00&access_rol=${jobTitle}&branch=${branch}&origin=web&version=${version}&rol_id=${rolId}`
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: url,
  };

  return axiosInstance(requestConfig);
}

