import axios from "axios";

export const axiosInstance = axios.create();
// Agregar un interceptor para registrar las solicitudes
axiosInstance.interceptors.request.use(
  async (configGraph) => {
    // Registra el request antes de enviarlo
    // console.log("Request completo de graph:", configGraph);
    try {
      const configJSON = JSON.stringify(configGraph); // Convertir el objeto en una cadena JSON
      localStorage.setItem("Request-fetchGraph", configJSON);
    } catch (error) {
      console.error("Error al guardar la solicitud en AsyncStorage:", error);
    }
    return configGraph;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function fetchGraph(accessToken) {
  const requestConfig = {
    method: "GET", // Cambia el método si es necesario
    url: "https://graph.microsoft.com/v1.0/me?$select=employeeID,displayName,givenName,jobTitle,mail,surname,companyName,onPremisesExtensionAttributes,lastPasswordChangeDateTime,accountEnabled",
    timeout: 20000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axiosInstance(requestConfig);
}
