import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { LoginPage } from "../pages/LoginPage";
import { AvisoPrivacidad } from "../pages/AvisoPrivacidad";
import { PlatformRedirect } from "../pages/PlatformRedirect";

export const UnAuthenticatedRouter = () => {
  return (
    <Routes>
      <Route exact path="/Login" element={<LoginPage />} />
      <Route
        exact
        path="/Politicas_de_privacidad"
        element={<AvisoPrivacidad />}
      />

      <Route exact path="/descargacog" element={<PlatformRedirect />} />
      <Route exact path="/*" element={<Navigate to="/Login" />} />
    </Routes>
  );
};

