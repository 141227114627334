import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { AddFetchComunicados } from "../Api/Administracion/AddFetchComunicados";
import { PutFetchComunicados } from "../Api/Administracion/PutFetchComunicados";
import { GetFetchRolByMarca} from "../Api/Administracion/GetFetchRolByMarca"

export const AdministrationComunicadosAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  rol,
  modalKey
}) => {
  const objDefault = {
    FIMARCAID: "",
    FCLINKEXTERNO: "",
    FIROLID: "",
    FCTITULO: "",
    FICOMUNICADOID: 0,
    FCDESCRIPCION: "",
    FIESTATUSID: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [dataRoles, setdataRoles] = useState("");
  const [isLoadingRol, setIsLoadingRol] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIMARCAID: selectedItem.FIMARCAID,
        FCLINKEXTERNO: selectedItem.FCLINKEXTERNO,
        FIROLID: selectedItem.FIROLID,
        FCTITULO: selectedItem.FCTITULO,
        FICOMUNICADOID: selectedItem.FICOMUNICADOID,
        FCDESCRIPCION: selectedItem.FCDESCRIPCION,
        FIESTATUSID: selectedItem.FIESTATUSID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIMARCAID: 0,
        FCLINKEXTERNO: "",
        FIROLID: "",
        FCTITULO: "",
        FICOMUNICADOID: 0,
        FCDESCRIPCION: "",
        FIESTATUSID: 1,
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCTITULO" && value.length > 50) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCTITULO: "El título no debe exceder 50 caracteres.",
      }));
      return;
     }
    if (name === "FCDESCRIPCION" && value.length > 300) {
      setErrors((prevFormData) => ({
        ...prevFormData,
        FCDESCRIPCION: "La descripción no debe exceder 300 caracteres.",
      }));
      return;
    }
    const parsedValue =
      name === "FIMARCAID" || name === "FITIPOBANNERID" || name === "FIBANNERID"
        ? parseInt(value, 10)
        : value;
    setFormData({ ...formData, [name]: parsedValue });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCTITULO === "") {
      errors.FCTITULO = "Ingresa el valor de título";
    } 
    if (formData.FIMARCAID === "") {
      errors.FIMARCAID = "Ingresa el valor de marca";
    }
    if (formData.FIROLID === "") {
      errors.FIROLID = "Ingresa el valor de rol";
    }
    const contieneHTTP = formData.FCLINKEXTERNO.includes("http://") || formData.FCLINKEXTERNO.includes("https://");
    if(formData.FCLINKEXTERNO!==""){
      if(!contieneHTTP){
        errors.FCLINKEXTERNO = "Ingresa una URL válida que comience con http:// o https://.";
      }
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    } 
    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };
      const onSuccess = ({ data }) => {
        setLoading(false)
        // console.log(data);
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      if (selectedItem) {
        PutFetchComunicados(formData)
          .then(onSuccess)
          .catch(onFailure);
      } else {
        AddFetchComunicados(formData)
          .then(onSuccess)
          .catch(onFailure);
      }
    }
    
  };
  useEffect(() => {
    fetchDataRol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.FIMARCAID]);

  const fetchDataRol = async () => {
    if (formData.FIMARCAID !== 0) {
      setIsLoadingRol(true);
      const onFailure = (error) => {
        setIsLoadingRol(false);
      };
      const onSuccess = ({ data }) => {
        //console.log("obtener roles",data.body.data);
        setdataRoles(data.body.data);
        setIsLoadingRol(false);
      };

      GetFetchRolByMarca(formData.FIMARCAID).then(onSuccess).catch(onFailure);
    }
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Comunicados
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCTITULO}
          name={"FCTITULO"}
          type={"text"}
          label={"Titulo"}
        />
        {errors && <div className="error-input-data">{errors.FCTITULO}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
        {errors && <div className="error-input-data">{errors.FIMARCAID}</div>}
      </Col>
      <Col xs={24} md={10}>
        {formData.FIMARCAID !== "" && (
          <>
            {isLoadingRol ? (
              <LoadingSpinner />
            ) : (
              <>
                <label className="text-context-inputs">Roles:</label>
                <select
                  className="input-content"
                  value={formData.FIROLID}
                  onChange={handleChange}
                  name={"FIROLID"}
                  //disabled={selectedItem ? true : false}
                >
                  <option value="0">Selecionar Rol</option>
                  {dataRoles.map((option) => (
                    <option key={option.FIROLID} value={option.FIROLID}>
                      {option.FCNOMBRE}
                    </option>
                  ))}
                </select>
                {errors && <div className="error-input-data">{errors.FIROLID}</div>}
              </>
            )}
          </>
        )}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCLINKEXTERNO}
          name={"FCLINKEXTERNO"}
          type={"text"}
          label={"Enlace"}
        />
        {errors && <div className="error-input-data">{errors.FCLINKEXTERNO}</div>}
      </Col>

      <Col xs={24} md={22}>
        <label className="text-context-inputs">Descripción:</label>
          <textarea
            type="text"
            name="FCDESCRIPCION"
            onChange={handleChange}
            value={formData.FCDESCRIPCION}
            className="input-content"
          ></textarea>
          {errors && <div className="error-input-data">{errors.FCDESCRIPCION}</div>}
      </Col>


      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
