import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { InputSelect } from "./utils/InputSelect";
import { UseTokenGlobal } from "../hooks/UseTokenGlobal";
import { AddFetchRol } from "../Api/Administracion/AddFetchRol";
import { PutFetchRol } from "../Api/Administracion/PutFetchRol";

export const AdministrationRolAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  modalKey
}) => {
  const objDefault = {
    FIROLID: 0,
    FCNOMBRE: "",
    FIESTATUSID: 1,
    FCEQUIVALENCIA: "",
    FIMARCAID: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem) {
      const newObj = {
        FIROLID: selectedItem.FIROLID,
        FCNOMBRE: selectedItem.FCNOMBRE,
        FIESTATUSID: selectedItem.FIESTATUSID,
        FCEQUIVALENCIA: selectedItem.FCEQUIVALENCIA,
        FIMARCAID: selectedItem?.FIMARCAID,
      };
      setFormData(newObj);
    } else {
      const objDefault = {
        FIROLID: 0,
        FCNOMBRE: "",
        FIESTATUSID: 1,
        FCEQUIVALENCIA: "",
        FIMARCAID: "",
      };
      setFormData(objDefault);
    }
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "FCNOMBRE" && value.length > 30) {
      setErrors((prevFormData) => ({
          ...prevFormData,
          FCNOMBRE: "El nombre no debe exceder 30 caracteres.",
      }));
      return;
     }
    setFormData({ ...formData, [name]: value });
  };

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };
  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCNOMBRE === "") {
      errors.FCNOMBRE = "Ingresa el valor de nombre";
    } 
    if (formData.FCEQUIVALENCIA == "") {
      errors.FCEQUIVALENCIA = "Ingresa el valor de equivalencia";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }

    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error", error);
        setLoading(false)
        alert(error);
      };

      const onSuccess = ({ data }) => {
        //console.log(data);
        setLoading(false)
        // eslint-disable-next-line no-lone-blocks
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envio exitoso");
        } else {
          alert(data.error);
        }
      };

      if (selectedItem) {
        PutFetchRol(formData).then(onSuccess).catch(onFailure);
      } else {
        AddFetchRol(formData).then(onSuccess).catch(onFailure);
      }
    }
      
    
  };
  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Rol
        </div>
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBRE}
          name={"FCNOMBRE"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCNOMBRE}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCEQUIVALENCIA}
          name={"FCEQUIVALENCIA"}
          type={"text"}
          label={"Equivalencia"}
        />
        {errors && <div className="error-input-data">{errors.FCEQUIVALENCIA}</div>}
      </Col>
      <Col xs={24} md={22}>
        <InputSelect
          value={formData.FIMARCAID}
          handleChange={handleChange}
          options={marca}
          label="Marca"
          optionsKey={"FIMARCAID"}
          optionsName={"FCNOMBRE"}
          //disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
          name={"FIMARCAID"}
        />
      </Col>

      <div className="modal-footer-edith">
       <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
