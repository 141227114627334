import React, { useState,useEffect } from 'react';
import { fetchUploadUrlAtb} from "../Api/fetchUploadUrlATB"
import { UseAdmin } from "../hooks/index"

export const  ModalSelectImageAtb=({tipoImage, setModalIsOpen,onImageUpload,selectedItem})=> {
  //console.log("tipoImage",tipoImage)
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastFileName, setLastFileName] = useState(null);
  const {brandfiMarcaId,branchFiSucursalId,branchFiPaisId} = UseAdmin()

  useEffect(() => {
    setSelectedFile(null)
    setPreviewUrl(null)
    setLastFileName(null)
  }, [selectedItem,tipoImage, setModalIsOpen,onImageUpload])
  

  const handleFileChange = (event) => {
    //console.log("event",event)
    const file = event.target.files[0];
    //console.log("entrao a file",file)
    if (!file) return;

    // Validar si el archivo tiene el mismo nombre que el previamente seleccionado
    if (file.name === lastFileName) {
      alert('Ya has seleccionado este archivo anteriormente. Por favor, elige una imagen diferente.');
      event.target.value = ''; // Restablecer el valor del input
      return;
    }
    // Validar el tipo de archivo
    if (!file.type.startsWith('image/jpeg')) {
      alert('Por favor selecciona un archivo JPEG.');
      event.target.value = '';
      return;
    }
    // Validar el tamaño del archivo (menor a 1 MB)
    if (file.size > 1024 * 1024) {
      alert('La imagen seleccionada debe tener menos de 1 MB.');
      event.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      //console.log("base64String",base64String)
      const base64Data = base64String.replace(/^data:image\/jpeg;base64,/, "");
      //console.log("base64Data",base64Data)
      setSelectedFile(base64Data);
      setPreviewUrl(base64String);
      setLastFileName(file.name);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    event.target.value = ''; // Restablecer el valor del input para permitir seleccionar el mismo archivo nuevamente
  };

  const handleUpload = async () => {
    if (tipoImage.FCNOSERIE=="") {
      alert('Por favor coloca un numero de serie antes de tomas la fotografia');
      return;
    }
    setIsLoading(true)
    const onFailure = (error) => {
        //console.log(error);
        setIsLoading(false)
    };
    const onSuccess = ({ data }) => {
        //console.log("data-url",data.FCURLFOTOGRAFICA)
        setSelectedFile(null);
        setPreviewUrl(null);
        setModalIsOpen(false);
        setIsLoading(false)
        onImageUpload(data.FCURLFOTOGRAFICA);
    }
    const objetSentData = {
      FCNOSERIE: tipoImage.FCNOSERIE ,  
      FIANIOID: tipoImage.FIANIOID,   
      FITRIMESTREID: tipoImage.FITRIMESTREID,
      FIPAISID: branchFiPaisId,   
      FIMARCAID: brandfiMarcaId,   
      FISUCURSALID: branchFiSucursalId,   
      FITIPOFOTOID: tipoImage.FITIPOFOTOID,
      FCIMAGEN: selectedFile
    }
    fetchUploadUrlAtb(objetSentData).then(onSuccess).catch(onFailure);
  };

  const fetchHandleUpdateCancel = () => {
    setSelectedFile(null)
    setPreviewUrl(null)
    setModalIsOpen(false);
  };

  return (
    <div className="conteiner-modal-body-evidencias">
          <div>
              <>
                <div>
                  La imagen seleccionada debe estar en formato JPG y tener un
                  tamaño inferior a 1 MB
                </div>
                <label
                  htmlFor="image-input"
                  style={{
                    background: "#41b6e6", // Color de fondo
                    color: "#fff", // Color del texto
                    padding: "10px 15px", // Espaciado interno
                    borderRadius: "5px", // Borde redondeado
                    cursor: "pointer", // Cursor al pasar sobre el botón
                  }}
                >
                  Seleccionar Imagen
                </label>
                <input
                  type="file"
                  id="image-input" // Añade un ID al input
                  onChange={handleFileChange}
                  accept=".jpg, .jpeg"
                  capture="user"
                  style={{
                    display: "none", // Oculta el input
                  }}
                />
              </>
    

            {previewUrl && (
              <>
                <div>
                  <img
                    src={previewUrl}
                    alt="Selected Image"
                    className="image-pick-recolecion"
                  />
                </div>
                <div>
                  {!isLoading &&  <button className={`${
                  isLoading ? "file-button-disable" : "file-button-cancel"
                  }`}
                  onClick={fetchHandleUpdateCancel}
                  disabled={isLoading}
                  >
                    Cancelar
                    </button>}

                  <button className={`${
                  isLoading ? "file-button-disable" : "file-button-save"
                  }`}onClick={handleUpload } 
                  disabled={isLoading}
                  >
                 
                  {isLoading ? "Guardando" : "Guardar"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        
  )
}
